import React from 'react';

import { Alert } from '@mui/material';
import { green } from '@mui/material/colors';
import { DataGridPremium, GridColDef, GridToolbar, useGridApiRef } from '@mui/x-data-grid-premium';

interface Props {
  fetchReqState: {
    status: number | string;
    error?: Error;
    result?: App.Bedbank.AdminRatesForProperty;
  };
}

const columns = [
  { field: 'supplier', minWidth: 160 },
  { field: 'id', minWidth: 160 },
  { field: 'isFlightBundle', minWidth: 160, filterable: true },
  { field: 'roomCode' },
  { field: 'description', minWidth: 260 },
  { field: 'boardCode', filterable: true },
  { field: 'cancellationPolicies', minWidth: 170 },
  { field: 'cost' },
  { field: 'marketingFee', minWidth: 120 },
  { field: 'margin' },
  { field: 'taxesAndFees', minWidth: 170 },
  { field: 'propertyFees', minWidth: 170 },
  { field: 'sellingPrice', minWidth: 170 },
  { field: 'package', filterable: true },
  { field: 'isBestRate', filterable: true },
  { field: 'currencyCode', minWidth: 160 },
];

const updatedColumns = columns.map((column) => ({
  ...column,
  display: 'flex' as const,
  minWidth: column.minWidth || 100,
  flex: 1,
  filterable: column.filterable || false,
}));

const ratingColDef: GridColDef = {
  field: 'name',
  headerName: 'name',
  type: 'string',
  renderCell: (params) => {
    if (params.rowNode.type === 'group' && params.field === params.rowNode.groupingField) {
      return '';
    }
    return <b>{params.value}</b>;
  },
};

const ratingColDefGroupId: GridColDef = {
  field: 'groupId',
  headerName: 'groupId',
  type: 'string',
  renderCell: (params) => {
    if (params.rowNode.type === 'group' && params.field === params.rowNode.groupingField) {
      return '';
    }
    return <b>{params.value}</b>;
  },
};

const groupingColDef = {
  minWidth: 250,
  hideDescendantCount: true,
};

function RateInfoResultsTable({ fetchReqState }: Props) {
  if (!fetchReqState) {
    return null;
  }
  const apiRef = useGridApiRef();
  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      <DataGridPremium
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              name: false,
              description: false,
              roomCode: false,
              groupId: false,
              boardCode: false,
              cancellationPolicies: false,
              currencyCode: false,
              isBestRate: false,
            },
          },
          rowGrouping: {
            model: ['name', 'groupId'],
          },
        }}
        className="rate-info-results-table"
        columns={[...updatedColumns, ratingColDef, ratingColDefGroupId]}
        getRowClassName={(params) => (params.row.isBestRate ? 'Mui-br' : 'Mui-nonbr')}
        defaultGroupingExpansionDepth={-1}
        sx={{
          '.Mui-br': {
            bgcolor: green[100],
          },
        }}
        apiRef={apiRef}
        groupingColDef={{
          ...groupingColDef,
        }}
        rows={fetchReqState.status === 200 ? (fetchReqState.result as any).rooms : []}
        getRowHeight={() => 'auto'}
        slots={{ toolbar: GridToolbar }}
        loading={fetchReqState.status === 'pending'}
      />
    </>
  );
}

export default RateInfoResultsTable;
