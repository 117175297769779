import React, { useCallback, useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

import { Autocomplete, Chip, FormControl, Stack, TextField } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Spinner from '~/components/Common/Spinner';
import { Option, Request } from '~/components/Cruises/pages/Promotions/types';

import rateCodeService from '~/services/cruises/RateCodeService';

import { isNumeric } from '~/utils/stringUtils';

const RATE_CODE_LIMIT = 500;

interface Props {
  selectedRateIds: Array<string>;
  onChange: (selectedRateIds: Array<string>) => void;
  vendorCode: string;
  isLoading?: boolean;
}

function InputRateCodeSelect({ selectedRateIds, isLoading, onChange, vendorCode }: Props) {
  const [loadedVendorCode, setLoadedVendorCode] = useState<string | null>(null);
  const [selectedRates, setSelectedRates] = useState<Array<API.RateCodeListResponse>>([]);
  const [cruiseRateCodes, setCruiseRateCodes] = useState<Request<Array<API.RateCodeListResponse>>>({
    result: [],
    loading: false,
  });

  const fetchRateCodes = useCallback(
    async (rateCode?: string) => {
      const isOnlyNumber = isNumeric(rateCode);
      if (!rateCode || !vendorCode || isOnlyNumber) {
        return;
      }

      setCruiseRateCodes((prev) => ({ ...prev, loading: true }));
      const res = await rateCodeService.getRateCodeList({
        take: RATE_CODE_LIMIT,
        vendorCode,
        rateCodes: rateCode,
      });

      setLoadedVendorCode(vendorCode);
      setCruiseRateCodes({ loading: false, result: res.result });
    },
    [vendorCode],
  );

  useEffect(() => {
    if (!!vendorCode && loadedVendorCode !== vendorCode) {
      fetchRateCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorCode]);

  const ratesSelectOptions = useMemo(() => {
    return cruiseRateCodes.result
      ?.filter((item) => !selectedRateIds.includes(item.id))
      .map((item) => ({
        value: item.id,
        label: `${item.id} - ${item.code}`,
      }));
  }, [cruiseRateCodes.result, selectedRateIds]);

  const ratesSelected = useMemo(() => {
    return selectedRates.map((item) => ({
      label: `${item.id} - ${item.code}`,
      value: item.id,
    }));
  }, [selectedRates]);

  const handleRatesChange = useCallback(
    (_, selectedOptions: Array<Option> | null) => {
      onChange(selectedOptions?.map((selectedRate) => selectedRate.value) || []);
      setSelectedRates((prevState) => {
        if (!selectedOptions) {
          return prevState;
        }
        return selectedOptions
          .map((selectedRate) => ({
            id: selectedRate.value,
            code: selectedRate.label.split(' - ')[1],
          }))
          .filter((item) => !!item) as Array<API.RateCodeListResponse>;
      });
    },
    [onChange],
  );

  const debouncedFetchRateCodes = useMemo(() => debounce(fetchRateCodes, 500), [fetchRateCodes]);

  return (
    <Stack spacing={2} direction="row">
      <FormControl fullWidth>
        <Autocomplete
          id="select-rates"
          multiple
          selectOnFocus
          clearOnBlur={false}
          disableCloseOnSelect
          disabled={!vendorCode || isLoading}
          loading={cruiseRateCodes.loading || isLoading}
          value={ratesSelected}
          onChange={handleRatesChange}
          options={ratesSelectOptions}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                variant="outlined"
                key={option.value}
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} required fullWidth label="Rate Codes" placeholder="Please Enter Rate Codes" />
          )}
          onInputChange={(_, value) => debouncedFetchRateCodes(value)}
        />
      </FormControl>
      {(cruiseRateCodes.loading || isLoading) && <Spinner size={20} />}
    </Stack>
  );
}

export default InputRateCodeSelect;
