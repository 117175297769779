import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { LinearProgress, Link, Paper } from '@mui/material';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import { userLinkFormatter } from '~/components/Purchases/Home/formatters';

import { getOrdersByIds, getRefundRequests } from '~/services/OrdersService';
import { formatDateSlashes, formatDateSlashesWithClock } from '~/services/TimeService';

import { isBeforeAutoRejectedLogicCutoff } from '~/utils/refundRequests';

import ExpandableText from './ExpandableText';

interface RefundRequestData {
  refundRequests: Array<RefundRequest>;
  total: number;
}

export default function RefundsOutsidePolicy() {
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [refundRequestData, setRefundRequestData] = useState<RefundRequestData>({
    refundRequests: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const fetchRefundRequest = useCallback(
    async (currentPage, perPage) => {
      setLoading(true);
      try {
        const response = await getRefundRequests(currentPage + 1, perPage);
        const refundRequests = response.result;

        const orderIds = [...new Set<string>(refundRequests.map((refundRequest) => refundRequest.fk_order_id))];

        // Fetch orders data
        const orders = await getOrdersByIds(orderIds, brand);

        // Attach relevant order data to refund requests
        const updatedRefundRequests = refundRequests.map((refundRequest) => {
          const matchedOrder = orders.find((order) => order.id === refundRequest.fk_order_id);
          const matchedOrderItem = matchedOrder?.items.find((item) => item.id === refundRequest.fk_item_id);

          // Customers can have " " as full name for some reason
          const customerNameTrimmed = matchedOrder?.customer_full_name?.trim() || '';

          const customer_name = customerNameTrimmed || matchedOrder?.customer_email || 'N/A';
          const check_in_date = matchedOrderItem?.reservation?.check_in || null;

          let status = refundRequest.status;
          if (check_in_date && isBeforeAutoRejectedLogicCutoff(refundRequest, check_in_date)) {
            status = 'auto_rejected';
          }

          return {
            ...refundRequest,
            customer_name,
            check_in_date,
            status,
          };
        });

        setRefundRequestData({
          refundRequests: updatedRefundRequests,
          total: response.total,
        });
      } catch (error) {
        enqueueSnackbar('Failed to fetch refund requests.', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [brand, enqueueSnackbar],
  );

  useEffect(() => {
    fetchRefundRequest(paginationModel.page, paginationModel.pageSize);
  }, [fetchRefundRequest, paginationModel.page, paginationModel.pageSize]);

  const vendorPortalLink = useCallback((vendorId: string) => {
    return `${window.configs.VENDOR_PORTAL}/${vendorId}/cancellation-requests`;
  }, []);

  const ordersPageLink = useCallback((orderId: string) => {
    return `/purchases/${orderId}`;
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'id_refund_request',
        headerName: 'Refund Request ID',
        width: 100,
        renderCell: (params) => {
          const vendorId = params.row.vendor_salesforce_id;
          return (
            <Link href={vendorPortalLink(vendorId)} target="_blank" rel="noopener" title={params.value} noWrap>
              {params.value}
            </Link>
          );
        },
      },
      {
        field: 'customer_name',
        headerName: 'Customer',
        width: 150,
        renderCell: userLinkFormatter,
      },
      {
        field: 'fk_order_id',
        headerName: 'Order ID',
        width: 100,
        renderCell: (params) => {
          const orderId = params.value;
          return (
            <Link href={ordersPageLink(orderId)} target="_blank" rel="noopener" title={params.value} noWrap>
              {params.value}
            </Link>
          );
        },
      },
      { field: 'status', headerName: 'Status', width: 100 },
      { field: 'refund_option', headerName: 'Refund Option', width: 150 },
      {
        field: 'reason_classification',
        headerName: 'Reason Classification',
        width: 300,
      },
      {
        field: 'reason',
        headerName: 'Reason',
        width: 300,
        renderCell: (params) => <ExpandableText value={params.value} />,
      },
      {
        field: 'created_at',
        headerName: 'Date of request',
        width: 150,
        valueFormatter: (value) => {
          return formatDateSlashesWithClock(value);
        },
      },
      {
        field: 'check_in_date',
        headerName: 'Check In',
        width: 150,
        valueFormatter: (value) => {
          return value ? formatDateSlashes(value) : 'N/A';
        },
      },
    ],
    [ordersPageLink, vendorPortalLink],
  );

  return (
    <Paper>
      <PageSubheader title="Refunds outside policy" />
      {loading && <LinearProgress />}
      <DataGrid
        rows={refundRequestData.refundRequests}
        columns={columns}
        rowCount={refundRequestData.total}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        getRowId={(row) => row.id_refund_request}
        getRowHeight={() => 'auto'}
        autoHeight
        disableRowSelectionOnClick={loading}
      />
    </Paper>
  );
}
