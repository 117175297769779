import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';

import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ClearIcon, DatePicker } from '@mui/x-date-pickers-pro';

import Spinner from '~/components/Common/Spinner';

import {
  CommissionsConditionTypes,
  INITIAL_COMMISSION_CONDITIONS,
  PRODUCT_TYPES_OPTIONS,
  RuleCondition,
  RuleConditionType,
  RuleConditions,
  ruleConditionsToTextMap,
} from '~/consts/agentHub';

import { getAgenciesById, updateCommission } from '~/services/AgentHub/AgentService';
import { Dayjs, formatDateLongISO, formatDateSlashes, toDayJs } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import { Commission } from '~/types/services/agentHub';

import { buildRuleParam, getSortedRegions, groupByValue } from '~/utils/commission';

import AddNewConditionForm from './AddNewConditionForm';

interface Props {
  data?: Commission;
  isOpen: boolean;
  onClose: () => void;
  listCommissions: () => void;
}

type InputOptionFunc = (data) => { value: string; label: string };

type CommissionTypeMapping = Record<CommissionsConditionTypes, InputOptionFunc>;

type CommissionEditHistory = {
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
};

export default function EditCommissionDialog({ data, onClose, isOpen, listCommissions }: Props) {
  const [createdConditions, setCreatedConditions] = useState<RuleConditions>(INITIAL_COMMISSION_CONDITIONS);
  const [isNewConditionModalOpen, setIsNewConditionModalOpen] = useState<boolean>(false);
  const [regions, setRegions] = useState<Array<string>>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [commissionEditHistory, setCommissionEditHistory] = useState<CommissionEditHistory | null>(null);
  const [isLoadingCommissionEditHistory, setIsLoadingCommissionEditHistory] = useState<boolean>(false);
  const [isLoadingConditions, setIsLoadingConditions] = useState<boolean>(false);
  const commissionFormRef = useRef<HTMLFormElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const groupedProductedTypes = useMemo(() => {
    return Object.values(PRODUCT_TYPES_OPTIONS).reduce((acc, curr) => ({ ...acc, [curr]: curr }), {});
  }, []);
  const groupedRuleType: CommissionTypeMapping = useMemo(
    () => ({
      productType: (data) => ({ value: data, label: groupedProductedTypes[data] }),
      location: (data) => ({ value: data, label: data }),
      agency: (data) => ({ value: data, label: data }),
      agent: (data) => ({ value: data, label: data }),
      offer: (data) => ({ value: data, label: data }),
      vendor: (data) => ({ value: data, label: data }),
      affiliation: (data) => ({ value: data, label: data }),
      tier: (data) => ({ value: data, label: data }),
    }),
    [groupedProductedTypes],
  );

  useEffect(() => {
    if (!data || !isOpen) return;
    setRegions(data.regions);
    if (data.startDate) {
      setStartDate(toDayJs(data.startDate));
    }

    if (data.endDate) setEndDate(toDayJs(data.endDate));
    else setEndDate(null);

    const conditionsObject = Object.entries(data.rules).reduce((acc, [key, rules]: [string, string[]]) => {
      return {
        ...acc,
        [key]: rules.map((rule) => groupedRuleType[key](rule)),
      };
    }, {} as RuleConditions);

    const fetchAndSetAgencies = async () => {
      setIsLoadingConditions(true);
      const agencies = await getAgenciesById(conditionsObject.agency.map((agency) => agency.value));
      const updatedConditions = {
        ...conditionsObject,
        agency: agencies.map((agency) => ({ value: agency.id, label: agency.name })),
      };
      setCreatedConditions(updatedConditions);
      setIsLoadingConditions(false);
    };

    if (conditionsObject.agency) {
      fetchAndSetAgencies();
    } else {
      setCreatedConditions(conditionsObject);
    }
  }, [data, groupedRuleType, isOpen]);

  function addCondition(newCondition: RuleCondition) {
    setCreatedConditions((createdConditions) => {
      const conditions = createdConditions[newCondition.type];
      if (!conditions) {
        return { ...createdConditions, [newCondition.type]: [...newCondition.value] };
      }
      const currentConditions = conditions.reduce(groupByValue, {});
      const newConditions = newCondition.value.reduce(groupByValue, {});
      Object.assign(newConditions, currentConditions);
      const newConditionsForType = Object.entries(newConditions).map(([value, label]) => ({ value, label }));
      return {
        ...createdConditions,
        [newCondition.type]: newConditionsForType,
      };
    });
  }

  function handleClearCondition(type: string) {
    setCreatedConditions((createdConditions) => {
      return {
        ...createdConditions,
        [type]: [],
      };
    });
  }

  function handleStartDateChange(date: Dayjs) {
    setStartDate(date);
  }

  function handleEndDateChange(date: Dayjs) {
    setEndDate(date);
  }

  async function handleEditCommission(event: React.FormEvent<HTMLFormElement>, id: string, conditions: RuleConditions) {
    event.preventDefault();

    const isConditionsEmpty = Object.values(createdConditions).every((condition) => condition.length === 0);

    if (isConditionsEmpty) {
      enqueueSnackbar('Please add at least one condition', { variant: 'error' });
      return;
    }

    try {
      const formData = new FormData(event.currentTarget);
      const regions = formData?.get('regions')?.toString().split(',');
      const formObj = {
        id,
        rules: buildRuleParam(conditions),
        description: formData.get('description') as string,
        startDate: startDate ? formatDateLongISO(startDate.toDate()) : formatDateLongISO(new Date()),
        endDate: endDate ? formatDateLongISO(endDate.toDate()) : undefined,
        regions: regions,
        discountType: formData.get('discountType') as string,
        discountAmount: parseInt(formData.get('discountAmount') as string),
        commissionType: formData.get('discountType') as string,
        commissionPercentage: parseInt(formData.get('discountAmount') as string),
      };
      await updateCommission(formObj.id, formObj);
      listCommissions();
      onClose();
    } catch (err) {
      enqueueSnackbar(err?.message || 'Unknown error', { variant: 'error' });
    }
  }

  const handleAddCondition = () => setIsNewConditionModalOpen(true);
  const handleCloseNewConditionModal = () => setIsNewConditionModalOpen(false);

  useEffect(() => {
    if (data) {
      setIsLoadingCommissionEditHistory(true);
      Promise.all([UsersService.getUser(data.createdBy), data.updatedBy ? UsersService.getUser(data.updatedBy) : null])
        .then(([createdBy, updatedBy]) => {
          setCommissionEditHistory({
            createdBy: createdBy?.fullName,
            createdAt: formatDateSlashes(data.createdAt),
            updatedBy: updatedBy ? updatedBy?.fullName : '',
            updatedAt: data.updatedAt ? formatDateSlashes(data.updatedAt) : '',
          });
        })
        .catch(() => {
          setCommissionEditHistory(null);
        })
        .finally(() => {
          setIsLoadingCommissionEditHistory(false);
        });
    }

    return () => {
      setCommissionEditHistory(null);
    };
  }, [data]);

  function onCloseModal() {
    setCreatedConditions(INITIAL_COMMISSION_CONDITIONS);
    setRegions([]);
    setStartDate(null);
    setEndDate(null);
    setCommissionEditHistory(null);
    setIsLoadingCommissionEditHistory(false);
    commissionFormRef.current?.reset();
    onClose();
  }

  return (
    <Dialog open={isOpen} fullWidth onClose={onCloseModal}>
      <DialogTitle>Edit commission rule "{data?.description}"</DialogTitle>
      <form ref={commissionFormRef} onSubmit={(event) => handleEditCommission(event, data?.id, createdConditions)}>
        <Stack padding={2} direction={'column'} spacing={2}>
          <Stack direction={'row'} gap={2}>
            <TextField name="description" defaultValue={data?.description} label="Description" required fullWidth />
          </Stack>
          <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
            <Stack flex={1}>
              <DatePicker
                value={startDate}
                onChange={handleStartDateChange}
                format="DD/MM/YYYY"
                name="startDate"
                label="Initial date"
              />
            </Stack>
            <Stack flex={1}>
              <DatePicker
                value={endDate}
                onChange={handleEndDateChange}
                format="DD/MM/YYYY"
                disablePast
                name="endDate"
                label="End date"
              />
            </Stack>
          </Stack>

          <Stack direction={'row'} gap={2}>
            <FormControl fullWidth>
              <InputLabel id="agent-hub-commissions-regions-input-label">Regions</InputLabel>
              <Select
                name="regions"
                labelId="agent-hub-commissions-regions-input-label"
                multiple
                value={regions}
                input={<OutlinedInput label="Regions" />}
                renderValue={(selected: string[]) => selected.join(', ')}
              >
                {getSortedRegions().map((region) => (
                  <MenuItem key={region.code} value={region.code}>
                    <Checkbox checked={regions.indexOf(region.code) > -1} />
                    <ListItemText primary={region.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField defaultValue={data?.type} name="discountType" select label="Discount type" required fullWidth>
              <MenuItem value={'base'}>Base</MenuItem>
              <MenuItem value={'additional'}>Additional</MenuItem>
            </TextField>

            <TextField
              name="discountAmount"
              InputProps={{ inputProps: { min: 1 } }}
              label="Discount amount"
              defaultValue={data?.commissionPercentage}
              type="number"
              required
              fullWidth
            />
          </Stack>

          <Stack>
            <Typography variant="caption" color="secondary">
              Rule conditions:
            </Typography>

            {isLoadingConditions && <Spinner size={14} />}

            {Object.entries(createdConditions)
              .filter(([_, value]: RuleConditionType) => value.length > 0)
              .map(([key, value]: RuleConditionType) => (
                <Stack key={key} direction={'row'} alignItems={'center'}>
                  <Typography>
                    {ruleConditionsToTextMap.get(key)}:{' '}
                    {value.map((condition) => (typeof condition === 'string' ? condition : condition.label)).join(', ')}
                  </Typography>
                  <IconButton onClick={() => handleClearCondition(key)}>
                    <ClearIcon sx={{ fontSize: 16 }} color="secondary" />
                  </IconButton>
                </Stack>
              ))}
          </Stack>

          {isLoadingCommissionEditHistory && <Spinner size={14} />}

          {commissionEditHistory && (
            <Stack>
              <Typography variant="caption" color="secondary">
                Created By: {commissionEditHistory?.createdBy} at {commissionEditHistory?.createdAt}
              </Typography>
              {commissionEditHistory.updatedBy && (
                <Typography variant="caption" color="secondary">
                  Edited By: {commissionEditHistory?.updatedBy} at {commissionEditHistory?.updatedAt}
                </Typography>
              )}
            </Stack>
          )}

          <AddNewConditionForm
            isOpen={isNewConditionModalOpen}
            onClose={handleCloseNewConditionModal}
            addCondition={addCondition}
          />

          <Button size="small" variant="contained" onClick={handleAddCondition}>
            Add condition
          </Button>

          <Button type="submit" size="medium" variant="contained">
            Edit Commission Rule
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
}
