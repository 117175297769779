import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { captureException } from '@sentry/browser';
import { useSnackbar } from 'notistack';

import { Button, Grid, Link, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridPagination } from '@mui/x-data-grid';

import { getRegionNameByCode } from '@luxuryescapes/lib-regions';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';
import Spinner from '~/components/Common/Spinner';

import {
  createRegistrationToken,
  listRegistrationTokens,
  listUserEmailLogs,
  resendActivationEmail,
  updateEOIStatus,
} from '~/services/AgentHub/AgentService';
import { addHours, formatDateDateTimeWidget, formatDateShortDD } from '~/services/TimeService';

import { Eoi, EoiStatus } from '~/types/services/agentHub';

import { titleCase } from '~/utils/stringUtils';

const columns: Array<GridColDef> = [
  { field: 'status', headerName: 'Status', sortable: false, display: 'flex' },
  { field: 'sentAt', headerName: 'Sent At', flex: 1, sortable: false, display: 'flex' },
  { field: 'type', headerName: 'Type', flex: 1, sortable: false, display: 'flex' },
  { field: 'failureMessage', headerName: 'Error Message', flex: 1, sortable: false, display: 'flex' },
];

const SIZE_PER_PAGE = 4;

export interface AgencySubmissionDetailsProps {
  eoiDetails: Eoi;
  setEditing: (value: boolean) => void;
  onStatusUpdate: (eoiData: Eoi) => void;
}

export default function AgencySubmissionDetails({
  eoiDetails,
  setEditing,
  onStatusUpdate,
}: AgencySubmissionDetailsProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [emailLogs, setEmailLogs] = useState([]);
  const [totalEmailLogs, setTotalEmailLogs] = useState(0);

  const [registrationTokens, setRegistrationTokens] = useState([]);
  const [totalRegistrationTokens, setTotalRegistrationTokens] = useState(0);

  const [expiresAt, setExpiresAt] = useState<string>(formatDateDateTimeWidget(addHours(48, new Date())));

  const [filter, setFilter] = useState({ page: 0 });
  const [loading, setLoading] = useState(true);

  const copyOnboardLink = useCallback(
    (token: string) => {
      navigator.clipboard.writeText(
        `${window.configs.LEAGENTHUB_CUSTOMER_PORTAL}/${
          eoiDetails.regionCode
        }/agent-hub/complete-account-setup?token=${encodeURIComponent(token)}`,
      );
      enqueueSnackbar(`Copied to clipboard`, { variant: 'success' });
    },
    [enqueueSnackbar, eoiDetails.regionCode],
  );

  const registrationTokenColumns: Array<GridColDef> = useMemo(() => {
    return [
      { field: 'id', headerName: 'ID', flex: 1, sortable: false, display: 'flex' },
      { field: 'createdAt', headerName: 'Created At', flex: 1, sortable: false, display: 'flex' },
      {
        field: 'expiresAt',
        headerName: 'Expires At',
        flex: 1,
        sortable: false,
        display: 'flex',
        renderCell: (params) => {
          if (params.row.expired) {
            return 'Expired';
          }
          return params.row.expiresAt;
        },
      },
      { field: 'isUsed', headerName: 'Used', flex: 1, sortable: false, display: 'flex' },
      {
        field: 'action',
        headerName: '',
        flex: 1,
        renderCell: (params) => {
          if (params.row.expired || params.row.isUsed) {
            return null;
          }
          return (
            <Button variant="text" onClick={() => copyOnboardLink(params.row.id)}>
              Copy Onboard Link
            </Button>
          );
        },
        display: 'flex',
      },
    ];
  }, [copyOnboardLink]);

  async function updateSubmission(status: 'approved' | 'rejected') {
    setLoading(true);
    try {
      const updatedEoi = await updateEOIStatus(eoiDetails.id, status);
      onStatusUpdate(updatedEoi);
      enqueueSnackbar(`Submission ${status === 'approved' ? 'approved' : 'rejected'}`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to update submission: ${error}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const fetchEmailLogList = useCallback(async (): Promise<void> => {
    try {
      if (!eoiDetails.agentId) {
        return;
      }

      setLoading(true);

      const { rows, total } = await listUserEmailLogs(eoiDetails.agentId, {
        type: 'activation',
        page: filter.page.toString(),
        pageSize: SIZE_PER_PAGE.toString(),
      });

      setTotalEmailLogs(total);
      setEmailLogs(rows);
    } catch (e) {
      enqueueSnackbar(`Failed to load Email Logs: ${e}`, { variant: 'error' });
      captureException(e, {
        tags: {
          eoiId: eoiDetails.id,
        },
      });
      setTotalEmailLogs(0);
      setEmailLogs([]);
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, eoiDetails.agentId, eoiDetails.id, filter.page]);

  const fetchRegisterTokenList = useCallback(async (): Promise<void> => {
    try {
      if (!eoiDetails.agentId) {
        return;
      }

      setLoading(true);
      const { rows, total } = await listRegistrationTokens(eoiDetails.agentId, {
        page: filter.page.toString(),
        pageSize: SIZE_PER_PAGE.toString(),
      });

      setRegistrationTokens(rows);
      setTotalRegistrationTokens(total);
    } catch (e) {
      enqueueSnackbar(`Failed to load Register Token List: ${e}`, { variant: 'error' });
      captureException(e, {
        tags: {
          eoiId: eoiDetails.id,
        },
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, eoiDetails.agentId, eoiDetails.id, filter.page]);

  useEffect(() => {
    if (open) {
      fetchEmailLogList();
      fetchRegisterTokenList();
    }
  }, [filter, fetchEmailLogList, fetchRegisterTokenList]);

  const fetchResendActivationEmail = useCallback(async () => {
    try {
      setLoading(true);

      await resendActivationEmail(eoiDetails.id);

      fetchEmailLogList();
      fetchRegisterTokenList();
    } catch (err) {
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [eoiDetails.id, setLoading, enqueueSnackbar, fetchEmailLogList, fetchRegisterTokenList]);

  return (
    <Grid container direction="column" wrap="nowrap" columns={12}>
      {loading && <Spinner />}
      {!loading && (
        <>
          <Typography variant="h5">Agency</Typography>
          <Grid item mt={1}>
            <Typography>Agency Name:</Typography>
            {eoiDetails.agencyId && (
              <Link
                href={`/users-list/agencies/${eoiDetails.agencyId}`}
                target="_blank"
                rel="noreferrer"
                fontWeight="bold"
              >
                {eoiDetails.agencyName}
              </Link>
            )}
            {!eoiDetails.agencyId && <Typography fontWeight="bold">{eoiDetails.agencyName}</Typography>}
          </Grid>

          <Grid item mt={1}>
            <Typography>Affiliation:</Typography>
            <Typography fontWeight="bold">{eoiDetails.affiliation}</Typography>
          </Grid>

          <Grid item mt={1}>
            <Typography>Region:</Typography>
            <Typography fontWeight="bold">
              {eoiDetails.regionCode} - {getRegionNameByCode(eoiDetails.regionCode)}
            </Typography>
          </Grid>

          <Typography mt={2} variant="h5">
            User
          </Typography>

          <Grid item mt={1}>
            <Typography>Name:</Typography>
            <Typography fontWeight="bold">
              {eoiDetails.givenName} {eoiDetails.surname}
            </Typography>
          </Grid>

          <Grid item mt={1}>
            <Typography>Email:</Typography>
            {eoiDetails.customerId && (
              <Link
                sx={{ wordBreak: 'break-all' }}
                href={`/users/${eoiDetails.customerId}`}
                target="_blank"
                rel="noreferrer"
                fontWeight="bold"
              >
                {eoiDetails.email}
              </Link>
            )}
            {!eoiDetails.customerId && (
              <Typography sx={{ wordBreak: 'break-all' }} textOverflow="clip" fontWeight="bold">
                {eoiDetails.email}
              </Typography>
            )}
          </Grid>
          <Grid item mt={1}>
            <Typography>Phone:</Typography>
            <Typography fontWeight="bold">{eoiDetails.phone}</Typography>
          </Grid>
          <Grid item mt={1}>
            <Typography>Submitted At:</Typography>
            <Typography fontWeight="bold">{formatDateShortDD(eoiDetails.submittedAt)}</Typography>
          </Grid>
          <Grid item mt={1} display="flex" direction="row">
            <Stack>
              <Typography>Status:</Typography>
              <Typography fontWeight="bold">{titleCase(eoiDetails.status)}</Typography>
            </Stack>
            {eoiDetails.onboardedAt && (
              <Stack ml={2}>
                <Typography>Onboarded At:</Typography>
                <Typography fontWeight="bold">{formatDateShortDD(eoiDetails.onboardedAt)}</Typography>
              </Stack>
            )}
            {(eoiDetails.status === EoiStatus.Approved || eoiDetails.status === EoiStatus.Onboarded) && (
              <Stack ml={2}>
                <Typography>Approved At: </Typography>
                <Typography fontWeight="bold">
                  {eoiDetails.approvedAt && formatDateShortDD(eoiDetails.approvedAt)}
                </Typography>
              </Stack>
            )}
            {eoiDetails.status === EoiStatus.Rejected && (
              <Stack ml={2}>
                <Typography>Rejected At: </Typography>
                <Typography fontWeight="bold">
                  {eoiDetails.rejectedAt && formatDateShortDD(eoiDetails.rejectedAt)}
                </Typography>
              </Stack>
            )}
          </Grid>
          {[EoiStatus.Pending, EoiStatus.Rejected].includes(eoiDetails.status) && (
            <Grid item mt={2} display="flex" justifyContent="space-around" direction="row">
              <Button variant="contained" color="secondary" onClick={() => setEditing(true)}>
                Edit
              </Button>
              <Button variant="contained" onClick={() => updateSubmission('approved')}>
                Approve
              </Button>

              {eoiDetails.status !== EoiStatus.Rejected && (
                <Button variant="contained" color="error" onClick={() => updateSubmission('rejected')}>
                  Reject
                </Button>
              )}
            </Grid>
          )}
          {[EoiStatus.Approved, EoiStatus.Onboarded].includes(eoiDetails.status) && (
            <>
              <Typography mt={2} variant="h5">
                Email Logs
              </Typography>
              <Grid item mt={2}>
                <DataGrid
                  rows={emailLogs}
                  rowCount={totalEmailLogs}
                  columns={columns}
                  loading={loading}
                  pagination
                  paginationMode="server"
                  paginationModel={{ page: filter.page, pageSize: SIZE_PER_PAGE }}
                  pageSizeOptions={[SIZE_PER_PAGE]}
                  onPaginationModelChange={({ page }) => setFilter({ page: page })}
                  slots={{ pagination: GridPagination }}
                  getRowId={(row) => row.sentAt}
                  disableColumnFilter
                  disableColumnMenu
                  disableRowSelectionOnClick
                  autoHeight
                />
                <Grid item mt={2} display="flex" justifyContent="space-between" direction="row-reverse">
                  <Button
                    title="This will use the last valid token or create a new one if needed"
                    variant="contained"
                    onClick={() => fetchResendActivationEmail()}
                  >
                    Re-send Activation Email
                  </Button>
                </Grid>
              </Grid>
              <Typography mt={2} variant="h5">
                Registration Tokens
              </Typography>
              <Grid item mt={2}>
                <DataGrid
                  rows={registrationTokens}
                  rowCount={totalRegistrationTokens}
                  columns={registrationTokenColumns}
                  loading={loading}
                  pagination
                  paginationMode="server"
                  paginationModel={{ page: filter.page, pageSize: SIZE_PER_PAGE }}
                  pageSizeOptions={[SIZE_PER_PAGE]}
                  onPaginationModelChange={({ page }) => setFilter({ page: page })}
                  slots={{ pagination: GridPagination }}
                  getRowId={(row) => row.createdAt}
                  disableColumnFilter
                  disableColumnMenu
                  disableRowSelectionOnClick
                  autoHeight
                />
                <Grid item mt={2} display="flex" justifyContent="flex-end" direction="row">
                  <DateTimeWidget
                    disablePast
                    format="MM/DD/YYYY HH:mm:ss"
                    label="Expires At"
                    value={expiresAt}
                    onChange={(value) => setExpiresAt(value)}
                  />
                  <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    onClick={() => createRegistrationToken(eoiDetails.agentId, expiresAt)}
                  >
                    Create Registration Token
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}
