import { useEffect } from 'react';

import { HttpResponse } from '~/services/types';

import { useAccommodationServiceRequest } from './useAccommodationServiceRequest';

interface UseFetchDataOptions<APIResp> {
  fetchFn: () => Promise<HttpResponse<APIResp>>;
  onSuccess?: (data: APIResp) => void;
}

export function useAccommodationServiceFetch<APIResp>({ fetchFn, onSuccess }: UseFetchDataOptions<APIResp>) {
  const { reqState, doRequest } = useAccommodationServiceRequest<APIResp>({ apiReq: fetchFn, onSuccess });

  useEffect(() => {
    doRequest();
  }, [doRequest]);

  return { fetchReqState: reqState, refetch: doRequest };
}
