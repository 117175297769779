export const availableEndpoints = [
  '/abandoned-cart',
  '/account-delete-request',
  '/add-credits',
  '/add-referrer-credits',
  '/addon-reminder',
  '/agent-hub-application-successful',
  '/agent-hub-pre-registration-successful',
  '/agent-hub/order-cancellation/',
  '/agent-hub/order-change-dates',
  '/agent-hub/order-change-package',
  '/agent-hub/order-confirmation/',
  '/arrival-details-reminder',
  '/atol-certificate/create',
  '/atol-certificate/edit',
  '/atol-certificate/resend',
  '/autodebit-failure',
  '/balance-reminder',
  '/booking-confirmation',
  '/booking-confirmation-custom-offer',
  '/booking-confirmation-deposit',
  '/booking-confirmation-deposit/resend',
  '/booking-confirmation-instalment',
  '/booking-confirmation-instalment/resend',
  '/booking-confirmation-tour',
  '/booking-confirmation-tour-experiences',
  '/booking-confirmation-tour/resend',
  '/booking-confirmation/customer-upgrade-confirmation',
  '/booking-confirmation/resend',
  '/booking-confirmation/vendor-upgrade-confirmation',
  '/booking-confirmed-tour',
  '/booking-confirmed-tour/',
  '/booking-failed',
  '/booking-failed-customer-service',
  '/business-approval-request',
  '/business-approval-request-approved',
  '/business-approval-request-denied',
  '/business-approval-request-expired',
  '/business-created',
  '/business-credits-applied',
  '/business-employee-invited',
  '/business-flights-approval-request',
  '/business-flights-approval-request-approved',
  '/business-flights-approval-request-denied',
  '/business-flights-approval-request-expired',
  '/business-frequent-flyer-linked',
  '/business-frequent-flyer-removed',
  '/business-frequent-flyer-request-join',
  '/business-frequent-flyer-request-link',
  '/business-frequent-flyer-request-removal',
  '/business-invitation-accepted',
  '/business-request-demo',
  '/cancellation-outside-policy-request/customer',
  '/cancellation-outside-policy-request/vendor',
  '/cancellation-outside-policy-request/vendor/update',
  '/cancellation-outside-policy-sms',
  '/car-hire-booking-cancelled',
  '/car-hire-booking-confirmed',
  '/car-hire-campaign',
  '/choose-referral-earn',
  '/concierge-trip-created',
  '/corporate-benefits-promo-code',
  '/cruise-booking-cancelled',
  '/cruise-booking-confirmed',
  '/cruise-customer-enquiry',
  '/cruise-deposit-details-internal',
  '/cruise-manual-cancellation-alert',
  '/custom-offer-notification',
  '/customer-callback-confirmation',
  '/customer-cancel-purchase',
  '/customer-change-dates-confirmation',
  '/customer-change-dates-confirmation-deposit',
  '/customer-change-dates-confirmation/resend',
  '/customer-put-tour-on-hold-confirmation',
  '/customer-self-service',
  '/deposit-failure-sms',
  '/deposit-reminder-sms',
  '/experience-bnbl-reminder',
  '/experience-booking-cancelled',
  '/experience-booking-confirmation',
  '/experience-gifting-purchase-confirmation',
  '/experience-operations-bnbl-booking-confirmation',
  '/experience-vouchers',
  '/expiry-reminder',
  '/failed-emails',
  '/failed-order',
  '/failed-reservations-notification',
  '/flight-booking-confirmation',
  '/fraud-order',
  '/friends-and-family-promo-code',
  '/gdpr-removal-request',
  '/gift-booking-confirmation',
  '/gift-booking-confirmation/resend',
  '/gift-card',
  '/gift-card-confirmation',
  '/gift-card-confirmation/resend',
  '/health-check',
  '/holiday-satisfaction-survey',
  '/instalment-autodebit-failure',
  '/instalment-balance-reminder',
  '/insurance-cancelled',
  '/inventory-request/customer',
  '/inventory-request/salesforce',
  '/inventory-request/support',
  '/inventory-request/vendor',
  '/lux-plus-auto-renewal-failed',
  '/lux-plus-subscription-cancelled',
  '/lux-plus-subscription-confirmation',
  '/offline-flight-payment',
  '/partner-central-confirm',
  '/partner-central-invite',
  '/partner-central-remind',
  '/payment-generic-sms',
  '/payment-refund',
  '/payment-refund/flash-hotel',
  '/primal-res-unconfirmed-reservations',
  '/private-request-confirmation-tour/',
  '/private-request-salesforce-tour',
  '/promo-code',
  '/purchase-sync',
  '/quote-automation',
  '/refund-protect-cancel-flight-request',
  '/remind-enter-traveller-details',
  '/reserve-for-zero-booking-confirmation',
  '/reserve-for-zero-booking-confirmation/resend',
  '/reserve-for-zero-payment-failed',
  '/review-created',
  '/sales-video-chat-notification',
  '/send-competition-promo',
  '/share-referral-link',
  '/share-with-friend',
  '/skycheck-confirmation',
  '/skycheck-request-email',
  '/tailor-made-tour-request-confirmation',
  '/tour-booking-cancelled',
  '/tour-optional-experience-booking-cancelled',
  '/travelshoot-promo-code',
  '/trip-quote-automation',
  '/vendor-addon-confirmation',
  '/vendor-bnbl-switch-confirmation',
  '/vendor-booking-confirmation',
  '/vendor-booking-confirmation/resend',
  '/vendor-cancel-addon',
  '/vendor-cancel-purchase',
  '/vendor-change-dates-confirmation',
  '/vendor-experience-booking-cancellation',
  '/vendor-led-booking-cancelled',
  '/vendor-led-booking-confirmed',
  '/vendor-put-tour-on-hold-confirmation',
  '/vendor-special-request-update',
  '/vendor-tour-booking-cancellation',
  '/vendor-tour-booking-confirmation',
  '/waiting-list-sold-out-tour',
];
