/**
 * Returns a new array, sorted by the value returned by `valueFunc` for each element.
 * Does not modify the source array.
 * @param {Array} values The array to sort
 * @param {Function} valueFunc A function that returns the value to sort by
 * @param {'desc' | 'asc'} [direction]
 */
export function sortBy<T, V>(
  values: Array<T> | IterableIterator<T>,
  valueFunc: (val: T) => V,
  direction: 'desc' | 'asc' = 'desc',
) {
  return [...values].sort((v1, v2) => {
    const a = valueFunc(v1);
    const b = valueFunc(v2);
    if (a > b) {
      return direction === 'desc' ? -1 : 1;
    } else if (a < b) {
      return direction === 'desc' ? 1 : -1;
    } else {
      return 0;
    }
  });
}

export function ConvertToCSV(objArray: Array<Record<string, unknown>>) {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  for (let i = 0; i < array.length; i++) {
    if (i === 0) {
      str += Object.keys(array[i]).join(',');
      str += '\r\n';
    }

    let line = '';
    for (const index in array[i]) {
      if (line != '') {
        line += ',';
      }
      const val = array[i][index];
      if (val && typeof val === 'string' && val.includes(',')) {
        line += `"${val}"`;
      } else {
        line += val;
      }
    }

    str += line + '\r\n';
  }

  return str;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EmptyArray = new Proxy<Array<any>>([], {
  set() {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error('Cannot modify EmptyArray');
    }
    return true;
  },
});

const returnValFunc = (val) => val;

/**
 * Converts the array given to a object by the key selector function given
 * @param array The array to convert to a object
 * @param keySelector A function whose return value will be used as key of the object
 * @param valueSelector A function whose return value will be used as value of the object
 */
export function arrayToObject<T, R = T, K extends string | number = string | number>(
  array: Readonly<Array<T>> | IterableIterator<T> = [],
  keySelector: (val: T, index: number) => K,
  valueSelector: (val: T, index: number) => R = returnValFunc,
): { [key in K]: R } {
  const values = Array.isArray(array) ? array : Array.from(array);
  return values.reduce<{ [key in K]: R }>((map, val, index) => {
    map[keySelector(val, index)] = valueSelector(val, index);
    return map;
  }, {} as { [key in K]: R });
}

/**
 * Retrieves the last value of an array
 */
export function lastValueOf<T>(array: Array<T>): T {
  return array?.[array.length - 1];
}

/**
 * Take the number of values specified from the array
 * If no enough values are in the array, will take as many as it can
 */
export function takeFrom<T>(array: Array<T> | Readonly<Array<T>>, numberOfValues: number, startIndex = 0): Array<T> {
  return array.slice(startIndex, startIndex + numberOfValues);
}

/**
 * Function will check if an object only has the keys provided
 *
 * @example
 * obj = {'testB': 1, 'testA': 2}
 * onlyHasSpecificKeys(obj, ['testA']) false
 * onlyHasSpecificKeys(obj, ['testB']) false
 * onlyHasSpecificKeys(obj, ['testA', 'testB']) true
 * @param obj The dictionary you want to check for keys for
 * @param keys A list of keys you expect
 * @returns true if the dictionary ONLY has the keys in `keys`
 */
export function onlyHasSpecificKeys(obj: Record<string, unknown>, keys: Array<string>): boolean {
  const objKeys = new Set(Object.keys(obj));
  const expectedKeysSet = new Set(keys);
  // Check if the object's keys match the specified keys
  return objKeys.size === expectedKeysSet.size && keys.every((key) => objKeys.has(key));
}
