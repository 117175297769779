export const BONUS_INCLUSIONS_MAX_TO_NIGHTS = 999;
export const ROOM_RATE_LPC_TYPE = 'lux_premium_collection';
export const ROOM_RATE_RENTAL_TYPE = 'rental';
export const ROOM_RATE_LAST_MINUTE_TYPE = 'last_minute';
export const ROOM_RATE_FLASH_TYPE = 'limited_time_exclusive';

export const BOARD_TYPE_ROOM_ONLY = 'roomonly';
export const BOARD_TYPE_DAILY_BREAKFAST = 'breakfast';
export const BOARD_TYPE_DAILY_BREAKFAST_AND_LUNCH_OR_DINNER = 'halfboard';
export const BOARD_TYPE_DAILY_BREAKFAST_AND_LUNCH_AND_DINNER = 'fullboard';
export const BOARD_TYPE_ALL_INCLUSIVE = 'allinclusive';
export const BOARD_TYPES = [
  BOARD_TYPE_ROOM_ONLY,
  BOARD_TYPE_DAILY_BREAKFAST,
  BOARD_TYPE_DAILY_BREAKFAST_AND_LUNCH_OR_DINNER,
  BOARD_TYPE_DAILY_BREAKFAST_AND_LUNCH_AND_DINNER,
  BOARD_TYPE_ALL_INCLUSIVE,
];

export const BOARD_TYPES_ALIAS = {
  roomonly: 'RO',
  breakfast: 'BB',
  halfboard: 'HB',
  fullboard: 'FB',
  allinclusive: 'AI',
};
