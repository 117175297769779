import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Alert, Box, Button, Dialog, DialogContent, Stack, TextField } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import SearchService, { UserRecentSearch } from '~/services/SearchService';
import { diffDays, formatDateWithClock } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import { reportError } from '~/utils/reportError';
import { SpoofUrlParts, constructSearchSpoofUrl } from '~/utils/url-formatter';

import GridPagination from '../Common/Elements/GridPagination';

type Props = {
  memberId: string;
  memberEmail: string;
  tenant: App.Tenant;
};

const defaultColumns: Array<GridColDef> = [
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    flex: 4,
    valueGetter: (value) => formatDateWithClock(value),
    display: 'flex',
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'vertical',
    headerName: 'Vertical',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'searchType',
    headerName: 'Type',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'destinationName',
    headerName: 'Destination',
    flex: 4,
    display: 'flex',
  },
  {
    field: 'numberOfAdults',
    headerName: '# Adults',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'numberOfChildren',
    headerName: '# Children',
    flex: 2,
    display: 'flex',
    valueGetter: (value) => value || 0,
  },
  {
    field: 'rooms',
    headerName: '# Rooms',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'dateType',
    headerName: 'Date Type',
    flex: 2,
    display: 'flex',
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    flex: 2,
    display: 'flex',
    valueGetter: (value) => value || '-',
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    flex: 2,
    display: 'flex',
    valueGetter: (value) => value || '-',
  },
  {
    field: 'durationType',
    headerName: 'Nights',
    flex: 2,
    display: 'flex',
    valueGetter: (value, row: UserRecentSearch) => {
      if (row.dateType === 'flexible') {
        return row.durationType;
      }
      if (row.dateType === 'specific') {
        return diffDays(row.endDate, row.startDate, 'day');
      }
      return '-';
    },
  },
];

interface UrlData {
  url: string;
  spoofUrl: string;
}

interface SearchUrlData {
  url: string;
  spoofUrl: string;
}

function UserRecentSearchTable({ memberId, memberEmail, tenant }: Props) {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [spoofUrl, setSpoofUrl] = useState<string | null>(null);
  const [urlData, setUrlData] = useState<UrlData | null>(null);

  const displayColumns = [
    {
      field: 'url',
      headerName: 'URL',
      renderCell: (params) => (
        <Button variant="text" onClick={() => handleUrlClick(params.row.urlData)}>
          URL
        </Button>
      ),
    },
    ...defaultColumns,
  ];

  function transformSearchResults(
    searches: Array<UserRecentSearch>,
    spoofUrlParts: SpoofUrlParts,
  ): Array<UserRecentSearch & { urlData: SearchUrlData }> {
    return searches.map((item) => ({
      ...item,
      urlData: {
        url: item.url,
        spoofUrl: constructSearchSpoofUrl(spoofUrlParts, item.url),
      },
    }));
  }

  const handleUrlClick = (urlData: UrlData) => setUrlData(urlData);

  const flushSpoofingState = () => {
    setUrlData(null);
    setSpoofUrl(null);
  };

  const handleViewSearchClick = () => {
    if (!urlData) {
      return;
    }
    window.open(urlData.url, '_blank', 'noopener,noreferrer');
  };

  const handleViewSearchWhileSpoofingClick = () => {
    if (!urlData) {
      return;
    }
    navigator.clipboard.writeText(urlData.spoofUrl);
    setSpoofUrl(urlData.spoofUrl);
  };

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        const [spoofUrl, searches] = await Promise.all([
          UsersService.spoofUserSearch(memberId, tenant),
          SearchService.getUserRecentSearches(memberId, brand),
        ]);

        const resultsWithUrls = transformSearchResults(searches.result, spoofUrl);
        setResult(resultsWithUrls);
      } catch (error) {
        reportError(error);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [memberId, tenant, brand]);

  return (
    <>
      <Box>
        <DataGrid
          rows={result || []}
          columns={displayColumns}
          getRowId={() => uuid()}
          autoHeight
          loading={loading}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5]}
          paginationModel={{ pageSize: 10, page }}
          onPaginationModelChange={({ page }) => setPage(page)}
          disableColumnMenu
          disableRowSelectionOnClick
          density="compact"
          slots={{ pagination: GridPagination }}
        />
      </Box>

      {spoofUrl && (
        <Dialog open onClose={flushSpoofingState}>
          <DialogContent>
            <Stack direction="column" spacing={2} sx={{ my: 2 }}>
              <Alert severity="warning" onClose={flushSpoofingState}>
                Extreme caution, paste into incognito only
              </Alert>
              <Alert severity="info" onClose={flushSpoofingState}>
                Your link has been copied to the clipboard!
              </Alert>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={`Spoofing link for ${memberEmail}`}
                defaultValue={spoofUrl}
                multiline
                disabled
              />
            </Stack>
          </DialogContent>
        </Dialog>
      )}

      {urlData && !spoofUrl && (
        <Dialog open onClose={flushSpoofingState}>
          <DialogTitle>View Search</DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={2} sx={{ my: 2 }}>
              <Button variant="contained" onClick={handleViewSearchClick}>
                View search
              </Button>
              <Button variant="contained" onClick={handleViewSearchWhileSpoofingClick}>
                View the search while spoofing
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default UserRecentSearchTable;
