import { CollectionReason, CollectionReasons } from '~/components/Refund/types';

export const STEP_SELECT_TYPE_OF_REFUND = 'select_type_of_refund';
export const STEP_PREREFUND_NIB_INSURANCE = 'prerefund_nib_insurance';
export const STEP_LATE_INSURANCE_REFUND = 'late_insurance_refund';
export const STEP_ADDITIONAL_INFO = 'additional_info';
export const STEP_SUMMARY = 'summary';
export const STEP_BEDBANK_AUTOMATIC_REFUND = 'bedbank_automatic_refund';
export const STEP_TOUR_AUTOMATIC_REFUND = 'tour_automatic_refund';
export const STEP_CONNECTION_AUTOMATIC_REFUND = 'connection_automatic_refund';
export const STEP_VENDOR_EMAIL_REMINDER = 'ttc_vendor_email_reminder';
export const ALL_STEPS = [
  STEP_SELECT_TYPE_OF_REFUND,
  STEP_PREREFUND_NIB_INSURANCE,
  STEP_LATE_INSURANCE_REFUND,
  STEP_ADDITIONAL_INFO,
  STEP_SUMMARY,
  STEP_BEDBANK_AUTOMATIC_REFUND,
  STEP_TOUR_AUTOMATIC_REFUND,
  STEP_CONNECTION_AUTOMATIC_REFUND,
  STEP_VENDOR_EMAIL_REMINDER,
];
export const CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD = 'Change of Mind Within Refund Guarantee Period';
export const PACKAGE_AMENDMENT = 'Package Amendment';
export const MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION =
  'Misleading Content, Incorrect Pricing & Customer Retention';
export const LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS =
  'Late Change of Mind, Bad Experience or Unfortunate Events';
export const LATE_CANCEL_GOODWILL = 'Late Cancel - Goodwill';
export const OPERATOR_CANCELLATION = 'Operator Cancellation';
export const FRAUD_AND_COMPLIANCE_AND_DISPUTE = 'Fraud, Compliance and Dispute';
export const FAILED_REFUND_FROM_PAYMENT_GATEWAYS = 'Failed refund from Payment Gateways';
export const BEDBANK_AUTOMATIC_REFUND = 'Bedbank automatic refund';
export const TOUR_AUTOMATIC_REFUND = 'Tour automatic refund';
export const AO_AUTOMATIC_REFUND = 'AO automatic refund';
export const RENTAL_AUTOMATIC_REFUND = 'Rental automatic refund';
export const REFUND_TEST_PURCHASE = 'Refund test purchase';
export const EXPIRED_BNBL_PACKAGE = 'Expired BNBL package';
export const INSURANCE_BREAKAGE = 'Insurance Breakage';

export const VARIANT_OF_REFUND_ALL = 'all';
export const VARIANT_OF_REFUND_PARTIAL = 'partial';
export const VARIANT_OF_REFUND_PARTIAL_CALCULATED = 'partial_calculated';
export const VARIANT_OF_REFUND_SURCHARGE = 'surcharge';
export const VARIANT_OF_REFUND_NONE = 'none';

export const VENDOR_REFUND_AGREES_NUMBER = 'number';
export const VENDOR_REFUND_AGREES_PERCENTAGE = 'percentage';

export const REFUND_METHOD_DEFAULT = 'Back To Original';
export const REFUND_METHOD_CREDITS = 'LE Credits';
export const REFUND_METHOD_MANUAL = 'Manual';

export const SERVICE_FEE_ALLOWED_TYPES = [
  'PACKAGE_AMENDMENT',
  'MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION',
];

export const EXPIRED_TOUR_REFUND_ALLOWED_TYPES = [
  'LATE_CANCEL_GOODWILL',
  'OPERATOR_CANCELLATION',
  'LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS',
];

export const EXPIRED_ONLY_TOUR_REFUND_ALLOWED_TYPES = ['LATE_CANCEL_GOODWILL', 'OPERATOR_CANCELLATION'];
export const TNFH_REASON_SOURCE = 'Luxury Escapes (TNFH)';
export const DEFAULT_REASON_SOURCE = 'Default';
export const CUSTOM_REASON_SOURCE = 'Custom';

export enum RefundSource {
  TNFH_REASON_SOURCE = 'Luxury Escapes (TNFH)',
  DEFAULT_REASON_SOURCE = 'Default',
  CUSTOM_REASON_SOURCE = 'Custom',
}

export const REASONS_WITH_ADDITIONAL_INFO = ['LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS'];

const collectionReasons: CollectionReasons = {
  CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD: {
    key: CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD,
    hidden: false,
    description: 'Customer wants to cancel within 7 days for a hotel or 48 hours for tour.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  PACKAGE_AMENDMENT: {
    key: PACKAGE_AMENDMENT,
    hidden: false,
    description: 'Customer wants to upgrade, downgrade or change to dates with different price.',
    can_select_variant: true,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION: {
    key: MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION,
    hidden: false,
    description: 'Customer happiness at LE expense.',
    can_select_variant: true,
    defaults: {
      source: TNFH_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS: {
    key: LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
    hidden: false,
    description:
      'Customer wants to cancel after 7 days. LE and vendor share responsibility. Management approval required.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  LATE_CANCEL_GOODWILL: {
    key: LATE_CANCEL_GOODWILL,
    hidden: false,
    description:
      'The customer wants to cancel their booking after the final payment has been made. Customer may be entitled to a full refund. LE to bear the cost.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  OPERATOR_CANCELLATION: {
    key: OPERATOR_CANCELLATION,
    hidden: false,
    description:
      'All or part of the tour has been cancelled by the operator. Customer is entitled to a full or partial refund. DMC to bear the cost.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  FRAUD_AND_COMPLIANCE_AND_DISPUTE: {
    key: FRAUD_AND_COMPLIANCE_AND_DISPUTE,
    hidden: false,
    description: 'Credit Card Fraud, fair trading and consumer affair complaint, paypal dispute.',
    can_select_variant: false,
    defaults: {
      source: null,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
  FAILED_REFUND_FROM_PAYMENT_GATEWAYS: {
    key: FAILED_REFUND_FROM_PAYMENT_GATEWAYS,
    hidden: false,
    description: 'Use this refund type only when the refund needs to be manually processed from the payment gateway.',
    can_select_variant: true,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: null,
      is_refunded_surcharge: null,
      mark_cancelled: null,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_MANUAL],
    },
  },
  BEDBANK_AUTOMATIC_REFUND: {
    key: BEDBANK_AUTOMATIC_REFUND,
    hidden: true,
    description: 'Automatic refund',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      comment: 'Automatic refund',
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS],
    },
  },
  TOUR_AUTOMATIC_REFUND: {
    key: TOUR_AUTOMATIC_REFUND,
    hidden: true,
    description: 'Automatic refund',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      comment: 'Automatic refund',
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS],
    },
  },
  AO_AUTOMATIC_REFUND: {
    key: AO_AUTOMATIC_REFUND,
    hidden: true,
    description: 'Automatic refund',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      comment: 'Automatic refund',
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS],
    },
  },
  RENTAL_AUTOMATIC_REFUND: {
    key: RENTAL_AUTOMATIC_REFUND,
    hidden: true,
    description: 'Automatic refund',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      comment: 'Automatic refund',
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS],
    },
  },
  REFUND_TEST_PURCHASE: {
    key: REFUND_TEST_PURCHASE,
    hidden: false,
    description: 'Should only be used to refund test purchases made for the purpose of testing the setup of an offer',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: true,
      mark_cancelled: true,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_DEFAULT],
    },
  },
  EXPIRED_BNBL_PACKAGE: {
    key: EXPIRED_BNBL_PACKAGE,
    hidden: false,
    description:
      "Only to be used in circumstances where a customer with a 'buy now, book later' package has not selected dates within the book by date",
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: null,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: false,
      methods: [REFUND_METHOD_CREDITS],
    },
  },
  INSURANCE_BREAKAGE: {
    key: INSURANCE_BREAKAGE,
    label: 'Insurance letter',
    hidden: false,
    description:
      'Customer wishes to file an insurance claim or has been paid out by insurance. Order will be cancelled and customer will not receive a refund from LE',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: false,
      mark_cancelled: true,
      send_refund_notification: false,
      send_customer_refund_notification: false,
      comment: 'Insurance breakage',
      methods: [REFUND_METHOD_MANUAL],
    },
  },
};

export const COLLECTION_REASONS = collectionReasons;

export const CH_WITHIN_CANCELLATION_PERIOD = 'Within cancellation period';
export const CH_OUTSIDE_CANCELLATION_PERIOD = 'Outside of cancellation period';
export const CH_GOODWILL = 'Goodwill';

export const CAR_HIRE_REASONS: CollectionReasons = {
  CH_WITHIN_CANCELLATION_PERIOD: {
    key: CH_WITHIN_CANCELLATION_PERIOD,
    hidden: false,
    description:
      'The customer wants to cancel their booking within the cancellation period. Refund should be provided to customer. No additional charge.',
    can_select_variant: false,
    defaults: {
      source: DEFAULT_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: null,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT],
    },
  },
  CH_OUTSIDE_CANCELLATION_PERIOD: {
    key: CH_OUTSIDE_CANCELLATION_PERIOD,
    hidden: false,
    description:
      'Customer wants to cancel their booking outside of cancellation period. The booking can be cancelled but no refund provided.',
    can_select_variant: false,
    defaults: {
      source: TNFH_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_NONE,
      is_refunded_surcharge: null,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT],
    },
  },
  CH_GOODWILL: {
    key: CH_GOODWILL,
    hidden: false,
    description:
      'Customer wants to cancel their booking outside of cancellation period and goodwill has been agreed to be provided. LE will bear the cost.',
    can_select_variant: true,
    defaults: {
      source: TNFH_REASON_SOURCE,
      variant_of_refund: VARIANT_OF_REFUND_ALL,
      is_refunded_surcharge: null,
      mark_cancelled: true,
      send_refund_notification: true,
      send_customer_refund_notification: true,
      methods: [REFUND_METHOD_DEFAULT, REFUND_METHOD_CREDITS, REFUND_METHOD_MANUAL],
    },
  },
};

export const SUBSCRIPTION_REASONS = [
  COLLECTION_REASONS.MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION,
  COLLECTION_REASONS.FRAUD_AND_COMPLIANCE_AND_DISPUTE,
  COLLECTION_REASONS.FAILED_REFUND_FROM_PAYMENT_GATEWAYS,
];

export const SUBSCRIPTION_REASONS_V2: CollectionReasons = {
  MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION:
    COLLECTION_REASONS.MISLEADING_CONTENT_AND_INCORRECT_PRICING_AND_CUSTOMER_RETENTION,
  FRAUD_AND_COMPLIANCE_AND_DISPUTE: COLLECTION_REASONS.FRAUD_AND_COMPLIANCE_AND_DISPUTE,
  FAILED_REFUND_FROM_PAYMENT_GATEWAYS: COLLECTION_REASONS.FAILED_REFUND_FROM_PAYMENT_GATEWAYS,
};

export const REFUND_V2_BETA_TESTERS = [
  '1eddbe6e-4d2d-4734-9499-02acd49c4a9b', // robert preswick
  'ddd070be-9155-4aaf-8100-ff718d857ad9', // koren sela
  '0386d4bc-43f1-4f1a-ada0-c667c3dbffe4', // sam drown
  '7ac65b6f-b37b-4036-a71f-9787f56c6741', // georgia wahib
  '1162b448-6c51-4e32-bbc6-5d660e5d7ba0', // eugene moskvita
  'fbdc39f3-d5d3-488a-b6ed-5a0589dda16b', // caroline ball
  'cdc72666-cb3d-4920-a7a4-a8c66e2f2fae', // tammie russell
  '24554ed6-51b6-4ff5-965f-279ad1a924d4', // robert rossi
  '9725dcf0-1011-4d93-99af-c6d9a8358009', // KP
  '43318e38-8008-4bbe-940f-e6846d186182', // lachlan mellington
  '37b89660-7231-4b09-8e1a-f57c58712db2', // wendy foerster
  'ac88d068-09ea-4ad1-95a4-51af22785504', // riley laverty
  'df2001c9-252b-48af-80c9-df0f186b313f', // hannah eaton
  '423ec44b-ad7f-4e13-bfc7-97effe41e615', // bill helwe
];

export const DETAILED_REFUND_TYPES = {
  'Change of Mind Within Refund Guarantee Period': {
    'Better Deal Found': 'Cancellations made because the customer finds a more advantageous deal or offer elsewhere.',
    'Booking Errors':
      'Issues arising from mistakes during the booking process, such as selecting incorrect dates, rooms, or making duplicate bookings.',
    'Change of Plans':
      'Adjustments or changes in personal or travel plans that require cancellations or modifications to the booking.',
    'Medical Reasons':
      'Cancellations due to health issues or medical emergencies affecting the customer or their immediate family.',
  },
  'Late Change of Mind, Bad Experience or Unfortunate Events': {
    'Booking Errors or Issues':
      'Errors such as double bookings, incorrect dates, or issues with booking processes that lead to cancellations. This includes issues like being booked in the wrong system or expired book-by dates. These cancellations are approved without penalty by the property or vendor.',
    'Change of Mind or Plans':
      'Guests who change their travel plans or no longer wish to visit for personal reasons, such as deciding not to visit or changing accommodation. These cancellations are approved without penalty by the property or vendor.',
    'Flight or Travel Disruptions':
      'Cancellations due to flights being canceled or travel disruptions that prevent the guest from making the trip. These cancellations are approved without penalty by the property or vendor.',
    'Medical and Compassionate Grounds':
      'Cancellations due to serious medical conditions or emergencies. These cancellations are approved without penalty by the property or vendor.',
    'Negative Feedback or Experience':
      'Cancellations requested due to poor feedback or negative experiences at the property. In such cases, the property may offer partial refunds or credits to address dissatisfaction.',
    'Personal Reasons':
      "Personal circumstances that affect the guest's ability to travel, such as family emergencies, bereavements, or significant life changes. These cancellations are approved without penalty by the property or vendor.",
    'Refund Processing Issues':
      'Cancellations due to issues with refunds, such as incorrect refunds or missing transfers. These are addressed by processing refunds or credits as appropriate (e.g., refund for arrival transfer not arranged, refund with adjusted percentage).',
    'Unused Services':
      'Refund given for services or experiences that were not used (Should be under Late Change of Mind with vendor approving refund. Unless at LE expense)',
    'Vendor or Property Issues':
      "Cancellations due to vendor or property-specific issues such as overbooking, no availability, or property rejection. These are usually handled by issuing credits or refunds based on the vendor's approval (e.g., hotel overbooked, property rejection).",
  },
  'Misleading Content, Incorrect Pricing & Customer Retention': {
    'Booking Changes':
      'Refund issued due to changes or issues with bookings, such as cancellations or misunderstandings.',
    Misinformation: 'Refund issued due to incorrect or misleading information given by agent.',
    'Negative Experience': 'Compensation provided because the customer had a negative experience.',
    'Processing Errors': 'Refund issued due to errors in processing promotions or membership requests.',
    'Promotion Issues': 'Refund given due to problems applying promotional codes or discounts at time of purchase.',
    'Service Quality Issues': 'Refund granted due to poor service or handling by the agent.',
    'System Problems': 'Refund approved due to system issues affecting bookings or cancellations.',
    'Website Clarity': 'Refund granted because of unclear information on the website.',
  },
  'Package Amendment': {
    'Additional Guests and Configurations': 'Adding extra guests or modifying the number of travelers.',
    'Cancellation and Rebooking':
      'Requests to cancel a booking and rebook with new dates, deals, or corrected details.',
    'Date Change':
      'Adjustments to the booking dates where a difference in surcharge is applicable or any other date change that requires a cancel rebook',
    Downgrades: 'Requests downgrade room types or packages.',
    'Error Resolutions':
      'Addressing and correcting booking errors, including issues with incorrect details, payment discrepancies, or system errors.',
    Extensions: 'Requests to extend the stay',
    'Incorrect Account or Booking Details':
      'Problems with bookings made under the wrong account or with incorrect details (e.g., wrong room type, wrong dates) that require correction or cancellation.',
    'Promotion and Pricing Adjustments':
      'Handling the application of promotional codes, correcting pricing discrepancies, or adjusting payments in response to changes in offers or deals.',
    Reductions: 'Requests to shorten the stay',
    Upgrades: 'Requests to upgrade room types or packages.',
  },
};
export function getDetailedReason(reason: CollectionReason) {
  if (Object.keys(DETAILED_REFUND_TYPES).includes(reason.key)) {
    return DETAILED_REFUND_TYPES[reason.key];
  }
  return undefined;
}

export const REFUND_V2 = 'V2';
export const REFUND_V1 = 'V1';
