import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

import {
  ServerConnectionCancellationPolicy,
  buildCancellationPolicies,
  buildPartiallyRefundableCancellationPolicies,
  connectionBuildCancellationPolicies,
  connectionCancellationPolicyMap,
  getExpiredCancellationPolicy,
} from '@luxuryescapes/lib-refunds';

import { PROVIDER_NIB } from '~/consts/insurance';
import {
  ALL_STEPS,
  CAR_HIRE_REASONS,
  COLLECTION_REASONS,
  INSURANCE_BREAKAGE,
  LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS,
  REFUND_TEST_PURCHASE,
  STEP_ADDITIONAL_INFO,
  STEP_BEDBANK_AUTOMATIC_REFUND,
  STEP_CONNECTION_AUTOMATIC_REFUND,
  STEP_LATE_INSURANCE_REFUND,
  STEP_PREREFUND_NIB_INSURANCE,
  STEP_SELECT_TYPE_OF_REFUND,
  STEP_SUMMARY,
  STEP_TOUR_AUTOMATIC_REFUND,
  STEP_VENDOR_EMAIL_REMINDER,
  VARIANT_OF_REFUND_ALL,
  VARIANT_OF_REFUND_NONE,
  VARIANT_OF_REFUND_PARTIAL,
  VARIANT_OF_REFUND_PARTIAL_CALCULATED,
  VARIANT_OF_REFUND_SURCHARGE,
  VENDOR_REFUND_AGREES_NUMBER,
} from '~/consts/refund';

import { getOrderRefundInfo } from '~/services/OrdersService';
import { getPaymentScheduleDetails } from '~/services/PaymentsService';
import ReservationService from '~/services/ReservationService';
import { addDays, dateNowUtc, diffDays } from '~/services/TimeService';

import getDateDayDiff from '~/utils/getDateDayDiff';
import { calculateMaxAmountOfRefund } from '~/utils/refund';

import ErrorDisplay from '../Common/ErrorDisplay';
import Spinner from '../Common/Spinner';

import AdditionalInfo from './AdditionalInfo';
import BedbankAutomaticRefund from './BedbankAutomaticRefund';
import ConnectionAutomaticRefund from './ConnectionAutomaticRefund';
import LateInsuranceRefund from './LateInsuranceRefund';
import LinkBack from './LinkBack';
import PreRefundInsurance from './PreRefundInsurance';
import SelectTypeOfRefund from './SelectTypeOfRefund';
import Summary from './Summary';
import TourAutomaticRefund from './TourAutomaticRefund';
import buildAccountingMetadataPayload from './utils/buildAccountingMetadataPayload';
import calculateAccountingAmount from './utils/calculateAccountingAmount';
import { calculateTotalRefund } from './utils/calculateTotalRefund';
import { hasRefundItem } from './utils/checkHasRefundItem';
import { hasRefundSurcharge } from './utils/checkHasRefundSurcharge';
import { getItemRefundAmount } from './utils/getItemRefundAmount';
import { getSurchargeRefundAmount } from './utils/getSurchargeRefundAmount';

const formatModalHeader = ({
  total_sale_price: salePrice,
  currency_code: currencyCode,
  is_addon: isAddon,
  is_bedbank: isBedbank,
  is_tour_v2: isTourV2,
  is_deposit: isDeposit,
  is_instalment: isInstalment,
  is_pending_deferred_payment: isDeferredPayment,
  automatic,
  merchant_fee_amount,
}: Refund) => {
  const formattedAmount = currencyFormatter.format(salePrice + merchant_fee_amount, {
    code: currencyCode,
  });

  let typeOfItem = 'Package price';
  let typeOfRefund = 'Issue Refund';

  if (isAddon) {
    typeOfItem = 'Addon price';
  } else if (isBedbank) {
    typeOfItem = 'Room price';
  } else if (isTourV2) {
    typeOfItem = 'TourV2 price';
  } else if (isDeposit) {
    typeOfRefund = 'Issue Deposit Refund';
    typeOfItem = 'Refund amount';
  } else if (isInstalment) {
    typeOfRefund = 'Issue Instalment Refund';
    typeOfItem = 'Refund amount';
  } else if (isDeferredPayment) {
    typeOfRefund = 'Issue Reserve for $0 Refund';
    typeOfItem = 'Refund amount';
  }

  if (automatic) {
    typeOfRefund = 'Automatic Refund';
  }
  return `${typeOfRefund} - ${typeOfItem} ${formattedAmount} ${currencyCode}`;
};

export interface Refund {
  reason: (typeof COLLECTION_REASONS)[keyof typeof COLLECTION_REASONS]['key'];
  methods: Array<any>;
  refund_method: string;
  refund_policies: Array<string>;
  expired_cancellation_policy: ServerConnectionCancellationPolicy | undefined;
  automatic: boolean;
  is_accommodation: boolean;
  is_addon: boolean;
  is_experience: boolean;
  is_insurance: boolean;
  is_bedbank: boolean;
  is_tour: boolean;
  is_ttc_tour: boolean;
  is_expired_tour_refund: boolean;
  is_deposit: boolean;
  out_of_cooling_off: boolean;
  item_id: string;
  room_id: string;
  id_orders: string;
  has_reservation: boolean;
  has_surcharge: boolean;
  is_refunded_surcharge: boolean;
  currency_code: any;
  package_cost_price_data: any;
  package_sale_price: number;
  extra_nights_cost_price_data: any;
  extra_nights_sale_price: number;
  total_sale_price: number;
  surcharge_price: number;
  package_nights: number;
  extra_nights: number;
  total_nights: number;
  number_of_nights: number;
  comment: string;
  ticket_id: string;
  nib_reference_id: string;
  mark_cancelled: boolean;
  send_refund_notification: boolean;
  send_customer_refund_notification: boolean;
  override: boolean;
  update_vcc_balance: boolean;
  source: 'Custom' | 'Default' | '';
  variant_of_refund:
    | typeof VARIANT_OF_REFUND_ALL
    | typeof VARIANT_OF_REFUND_PARTIAL
    | typeof VARIANT_OF_REFUND_PARTIAL_CALCULATED
    | typeof VARIANT_OF_REFUND_SURCHARGE
    | typeof VARIANT_OF_REFUND_NONE;
  refund_vendor_agrees_to: typeof VENDOR_REFUND_AGREES_NUMBER | null;
  percentage: number;
  item_metadata: RefundAttributes;
  surcharge_metadata: RefundAttributes;
  charge_component_count: number;
  tmp: {
    amount: string | number;
    fee: string | number;
  };
  missing_cost_price_data: boolean;
  disable_credits_promo_refund: boolean;
  remaining_deposit_items: Array<App.OrderItem> & Array<App.OrderTourItem>;
  current_deposit_item: App.OrderItem & App.OrderTourItem;
  is_final_deposit_refund: boolean;
  refund_provider_fee: boolean;
  is_instalment: boolean;
  remaining_instalment_items: Array<App.OrderItem> & Array<App.OrderTourItem>;
  current_instalment_item: App.OrderItem & App.OrderTourItem;
  is_pending_deferred_payment: boolean;
  remaining_deferred_payment_items: Array<App.OrderItem> & Array<App.OrderTourItem>;
  current_deferred_payment_item: App.OrderItem & App.OrderTourItem;
  business_credit_refund_amount: number;
  is_tour_v2?: boolean;
  is_car_hire?: boolean;
  is_cruise?: boolean;
  is_subscription?: boolean;
  merchant_fee_amount: number;
  is_booking_protection: boolean;
  pro_rata_subscription_refund_amount: number;
  channel_manager: string | null;
  refund_subtype?: string;
  has_manager_approval: boolean;
  needs_manager_approval: boolean;
  partial_refund_enabled: boolean;
}

interface Props {
  show: boolean;
  onHide: () => void;
  refreshData: () => unknown;
  order: App.Order;
  offer?: App.Offer;
  itemId: string;
  roomId?: string;
  automatic: boolean;
  itemType: string;
  isAfterChangeDate: boolean;
  refundApprover?: string;
  isRefundable?: boolean;
}

interface State {
  refund: Refund;
  step: (typeof ALL_STEPS)[number];
  loading: boolean;
  step_back: (typeof ALL_STEPS)[number] | null;
  failed: boolean;
  error_msg: string;
}

export interface RefundAttributes {
  charge_component_key?: string;
  transaction_key?: string;
  cancel_refund?: any;
  promo_amount: number;
  promo_percentage: number;
  accounting_amount: number;
  initial_amount: number;
  cash_amount_original: number;
  cash_amount: number;
  refund_fee: number;
  fee: number;
  source?: string;
  include_promo: boolean;
  vendor_refund_fee?: number;
}

export interface AccountingMetaPayload {
  source: string;
  refund_fee: string;
  cash_amount: string;
  accounting_amount: string;
  charge_component_key: string;
}

export default class RefundModal extends React.Component<Props, State> {
  state: State = {
    loading: true,
    step: STEP_SELECT_TYPE_OF_REFUND,
    step_back: null,
    failed: false,
    error_msg: '',
    refund: null,
  };

  async componentDidUpdate() {
    if (this.props.show && this.state.loading) {
      const [loadData, paymentSchedule] = await Promise.all([
        getOrderRefundInfo({
          orderId: this.props.order.id_orders,
          itemId: this.props.itemId,
          roomId: this.props.roomId,
          refundType: this.props.automatic ? 'auto' : '',
        }),
        getPaymentScheduleDetails(this.props.order.id_orders),
      ]);

      let refundReason = this.state.refund?.reason || '';
      let refundPolicies = [];
      let expiredCancellationPolicy;
      let firstStep = this.state.step || STEP_SELECT_TYPE_OF_REFUND;
      const date = addDays(21, new Date(this.props.order.created_at));
      // +1 to include start date
      const outOfCoolingOff = diffDays(date) + 1 <= 0;

      if (loadData.is_accommodation && loadData.has_connection) {
        const accomodationItem = this.props.order.items.find((item) => item.id === this.props.itemId);

        const property = (await ReservationService.getProperty(accomodationItem.offer_package.fk_property_id)).result;

        refundPolicies = connectionBuildCancellationPolicies(
          accomodationItem.reservation.connection.cancellation_policies.map(connectionCancellationPolicyMap),
          {
            timezone: property.timezone,
            regionCode: this.props.order.region_code,
            currencyCode: this.props.order.currency_code,
          },
        ).description;

        expiredCancellationPolicy = getExpiredCancellationPolicy(
          accomodationItem.reservation.connection.cancellation_policies,
          property.timezone,
        );
      }

      if (this.props.automatic) {
        firstStep = STEP_CONNECTION_AUTOMATIC_REFUND;
        refundReason = COLLECTION_REASONS.AO_AUTOMATIC_REFUND.key;
      }

      if (
        loadData.is_insurance &&
        this.props.order.insurance_items.find((item) => item.id_insurance_items === this.props.itemId).provider ===
          PROVIDER_NIB
      ) {
        refundReason = outOfCoolingOff
          ? COLLECTION_REASONS.LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS.key
          : COLLECTION_REASONS.CHANGE_OF_MIND_WITHIN_REFUND_GUARANTEE_PERIOD.key;
        firstStep = STEP_PREREFUND_NIB_INSURANCE;
      }

      if (loadData.is_bedbank) {
        if (this.props.automatic) {
          firstStep = STEP_BEDBANK_AUTOMATIC_REFUND;
          refundReason = COLLECTION_REASONS.BEDBANK_AUTOMATIC_REFUND.key;
        }

        const bedbankItem = this.props.order.bedbank_items.find((item) =>
          item.rooms.find((room) => room.id === this.props.roomId),
        );

        const refundItem = this.props.order.bedbank_items
          .map((item) => item.rooms.find((room) => room.id === this.props.roomId))
          .filter(Boolean) // remove non-related rooms
          .pop();

        refundPolicies = refundItem.partially_refundable
          ? buildPartiallyRefundableCancellationPolicies(
              {
                checkIn: bedbankItem.check_in,
                checkOut: bedbankItem.check_out,
                policies: refundItem.cancellation_policies,
                nonRefundableDates: refundItem.nonrefundable_dateranges,
              },
              {
                timezone: bedbankItem.offer.timezone,
              },
            ).description
          : buildCancellationPolicies(refundItem.cancellation_policies, {
              regionCode: this.props.order.region_code,
              currencyCode: this.props.order.currency_code,
              timezone: bedbankItem.offer.timezone,
            }).description;
      }

      if (loadData.is_tour) {
        if (this.props.automatic) {
          firstStep = STEP_TOUR_AUTOMATIC_REFUND;
          refundReason = COLLECTION_REASONS.TOUR_AUTOMATIC_REFUND.key;
        }

        refundPolicies = buildCancellationPolicies(null, {
          timezone: '',
          regionCode: this.props.order.region_code,
          currencyCode: this.props.order.currency_code,
        }).description;
      }

      const paymentScheduleItems =
        paymentSchedule.result?.payment_schedules.map((schedule) => schedule.items).flatMap((item) => item) || [];
      const paymentScheduleExists = paymentScheduleItems.find((item) => item.id_items === this.props.itemId);

      this.setState({
        loading: false,
        step: firstStep,
        step_back: null,
        failed: false,
        error_msg: '',
        refund: {
          reason: refundReason,
          methods: [],
          refund_method: '',
          refund_policies: refundPolicies,
          expired_cancellation_policy: expiredCancellationPolicy,
          automatic: this.props.automatic,
          is_accommodation: loadData.is_accommodation,
          is_addon: loadData.is_addon,
          is_experience: loadData.is_experience,
          is_insurance: loadData.is_insurance,
          is_bedbank: loadData.is_bedbank,
          is_tour: loadData.is_tour,
          is_ttc_tour: loadData.is_tour && !this.props.order.tour_items[0]?.vendor_id,
          is_expired_tour_refund: this.props.isAfterChangeDate,
          is_deposit: loadData.is_deposit,
          is_car_hire: loadData.is_car_hire,
          is_cruise: loadData.is_cruise,
          is_subscription: loadData.is_subscription,
          out_of_cooling_off: outOfCoolingOff,
          item_id: this.props.itemId,
          room_id: this.props.roomId,
          id_orders: this.props.order.id_orders,
          has_reservation: loadData.has_reservation,
          has_surcharge: loadData.has_surcharge,
          is_refunded_surcharge: null,
          currency_code: this.props.order.currency_code,
          package_cost_price_data: loadData.cost_price_data,
          package_sale_price: loadData.sales_price,
          extra_nights_cost_price_data: loadData.extra_nights_cost_price_data,
          extra_nights_sale_price: loadData.extra_nights_sale_price,
          total_sale_price: loadData.sales_price,
          surcharge_price: loadData.surcharge_price,
          package_nights: loadData.nights,
          extra_nights: loadData.extra_nights,
          total_nights: loadData.nights + loadData.extra_nights,
          number_of_nights: loadData.nights + loadData.extra_nights,
          comment: '',
          ticket_id: '',
          nib_reference_id: '',
          mark_cancelled: null,
          send_refund_notification: false,
          send_customer_refund_notification: false,
          override: false,
          source: loadData.is_insurance ? 'Default' : '',
          variant_of_refund: null,
          update_vcc_balance: false,
          refund_vendor_agrees_to: null,
          percentage: 100,
          item_metadata: loadData.item_metadata,
          surcharge_metadata: loadData.surcharge_metadata,
          charge_component_count: 1,
          tmp: {
            amount: '',
            fee: '',
          },
          missing_cost_price_data: loadData.missing_cost_price_data,
          disable_credits_promo_refund: loadData.disable_credits_promo_refund,
          remaining_deposit_items: loadData.remaining_deposit_items,
          current_deposit_item: loadData.current_deposit_item,
          is_final_deposit_refund: loadData.is_final_deposit_refund,
          refund_provider_fee: false,
          is_instalment: loadData.is_instalment,
          remaining_instalment_items: loadData.remaining_instalment_items,
          current_instalment_item: loadData.current_instalment_item,
          is_pending_deferred_payment: loadData.is_pending_deferred_payment,
          remaining_deferred_payment_items: loadData.remaining_deferred_payment_items,
          current_deferred_payment_item: loadData.current_deferred_payment_item,
          business_credit_refund_amount: loadData.business_credit_refund_amount,
          merchant_fee_amount: loadData.merchant_fee_amount,
          is_booking_protection: loadData.is_booking_protection,
          pro_rata_subscription_refund_amount: loadData.pro_rata_subscription_refund_amount,
          channel_manager: loadData.channel_manager,
          has_manager_approval: false,
          needs_manager_approval:
            getDateDayDiff(dateNowUtc(new Date()).toISOString(), this.props.order.created_at) > 7 &&
            Boolean(paymentScheduleExists),
          partial_refund_enabled: !paymentScheduleExists,
        },
      });
    }
  }

  itemCancelRefund = () => {
    return this.state.refund.item_metadata.cancel_refund;
  };

  itemRefundOriginal = () => {
    return this.state.refund.item_metadata.cash_amount_original;
  };

  surchargeRefundOriginal = () => {
    if (this.state.refund.has_surcharge) {
      return this.state.refund.surcharge_metadata.cash_amount_original;
    }

    return 0;
  };

  surchargeInfo = () => {
    return {
      currency_code: this.state.refund.currency_code,
      price: this.surchargeRefundOriginal(),
    };
  };

  itemFee = () => {
    return this.state.refund.item_metadata.fee;
  };

  surchargeFee = () => {
    return this.state.refund.surcharge_metadata.fee;
  };

  itemPromoAmount = () => {
    return this.state.refund.item_metadata.promo_amount;
  };

  surchargePromoAmount = () => {
    return this.state.refund.surcharge_metadata.promo_amount;
  };

  totalPromoAmount = () => {
    let amountItem = parseFloat('0.0');
    let amountSurcharge = parseFloat('0.0');
    if (hasRefundItem(this.state.refund)) {
      amountItem = parseFloat(`${this.itemPromoAmount()}`);
    }
    if (hasRefundSurcharge(this.state.refund)) {
      amountSurcharge = parseFloat(`${this.surchargePromoAmount()}`);
    }
    return parseFloat(`${amountItem + amountSurcharge}`).toFixed(2);
  };

  tmpValue = (key) => {
    return this.state.refund.tmp[key];
  };

  totalFee = () => {
    let amountItem = parseFloat('0.0');
    let amountSurcharge = parseFloat('0.0');
    if (hasRefundItem(this.state.refund)) {
      amountItem = parseFloat(`${this.itemFee()}`);
    }
    if (hasRefundSurcharge(this.state.refund)) {
      amountSurcharge = parseFloat(`${this.surchargeFee()}`);
    }
    return parseFloat(`${amountItem + amountSurcharge}`).toFixed(2);
  };

  handleFlagChange = (event) => {
    const key = event.target.name;
    const value = Number(event.target.value);
    const data = {};
    data[key] = value === 1;
    this.updateRefund(data);
  };

  handleValueChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    const data = {};
    data[key] = value;
    this.updateRefund(data);
  };

  handleCommentChange = (value = '') => {
    const data = {};
    const approverString = this.props.refundApprover ? 'Approver: ' + this.props.refundApprover + '\n' : '';
    data['comment'] = approverString + value;
    this.updateRefund(data);
  };

  handleCheckedChange = (event) => {
    const key = event.target.name;
    const value = event.target.checked;
    const data = {};
    data[key] = value;
    this.updateRefund(data);
  };

  handlerOfCalculations = (handlers) => {
    let updateData = {};
    handlers.forEach((handler) => {
      if (handler === 'cash') {
        updateData = Object.assign(updateData, this.handleCashChange());
      } else if (handler === 'fee') {
        updateData = Object.assign(updateData, this.handleFeeChange());
      } else if (handler === 'surcharge') {
        updateData = Object.assign(updateData, this.handleSurchargeChange());
      }
    });
    this.updateRefund(updateData);
  };

  accountingMetaPayload = (accountingMetadata: Array<RefundAttributes>): Array<AccountingMetaPayload> => {
    return buildAccountingMetadataPayload(accountingMetadata);
  };

  handleCashChange = () => {
    return this.calculateCashAmount('item', this.state.refund.tmp.amount as number);
  };

  handleFeeChange = () => {
    return this.calculateCashAmount('item', this.itemRefundOriginal(), this.state.refund.tmp.fee as number);
  };

  handleSurchargeChange = () => {
    const amount = this.surchargeRefundOriginal();
    let fee = 0;

    if (this.state.refund.has_surcharge && !this.state.refund.is_refunded_surcharge) {
      fee = amount;
    }

    return this.calculateCashAmount('surcharge', amount, fee);
  };

  handleTmpChange = (event) => {
    const key = event.target.name;
    let value = event.target.value;

    if (key === 'amount' && Number(value) > this.itemRefundOriginal()) {
      value = this.itemRefundOriginal();
    }

    const data = Object.assign({}, this.state.refund.tmp);
    data[key] = value;
    this.updateRefund({ tmp: data });
  };

  handleVariantChange = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    const Info = this.getReasonInfo();

    const data: Partial<Refund> = Object.assign({}, this.calculateCashAmount('default'), {
      is_refunded_surcharge: Info.defaults.is_refunded_surcharge,
      mark_cancelled: Info.defaults.mark_cancelled,
    });
    data[key] = value;

    if (this.state.refund.reason === LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS) {
      if (value === VARIANT_OF_REFUND_ALL) {
        data['source'] = 'Default';
      } else if (value === VARIANT_OF_REFUND_PARTIAL) {
        data['source'] = 'Custom';
      }
    }

    if (Info.can_select_variant) {
      if (value === VARIANT_OF_REFUND_SURCHARGE) {
        data['mark_cancelled'] = false;
      }
    }

    if (this.state.refund.is_subscription) {
      data['mark_cancelled'] = true;
    }

    if (value === VARIANT_OF_REFUND_PARTIAL_CALCULATED) {
      if (typeof data.tmp !== 'undefined' && typeof data.tmp.amount !== 'undefined') {
        data['tmp']['amount'] = this.calculateAmountAgainstPolicies();
      }
    }

    this.updateRefund(data);
  };

  calculateAmountAgainstPolicies = () => {
    let amount = this.itemRefundOriginal();
    if (this.state.refund.expired_cancellation_policy) {
      amount = amount - this.state.refund.expired_cancellation_policy.amountPercent.sell;
    }
    return amount;
  };

  setError = (error_msg) => {
    this.setState({
      failed: true,
      error_msg: error_msg,
    });
  };

  clearError = () => {
    this.setState({
      failed: false,
      error_msg: '',
    });
  };

  setBackStep = (step) => {
    this.setState({ step_back: step });
  };

  stepToggle = (step) => {
    this.clearError();
    this.setState({ step: step });
  };

  stepHandler = (validate) => {
    this.clearError();
    validate(this.setError, this.stepToggle);
  };

  findReasonInfoByKey = (key): (typeof COLLECTION_REASONS)[keyof typeof COLLECTION_REASONS] => {
    let Info = {} as (typeof COLLECTION_REASONS)[keyof typeof COLLECTION_REASONS];
    Object.keys(COLLECTION_REASONS).map((index) => {
      if (COLLECTION_REASONS[index].key === key) {
        Info = COLLECTION_REASONS[index];
      }
    });
    if (!Info.key) {
      Object.keys(CAR_HIRE_REASONS).map((index) => {
        if (CAR_HIRE_REASONS[index].key === key) {
          Info = CAR_HIRE_REASONS[index];
        }
      });
    }
    return Info;
  };

  getReasonInfo = () => {
    return this.findReasonInfoByKey(this.state.refund.reason);
  };

  calculateRefundDataByType = ({ type, refund, amount, fee }) => {
    const data = {};
    const keys = [];

    if (type === 'item') {
      keys.push('item_metadata');
    } else if (type === 'surcharge') {
      keys.push('surcharge_metadata');
    } else {
      keys.push('item_metadata');
      if (refund.has_surcharge) {
        keys.push('surcharge_metadata');
      }
      data['tmp'] = {
        amount: '',
        fee: '',
      };
    }

    const feeAmount = Number(fee ? fee : 0);
    const inputs = amount ? { amount: Number(amount), fee: feeAmount } : null;

    keys.forEach((key) => {
      if (refund[key].initial_amount > refund[key].refund_fee) {
        data[key] = Object.assign({}, refund[key], {
          cash_amount: inputs ? inputs.amount - inputs.fee : refund[key].cash_amount_original,
          accounting_amount: calculateAccountingAmount(refund[key], refund, inputs),
          fee: feeAmount,
        });
      }
    });

    return data;
  };

  calculateCashAmount = (type, amount = 0, fee = 0) => {
    return this.calculateRefundDataByType({
      type: type,
      refund: this.state.refund,
      amount: amount,
      fee: fee,
    });
  };

  updateRefund = (data) => {
    let refund = Object.assign({}, this.state.refund);
    let source = {};

    let item_metadata = Object.assign({}, refund.item_metadata);
    if (data.item_metadata) {
      item_metadata = Object.assign(item_metadata, data.item_metadata);
    }
    let surcharge_metadata = Object.assign({}, refund.surcharge_metadata);
    if (data.surcharge_metadata) {
      surcharge_metadata = Object.assign(surcharge_metadata, data.surcharge_metadata);
    }

    if (data.source) {
      source = {
        source: data.source,
        item_metadata: Object.assign(item_metadata, {
          source: data.source,
        }),
        surcharge_metadata: Object.assign(surcharge_metadata, {
          source: data.source,
        }),
      };
    }

    if (data.refund_provider_fee) {
      data.refund_provider_fee = JSON.parse(data.refund_provider_fee);
    }

    refund = Object.assign(
      {},
      refund,
      data,
      refund.variant_of_refund === VARIANT_OF_REFUND_NONE
        ? this.calculateCashAmount('item', this.itemRefundOriginal(), this.itemRefundOriginal())
        : {},
      source,
    );

    const maxRefundAmount = calculateMaxAmountOfRefund(refund, data);
    if (maxRefundAmount !== null) {
      refund = Object.assign(refund, {
        tmp: {
          amount: maxRefundAmount,
        },
      });
    }

    this.setState({
      refund: refund,
    });
  };

  setDefaultValuesForReason = (overriddenDefaults) => {
    const Info = this.getReasonInfo();
    const refundData: Partial<Refund> = {
      send_refund_notification: false,
      send_customer_refund_notification: false,
      refund_vendor_agrees_to: null,
      refund_method: '',
      tmp: {
        amount: '',
        fee: '',
      },
      comment: this.props.refundApprover ? 'Approver: ' + this.props.refundApprover + '\n' : '',
    };

    if (this.state.refund.has_reservation && this.state.refund.reason !== INSURANCE_BREAKAGE) {
      refundData.send_refund_notification = true;
      refundData.send_customer_refund_notification = true;
    }

    if (this.state.refund.reason === LATE_CHANGE_OF_MIND_OR_BAD_EXPERIENCE_OR_UNFORTUNATE_EVENTS) {
      refundData.refund_vendor_agrees_to = VENDOR_REFUND_AGREES_NUMBER;
    }

    if (this.state.refund.is_insurance) {
      refundData.mark_cancelled = true;
      refundData.variant_of_refund = VARIANT_OF_REFUND_ALL;
    }

    if (this.state.refund.reason === REFUND_TEST_PURCHASE) {
      refundData.ticket_id = 'N/A';
    } else {
      refundData.ticket_id = '';
    }

    if (this.state.refund.is_subscription) {
      refundData.mark_cancelled = true;
    }

    // Reset the refund subtype between reasons
    refundData.refund_subtype = undefined;

    this.updateRefund(
      Object.assign({}, Info.defaults, refundData, overriddenDefaults || {}, this.calculateCashAmount('default')),
    );
  };

  closeModal = () => {
    this.props.refreshData();
  };

  render() {
    if (this.state.loading) {
      return (
        <Dialog open={this.props.show}>
          <DialogContent>
            <Spinner />
          </DialogContent>
        </Dialog>
      );
    }

    return (
      <Dialog
        open={this.props.show}
        onClose={this.state.step === STEP_VENDOR_EMAIL_REMINDER ? this.closeModal : this.props.onHide}
      >
        <DialogTitle>
          <Stack spacing={2}>
            {this.state.step_back && this.state.step !== STEP_VENDOR_EMAIL_REMINDER && (
              <LinkBack stepToggle={this.stepToggle} stepBack={this.state.step_back} />
            )}
            {this.state.step === STEP_VENDOR_EMAIL_REMINDER ? 'Success!' : formatModalHeader(this.state.refund)}
          </Stack>
        </DialogTitle>

        <DialogContent>
          {this.state.refund.business_credit_refund_amount > 0 && (
            <Alert severity="warning">
              This includes refund of{' '}
              {currencyFormatter.format(
                this.state.refund.business_credit_refund_amount,
                this.state.refund.currency_code,
              )}{' '}
              for business credit.
            </Alert>
          )}

          {this.state.failed && <ErrorDisplay message={this.state.error_msg} />}
          {this.state.step === STEP_PREREFUND_NIB_INSURANCE && (
            <PreRefundInsurance
              setDefaultValuesForReason={this.setDefaultValuesForReason}
              updateRefund={this.updateRefund}
              stepHandler={this.stepHandler}
              setBackStep={this.setBackStep}
              outOfCoolingOff={this.state.refund.out_of_cooling_off}
              closeModal={this.closeModal}
            />
          )}
          {this.state.step === STEP_SELECT_TYPE_OF_REFUND && (
            <SelectTypeOfRefund
              setDefaultValuesForReason={this.setDefaultValuesForReason}
              handleCheckedChange={this.handleCheckedChange}
              handleValueChange={this.handleValueChange}
              setBackStep={this.setBackStep}
              stepHandler={this.stepHandler}
              refund={this.state.refund}
              itemType={this.props.itemType}
              order={this.props.order}
              isRefundable={this.props.isRefundable}
            />
          )}
          {this.state.step === STEP_ADDITIONAL_INFO && (
            <AdditionalInfo
              getReasonInfo={this.getReasonInfo}
              handleCheckedChange={this.handleCheckedChange}
              handleValueChange={this.handleValueChange}
              handleFlagChange={this.handleFlagChange}
              handlerOfCalculations={this.handlerOfCalculations}
              handleTmpChange={this.handleTmpChange}
              tmpValue={this.tmpValue}
              handleVariantChange={this.handleVariantChange}
              setBackStep={this.setBackStep}
              stepHandler={this.stepHandler}
              totalRefund={() => calculateTotalRefund(this.state.refund)}
              itemRefund={() => getItemRefundAmount(this.state.refund)}
              itemRefundOriginal={this.itemRefundOriginal}
              surchargeRefund={() => getSurchargeRefundAmount(this.state.refund)}
              surchargeRefundOriginal={this.surchargeRefundOriginal}
              refund={this.state.refund}
              surchargeInfo={this.surchargeInfo()}
              handleCommentChange={this.handleCommentChange}
            />
          )}
          {this.state.step === STEP_LATE_INSURANCE_REFUND && (
            <LateInsuranceRefund
              setError={this.setError}
              handleValueChange={this.handleValueChange}
              hasRefundSurcharge={() => hasRefundSurcharge(this.state.refund)}
              hasRefundItem={() => hasRefundItem(this.state.refund)}
              totalPromoAmount={this.totalPromoAmount}
              accountingMetaPayload={this.accountingMetaPayload}
              totalFee={this.totalFee}
              totalRefund={() => calculateTotalRefund(this.state.refund)}
              setBackStep={this.setBackStep}
              refund={this.state.refund}
              order={this.props.order}
              closeModal={this.closeModal}
            />
          )}
          {this.state.step === STEP_SUMMARY && (
            <Summary
              getReasonInfo={this.getReasonInfo}
              setError={this.setError}
              handleCheckedChange={this.handleCheckedChange}
              handleValueChange={this.handleValueChange}
              handleFlagChange={this.handleFlagChange}
              hasRefundSurcharge={() => hasRefundSurcharge(this.state.refund)}
              hasRefundItem={() => hasRefundItem(this.state.refund)}
              totalPromoAmount={this.totalPromoAmount}
              accountingMetaPayload={this.accountingMetaPayload}
              totalFee={this.totalFee}
              totalRefund={() => calculateTotalRefund(this.state.refund)}
              setBackStep={this.setBackStep}
              refund={this.state.refund}
              order={this.props.order}
              closeModal={this.closeModal}
              stepHandler={this.stepHandler}
            />
          )}
          {this.state.step === STEP_BEDBANK_AUTOMATIC_REFUND && (
            <BedbankAutomaticRefund
              setDefaultValuesForReason={this.setDefaultValuesForReason}
              getReasonInfo={this.getReasonInfo}
              setError={this.setError}
              handleCheckedChange={this.handleCheckedChange}
              handleValueChange={this.handleValueChange}
              hasRefundSurcharge={() => hasRefundSurcharge(this.state.refund)}
              hasRefundItem={() => hasRefundItem(this.state.refund)}
              totalPromoAmount={this.totalPromoAmount}
              accountingMetaPayload={this.accountingMetaPayload}
              totalFee={this.totalFee}
              totalRefund={() => calculateTotalRefund(this.state.refund)}
              setBackStep={this.setBackStep}
              refund={this.state.refund}
              order={this.props.order}
              closeModal={this.closeModal}
            />
          )}
          {this.state.step === STEP_TOUR_AUTOMATIC_REFUND && (
            <TourAutomaticRefund
              setDefaultValuesForReason={this.setDefaultValuesForReason}
              getReasonInfo={this.getReasonInfo}
              setError={this.setError}
              handleCheckedChange={this.handleCheckedChange}
              handleValueChange={this.handleValueChange}
              hasRefundSurcharge={() => hasRefundSurcharge(this.state.refund)}
              hasRefundItem={() => hasRefundItem(this.state.refund)}
              totalPromoAmount={this.totalPromoAmount}
              accountingMetaPayload={this.accountingMetaPayload}
              totalFee={this.totalFee}
              totalRefund={() => calculateTotalRefund(this.state.refund)}
              setBackStep={this.setBackStep}
              refund={this.state.refund}
              order={this.props.order}
              closeModal={this.closeModal}
            />
          )}
          {this.state.step === STEP_CONNECTION_AUTOMATIC_REFUND && (
            <ConnectionAutomaticRefund
              setDefaultValuesForReason={this.setDefaultValuesForReason}
              getReasonInfo={this.getReasonInfo}
              setError={this.setError}
              handleCheckedChange={this.handleCheckedChange}
              handleValueChange={this.handleValueChange}
              hasRefundSurcharge={() => hasRefundSurcharge(this.state.refund)}
              hasRefundItem={() => hasRefundItem(this.state.refund)}
              totalPromoAmount={this.totalPromoAmount}
              accountingMetaPayload={this.accountingMetaPayload}
              totalFee={this.totalFee}
              totalRefund={() => calculateTotalRefund(this.state.refund)}
              setBackStep={this.setBackStep}
              refund={this.state.refund}
              order={this.props.order}
              closeModal={this.closeModal}
            />
          )}
          {this.state.step === STEP_VENDOR_EMAIL_REMINDER && (
            <>
              An automated message has been sent to TTC to cancel this tour.{' '}
              <strong>
                <em>ttc.tours@luxuryescapes.com</em>
              </strong>{' '}
              has been cc'd in this email. No further action required.
            </>
          )}
        </DialogContent>

        {this.state.step === STEP_VENDOR_EMAIL_REMINDER && (
          <DialogActions>
            <Button variant="contained" onClick={this.closeModal}>
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}
