import React, { FormEventHandler } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';

import { CollectionReason } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';
import RefundSurcharge from '../Common/RefundSurcharge';

interface Props {
  currencyCode: string;
  decrementStep: () => void;
  defaultCaseId: string;
  defaultComment: string;
  hasSurcharge?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  reason: CollectionReason;
  surchargeAmount?: number;
}

export default function ExpiredBNBLPackage({
  currencyCode,
  decrementStep,
  defaultCaseId,
  defaultComment,
  hasSurcharge = false,
  onSubmit,
  reason,
  surchargeAmount,
}: Props) {
  return (
    <>
      <DialogContent dividers>
        <form onSubmit={onSubmit} id="expired-bnbl-form">
          <CaseIDAndReasons reason={reason} defaultComment={defaultComment} defaultCaseId={defaultCaseId} />
          {hasSurcharge && <RefundSurcharge surchargeAmount={surchargeAmount} currencyCode={currencyCode} />}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained" form="expired-bnbl-form">
          Continue
        </Button>
      </DialogActions>
    </>
  );
}
