import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Button, Container, Stack, Tooltip, Typography } from '@mui/material';

import CustomOfferForm from '~/components/Common/CustomOffers/CustomOfferForm';
import CustomOfferCancelDialog from '~/components/Common/CustomOffers/dialogs/CustomOfferCancelDialog';
import CustomOfferDuplicateDialog from '~/components/Common/CustomOffers/dialogs/CustomOfferDuplicateDialog';
import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';

import { OPERATION_UPDATE_ORDER_CUSTOM_ITEM } from '~/consts/order';

import useToggleState from '~/hooks/useToggleState';

import OffersService from '~/services/OffersService';
import * as OrderService from '~/services/OrdersService';
import { getPayments } from '~/services/PaymentsService';
import UsersService from '~/services/UsersService';

import { titleCase } from '~/utils/stringUtils';

export default function Page() {
  const { id_user: userId, offer_id: offerId } = useParams<{ id_user: string; offer_id: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [user, setUser] = useState<App.User | null>(null);
  const [offer, setOffer] = useState<App.CustomOffer | null>(null);
  const [orders, setOrders] = useState<Array<App.Order>>([]);

  const {
    isToggled: isDuplicateCustomOfferDialogOpen,
    toggleOn: openDuplicateCustomOfferDialog,
    toggleOff: closeDuplicateCustomOfferDialog,
  } = useToggleState();
  const [wasDepositPaid, setWasDepositPaid] = useState<boolean>(false);
  const [paymentsMade, setPaymentsMade] = useState<boolean>(false);
  const [paymentDate, setPaymentDate] = useState<string>(undefined);

  const {
    isToggled: isCancelCustomOfferDialogOpen,
    toggleOn: openCancelCustomOfferDialog,
    toggleOff: closeCancelCustomOfferDialog,
  } = useToggleState();

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const isLoading = fetchingState == 'loading';
  const showForm = fetchingState == 'success';

  const fetchData = useCallback(async () => {
    try {
      setFetchingState('loading');
      const [user, offer, purchases] = await Promise.all([
        UsersService.getUser(userId, { brand }),
        OffersService.getCustomOffer(offerId, { include_payment_metadata: true }),
        OrderService.getPurchases({
          item_type: 'custom_offer',
          filterBy: 'offer_id',
          filterValue: offerId,
          page: 1,
          per_page: 10,
          brand,
        }),
      ]);

      setUser(user);
      setOffer(offer.result);
      setOrders(purchases.result);
      setWasDepositPaid(offer.result.payment_metadata.customer_deposit_payment_made);
      if (purchases.result.length && purchases.result[0]) {
        const paymentInfo = await getPayments(purchases.result[0].id);
        if (paymentInfo.result.length) {
          const successfulPayments = paymentInfo.result.filter((payment) => payment.success);
          if (successfulPayments.length > 0) {
            setPaymentDate(successfulPayments[0].created_at);
            setPaymentsMade(true);
          } else {
            // payment failed
            setPaymentsMade(false);
            setPaymentDate(undefined);
          }
        } else {
          // order has been made, no payments though
          setPaymentsMade(false);
          setPaymentDate(undefined);
        }
      } else {
        // no order and no payments
        setPaymentsMade(false);
        setPaymentDate(undefined);
      }
      setFetchingState('success');
    } catch (err) {
      enqueueSnackbar(`Error loading offer: ${err.message}`, { variant: 'error' });
      setFetchingState('failed');
    }
  }, [userId, offerId, brand, enqueueSnackbar]);

  const handleUpdate = useCallback(
    async (offer: App.CustomOffer) => {
      if (orders.length == 0) {
        return;
      }

      // trigger update for each connected order
      // we need this to correctly update stored snapshot of custom order
      // and related information which is used on the customer portal
      for (const order of orders) {
        await OrderService.updateOrder(order.id, {
          fk_custom_offer_id: offer.id,
          op: OPERATION_UPDATE_ORDER_CUSTOM_ITEM,
        });
      }
    },
    [orders],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth="xl">
      <PageHeader title="Edit custom offer" backButton={`/users/${userId}`}>
        {offer && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Button variant="contained" onClick={openDuplicateCustomOfferDialog}>
              Duplicate
            </Button>
            {offer.status !== 'draft' && (
              <Tooltip title={paymentsMade ? 'Cannot delete offer as customer has made payment' : ''}>
                <div>
                  <Button
                    variant="contained"
                    color="error"
                    disabled={offer.status === 'declined' || paymentsMade}
                    onClick={openCancelCustomOfferDialog}
                  >
                    {wasDepositPaid ? 'Cancel Offer' : 'Delete Offer'}
                  </Button>
                </div>
              </Tooltip>
            )}
            <Typography>
              Offer status: <strong>{titleCase(offer.status)}</strong>
            </Typography>
            <CustomOfferDuplicateDialog
              offer={offer}
              brand={brand}
              isOpen={isDuplicateCustomOfferDialogOpen}
              handleClose={closeDuplicateCustomOfferDialog}
            />
            <CustomOfferCancelDialog
              wasDepositPaid={wasDepositPaid}
              isOpen={isCancelCustomOfferDialogOpen}
              handleClose={closeCancelCustomOfferDialog}
            />
          </Stack>
        )}
      </PageHeader>

      {isLoading && <Spinner />}

      {showForm && !isLoading && (
        <CustomOfferForm
          user={user}
          currentOffer={offer}
          hasOrders={orders.length > 0}
          order={orders[0]}
          onUpdate={handleUpdate}
          paymentDate={paymentDate}
        />
      )}
    </Container>
  );
}
