import React, { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { Box, Button, Stack } from '@mui/material';

import UsersService from '../../services/UsersService';
import UpdateUser from '../Common/UpdateUser';

import UserLoginTable from './UserLoginTable';

interface Props {
  id_user: string;
  user: App.User;
  update: () => void;
}

function UserDetails(props: Props) {
  const [accountStatus] = useState(props.user.status);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null);
  const tenant = useSelector((state: App.State) => state.tenant);
  const UpdateUserFormRef = useRef(null);

  const saveUser = () => {
    UpdateUserFormRef.current.submitButton.click();
  };

  const toggleAccountStatus = async () => {
    await UsersService.patch({
      id_account: props.id_user,
      params: `is_enabled=${accountStatus === 'DISABLED'}`,
    });

    window.location.reload();
  };

  const enabled = accountStatus === 'ENABLED';

  useEffect(() => {
    try {
      UsersService.getUpdateSchema(props.user)
        .then((response) => setResult(response.put))
        .finally(() => setLoading(false));
    } catch (error) {
      reportError(error);
    }
  }, [props.user]);

  return (
    <div>
      <div>
        <Helmet>
          <title>Users | {props.user.fullName}</title>
        </Helmet>
        <Box>
          <h2>{props.user.fullName}</h2>
          {!loading && <UpdateUser schema={result} brand={tenant.brand} user={props.user} ref={UpdateUserFormRef} />}
          <dl key="accountStatus">
            <dt className="h6 text-muted text-uppercase">Account Status</dt>
            <dd>{accountStatus}</dd>
          </dl>
          <UserLoginTable userId={props.id_user} />
        </Box>
      </div>
      <Box className="button-container">
        <Stack direction="row" spacing={2}>
          <Button variant="contained" onClick={saveUser}>
            Save
          </Button>
          <Button variant="contained" color={enabled ? 'error' : 'success'} onClick={toggleAccountStatus}>
            {enabled ? 'De-' : null}Activate Account
          </Button>
        </Stack>
      </Box>
    </div>
  );
}

export default UserDetails;
