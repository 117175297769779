import { RefundRequestRefundOptions, RefundRequestStatuses } from '~/consts/refundRequests';
import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER } from '~/consts/roles';

export const getRefundRequestStatus = (status: string, refundOption: string | null, reviewerUser?: App.User) => {
  const reviewerIsEmployee = reviewerUser?.roles.some((role) => [ROLE_EMPLOYEE_USER, ROLE_ADMIN_USER].includes(role));
  const agentPrefix = reviewerIsEmployee ? `Agent (${reviewerUser?.fullName ?? reviewerUser?.email}) - ` : '';

  if (status === RefundRequestStatuses.rejected) {
    if (refundOption === RefundRequestRefundOptions.letter) {
      return { text: 'Request Rejected - Insurance Letter Downloaded', severity: 'error' };
    } else if (!refundOption) {
      return { text: `${agentPrefix}Request Rejected - Insurance Letter Presented`, severity: 'error' };
    }
  }

  if (status === RefundRequestStatuses.autoRejectedPending || status === RefundRequestStatuses.autoRejected) {
    if (refundOption === RefundRequestRefundOptions.letter) {
      return { text: 'Auto-Rejected - Insurance Letter Downloaded', severity: 'error' };
    } else if (!refundOption) {
      return { text: 'Auto-Rejected - Insurance Letter Presented', severity: 'error' };
    }
  }

  if (status === RefundRequestStatuses.approved) {
    if (refundOption === RefundRequestRefundOptions.credit) {
      return { text: '100% LE Credit Refund', severity: 'success' };
    } else if (refundOption === RefundRequestRefundOptions.backToOrigin) {
      return { text: '80% Original Payment', severity: 'success' };
    } else if (refundOption === RefundRequestRefundOptions.letter) {
      return { text: 'Insurance Letter Downloaded', severity: 'success' };
    } else if (refundOption === RefundRequestRefundOptions.insuranceClaim) {
      return { text: 'Insurance Claim Downloaded', severity: 'success' };
    } else if (!refundOption) {
      return { text: `${agentPrefix}Request Approved - Pending Customer Response`, severity: 'success' };
    }
  }

  if (status === RefundRequestStatuses.expired) {
    return { text: 'Refund Options Expired', severity: 'success' };
  }

  if (status === RefundRequestStatuses.approvedBnbl) {
    return { text: 'Request approved as BNBL', severity: 'success' };
  }

  if (status === RefundRequestStatuses.customerPutOnHold) {
    return { text: 'Customer put on hold', severity: 'success' };
  }

  if (status === RefundRequestStatuses.pending) {
    return { text: 'Request Pending', severity: 'none' };
  }

  if (status === RefundRequestStatuses.cancelled) {
    return { text: 'Request Withdrawn', severity: 'none' };
  }

  return { text: 'Unknown Status', severity: 'info' };
};
