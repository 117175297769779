import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, DialogContent, Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';
import { CancellationPolicy } from '@luxuryescapes/lib-refunds';

import CancellationPolicies from '~/components/Purchases/OrderDetail/BedbankList/CancellationPolicies';

interface Props {
  room: Order.BedbankItemRoom;
  customerCancellationPolicy: CancellationPolicy;
  supplierCancellationPolicy: CancellationPolicy;
  propertyTimezone: string;
  item: any;
}

export default function BedbankCancellationPolicies({
  room,
  customerCancellationPolicy,
  item,
  propertyTimezone,
  supplierCancellationPolicy,
}: Props) {
  return (
    <DialogContent
      dividers
      sx={{
        padding: 0,
      }}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontWeight="medium">Customer Refund Policy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CancellationPolicies
            room={room}
            cancellationPolicy={customerCancellationPolicy}
            propertyTimezone={propertyTimezone}
            refundIssuer="LE"
            nights={item.nights}
          />
        </AccordionDetails>
      </Accordion>
      {!!supplierCancellationPolicy && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontWeight="medium">Supplier Refund Policy</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CancellationPolicies
              room={room}
              cancellationPolicy={supplierCancellationPolicy}
              propertyTimezone={propertyTimezone}
              refundIssuer="Vendor"
              nights={item.nights}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </DialogContent>
  );
}
