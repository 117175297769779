import {
  EXP_PROVIDER_DERBYSOFT,
  EXP_PROVIDER_DERBYSOFT_PREFIX,
  EXP_PROVIDER_LED,
  EXP_PROVIDER_LED_PREFIX,
  EXP_PROVIDER_REZDY,
  EXP_PROVIDER_REZDY_PREFIX,
} from '~/consts/experiences';

const defineVendorId = (items: App.ExperienceItem[]) => {
  const hasMusement = items.some((item) => item.provider === 'mus');
  const hasLED = items.some((item) => item.provider === EXP_PROVIDER_LED_PREFIX);
  const hasRezdy = items.some((item) => item.provider === EXP_PROVIDER_REZDY_PREFIX);
  const hasDerbysoft = items.some((item) => item.provider === EXP_PROVIDER_DERBYSOFT_PREFIX);

  const vendorIds: string[] = [];

  if (hasMusement) {
    vendorIds.push('Musement');
  }

  if (hasRezdy) {
    vendorIds.push(EXP_PROVIDER_REZDY);
  }

  if (hasLED) {
    vendorIds.push(EXP_PROVIDER_LED);
  }

  if (hasDerbysoft) {
    vendorIds.push(EXP_PROVIDER_DERBYSOFT);
  }

  return vendorIds.join(' + ');
};
const addonsVendorFormatter = (row: App.Order) => {
  return row.addon_items.length > 0 ? 'Internal LE' : defineVendorId(row.experience_items);
};

export default addonsVendorFormatter;
