import React from 'react';

import styled from 'styled-components';
import { theme } from '~/theme';

import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

function BaseTooltip({ className, ...props }: TooltipProps) {
  return <Tooltip {...props} classes={{ popper: className }} />;
}

const tooltipStyles = {
  // override tooltip CSS
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[100],
    maxWidth: 220,
    border: '1px solid #dadde9',
  },
};

export const HtmlTooltip = styled(BaseTooltip)(tooltipStyles);
