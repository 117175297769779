import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, CircularProgress, MenuItem, Select, Stack } from '@mui/material';

import { TIER_OPTIONS } from '~/consts/agentHub';

import { Tier } from '~/types/services/agentHub';

interface Props {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  setShowEditTierForm: (show: boolean) => void;
  setTier: (tier: Tier) => void;
  tier: Tier;
}

export default function EditTierForm({ onSubmit, setShowEditTierForm, setTier, tier }: Props) {
  const [isEditingTier, setIsEditingTier] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function handleTierChange(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsEditingTier(true);

    try {
      await onSubmit(event);
      enqueueSnackbar('Agents Tier updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to update agents tier`, { variant: 'error' });
    } finally {
      setShowEditTierForm(false);
      setIsEditingTier(false);
    }
  }

  return (
    <>
      {isEditingTier && <CircularProgress size={20} />}
      {!isEditingTier && (
        <form onSubmit={handleTierChange}>
          <Stack direction="row" spacing={2}>
            <Select id="agent-tier" label="Tier" value={tier} onChange={(event) => setTier(event.target.value as Tier)}>
              {TIER_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            <Stack spacing={1} direction="row">
              <Button variant="contained" type="submit" size="small">
                Save
              </Button>
              <Button variant="outlined" size="small" onClick={() => setShowEditTierForm(false)}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
    </>
  );
}
