import React, { useCallback, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getAffiliationList, getEOI, updateEOIDetails } from '~/services/AgentHub/AgentService';

import { Affiliation, Eoi } from '~/types/services/agentHub';

import { noop } from '~/utils/noop';

import AgencySubmissionDetails from './AgencySubmissionDetails';
import AgencySubmissionEditor from './AgencySubmissionEditor';

interface Props {
  id: string;
  onClose?: () => void;
  opened?: boolean;
}

export default function AgencySubmissionDetailModal({ id, onClose = noop, opened = false }: Props) {
  const [open, setOpen] = useState(opened);
  const [eoiDetails, setEoiDetails] = useState<Eoi | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [availableAffiliations, setAvailableAffiliations] = useState<Array<Affiliation>>([]);
  const [isSaving, setIsSaving] = useState(false);

  const closeModal = useCallback(() => {
    setOpen(false);
    setEditing(false);
    onClose();
  }, [setOpen, onClose]);

  const openModal = useCallback(async () => {
    if (!id) {
      closeModal();
    }

    setOpen(true);

    try {
      setLoading(true);

      const [eoiDetailsResult, availableAffiliationsResult] = await Promise.all([getEOI(id), getAffiliationList()]);

      setEoiDetails(eoiDetailsResult);
      setAvailableAffiliations(availableAffiliationsResult);
    } finally {
      setLoading(false);
    }
  }, [setOpen, closeModal, id]);

  const onSave = useCallback(
    async (eoiData: Eoi) => {
      setIsSaving(true);
      const result = await updateEOIDetails(id, eoiData);

      setEoiDetails(result);
      setEditing(false);
      setIsSaving(false);
    },
    [id],
  );

  const onStatusUpdate = useCallback((eoiData: Eoi) => {
    setEoiDetails(eoiData);
  }, []);

  const onCancel = useCallback(() => {
    setEditing(false);
  }, []);

  useEffect(() => {
    if (opened) {
      openModal();
    }
  }, [openModal, opened]);

  return (
    <Dialog open={open} onClose={closeModal} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Agency Submission Details</Box>
          <Box>
            <IconButton onClick={closeModal}>
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading && <Spinner />}
        {!loading && editing && (
          <AgencySubmissionEditor
            eoiData={eoiDetails}
            availableAffiliations={availableAffiliations}
            onCancel={onCancel}
            onSave={onSave}
            isSaving={isSaving}
          />
        )}
        {!loading && !editing && eoiDetails && (
          <AgencySubmissionDetails onStatusUpdate={onStatusUpdate} eoiDetails={eoiDetails} setEditing={setEditing} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
