import React from 'react';

import { useSnackbar } from 'notistack';

import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@mui/material';

import {
  callDepositTriggers,
  callInstalmentTriggers,
  callReserveForZeroTriggers,
  paymentScheduleChargeSimulation,
} from '~/services/PaymentsService';

interface Props {
  order: App.Order;
  paymentScheduleDetails: any;
}

export default function PaymentChargeSimulation({ order, paymentScheduleDetails }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const callDepositTrigger = async (action) => {
    try {
      await callDepositTriggers(action, {
        orderId: order.id_orders,
      });
      window.location.reload();
    } catch (err) {
      console.error('Error calling deposit triggers:', err);
      enqueueSnackbar('Something went wrong!', { variant: 'error' });
    }
  };

  const callInstalmentTrigger = async (action) => {
    try {
      await callInstalmentTriggers(action, {
        orderId: order.id_orders,
      });
      window.location.reload();
    } catch (err) {
      console.error('Error calling instalment triggers:', err);
      enqueueSnackbar('Something went wrong!', { variant: 'error' });
    }
  };

  const callReserveForZeroTrigger = async (action) => {
    try {
      await callReserveForZeroTriggers(action, {
        orderId: order.id_orders,
      });
      window.location.reload();
    } catch (err) {
      console.error('Error calling reserve for zero triggers:', err);
      enqueueSnackbar('Something went wrong!', { variant: 'error' });
    }
  };

  const simulatePaymentScheduleCharge = async (action) => {
    try {
      await paymentScheduleChargeSimulation(action, {
        orderId: order.id_orders,
        brand: order.brand,
      });
      window.location.reload();
    } catch (err) {
      console.error('Error simulating payment schedule charge:', err);
      enqueueSnackbar('Something went wrong!', { variant: 'error' });
    }
  };

  if (window.configs.ENABLE_PAYMENT_CHARGE_SIMULATION !== 'true') {
    return null;
  }

  return (
    <>
      {order.payments[0]?.instalmentDetails && (
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={{
              backgroundColor: 'grey.200',
              height: '60px',
            }}
          >
            <Typography>Instalment Triggers (TEST ONLY)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button variant="contained" onClick={() => callInstalmentTrigger('instalment_reminder')}>
                  Send Monthly Round-up Email
                </Button>
                <Typography mt={2}>
                  Every month we send the customer an email to show all the instalments they have paid. Click to send
                  this reminder email now.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" onClick={() => callInstalmentTrigger('auto_debit_success')}>
                  Simulate Auto-Debit Success
                </Button>
                <Typography mt={2}>
                  When each instalment amount is charged, Stripe will send us a message saying the payment succeeded (or
                  failed). This simulates that success message, though it doesn't actually take the payment in stripe.
                  On clicking this button, the Instalment Status of the current instalment should update to
                  "payment_auto_debit_taken", and a new transaction should appear under Transaction Details to reflect
                  the payment of the instalment amount.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" onClick={() => callInstalmentTrigger('auto_debit_failure')}>
                  Simulate Auto-Debit Failure
                </Button>
                <Typography mt={2}>
                  If the charge for the instalment amount fails, Stripe will send us a message saying the payment
                  failed. This simulates a single payment failure, though it doesn't actually take a failed payment in
                  Stripe. We will only cancel the order after 3 failures for an individual instalment, so on clicking
                  this button 3 times, the order's instalment status should change to "payment_auto_debit_failed" and
                  the order should be cancelled.
                </Typography>
                <Typography>
                  Current Auto-Debit Failure Count:{' '}
                  {order.payments[0]?.instalmentPaymentDetails?.length > 0
                    ? order.payments[0]?.instalmentPaymentDetails[
                        order.payments[0]?.instalmentPaymentDetails.length - 1
                      ].due_balance_auto_debit_failed_count
                    : 0}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {order.payments[0]?.deferredPaymentDetails && (
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={{
              backgroundColor: 'grey.200',
              height: '60px',
            }}
          >
            <Typography>Reserve for $0 Triggers (TEST ONLY)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button variant="contained" onClick={() => callReserveForZeroTrigger('auto_debit_success')}>
                  Simulate Auto-Debit Success
                </Button>
                <Typography mt={2}>
                  When Reserve for $0 amount is charged, Stripe will send us a message saying the payment succeeded. On
                  clicking this button, the Booking Status in the Reserve for $0 Details section should update to
                  "payment_auto_debit_taken", and a new transaction should appear under Transaction Details to reflect
                  the payment of the payable amount.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" onClick={() => callReserveForZeroTrigger('auto_debit_failure')}>
                  Simulate Auto-Debit Failure
                </Button>
                <Typography mt={2}>
                  If the charge for the Reserve for $0 amount fails, Stripe will send us a message saying the payment
                  failed. This simulates a single payment failure. We cancel the order after a failed payment, so
                  clicking this button once will result in the order being cancelled and the Booking Status in the
                  Reserve for $0 Details section to be 'payment_auto_debit_failed'.
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {order.payments[0]?.depositDetails && (
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={{
              backgroundColor: 'grey.200',
              height: '60px',
            }}
          >
            <Typography>Deposit Triggers (TEST ONLY)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button variant="contained" onClick={() => callDepositTrigger('deposit_reminder')}>
                  Send Reminder Email
                </Button>
                <Typography mt={2}>
                  5 days before the final payment is due, we send the customer an email. Click to send this reminder
                  email now.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" onClick={() => callDepositTrigger('auto_debit_success')}>
                  Simulate Auto-Debit Success
                </Button>
                <Typography mt={2}>
                  When the remaining balance is charged, Stripe will send us a message saying the payment succeeded (or
                  failed). This simulates that success message, though it doesn't actually take the payment in stripe.
                  On clicking this button, the order's Deposit Status should update from "deposit_taken" to
                  "due_balance_auto_debit_taken", and a new transaction should appear under Transaction Details to
                  reflect the payment of the remaining balance. You should also receive a confirmation email.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" onClick={() => callDepositTrigger('auto_debit_failure')}>
                  Simulate Auto-Debit Failure
                </Button>
                <Typography mt={2}>
                  If the charge for the remaining balance fails, Stripe will send us a message saying the payment
                  failed. This simulates a single payment failure, though it doesn't actually take a failed payment in
                  Stripe. We will only cancel the order after 3 failures, so on clicking this button 3 times, the
                  order's deposit status should change to "due_balance_auto_debit_failed" and the order should be
                  cancelled.
                </Typography>
                <Typography>
                  Current Auto-Debit Failure Count:{' '}
                  {order.payments[0]?.depositDetails.due_balance_auto_debit_failed_count}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {order.payments[0]?.paymentScheduleDetails && (
        <Accordion defaultExpanded>
          <AccordionSummary
            sx={{
              backgroundColor: 'grey.200',
              height: '60px',
            }}
          >
            <Typography>Payment Schedule Charge Simulation (TEST ONLY)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button variant="contained" onClick={() => simulatePaymentScheduleCharge('auto_debit_success')}>
                  Simulate Successful Charge
                </Button>
                <Typography mt={2}>
                  When a payment schedule is charged, Stripe will send us a message saying the payment succeeded (or
                  failed). This simulates that success message, though it doesn't actually take the payment in stripe.
                  On clicking this button, the status of the current payment schedule charge should update to "paid",
                  and a new transaction should appear under Transaction Details to reflect the payment of the charge
                  amount.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" onClick={() => simulatePaymentScheduleCharge('auto_debit_failure')}>
                  Simulate Failed Charge
                </Button>
                <Typography mt={2}>
                  If the charge for the payment schedule amount fails, Stripe will send us a message saying the payment
                  failed. This simulates a single payment failure, though it doesn't actually take a failed payment in
                  Stripe. We will only cancel the order after 3 failures for an individual charge, so on clicking this
                  button 3 times, the order's payment schedule status should change to "cancelled" and the order should
                  be cancelled.
                </Typography>
                {paymentScheduleDetails?.payment_schedules.length > 0 && (
                  <Typography>
                    Current Charge Failure Count:{' '}
                    {paymentScheduleDetails.payment_schedules.find((schedule) =>
                      ['failed', 'cancelled'].includes(schedule.status),
                    )?.retry?.job_retry_attempts ?? 0}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
