import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useQuery from '~/hooks/useQuery';

import { createChannelMarkup, expireChannelMarkup, getChannelMarkupList } from '~/services/PromoService';

import dateFormatter from '~/utils/dateFormatter';

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 1,
};

const getColumns = ({
  handleExpireChannelMarkup,
}: {
  handleExpireChannelMarkup: ({ id }: { id: string }) => Promise<void>;
}): Array<GridColDef> => [
  {
    field: 'channel_markup_id',
    headerName: 'ID',
    ...sharedOpts,
  },
  {
    field: 'source',
    headerName: 'Source',
    ...sharedOpts,
  },
  {
    field: 'medium',
    headerName: 'Medium',
    ...sharedOpts,
  },
  {
    field: 'campaign',
    headerName: 'Campaign',
    ...sharedOpts,
  },
  {
    field: 'term',
    headerName: 'Term',
    ...sharedOpts,
  },
  {
    field: 'markup',
    headerName: 'Markup',
    renderCell: (params) => `${params.row.markup}`,
    ...sharedOpts,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    renderCell: (params) => dateFormatter(params.row.created_at),
    ...sharedOpts,
  },
  {
    field: 'expires_at',
    headerName: 'Expires At',
    renderCell: (params) => dateFormatter(params.row.expires_at),
    ...sharedOpts,
  },
  {
    field: 'operation',
    headerName: 'Operation',
    renderCell: (params) => {
      const isExpired = params.row.expires_at && new Date(params.row.expires_at) < new Date();
      return (
        <Button
          variant="contained"
          color="secondary"
          disabled={isExpired}
          onClick={() => {
            handleExpireChannelMarkup({ id: params.row.channel_markup_id });
          }}
        >
          Expire
        </Button>
      );
    },
    ...sharedOpts,
  },
];

const isValidQueryParamsRegex = /^[^\s]+$/;

type ChannelMarkupStatus = 'active' | 'expired' | 'all';

function ChannelMarkupContainer() {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNum, setPageNum] = useState<number>(query.get('page_num') ? Number(query.get('page_num')) : 0);
  const [pageSize, setPageSize] = useState<number>(query.get('page_size') ? Number(query.get('page_size')) : 10);
  const [totalCount, setTotalCount] = useState(0);
  const tenant = useCurrentTenant();
  const [status, setStatus] = useState<ChannelMarkupStatus>((query.get('status') as ChannelMarkupStatus) || 'all');
  const history = useHistory();

  const setQueryParams = useCallback(() => {
    const searchParams = qs.stringify({
      page_num: pageNum.toString(),
      page_size: pageSize.toString(),
      status: status,
    });
    history.push({
      search: `?${searchParams}`,
    });
  }, [history, pageNum, pageSize, status]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        result: { channelMarkupList, total },
      } = await getChannelMarkupList({
        pageNum: pageNum,
        pageSize: pageSize,
        brand: tenant.tenant.brand,
        status: status,
      });
      setData(channelMarkupList ?? []);
      setIsLoading(false);
      setTotalCount(Number(total));
      setQueryParams();
    } catch (error) {
      enqueueSnackbar('Channel Markup fetch failed', { variant: 'error' });
      return;
    }
  }, [pageNum, pageSize, tenant.tenant.brand, status, setQueryParams, enqueueSnackbar]);

  const dataRows = data.map((row, index) => ({
    id: index,
    ...row,
  }));

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      setPageNum(page);
      setPageSize(pageSize);
      query.set('page_num', page.toString());
      query.set('page_size', pageSize.toString());
      window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`);
      fetchData();
    },
    [fetchData, query],
  );

  // START - New Markup Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleCreate = useCallback(
    async (data) => {
      const markupNumber = Number(data.markup);
      const response = await createChannelMarkup({ ...data, markup: markupNumber, brand: tenant.tenant.brand });

      if (response.status !== 201) {
        console.error('Channel markup creation failed:', response);
        enqueueSnackbar('Channel Markup creation failed', { variant: 'error' });
        return;
      }

      reset();
      fetchData();
      handleClose();
      enqueueSnackbar('Channel Markup created successfully', { variant: 'success' });
    },
    [enqueueSnackbar, fetchData, reset, tenant.tenant.brand],
  );

  // END - New Markup Modal

  const handleExpireChannelMarkup = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await expireChannelMarkup(id);
        enqueueSnackbar('Expired the markup successfully', { variant: 'success' });
        fetchData();
      } catch (error) {
        enqueueSnackbar(`Failed to expired the markup: ${error}`, { variant: 'error' });
      }
    },
    [enqueueSnackbar, fetchData],
  );

  // START - Status Filter
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    fetchData();
  };
  // END - Status Filter

  return (
    <>
      <Helmet>
        <title>Channel Markup</title>
      </Helmet>

      <PageSubheader title={`Search Channel Markup [${data?.length} of ${totalCount}]`} />

      <Grid container spacing={2} justifyContent="flex-end" alignItems="end">
        <Grid item>
          <InputLabel>Status</InputLabel>
          <Select value={status} label="Status" onChange={handleStatusChange}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="expired">Expired</MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            New Markup
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        component="form"
        onSubmit={handleSubmit(handleCreate)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Create a Channel Markup
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              id="source"
              label="Source"
              variant="outlined"
              {...register('source', {
                required: 'Source is required',
                pattern: {
                  value: isValidQueryParamsRegex,
                  message: 'utm_source',
                },
              })}
              error={!!errors.source}
              helperText={errors.source?.message}
            />
            <TextField
              id="medium"
              label="Medium"
              variant="outlined"
              {...register('medium', {
                required: 'Medium is required',
                pattern: {
                  value: isValidQueryParamsRegex,
                  message: 'utm_medium',
                },
              })}
              error={!!errors.medium}
              helperText={errors.medium?.message}
            />
            <TextField
              id="campaign"
              label="Campaign"
              variant="outlined"
              {...register('campaign', {
                required: 'Campaign is required',
                pattern: {
                  value: isValidQueryParamsRegex,
                  message: 'utm_campaign',
                },
              })}
              error={!!errors.campaign}
              helperText={errors.campaign?.message}
            />
            <TextField
              id="term"
              label="Term"
              variant="outlined"
              {...register('term', {
                pattern: {
                  value: isValidQueryParamsRegex,
                  message: 'utm_term',
                },
              })}
              error={!!errors.term}
              helperText={errors.term?.message}
            />
            <TextField
              id="markup"
              label="Markup"
              variant="outlined"
              {...register('markup', {
                required: 'Markup is required',
                validate: (value) => {
                  const num = parseFloat(value);
                  if (isNaN(num)) {
                    return 'Enter a valid number';
                  }
                  if (num < 0.95 || num > 1.05) {
                    return 'Enter a number between 0.95 and 1.05';
                  }
                  return true;
                },
              })}
              error={!!errors.markup}
              helperText={errors.markup?.message}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" type="submit">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          loading={isLoading}
          rows={dataRows}
          columns={getColumns({ handleExpireChannelMarkup })}
          autoHeight
          rowCount={totalCount}
          paginationMode="server"
          pageSizeOptions={[10, 20, 50]}
          paginationModel={{ page: pageNum, pageSize: pageSize }}
        />
      </Box>
    </>
  );
}

export default ChannelMarkupContainer;
