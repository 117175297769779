import React, { FormEventHandler } from 'react';

import { Button, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { DEFAULT_REASON_SOURCE, TNFH_REASON_SOURCE } from '~/consts/refund';

import { CollectionReason } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';
import MarkCancelled from '../Common/MarkCancelled';

interface Props {
  reason: CollectionReason;
  onSubmit: FormEventHandler<HTMLFormElement>;
  decrementStep: () => void;
  defaultComment: string;
  defaultCaseId: string;
}

export default function FraudComplianceAndDispute({
  onSubmit,
  reason,
  decrementStep,
  defaultComment,
  defaultCaseId,
}: Props) {
  return (
    <>
      <DialogContent dividers>
        <form onSubmit={onSubmit} id="fraud-compliance-dispute-form">
          <CaseIDAndReasons reason={reason} defaultComment={defaultComment} defaultCaseId={defaultCaseId} />
          <RadioGroup row name="liabilitySource">
            <FormControlLabel
              data-cy="Both LE & Vendor are liable"
              value={DEFAULT_REASON_SOURCE}
              control={<Radio />}
              label="Both LE & Vendor are liable"
            />
            <FormControlLabel
              data-cy="Only LE is liable"
              value={TNFH_REASON_SOURCE}
              control={<Radio />}
              label="Only LE is liable"
            />
          </RadioGroup>
          <MarkCancelled />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained" form="fraud-compliance-dispute-form">
          Continue
        </Button>
      </DialogActions>
    </>
  );
}
