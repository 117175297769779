import React from 'react';

import { Typography } from '@mui/material';

import { capitalise, titleCase } from '~/utils/stringUtils';

interface Props extends React.ComponentProps<typeof Typography> {
  tier: App.LuxLoyaltyTier;
  luxLoyaltyProgramConfig: App.LuxLoyaltyProgramConfig;
}

interface TierConfig {
  gradient: string;
  contrast: string;
}

const TIER_COLOURS: Record<App.LuxLoyaltyTier, TierConfig> = {
  silver: {
    gradient: 'linear-gradient(45.05deg, #DBDBDB 0.73%, #F2F2F2 45.77%, #DBDBDB 74.49%)',
    contrast: '#000000',
  },
  gold: {
    gradient: 'linear-gradient(45.05deg, #C8BF97 0.73%, #F3EFD5 45.77%, #C8BF97 74.49%)',
    contrast: '#000000',
  },
  platinum: {
    gradient: 'linear-gradient(45.05deg, #3D3B3B 0.73%, #939393 45.77%, #3D3B3B 74.49%);',
    contrast: '#FFFFFF',
  },
};

export default function LuxLoyaltyAccountChip({ tier, luxLoyaltyProgramConfig, ...rest }: Props) {
  const formattedProgramName = luxLoyaltyProgramConfig ? titleCase(luxLoyaltyProgramConfig.programName) : 'Loyalty';

  return (
    <Typography
      {...rest}
      component="span"
      sx={{
        padding: '4px 8px',
        borderRadius: '4px',
        background: TIER_COLOURS[tier].gradient,
        color: TIER_COLOURS[tier].contrast,
        fontWeight: 500,
      }}
    >
      {formattedProgramName} {capitalise(tier)} Member
    </Typography>
  );
}
