export interface SpoofUrlParts {
  baseUrl: string;
  queryParams: string;
}

/**
 * Constructs a spoof URL from URL parts and a search URL
 */
export function constructSearchSpoofUrl(spoofUrlParts: SpoofUrlParts, searchUrl: string): string {
  return `${spoofUrlParts.baseUrl}&url=${searchUrl}?${spoofUrlParts.queryParams}`;
}
