import React, { useCallback, useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

import { Autocomplete, Chip, FormControl, Stack, TextField } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Spinner from '~/components/Common/Spinner';
import { Option, Request } from '~/components/Cruises/pages/Promotions/types';

import departureService from '~/services/cruises/DepartureService';

import { isNumeric } from '~/utils/stringUtils';

const DEPARTURES_LIMIT = 500;

interface Props {
  selectedDepartureIds: Array<string>;
  onChange: (selectedDepartureIds: Array<string>) => void;
  vendorId: string;
}

function InputDepartureSelect({ selectedDepartureIds, onChange, vendorId }: Props) {
  const [loadedVendorId, setLoadedVendorId] = useState<string | null>(null);
  const [selectedDepartures, setSelectedDepartures] = useState<Array<API.DepartureResponse>>([]);
  const [departures, setDepartures] = useState<Request<Array<API.DepartureResponse>>>({
    result: [],
    loading: false,
  });

  const fetchDepartures = useCallback(
    async (departureName?: string) => {
      const isOnlyNumber = isNumeric(departureName);
      if (!departureName || !vendorId || isOnlyNumber) {
        return;
      }

      setDepartures((prev) => ({ ...prev, loading: true }));
      const res = await departureService.listWithPagination({
        take: DEPARTURES_LIMIT,
        status: 'ACTIVE',
        vendorId,
        name: String(departureName),
      });

      setLoadedVendorId(vendorId);
      setDepartures({ loading: false, result: res.result });
    },
    [vendorId],
  );

  useEffect(() => {
    if (!!vendorId && loadedVendorId !== vendorId) {
      fetchDepartures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorId]);

  const departuresSelectOptions = useMemo(() => {
    return departures.result
      ?.filter((item) => !selectedDepartureIds.includes(item.id))
      .map((item) => ({
        value: item.id,
        label: `${item.id} - ${item.name}`,
      }));
  }, [departures.result, selectedDepartureIds]);

  const departuresSelected = useMemo(() => {
    return selectedDepartures.map((item) => ({
      label: `${item.id} - ${item.name}`,
      value: item.id,
    }));
  }, [selectedDepartures]);

  const handleDepartureChange = useCallback(
    (_, selectedOptions: Array<Option> | null) => {
      onChange(selectedOptions?.map((selectedDeparture) => selectedDeparture.value) || []);
      setSelectedDepartures((prevState) => {
        if (!selectedOptions) {
          return prevState;
        }
        return selectedOptions
          .map((selectedDeparture) => ({
            id: selectedDeparture.value,
            name: selectedDeparture.label.split(' - ')[1],
          }))
          .filter((item) => !!item) as Array<API.DepartureResponse>;
      });
    },
    [onChange],
  );

  const debouncedFetchDepartures = useMemo(() => debounce(fetchDepartures, 500), [fetchDepartures]);

  return (
    <Stack spacing={2} direction="row">
      <FormControl fullWidth>
        <Autocomplete
          id="select-departures"
          multiple
          selectOnFocus
          clearOnBlur={false}
          disableCloseOnSelect
          disabled={!vendorId}
          loading={departures.loading}
          value={departuresSelected}
          onChange={handleDepartureChange}
          options={departuresSelectOptions}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                variant="outlined"
                key={option.value}
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} required fullWidth label="Departures" placeholder="Please Enter Departures" />
          )}
          onInputChange={(_, value) => debouncedFetchDepartures(value)}
        />
      </FormControl>
      {departures.loading && <Spinner size={20} />}
    </Stack>
  );
}

export default InputDepartureSelect;
