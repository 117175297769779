export enum RefundRequestRefundOptions {
  letter = 'letter',
  backToOrigin = 'back_to_origin',
  credit = 'credit',
  insuranceClaim = 'insurance_claim',
}

export enum RefundRequestStatuses {
  pending = 'pending',
  approved = 'approved',
  approvedBnbl = 'approved_bnbl',
  cancelled = 'cancelled',
  rejected = 'rejected',
  expired = 'expired',
  autoRejectedPending = 'auto_rejected_pending',
  autoRejected = 'auto_rejected',
  customerPutOnHold = 'customer_put_on_hold',
}
