import React from 'react';

import pluralize from 'pluralize';

import { Box, Stack, Typography } from '@mui/material';

import { capitalise, titleCase } from '~/utils/stringUtils';

interface Props {
  account: App.LuxLoyaltyAccount;
  luxLoyaltyProgramConfig: App.LuxLoyaltyProgramConfig;
  customerId: string;
}

export default function LuxLoyaltyAccountDetails({ account, luxLoyaltyProgramConfig }: Props) {
  const hasConfig = !!luxLoyaltyProgramConfig;
  const formattedStatusCreditsName = hasConfig ? titleCase(pluralize(luxLoyaltyProgramConfig.statusCreditsName)) : null;
  const formattedCurrencyName = hasConfig ? titleCase(pluralize(luxLoyaltyProgramConfig.currencyName)) : null;
  const formattedProgramName = hasConfig ? titleCase(luxLoyaltyProgramConfig.programName) : null;

  return (
    <Stack direction="row" gap={4}>
      <Box>
        <Typography variant="h6">Tier</Typography>
        <Typography variant="subtitle1">{capitalise(account.tier)}</Typography>
      </Box>
      {!!formattedStatusCreditsName && (
        <Box>
          <Typography variant="h6">{formattedStatusCreditsName}</Typography>
          <Typography variant="subtitle1">{account.statusCredits}</Typography>
        </Box>
      )}
      {!!formattedCurrencyName && (
        <Box>
          <Typography variant="h6">{formattedCurrencyName}</Typography>
          <Typography variant="subtitle1">{account.points}</Typography>
        </Box>
      )}
      {!!formattedProgramName && (
        <Box>
          <Typography variant="h6">{formattedProgramName} Member ID</Typography>
          <Typography variant="subtitle1">{account.ibsMemberId}</Typography>
        </Box>
      )}
    </Stack>
  );
}
