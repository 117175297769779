import React, { useState } from 'react';

import { Box } from '@mui/material';

import OrderSearchForm, { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';

import LuxPlusPage from './LuxPlusPage';

function LuxPlusPageContainer() {
  const [filterField, setFilterField] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('');
  const [additionalSearchParams, setAdditionalSearchParams] = useState<MultiFilterState>(null);

  const handleSearchQuery = (field, value) => {
    setFilterField(field);
    setFilterValue(value);
  };

  return (
    <Box>
      <OrderSearchForm
        bookingIdType={null}
        hideReferenceNumberFilter
        searchQuery={handleSearchQuery}
        setAdditionalParams={setAdditionalSearchParams}
      />
      <LuxPlusPage
        filterField={filterField}
        filterValue={filterValue}
        additionalSearchParams={additionalSearchParams}
      />
    </Box>
  );
}

export default LuxPlusPageContainer;
