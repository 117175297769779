import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { MarkdownPreview } from 'react-marked-markdown';
import Snowfall from 'react-snowfall';
import dayjs from '~/timeInit';

import { Box, Container, Dialog, DialogTitle, List, ListItem, Paper, Stack, Typography } from '@mui/material';

import { getChristmasMessage } from '~/services/SupportService';

import Spinner from './Common/Spinner';
import { withTenant } from './hoc';

interface IndexPageContextProps {
  user: App.User;
}

const snowflakeImage = new Image();
snowflakeImage.src = '/assets/snowflake.png';

const menorahImage = new Image();
menorahImage.src = '/assets/menorah.png';

const snowflakeModes = {
  default: {
    images: [snowflakeImage],
    radius: [10, 20],
  },
  hanukkah: {
    images: [menorahImage],
    radius: [10, 20],
  },
  easter: {
    images: [
      'adam.png',
      'andrew.png',
      'ben.png',
      'chetan.png',
      'daniel.png',
      'julia.png',
      'kieran.png',
      'shay.png',
      'shay.png',
      'shay.png',
      'shay.png',
      'shay.png',
      'shay.png',
    ].map((imgName) => {
      const faceImage = document.createElement('img');
      faceImage.src = `/assets/le-faces/${imgName}`;
      return faceImage;
    }),
    radius: [25, 50],
  },
};

function IndexPage(props, { user }: IndexPageContextProps) {
  const hasXmasTheme = props.tenant.brand === 'luxuryescapes';
  const hasHanukkahTheme = props.location.search.includes('celebrate=hanukkah');
  const [snowflakeMode, setSnowflakeMode] = useState('default');
  const [customMessage, setCustomMessage] = useState('');
  const [openModal, setModalOpen] = useState(false);

  const showLastLogin = useMemo(() => {
    if (user) {
      const lastLogin = user.lastLogin;
      if (lastLogin.login_date) {
        const lastLoginDate = new Date(lastLogin.login_date);
        return (
          <div>
            You last logged in {dayjs(lastLoginDate).fromNow()} on {dayjs(lastLoginDate).calendar()}{' '}
          </div>
        );
      }
    }
  }, [user]);

  // Merry Hanukkah to Greg
  useMemo(() => {
    if (user) {
      if (hasXmasTheme && (hasHanukkahTheme || user.email === 'gregory.fine@luxuryescapes.com')) {
        setSnowflakeMode('hanukkah');
      }
    }
  }, [hasXmasTheme, hasHanukkahTheme, user]);

  useEffect(() => {
    if (hasXmasTheme && customMessage === '') {
      getChristmasMessage()
        .then((msg) => {
          setCustomMessage(msg.message);
        })
        .catch((err) => {
          console.error(err);
          setCustomMessage(`Merry Christmas ${user.fullName}! 🎄`);
        });
    }
  });

  if (hasXmasTheme && customMessage === '') {
    return <Spinner />;
  }

  return (
    <Container maxWidth="xl">
      {hasXmasTheme && (
        <Snowfall images={snowflakeModes[snowflakeMode].images} radius={snowflakeModes[snowflakeMode].radius} />
      )}
      <Paper className="user-panel">
        <Typography fontWeight="bold" variant="h3">
          Welcome {user ? ' ' + user.givenName : null}!
        </Typography>
        {showLastLogin}
      </Paper>
      {hasXmasTheme && (
        <Stack mt={5} spacing={5} justifyContent="center" alignItems="center">
          <Typography textAlign="center" variant="h5" color="#BD0019">
            <MarkdownPreview value={customMessage} />
          </Typography>
          <Box
            sx={{
              cursor: snowflakeMode === 'easter' ? 'default' : 'url(/assets/cursor.png) 20 27, auto',
            }}
          >
            <img
              alt="Santa Cat"
              src={`/assets/santa-cat-${Math.floor(Math.random() * 6)}.gif`}
              onClick={() => {
                setSnowflakeMode('easter');
              }}
            />
          </Box>
          <Typography textAlign="center" fontWeight="bold" variant="h5" color="#BD0019">
            {new Date().getTime() < new Date('2024-12-25').getTime() ? (
              <Typography textAlign="center" fontWeight="bold" variant="h5" color="#00873E">
                {Math.floor((new Date('2024-12-25').getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))} days
                until Christmas!
              </Typography>
            ) : (
              'Merry Christmas!'
            )}
          </Typography>
          <Typography
            textAlign="center"
            fontWeight="bold"
            variant="h6"
            color="white"
            style={{ position: 'absolute', bottom: '0px', left: '0px' }}
          >
            The OpEx team takes no responsibility for any damage caused by the snowflakes or comedic but insensitive
            Christmas messages.
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              bottom: '0px',
              right: '0px',
              width: '50px',
              height: '50px',
              background: 'url(/assets/santa-hat.png) no-repeat center center',
              backgroundSize: 'contain',
              cursor: 'url(/assets/cursor.png) 20 27, auto',
              opacity: 0.5,
            }}
            onClick={() => {
              setModalOpen(true);
            }}
          />
          <Dialog open={openModal} onClose={() => setModalOpen(false)}>
            <DialogTitle>With love, OpEx team:</DialogTitle>
            <Typography textAlign="center" variant="subtitle2">
              <List>
                <ListItem>🇺🇸 Ron Crisostomo</ListItem>
                <ListItem>🇦🇺 Matthew Delaney</ListItem>
                <ListItem>🇦🇺 Sam Drown</ListItem>
                <ListItem>🇦🇺 Connor Kneebone</ListItem>
                <ListItem>🇺🇦 Eugene Moskvita</ListItem>
                <ListItem>🇪🇬 Georgia Wahib</ListItem>
              </List>
            </Typography>
            <Typography textAlign="center" variant="subtitle2">
              and OpEx alumni:
            </Typography>
            <Typography textAlign="center" variant="subtitle2">
              <List>
                <ListItem>🇦🇺 Matthew Delaney</ListItem>
                <ListItem>🐅 Kristina Hua</ListItem>
                <ListItem>🇺🇦 Felix Ivanov</ListItem>
                <ListItem>🇦🇺 Andrew Johnstone</ListItem>
                <ListItem>🇺🇦 Valentine Romantsov</ListItem>
                <ListItem>🇮🇪 Gearóid Sheehan</ListItem>
                <ListItem>🇦🇺 Nick Stevens</ListItem>
              </List>
            </Typography>
            <Typography textAlign="center" variant="subtitle2">
              and a special thanks to:
            </Typography>
            <Typography textAlign="center" variant="subtitle2">
              <List>
                <ListItem>
                  <img src="/assets/le-faces/eeyore.png" width="20px" height="20px" /> Vincent Tran
                </ListItem>
              </List>
            </Typography>
          </Dialog>
        </Stack>
      )}
    </Container>
  );
}

IndexPage.contextTypes = {
  user: PropTypes.object,
};

export default withTenant(IndexPage);
