import React, { useEffect, useState } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';

import { getTripConciergeNoteOptions } from '~/services/TripPlannerService';

interface NoteOption {
  id: string;
  description: string;
  content: string;
}

interface Props {
  tripId: string;
  setNote: (note: string) => void;
  sx?: React.ComponentProps<typeof Button>['sx'];
}

function CannedNotes({ tripId, setNote, sx }: Props) {
  const [noteOptions, setNoteOptions] = useState<Array<NoteOption>>([]);
  const [noteOptionsFetchingState, setNoteOptionsFetchingState] = useState<Utils.FetchingState>('idle');
  const [notesMenuAnchor, setNotesMenuAnchor] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const loadNoteOptions = async function () {
      setNoteOptionsFetchingState('loading');
      setNoteOptions([]);
      try {
        const response = await getTripConciergeNoteOptions(tripId);
        setNoteOptions(response.result);
        setNoteOptionsFetchingState('success');
      } catch (e) {
        console.error(e);
        setNoteOptionsFetchingState('failed');
      }
    };
    loadNoteOptions();
  }, [tripId]);

  const notesMenuOpen = Boolean(notesMenuAnchor);
  const notesButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotesMenuAnchor(event.currentTarget);
  };
  const notesMenuClose = () => {
    setNotesMenuAnchor(null);
  };

  const handleSelectMessage = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof HTMLElement) {
      const optionId = event.target.dataset.noteOptionId;
      const option = noteOptions.find((noteOption) => noteOption.id === optionId);
      if (option) {
        setNote(option.content);
      }
    }
    notesMenuClose();
  };

  let noteOptionsLabel = 'Canned notes';
  if (noteOptionsFetchingState === 'loading') {
    noteOptionsLabel += ' (loading...)';
  } else if (noteOptionsFetchingState === 'failed') {
    noteOptionsLabel += ' (failed to load)';
  }

  return (
    <>
      <Button variant="outlined" onClick={notesButtonClick} disabled={noteOptionsFetchingState !== 'success'} sx={sx}>
        {noteOptionsLabel}
      </Button>

      <Menu
        open={notesMenuOpen}
        onClose={notesMenuClose}
        anchorEl={notesMenuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {noteOptions.map((option) => (
          <MenuItem key={option.id} data-note-option-id={option.id} onClick={handleSelectMessage}>
            {option.description}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default CannedNotes;
