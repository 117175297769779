import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Stack } from '@mui/material';

import SupplierRoomListElement from '~/components/Accommodation/Pages/PropertyDetails/sections/AccommodationPropertyRoomMapping/SupplierRoomListElement';

import { InternalRoom } from '~/services/AccommodationService';

interface Props {
  internalRoomId: string;
  unsavedInternalRooms: Array<InternalRoom>;
}

export default function ToBeMappedRoomElement({ internalRoomId, unsavedInternalRooms }: Props) {
  const room = unsavedInternalRooms.find((r) => r.id === internalRoomId);

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        borderColor: 'info.light',
        borderWidth: '2px',
        borderStyle: 'solid',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" gap={1}>
          <Chip label="Unsaved" color="info" size="small" />
          <Chip label="Verified" color="success" variant="outlined" size="small" />
          <span>
            ({room.internalService}) {room.name} - {room.internalId}
          </span>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {room.supplierRooms.map((supplierRoom) => (
          <SupplierRoomListElement key={supplierRoom.id} supplierRoom={supplierRoom} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
