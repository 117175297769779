import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import BedbankService from '~/services/BedbankService';

interface BedbankSupplier {
  supplier: 'expedia' | 'hotelbeds' | 'webbeds';
  name: string;
  supplierRoomId: string;
  description: string;
  view: string;
  rateComments: Array<string>;
  bedGroups: Array<{
    id: string;
    description: string;
  }>;
  maxOccupancy: number;
}

const BedGroups = ({ bedGroups }: { bedGroups: Array<{ id: string; description: string }> }) => {
  const allDescriptions = bedGroups?.map((bedGroup) => bedGroup.description).join(',') || 'Not Available';
  return (
    <Typography>
      <strong>Bed Configuration(s):</strong> {allDescriptions}
    </Typography>
  );
};

const SupplierInfo = ({ supplierInfo }: { supplierInfo: BedbankSupplier }) => {
  if (!supplierInfo) {
    return <Box>No Supplier Data Found for the given reservation</Box>;
  }
  return (
    <Stack direction="column" gap={1}>
      <div>
        <Typography>
          <strong>Supplier:</strong> {supplierInfo?.supplier}
        </Typography>
        <Typography>
          <strong>Supplier Room name:</strong> {supplierInfo?.name}
        </Typography>
        <Typography>
          <strong>Supplier Room code:</strong> {supplierInfo?.supplierRoomId}
        </Typography>
        <Typography>
          <strong>Supplier Room Description:</strong> {supplierInfo?.description || 'Not Available'}
        </Typography>
        <Typography>
          <strong>View:</strong> {supplierInfo?.view || 'Not Available'}
        </Typography>
        <BedGroups bedGroups={supplierInfo?.bedGroups} />
        <Typography>
          <strong>Rate Comments:</strong> {supplierInfo?.rateComments?.join(', ') || 'Not Available'}
        </Typography>
        <Typography>
          <strong>Max Occupancy:</strong> {supplierInfo?.maxOccupancy || 'Not Available'}
        </Typography>
      </div>
    </Stack>
  );
};

export default function Supplier({ reservationId }: { reservationId: string }) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [open, setOpen] = useState(false);
  const [supplierInfo, setSupplierInfo] = useState<BedbankSupplier>(null);

  const handleChange = async (_: React.SyntheticEvent, isExpanded: boolean) => {
    try {
      setOpen(isExpanded);

      if (!isExpanded) {
        setLoadingState('idle');
        return;
      }

      setLoadingState('loading');
      setSupplierInfo(null);

      const bedbankResponse = await BedbankService.getReservationInfo(reservationId);

      setSupplierInfo(bedbankResponse?.result?.sourceRoom);
      setLoadingState('success');
    } catch (err) {
      setLoadingState('failed');
      setOpen(false);
    }
  };

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      variant="outlined"
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <strong>Supplier Room Booking Details</strong>
      </AccordionSummary>
      <AccordionDetails>
        {loadingState === 'loading' && <Spinner size={32} />}
        {loadingState === 'success' && <SupplierInfo supplierInfo={supplierInfo} />}
        {loadingState === 'failed' && <Box>Failed to load supplier data, please try again</Box>}
      </AccordionDetails>
    </Accordion>
  );
}
