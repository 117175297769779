import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Alert, Chip, IconButton, Link } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import useToggleState from '~/hooks/useToggleState';

import { SupplierProperty, listPropertySuppliers, unmapSupplierProperty } from '~/services/AccommodationService';

import AccommodationPropertySupplierRatesEnabledModal from './AccommodationPropertySupplierRatesEnabledModal';

const PAGE_SIZE = 20;

export interface AccommodationPropertySupplierResultsTableRef {
  refresh: () => void;
}

const AccommodationPropertySupplierResultsTable = forwardRef<AccommodationPropertySupplierResultsTableRef>(
  function AccommodationPropertySupplierResultsTable(props, ref) {
    const { propertyId } = useParams<{ propertyId: string }>();
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierProperty | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const {
      isToggled: isSupplierRatesEnabledModalOpen,
      toggleOn: setOpenSupplierRatesEnabledModal,
      toggleOff: setCloseSupplierRatesEnabledModal,
    } = useToggleState(false);
    const getSuppliers = useCallback(() => listPropertySuppliers({ propertyId }), [propertyId]);

    const showConfirmDialog = useConfirmDialog();

    const { fetchReqState, refetch } = useAccommodationServiceFetch({
      fetchFn: getSuppliers,
    });

    useImperativeHandle(ref, () => ({
      refresh: refetch,
    }));

    const handleRatesEnabledClick = useCallback(
      (supplier: SupplierProperty) => () => {
        setSelectedSupplier(supplier);
        setOpenSupplierRatesEnabledModal();
      },
      [setOpenSupplierRatesEnabledModal],
    );

    const closeRatesEnabledModal = useCallback(() => {
      setCloseSupplierRatesEnabledModal();
      setSelectedSupplier(null);
    }, [setCloseSupplierRatesEnabledModal]);

    const handleSupplierToggleRatesComplete = useCallback(() => {
      refetch();
      closeRatesEnabledModal();
    }, [refetch, closeRatesEnabledModal]);

    const onRemove = useCallback(
      async (supplierProperty: SupplierProperty) => {
        const confirmed = await showConfirmDialog({
          title: 'Unmap Supplier Property?',
          description: 'This will unmap this supplier property from this property. Are you sure you want to proceed?',
        });

        if (!confirmed) {
          return;
        }

        await unmapSupplierProperty(propertyId, supplierProperty.id);
        enqueueSnackbar(`Successfully unmapped "${supplierProperty.name}"`, { variant: 'success' });
        refetch();
      },
      [enqueueSnackbar, propertyId, refetch, showConfirmDialog],
    );

    const columns: Array<GridColDef<SupplierProperty>> = useMemo(
      () => [
        {
          field: 'supplier',
          headerName: 'Supplier Name',
          display: 'flex',
          minWidth: 260,
          hideable: false,
        },
        {
          field: 'supplierId',
          headerName: 'Supplier ID',
          maxWidth: 240,
          minWidth: 200,
          display: 'flex',
          hideable: true,
        },
        {
          field: 'name',
          headerName: 'Property Name',
          minWidth: 200,
          display: 'flex',
          flex: 1,
          hideable: true,
        },
        {
          field: 'source',
          headerName: 'Source',
          maxWidth: 240,
          minWidth: 150,
          display: 'flex',
          hideable: true,
        },
        {
          field: 'sourceId',
          headerName: 'ID',
          maxWidth: 240,
          minWidth: 200,
          display: 'flex',
          hideable: true,
          renderCell: (params) => {
            if (params.row.source === 'reservation') {
              return (
                <Link href={`/vendors/unknown/properties/${params.row.sourceId}`} target="_blank">
                  {params.value}
                </Link>
              );
            }
            if (params.row.source === 'bedbank') {
              return (
                <Link href={`/bedbank/properties/${params.row.sourceId}`} target="_blank">
                  {params.value}
                </Link>
              );
            }
          },
        },
        {
          field: 'sppEnabled',
          headerName: 'Status',
          maxWidth: 240,
          minWidth: 150,
          display: 'flex',
          hideable: true,
          renderCell: (params) => {
            if (params.row.source === 'bedbank') {
              if (params.value) {
                return (
                  <Chip
                    label="Rates Enabled"
                    variant="outlined"
                    color="success"
                    onClick={handleRatesEnabledClick(params.row)}
                  />
                );
              }
              return (
                <Chip
                  label="Rates Disabled"
                  variant="outlined"
                  color="warning"
                  onClick={handleRatesEnabledClick(params.row)}
                />
              );
            } else {
              return null;
            }
          },
        },
        {
          field: ' ',
          headerName: 'Unmap',
          headerAlign: 'center',
          width: 70,
          sortable: false,
          align: 'center',
          display: 'flex',
          renderCell: (params) => {
            return (
              <IconButton onClick={() => onRemove(params.row)} color="error">
                <LinkOffIcon fontSize="medium" />
              </IconButton>
            );
          },
        },
      ],
      [],
    );

    return (
      <>
        {fetchReqState.status === 'failed' && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {JSON.stringify(fetchReqState.error)}
          </Alert>
        )}
        <DataGrid
          className="accommodation-property-suppliers-results-table"
          columns={columns}
          rows={fetchReqState.status === 'succeeded' ? fetchReqState.result : []}
          rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.length : undefined}
          getRowHeight={() => 'auto'}
          pageSizeOptions={[PAGE_SIZE]}
          pagination
          loading={fetchReqState.status === 'pending'}
          autoHeight
          disableColumnFilter
          disableDensitySelector
          slots={{
            toolbar: GridToolbar,
          }}
        />
        {!!selectedSupplier && isSupplierRatesEnabledModalOpen && (
          <AccommodationPropertySupplierRatesEnabledModal
            propertyId={propertyId}
            supplier={selectedSupplier}
            onClose={closeRatesEnabledModal}
            onComplete={handleSupplierToggleRatesComplete}
          />
        )}
      </>
    );
  },
);

export default AccommodationPropertySupplierResultsTable;
