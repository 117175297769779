import * as yup from 'yup';
import { currencyCodes } from '~/globals';

import {
  CUSTOM_OFFER_CANCELLATION_POLICY_TYPES,
  FLEXIBLE_CANCELLATION_POLICY,
  LE_ENTITIES,
} from '~/components/Common/CustomOffers/constants';

const today = new Date();
today.setHours(0, 0, 0, 0);

const itemSchema = {
  type: yup
    .string()
    .required('Item type is required')
    .oneOf(['cruise', 'tour', 'hotel', 'experience', 'other'], 'Item type should be supported'),
  name: yup.string().required('Item name is required').max(300, 'Item name should not exceed 300 characters'),
  description: yup.string().required('Item description is required'),
  service_information: yup.string().required('Terms & Conditions is required'),
  vendor: yup.string().required('Vendor is required'),
  start_date: yup.date().required('Start date is required'),
  end_date: yup.date().required('End date is required'),
  // cruise specific fields, move to sub-schema later
  cruise_line: yup.string().optional(),
  ship_name: yup.string().optional(),
  ship_cabin: yup.string().optional(),
  ship_rate: yup.string().optional(),
};

const travellerSchema = yup.object({
  name: yup.string().optional().nullable(),
  type: yup.string().required('Traveller type is required'),
  country: yup.string().optional(),
  date_of_birth: yup.date().optional().nullable().max(new Date(), 'Date of birth should be in the past'),
});

const paymentMetadataSchema = yup.object({
  vendor_payment_type: yup
    .string()
    .required('Vendor payment type is required')
    .oneOf(['deposit', 'full_payment'], 'Vendor payment type should be supported'),
  vendor_due_date: yup.date().required('Vendor due date is required').min(new Date(), 'Should be in the future'),
  vendor_deposit_1_amount: yup.number().label('Deposit 1 amount').default(0).optional().min(0),
  vendor_deposit_1_due_date: yup
    .date()
    .when('vendor_payment_type', ([vendor_payment_type], schema) =>
      vendor_payment_type === 'deposit' ? schema.min(today, 'Should be in the future') : schema.optional(),
    ),
  vendor_deposit_payment_made: yup.boolean().label('Deposit paid?').default(false).required(),
  customer_deposit_payment_made: yup.boolean().label('Deposit paid?').default(false).notRequired(),
  vendor_was_paid: yup.boolean().label('Vendor payment made?').default(false).required(),
  deposit_pi_number_customer: yup.string().label('Deposit PI number').optional(),
  deposit_vcc_digits: yup.string().label('Deposit VCC digits').optional(),
  final_payment_vcc_digits: yup.string().label('Final payment VCC digits').optional(),
  margin: yup.number().default(0),
  le_service_fee: yup.number().default(0),
  final_amount_vendor: yup
    .number()
    .required('Final amount to vendor is required')
    .min(1, 'Final amount should not be 0'),
  final_amount_customer: yup
    .number()
    .required('Final amount to customer is required')
    .min(1, 'Final amount should not be 0'),
  payment_type: yup
    .string()
    .required('Payment type is required')
    .oneOf(['deposit', 'full_payment'], 'Payment type should be supported'),
  customer_deposit_1_due_date: yup
    .date()
    .label('Deposit 1 due date')
    .when('payment_type', ([payment_type], schema) =>
      payment_type === 'deposit' ? schema.min(today, 'Should be in the future') : schema.optional(),
    ),
  customer_deposit_1_amount: yup
    .number()
    .label('Deposit 1 amount')
    .default(0)
    .when('payment_type', ([payment_type], schema) => (payment_type === 'deposit' ? schema.moreThan(0) : schema)),
  vendor_deposit_payment_date: yup
    .date()
    .label('Vendor Deposit Payment Date')
    .when('vendor_deposit_payment_made', {
      is: true,
      then: () =>
        yup.date().required('This field is required when Vendor deposit was made').typeError('Date cannot be blank'),
      otherwise: () => yup.date().nullable(),
    }),
  vendor_payment_date: yup
    .date()
    .label('Vendor Payment Date')
    .when('vendor_was_paid', {
      is: true,
      then: () =>
        yup.date().required('This field is required when Vendor payment was made').typeError('Date cannot be blank'),
      otherwise: () => yup.date().nullable(),
    }),
  refund_recieved: yup.date().nullable().notRequired(),
  le_entity: yup.string().oneOf(LE_ENTITIES).required('LE Entity is required'),
  commission_amount: yup.number().optional().typeError('Cannot be empty, please set to 0 if unused.'),
  commission_received: yup.boolean().optional().nullable(),
  additional_vendor_payment: yup.number().optional().typeError('Cannot be empty, please set to 0 if unused.'),
  created_by: yup.string().optional(),
  sales_agent_name: yup.string().optional(),
  cancellation_type: yup.string().oneOf(CUSTOM_OFFER_CANCELLATION_POLICY_TYPES).optional().nullable(),
  cancellation_end_date: yup.date().when('cancellation_type', {
    is: FLEXIBLE_CANCELLATION_POLICY,
    then: () =>
      yup
        .date()
        .required('This field is required when cancellation type is flexible.')
        .typeError('Date cannot be blank'),
    otherwise: () => yup.date().nullable(),
  }),
  sales_attribution: yup.string().optional().nullable(),
  // Note: unlike other fields, this should be nullable and not default to 0 if cleared or not set
  // https://github.com/jquense/yup/issues/500#issuecomment-569343680
  additional_margin: yup
    .number()
    .transform((value, original) => (original === '' || isNaN(original) ? null : value))
    .nullable(),
});

export const customOfferSchema = yup.object({
  due_date: yup.date().required('Due date is required').min(new Date(), 'Due date should be in the future'),
  sold_at: yup.date().required('Sold date is required'),
  price: yup
    .number()
    .required('Price is required')
    .moreThan(0.99, 'Sell price should not be 0')
    .max(9999999999, 'Cannot be larger than 9999999999'),
  price_currency: yup
    .string()
    .default('AUD')
    .required('Sell currency is required')
    .oneOf(currencyCodes, 'Sell currency should be supported'),
  cost_price: yup
    .number()
    .required('Cost price is required')
    .moreThan(0.99, 'Cost price should not be 0')
    .max(9999999999, 'Cannot be larger than 9999999999'),
  cost_price_currency: yup
    .string()
    .default('AUD')
    .required('Cost currency is required')
    .oneOf(currencyCodes, 'Cost currency should be supported'),
  payment_terms: yup.string().optional().nullable(),
  payment_metadata: paymentMetadataSchema.required(),
  item: yup.object(itemSchema).required('Item is required'),
  travellers: yup
    .array(travellerSchema)
    .min(1, 'At least one traveller is required')
    .max(10, 'Maximum 10 travellers are allowed'),
  reason: yup.string().when('item.type', {
    is: 'other',
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required(),
  }),
  reasonOther: yup.string().when('reason', {
    is: 'other',
    then: () => yup.string().required('Please enter a reason for this custom offer'),
  }),
});

export const editableCustomOfferSchema = customOfferSchema.pick(['payment_terms', 'item', 'travellers']).concat(
  yup.object({
    payment_metadata: paymentMetadataSchema.pick([
      'customer_deposit_payment_made',
      'vendor_deposit_payment_made',
      'vendor_was_paid',
    ]),
  }),
);
