import React, { useCallback, useState } from 'react';

import { useParams } from 'react-router';

import { Box, Button, Stack } from '@mui/material';

import { InternalService, ValidationErrorCodes, importSupplier } from '~/services/AccommodationService';

import AccommodationAddPropertyProgressModal from '../../../Properties/components/AccommodationAddPropertyProgressModal';

import AccommodationPropertySupplierResultsTable, {
  AccommodationPropertySupplierResultsTableRef,
} from './AccommodationPropertySupplierResultsTable';

export default function AccommodationPropertySuppliersSection() {
  const { propertyId } = useParams<{ propertyId: string }>();
  const supplierTableRef = React.createRef<AccommodationPropertySupplierResultsTableRef>();
  const [addPropertyModalVisible, setAddPropertyModalVisible] = useState(false);
  const showAddPropertyModal = useCallback(() => setAddPropertyModalVisible(true), []);
  const closeAddPropertyModal = useCallback(() => {
    supplierTableRef.current?.refresh();
    setAddPropertyModalVisible(false);
  }, [supplierTableRef]);
  const startSupplierImport = useCallback(
    (
      supplierPropertyId: string,
      internalService: InternalService,
      validationOverrides?: Array<ValidationErrorCodes>,
    ) => {
      return importSupplier(propertyId, { propertyId: supplierPropertyId, internalService, validationOverrides });
    },
    [propertyId],
  );

  return (
    <>
      <Stack direction="column" gap={4}>
        <Box display="flex">
          <Button variant="text" size="small" onClick={showAddPropertyModal}>
            Add new supplier
          </Button>
        </Box>
        <AccommodationPropertySupplierResultsTable ref={supplierTableRef} />
      </Stack>
      <AccommodationAddPropertyProgressModal
        open={addPropertyModalVisible}
        onClose={closeAddPropertyModal}
        apiCall={startSupplierImport}
      />
    </>
  );
}
