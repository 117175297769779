import React, { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { Typography } from '@mui/material';
import { Button, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';

import BedbankService from '~/services/BedbankService';
import { Dayjs } from '~/services/TimeService';
import { formatDateISO } from '~/services/TimeService';

import DownloadRawRates from './DownloadRawRates';
import RateInfoResultsTable from './RateInfoResultsTable';

interface RateInfoProps {
  propertyId: string;
}

const today = dayjs();

function RateInfo({ propertyId }: RateInfoProps) {
  const [checkIn, setCheckIn] = useState<Dayjs | null>(null);
  const [checkOut, setCheckOut] = useState<Dayjs | null>(null);
  const [hasError, setHasError] = useState(true);
  const [errorMessage, setHasErrorMessage] = useState('');
  const [fetchReqState, setFetchReqState] = useState<{
    status: number | string;
    error?: Error;
    result?: App.Bedbank.AdminRatesForProperty;
  }>(null);

  function handleCheckInDateChange(date: Dayjs) {
    setCheckIn(date);
  }

  function validate() {
    if (!checkIn || !checkOut || checkOut.isBefore(checkIn)) {
      setHasError(true);
      return;
    }
    setHasError(false);
  }

  function handleCheckOutDateChange(date: Dayjs) {
    setCheckOut(date);
  }

  const getRates = useCallback(async () => {
    const updatedCheckIn = formatDateISO(checkIn.toISOString());
    const updatedCheckOut = formatDateISO(checkOut.toISOString());
    return await BedbankService.getRateInfo(updatedCheckIn, updatedCheckOut, propertyId);
  }, [checkIn, checkOut, propertyId]);

  async function onSubmit() {
    if (hasError) {
      setHasErrorMessage('Please select valid dates');
      return;
    }
    setFetchReqState({ status: 'pending' });
    try {
      const resp = await getRates();
      const updatedResp = resp.result.rooms.map((room) => {
        const updatedCancellationPolicy = room?.refundable ? 'RF' : 'NRF';
        const updatedGroupId = `${room.boardCode}-${updatedCancellationPolicy}`;
        return {
          ...room,
          cancellationPolicies: updatedCancellationPolicy,
          groupId: updatedGroupId,
        };
      });
      resp.result.rooms = updatedResp;
      setFetchReqState(resp);
    } catch (e) {
      setFetchReqState({ status: 'failed', error: e.message });
    }
  }

  useEffect(() => {
    validate();
  }, [checkIn, checkOut]);

  return (
    <>
      <Grid display="flex" flexDirection="row" justifyContent="space-evenly">
        <Grid display="flex" flexDirection="row" alignItems="center" gap="1rem">
          <Typography fontWeight="bold">Check In</Typography>
          <DatePicker onChange={handleCheckInDateChange} minDate={today} />
        </Grid>
        <Grid display="flex" flexDirection="row" alignItems="center" gap="1rem">
          <Typography fontWeight="bold">Check Out</Typography>
          <DatePicker onChange={handleCheckOutDateChange} minDate={checkIn || today} />
        </Grid>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Grid>
      <Grid display="flex" flexDirection="row" justifyContent="space-evenly">
        <div className="button-container">
          <Button variant="contained" color="primary" disabled={hasError} onClick={onSubmit}>
            Get Rates
          </Button>
        </div>
        <DownloadRawRates rawlogs={fetchReqState?.result?.rawRateLogs} />
      </Grid>
      <RateInfoResultsTable fetchReqState={fetchReqState} />
    </>
  );
}

export default RateInfo;
