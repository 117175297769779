import React, { useCallback, useState } from 'react';

import { isEqual } from 'lodash';

import { Button, Stack, TableCell, TableRow, TextField } from '@mui/material';

export interface PricingRoom {
  discountPercentage: number | undefined;
  surchargePercentage: number | undefined;
  luxPlusDiscount: number | undefined;
}
export interface EditPricingRooms {
  twin: PricingRoom;
  single: PricingRoom;
}

const INIT_PRICING_EDIT: EditPricingRooms = {
  twin: {
    discountPercentage: undefined,
    surchargePercentage: undefined,
    luxPlusDiscount: undefined,
  },
  single: {
    discountPercentage: undefined,
    surchargePercentage: undefined,
    luxPlusDiscount: undefined,
  },
};

interface Props {
  onApply: (data: EditPricingRooms) => void;
}

export default function TourPricingBulkUpdateForm({ onApply }: Props) {
  const [pricingEdit, setPricingEdit] = useState<EditPricingRooms>(INIT_PRICING_EDIT);

  const applyBulkPricing = useCallback(() => {
    if (!isEqual(pricingEdit, INIT_PRICING_EDIT)) {
      onApply(pricingEdit);
    }
  }, [onApply, pricingEdit]);

  const handleBulkPricingChange = useCallback(
    (event) => {
      const [room, field] = event.target.name.split('.');
      setPricingEdit({
        ...pricingEdit,
        [room]: {
          ...pricingEdit[room],
          [field]: Number(event.target.value),
        },
      });
    },
    [pricingEdit, setPricingEdit],
  );

  return (
    <>
      <TableRow>
        <TableCell sx={{ borderBottom: 0 }} align="center" colSpan={2} rowSpan={2}>
          Apply bulk update
        </TableCell>
        <TableCell sx={{ border: 0 }} align="center" colSpan={5}>
          Twin
        </TableCell>
        <TableCell sx={{ border: 0 }} align="center" colSpan={5}>
          Single
        </TableCell>
        <TableCell sx={{ borderBottom: 0, verticalAlign: 'bottom' }} align="center" colSpan={2} rowSpan={2}>
          <Button fullWidth variant="outlined" onClick={applyBulkPricing}>
            Apply
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ border: 0 }} colSpan={5}>
          <Stack direction="row" gap={2} justifyContent="space-between">
            <TextField
              fullWidth
              type="number"
              inputProps={{ step: 0.01, min: 0, max: 1 }}
              label="Discount %"
              name="twin.discountPercentage"
              defaultValue={pricingEdit.twin.discountPercentage}
              onChange={handleBulkPricingChange}
            />
            <TextField
              fullWidth
              type="number"
              inputProps={{ step: 0.01, min: 0, max: 1 }}
              label="Markup %"
              name="twin.surchargePercentage"
              defaultValue={pricingEdit.twin.surchargePercentage}
              onChange={handleBulkPricingChange}
            />
            <TextField
              fullWidth
              type="number"
              inputProps={{ step: 0.01, min: 0, max: 1 }}
              label="Luxplus %"
              name="twin.luxPlusDiscount"
              defaultValue={pricingEdit.twin.luxPlusDiscount}
              onChange={handleBulkPricingChange}
            />
          </Stack>
        </TableCell>
        <TableCell sx={{ border: 0 }} colSpan={5}>
          <Stack direction="row" gap={2} justifyContent="space-between">
            <TextField
              fullWidth
              type="number"
              inputProps={{ step: 0.01, min: 0, max: 1 }}
              label="Discount %"
              name="single.discountPercentage"
              defaultValue={pricingEdit.single.discountPercentage}
              onChange={handleBulkPricingChange}
            />
            <TextField
              fullWidth
              type="number"
              inputProps={{ step: 0.01, min: 0, max: 1 }}
              label="Markup %"
              name="single.surchargePercentage"
              defaultValue={pricingEdit.single.surchargePercentage}
              onChange={handleBulkPricingChange}
            />
            <TextField
              fullWidth
              type="number"
              inputProps={{ step: 0.01, min: 0, max: 1 }}
              label="Luxplus %"
              name="single.luxPlusDiscount"
              defaultValue={pricingEdit.single.luxPlusDiscount}
              onChange={handleBulkPricingChange}
            />
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow sx={{ border: 0 }}>
        <TableCell sx={{ border: 0, padding: 2 }} colSpan={14} />
      </TableRow>
    </>
  );
}
