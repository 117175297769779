import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getUser, updateUser } from '~/services/AgentHub/AgentService';

import EditTierForm from './Tier/EditTierForm';

interface Props {
  id: string;
}

type Tier = 'tier1' | 'tier2' | 'tier3';

export default function UserAgencyDetails({ id }: Props) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);
  const [showEditTierForm, setShowEditTierForm] = useState(false);
  const [tier, setTier] = useState<Tier>('tier1');
  const { enqueueSnackbar } = useSnackbar();

  const fetchUserData = useCallback(
    async (id: string) => {
      try {
        setLoading(true);

        const user = await getUser(id);

        setUser(user);
        setTier(user.tier);
      } catch (err) {
        enqueueSnackbar('Failed to load data', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    fetchUserData(id);
  }, [fetchUserData, id]);

  async function onUpdateTier() {
    const updatedUser = await updateUser(user.id, { tier });
    setUser(updatedUser);
  }

  const urlBuilder = () => {
    return `/users-list/agencies/${user.agencyId}`;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {user && (
        <Stack>
          <Typography>Agency Name:</Typography>
          <Typography fontWeight="bold">
            <Link target="_blank" rel="noreferrer" to={urlBuilder()}>
              {user.agencyName}
            </Link>
          </Typography>
          <Typography mt={2}>Affiliation:</Typography>
          <Typography fontWeight="bold">{user.affiliation}</Typography>

          <Typography mt={2}>Agent Tier:</Typography>
          <Stack direction="row" alignItems="center">
            {!showEditTierForm && (
              <>
                <IconButton onClick={() => setShowEditTierForm(true)}>
                  <EditIcon sx={{ fontSize: 16 }} color="primary" />
                </IconButton>
                <Typography fontWeight="bold">{user.tier}</Typography>
              </>
            )}

            {showEditTierForm && (
              <EditTierForm
                onSubmit={onUpdateTier}
                setShowEditTierForm={setShowEditTierForm}
                setTier={setTier}
                tier={tier}
              />
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
}
