import React from 'react';

import { Box, Typography } from '@mui/material';

import { DYNAMIC_PRICED_HOTEL_OFFER_TYPES } from '../../../consts/offerTypes';
import { VENDOR_REFUND_AGREES_NUMBER } from '../../../consts/refund';

interface IRefund {
  refunded_by: string;
  charge_component_key: string;
}

interface Props {
  refunds: { result: Array<IRefund> };
  sale_currency_code: string;
  offers: Array<{ items: Array<App.OrderItem>; offer: App.AccommodationOffer }>;
  order: App.Order;
  reservations: Array<App.OrderItemReservation>;
  addons: any;
  cancelledReservations: any;
}

export default class AccountingExplanation extends React.Component<Props> {
  tnfh(item) {
    return `For refund '${item.id_refund_metadata}', the source was Take Nothing From Holdback (TNFH). Vendor pays nothing; holdback amount will be nothing.`;
  }

  tefh(item, cost_data) {
    return `For refund '${
      item.id_refund_metadata
    }', the source was Take Everything From Holdback (TEFH).Cost price is ${cost_data.price} ${
      cost_data.currency_code
    }. Vendor pays everything; holdback amount will be ${(cost_data.holdback * 1.2).toFixed(2)} ${
      cost_data.currency_code
    }`;
  }

  default(item, sale_currency_code, cost_data) {
    return `For refund '${item.id_refund_metadata}', the source was Default. Cost price is ${cost_data.price} ${cost_data.currency_code}.
     ${cost_data.accounting_amount} ${sale_currency_code} (${cost_data.percentage}%) of the original payment (${cost_data.total_sale_price} ${sale_currency_code}) was refunded, so the Vendor Holdback will be ${cost_data.holdback} ${cost_data.currency_code}`;
  }

  custom(item, sale_currency_code, cost_data) {
    return `For refund '${item.id_refund_metadata}', the source was Custom. ${cost_data.payToVendorDesc} (${cost_data.payToVendor} ${cost_data.currency_code}) of the Cost price (${cost_data.price} ${cost_data.currency_code})
      will be paid to the Vendor, so the Vendor Holdback will be ${cost_data.holdback} ${cost_data.currency_code}.`;
  }

  lineItem(item, sale_currency_code, cost_data) {
    if (item.source == 'Luxury Escapes (TNFH)') {
      return this.tnfh(item);
    }
    if (item.source == 'Vendor (TEFH)') {
      return this.tefh(item, cost_data);
    }
    if (item.source == 'Custom') {
      return this.custom(item, sale_currency_code, cost_data);
    }
    if (item.source == 'Default') {
      return this.default(item, sale_currency_code, cost_data);
    }
  }

  costData({ refundAmount, costPrice, costCurrencyCode, totalSalePrice, item }) {
    const fraction = totalSalePrice > 0 ? refundAmount / totalSalePrice : 0;
    const out: any = {};
    out.currency_code = costCurrencyCode;
    out.price = costPrice.toFixed(2);
    out.percentage = (fraction * 100.0).toFixed(5);
    out.holdback = this.isDepositOrder() ? costPrice.toFixed(2) : (fraction * costPrice).toFixed(2);
    out.payToVendor = (costPrice - out.holdback).toFixed(2);
    out.payToVendorDesc = '';
    out.accounting_amount = refundAmount.toFixed(2);
    out.total_sale_price = totalSalePrice;

    if (item && item.source == 'Custom' && item.additional_info && item.additional_info.refund_vendor_agrees_to) {
      if (item.additional_info.refund_vendor_agrees_to == VENDOR_REFUND_AGREES_NUMBER) {
        out.payToVendorDesc = `${item.additional_info.number_of_nights} out of ${item.additional_info.total_nights} nights`;
        out.holdback = (
          (item.additional_info.total_nights - item.additional_info.number_of_nights) *
          (costPrice / item.additional_info.total_nights)
        ).toFixed(2);
      } else {
        out.payToVendorDesc = `${item.additional_info.percentage}%`;
        out.holdback = ((100 - item.additional_info.percentage) * (costPrice / 100)).toFixed(2);
      }
      out.payToVendor = (costPrice - out.holdback).toFixed(2);
    }

    return out;
  }

  addonCostData(item, purchaseItem, addon) {
    return this.costData({
      refundAmount: parseFloat(item.accounting_amount),
      costPrice: parseFloat(addon.cost_price),
      costCurrencyCode: purchaseItem.currency,
      totalSalePrice: purchaseItem.total,
      item: item,
    });
  }

  bedbankCostData(item, room) {
    return this.costData({
      refundAmount: parseFloat(item.accounting_amount),
      costPrice: parseFloat(room.cost_price),
      costCurrencyCode: this.props.order.currency_code,
      totalSalePrice: room.price,
      item: item,
    });
  }

  accommodationCostData(item, purchaseItem, offer, reservations, cancelledReservation) {
    const package_id = purchaseItem.fk_package;
    const package_data = offer.packages.find(
      (pkg) => pkg.id_salesforce_external == package_id || pkg.le_package_id == package_id,
    );
    let costPrice;
    let costCurrency = package_data.cost_currency;
    let totalSalePrice;

    if (item.charge_component_key.includes(`surcharge|`)) {
      totalSalePrice = 0;
      costPrice = 0;
      if (cancelledReservation) {
        totalSalePrice = cancelledReservation.surcharge_total;
        costPrice = parseFloat(cancelledReservation.surcharge_cost_total);
      } else if (purchaseItem && purchaseItem.reservation) {
        totalSalePrice = purchaseItem.reservation.surcharge;
        costPrice = parseFloat(reservations[item.charge_component_key.replace('surcharge|', '')].surcharge_cost_total);
      }
    } else if (DYNAMIC_PRICED_HOTEL_OFFER_TYPES.includes(purchaseItem.offer.type)) {
      totalSalePrice = purchaseItem.total;
      costPrice = purchaseItem.reservation.room_rate_cost_amount || 0;
      costCurrency = purchaseItem.reservation.room_rate_cost_currency || package_data.cost_currency;
    } else {
      totalSalePrice = purchaseItem.price;
      costPrice = parseFloat(package_data.cost_price);
      if (package_data.nightly_cost_price) {
        costPrice = parseFloat(package_data.cost_price + package_data.nightly_cost_price * purchaseItem.extra_nights);
      }
    }

    return this.costData({
      refundAmount: parseFloat(item.accounting_amount),
      costPrice: costPrice,
      costCurrencyCode: costCurrency,
      totalSalePrice: totalSalePrice,
      item: item,
    });
  }

  lineAccommodationItems(refundMetaItems) {
    const purchaseItems = this.props.order.items.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

    return refundMetaItems.reduce((acc, item) => {
      const cancelledReservation =
        this.props.cancelledReservations[item.charge_component_key?.replace('surcharge|', '')];
      const purchaseItem =
        purchaseItems[cancelledReservation?.item_id || item.charge_component_key?.replace('surcharge|', '')];
      if (purchaseItem) {
        const cost_data = this.accommodationCostData(
          item,
          purchaseItem,
          this.props.offers.find((o) => o.offer.id_salesforce_external === purchaseItem.offer.id_salesforce_external)
            .offer,
          this.props.reservations,
          cancelledReservation,
        );
        acc.push(
          <div key={item.id_refund_metadata}>{this.lineItem(item, this.props.sale_currency_code, cost_data)}</div>,
        );
      }
      return acc;
    }, []);
  }

  lineAddonItems(refundMetaItems) {
    const purchaseItems = this.props.order.addon_items.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

    return refundMetaItems.reduce((acc, item) => {
      const purchaseItem = purchaseItems[item.charge_component_key];
      if (purchaseItem) {
        const cost_data = this.addonCostData(item, purchaseItem, this.props.addons[purchaseItem.salesforce_id]);
        acc.push(
          <div key={item.id_refund_metadata}>{this.lineItem(item, this.props.sale_currency_code, cost_data)}</div>,
        );
      }
      return acc;
    }, []);
  }

  lineBedbankItems(refundMetaItems) {
    const purchaseItems = this.props.order.bedbank_items.reduce((acc, item) => {
      for (const room of item.rooms) {
        acc[room.id] = room;
      }
      return acc;
    }, {});

    return refundMetaItems.reduce((acc, item) => {
      const purchaseItem = purchaseItems[item.charge_component_key];
      if (purchaseItem) {
        const cost_data = this.bedbankCostData(item, purchaseItem);
        acc.push(
          <div key={item.id_refund_metadata}>{this.lineItem(item, this.props.sale_currency_code, cost_data)}</div>,
        );
      }
      return acc;
    }, []);
  }

  lineItems(data) {
    return [...this.lineAccommodationItems(data), ...this.lineAddonItems(data), ...this.lineBedbankItems(data)];
  }

  isDepositOrder() {
    return !!this.props.order?.payments[0]?.depositDetails;
  }

  render() {
    return (
      <>
        {this.lineItems(this.props.refunds.result).length > 0 && (
          <Box mt={2}>
            <Typography textAlign="left" fontWeight="bold">
              Vendor Holdback Explanation:
            </Typography>
            <Typography mt={1}>{this.lineItems(this.props.refunds.result)}</Typography>
          </Box>
        )}
      </>
    );
  }
}
