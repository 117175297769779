import React from 'react';

import { Helmet } from 'react-helmet';
import { Route, Switch, useParams } from 'react-router';

import { Box, Card, Container, Stack } from '@mui/material';

import TourDynamicPricingDetailsForm from '~/components/Tours/TourDetails/Forms/TourDynamicPricingDetailsForm';
import TourPricingDetailsForm from '~/components/Tours/TourDetails/Forms/TourPricingDetailsForm';

import TourCampaignScheduleDetailsForm from '../TourDetails/Forms/TourCampaignScheduleDetailsForm';
import TourFinePrintDetailsForm from '../TourDetails/Forms/TourFinePrintDetailsForm';
import TourImagesDetailsForm from '../TourDetails/Forms/TourImagesDetailsForm';
import TourInventoryDetailsForm from '../TourDetails/Forms/TourInventoryDetailsForm';
import TourOptionalExtrasDetailsForm from '../TourDetails/Forms/TourOptionalExtrasDetailsForm';
import TourOptionsDetailsForm from '../TourDetails/Forms/TourOptionsDetailsForm';
import TourRootDetailsForm from '../TourDetails/Forms/TourRootDetailsForm';
import TourTravellerRequirementsDetailsForm from '../TourDetails/Forms/TourTravellerRequirementsDetailsForm';
import TourAccommodationAddOns from '../TourDetails/TourAccommodationAddOns';
import { TourDetailsContextProvider } from '../TourDetails/TourDetailsContext';
import TourDetailsNav from '../TourDetails/TourDetailsNav';
import TourDetailUserReviews from '../TourDetails/TourDetailsUserReviews';

export default function TourDetailsPage() {
  const { tour_id } = useParams<{ tour_id: string }>();

  return (
    <TourDetailsContextProvider>
      <Helmet>
        <title>Tours | V2 | Details</title>
      </Helmet>
      <Container maxWidth={false}>
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
          <Box width={{ sm: 200, lg: 240, xl: 280 }} flexShrink={0}>
            <Card sx={{ position: 'sticky', top: 16 }}>
              <TourDetailsNav tourId={tour_id} />
            </Card>
          </Box>
          <Stack direction="column" flexGrow={1}>
            <Switch>
              <Route exact path="/tours/details/:tour_id">
                <TourRootDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/tour-options">
                <TourOptionsDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/tour-images">
                <TourImagesDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/tour-pricing">
                <TourPricingDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/tour-dynamic-pricing">
                <TourDynamicPricingDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/fine-print">
                <TourFinePrintDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/traveller-requirements">
                <TourTravellerRequirementsDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/campaign-schedule">
                <TourCampaignScheduleDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/inventory">
                <TourInventoryDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/optional-extras">
                <TourOptionalExtrasDetailsForm tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/user-reviews">
                <TourDetailUserReviews tourId={tour_id} />
              </Route>
              <Route exact path="/tours/details/:tour_id/add-ons/accommodation">
                <TourAccommodationAddOns tourId={tour_id} />
              </Route>
            </Switch>
          </Stack>
        </Stack>
      </Container>
    </TourDetailsContextProvider>
  );
}
