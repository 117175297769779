import React from 'react';

import { connect } from 'react-redux';

import { Box } from '@mui/material';

import CudoLogo from '~/components/Icons/CudoLogo';
import DealsLogo from '~/components/Icons/DealsLogo';
import KoganLogo from '~/components/Icons/KoganLogo';
import LELogoIcon from '~/components/Icons/LELogo';
import ScooponLogo from '~/components/Icons/ScooponLogo';
import TreatMeLogo from '~/components/Icons/TreatMeLogo';
import ZoomZoomLogo from '~/components/Icons/ZoomZoomLogo';

interface Props {
  tenant: App.TenantValues;
}

function TenantIcon({ tenant }: Props) {
  switch (tenant) {
    case 'scoopon':
      return <ScooponLogo sx={{ height: 32, width: 84 }} />;
    case 'cudo':
      return <CudoLogo sx={{ height: 32, width: 84 }} />;
    case 'deals':
      return <DealsLogo sx={{ height: 28, width: 84 }} />;
    case 'treatme':
      return <TreatMeLogo sx={{ height: 32, width: 84 }} />;
    case 'kogantravel':
      return <KoganLogo sx={{ height: 32, width: 84 }} />;
    case 'zoomzoom':
      return <ZoomZoomLogo sx={{ height: 32, width: 84 }} />;
    default:
      return (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <LELogoIcon sx={{ height: 32, width: 32 }} />
          <Box
            component="img"
            src="/assets/santa-hat.png"
            alt="Santa hat"
            sx={{
              position: 'absolute',
              left: '-12px',
              display: 'block',
              height: '32px',
              width: '32px',
              backgroundSize: '32px',
              backgroundRepeat: 'no-repeat',
              top: '-12px',
              transform: 'rotate(-18deg)',
              pointerEvents: 'none',
            }}
          />
        </Box>
      );
  }
}

const mapStateToProps = (state: App.State) => ({
  tenant: state.tenant.value,
});

export default connect(mapStateToProps)(TenantIcon);
