/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { Button } from '@mui/material';

import ConfirmCustomOfferRefundModal from '~/components/Refund/ConfirmCustomOfferRefundModal';

import { ITEM_STATUS_CANCELLED, ITEM_STATUS_FAILED, ITEM_TYPE_FLIGHT, ITEM_TYPE_OFFLINE_FLIGHT } from '~/consts/order';
import {
  INSURANCE_BREAKAGE,
  LATE_CANCEL_GOODWILL,
  OPERATOR_CANCELLATION,
  REFUND_TEST_PURCHASE,
  STEP_VENDOR_EMAIL_REMINDER,
} from '~/consts/refund';

import { cancelOrder, refundFlight, refundStatus } from '~/services/OrdersService';

import ApiError from '~/types/ApiError';

import asyncPoll from '~/utils/asyncPoll';
import generateTransactionKey from '~/utils/generateTransactionKey';

import Spinner from '../Common/Spinner';

export default class SubmitButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      confirmCustomOfferRefundModalOpen: false,
    };

    this.transactionKey = generateTransactionKey();
    this.handler = this.handler.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  buildBody = (payload, additionalInfo) => ({
    transaction_key: this.transactionKey,
    reason: this.props.refund.reason,
    refund_method: this.props.refund.refund_method,
    amount: this.props.totalRefund(),
    traveller_id: this.props.refund.traveller_id,
    comment: this.props.refund.comment,
    ticket_id: this.props.refund.ticket_id,
    room_id: this.props.refund.room_id,
    currency_code: this.props.refund.currency_code,
    mark_cancelled: this.props.refund.mark_cancelled,
    update_vcc_balance: this.props.refund.update_vcc_balance,
    send_refund_notification: this.props.refund.send_refund_notification,
    send_customer_refund_notification: this.props.refund.send_customer_refund_notification,
    accounting_metadata: payload,
    additional_info: additionalInfo,
    disable_credits_promo_refund: this.props.refund.disable_credits_promo_refund,
    remaining_deposit_items: this.props.refund.remaining_deposit_items,
    current_deposit_item: this.props.refund.current_deposit_item,
    is_deposit: this.props.refund.is_deposit,
    is_final_deposit_refund: this.props.refund.is_final_deposit_refund,
    is_instalment: this.props.refund.is_instalment,
    remaining_instalment_items: this.props.refund.remaining_instalment_items,
    current_instalment_item: this.props.refund.current_instalment_item,
    is_pending_deferred_payment: this.props.refund.is_pending_deferred_payment,
    remaining_deferred_payment_items: this.props.refund.remaining_deferred_payment_items,
    current_deferred_payment_item: this.props.refund.current_deferred_payment_item,
    merchant_fee_amount: this.props.refund.merchant_fee_amount,
    variant_of_refund: this.props.refund.variant_of_refund,
  });

  async cancel(payload, additionalInfo) {
    try {
      const body = this.buildBody(payload, additionalInfo);
      if ([ITEM_TYPE_FLIGHT, ITEM_TYPE_OFFLINE_FLIGHT].includes(this.props.type)) {
        await refundFlight(this.props.refund.id_orders, this.props.refund.item_id, body);
      } else {
        const selectedAddon = this.props.order.addon_items.find((addon) => addon.id === this.props.refund.item_id);
        if (selectedAddon && selectedAddon.channel_booking_id) {
          const allItemsForBooking = this.props.order.addon_items.filter(
            (addon) => addon.channel_booking_id === selectedAddon.channel_booking_id,
          );
          for (const addon of allItemsForBooking) {
            body.transaction_key = generateTransactionKey();
            body.amount = addon.total;
            body.accounting_metadata[0].accounting_amount = addon.total;
            body.accounting_metadata[0].cash_amount = addon.total;

            if (this.props.refund.reason === INSURANCE_BREAKAGE) {
              body.amount = 0;
              body.merchant_fee_amount = 0;
              body.accounting_metadata[0].cash_amount = 0;
            }

            await cancelOrder(this.props.refund.id_orders, addon.id, {
              ...body,
              merchant_fee_amount: addon.complimentary && addon.total === 0 ? 0 : body.merchant_fee_amount,
            });
          }
        } else {
          if (this.props.refund.reason === INSURANCE_BREAKAGE) {
            body.amount = 0;
            body.merchant_fee_amount = 0;
            body.accounting_metadata[0].cash_amount = 0;
          }
          await cancelOrder(this.props.refund.id_orders, this.props.refund.item_id, body);

          try {
            await asyncPoll({
              apiCall: () => refundStatus(this.props.refund.id_orders, this.props.refund.item_id),
              validateFunction: ({ jobState }) => {
                if (jobState === undefined) return true;

                return false;
              },
              maxTime: 30000,
            });
          } catch (e) {
            // We need to continue process whether refund checking request failed or not
          }
        }
      }
    } catch (err) {
      if (err.errors && err.errors.length) {
        const message = err.errors[0].stack ? err.errors[0].stack : err.errors[0].toString();

        throw new ApiError({
          message,
          name: 'cancelOrder',
        });
      }

      throw new ApiError({
        message: `Error: ${err.message}`,
        name: 'cancelOrder',
      });
    }
  }

  cancelComplimentaryAddons = async (payload) => {
    const accommodationItemId = this.props.refund.item_id;
    const orderId = this.props.refund.id_orders;
    const order = this.props.order;

    // order will be undefined for flight refund
    if (!order) return;

    const accommodationItem = order.accommodation_items.find((item) => item.id_items === accommodationItemId);

    // it's not a accommodation refund
    if (!accommodationItem) return;

    // if we reach this point it means that we are trying to cancel
    // accommodation item (it may take some time) and
    // it's, potentially, last non-cancelled item
    const otherAccommodations = order.accommodation_items.filter((item) => item.id_items !== accommodationItemId);
    const hasAllOtherAccommodationsCancelled =
      !otherAccommodations.length ||
      otherAccommodations.every((item) => [ITEM_STATUS_CANCELLED, ITEM_STATUS_FAILED].includes(item.status));

    // do we have active complimentary addons?
    const activeComplimentaryAddons = order.addon_items.filter(
      (addon) => addon.complimentary && ![ITEM_STATUS_CANCELLED, ITEM_STATUS_FAILED].includes(addon.status),
    );

    if (!hasAllOtherAccommodationsCancelled || !activeComplimentaryAddons.length) {
      return;
    }

    const body = this.buildBody(payload, {});
    const pendingPromises = activeComplimentaryAddons.map((addon) => {
      body.transaction_key = generateTransactionKey();
      body.amount = addon.total;
      body.accounting_metadata = [
        {
          accounting_amount: addon.total,
          cash_amount: addon.total,
          charge_component_key: addon.id,
          refund_fee: 0,
          source: this.props.refund.source,
        },
      ];

      if (this.props.refund.reason === INSURANCE_BREAKAGE) {
        body.amount = 0;
        body.merchant_fee_amount = 0;
        body.accounting_metadata[0].cash_amount = 0;
      }

      body.merchant_fee_amount = addon.complimentary && addon.total === 0 ? 0 : body.merchant_fee_amount;
      return cancelOrder(orderId, addon.id, body);
    });

    await Promise.all(pendingPromises);
  };

  async handler(event) {
    event.preventDefault();
    if (this.state.loading) {
      return;
    }

    this.setState({ loading: true });

    if (!this.props.refund.refund_method) {
      this.setState({ loading: false });
      this.props.setError('Select One From Methods of Refund to Customer');
      return;
    }

    if (this.props.refund.is_insurance && this.props.refund.out_of_cooling_off) {
      if (!this.props.refund.comment) {
        const errorMsg =
          this.props.refund.reason === REFUND_TEST_PURCHASE
            ? "Agent's name must be filled"
            : "A Customer's reason must be filled";
        this.setState({ loading: false });
        this.props.setError(errorMsg);
        return;
      }
      if (!this.props.refund.ticket_id) {
        this.setState({ loading: false });
        this.props.setError('A Case ID must be filled');
        return;
      }
    }

    if (
      this.props.refund.traveller_id &&
      this.props.refund.mark_cancelled === undefined &&
      this.props.itemStatus &&
      this.props.itemStatus !== 'cancelled'
    ) {
      this.setState({ loading: false });
      this.props.setError('Should decide whether the flight item needs to be cancelled');
      return;
    }

    try {
      let metaData = [];

      if (this.props.hasRefundItem()) {
        metaData.push(this.props.refund.item_metadata);
      }
      if (this.props.hasRefundSurcharge()) {
        metaData.push(this.props.refund.surcharge_metadata);
      }

      let payload = this.props.accountingMetaPayload(metaData);
      let additionalInfo = {};

      if (this.props.refund.refund_vendor_agrees_to) {
        additionalInfo = {
          refund_vendor_agrees_to: this.props.refund.refund_vendor_agrees_to,
          number_of_nights: this.props.refund.number_of_nights,
          percentage: this.props.refund.percentage,
          package_nights: this.props.refund.package_nights,
          extra_nights: this.props.refund.extra_nights,
          total_nights: this.props.refund.total_nights,
        };
      }

      if (this.props.refund.nib_reference_id) {
        additionalInfo['nib_reference_id'] = this.props.refund.nib_reference_id;
      }

      if (this.props.refund.refund_subtype) {
        additionalInfo['refund_subtype'] = this.props.refund?.refund_subtype;
      }

      if (!this.props.refund.has_manager_approval && this.props.refund.needs_manager_approval) {
        this.setState({ loading: false });
        this.props.setError('Manager approval is required');
        return;
      }

      await this.cancel(payload, additionalInfo);
      await this.cancelComplimentaryAddons(payload);

      this.setState({ loading: false });

      if (
        this.props.stepHandler &&
        this.props.refund.mark_cancelled &&
        this.props.refund.is_tour &&
        this.props.refund.is_ttc_tour
      ) {
        this.props.stepHandler((_, next) => next(STEP_VENDOR_EMAIL_REMINDER));
      } else {
        this.props.closeModal();
      }
    } catch (err) {
      this.props.setError(err.message);
      this.setState({ loading: false });
      console.warn('Exception:', err);
    }
  }

  onRefundSubmitClick = (event) => {
    if (this.props.order?.custom_offer_items && this.props.order.custom_offer_items.length > 0) {
      this.setState({ confirmCustomOfferRefundModalOpen: true });

      return;
    }

    this.handler(event);
  };

  onCustomOfferDialogConfirmClick = (event) => {
    this.setState({ confirmCustomOfferRefundModalOpen: false });
    this.handler(event);
  };

  render() {
    return (
      <>
        <div>
          {this.state.loading && <Spinner size={32} />}
          {!this.state.loading && (
            <Button className="T-submit" type="submit" onClick={this.onRefundSubmitClick} variant="contained">
              {this.props.refund.reason === LATE_CANCEL_GOODWILL || this.props.refund.reason === OPERATOR_CANCELLATION
                ? 'Submit for Approval'
                : 'Submit Refund'}
            </Button>
          )}
        </div>

        <ConfirmCustomOfferRefundModal
          open={this.state.confirmCustomOfferRefundModalOpen}
          onClose={() => this.setState({ confirmCustomOfferRefundModalOpen: false })}
          onConfirm={this.onCustomOfferDialogConfirmClick}
        />
      </>
    );
  }
}
