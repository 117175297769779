import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { TourDetailsFormResources, useTourDetailsFormQuery } from '~/queries/tours/useTourDetailsFormQueries';

import { Alert, AlertTitle, Box, Button, Stack, Typography } from '@mui/material';

import { Request as APIRequest } from '@luxuryescapes/contract-svc-tour/types/requests';
import { Response } from '@luxuryescapes/contract-svc-tour/types/response';

import Spinner from '~/components/Common/Spinner';
import TourPricingTableForm from '~/components/Tours/TourDetails/Forms/TourPricingTableForm';

import { useToggle } from '~/utils/hooks';

interface Props {
  tourId: string;
}
const emptyPricing = { data: [] };

export default function TourDynamicPricingDetailsForm({ tourId }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    fetchRequestInstance,
    fetch: fetchTourPricing,
    patchRequestInstance,
    patch: patchTourPricing,
  } = useTourDetailsFormQuery(TourDetailsFormResources.TOUR_DYNAMIC_PRICINGS);

  const [result, setResult] = useState<Response.TourPricingDynamicFormResponse>(emptyPricing);

  const { value: isViewMode, toggle } = useToggle(true);

  const onSubmit = useCallback(
    (formData: Array<APIRequest.TourPricingFormDataV2>) => {
      patchTourPricing(tourId, formData);
    },
    [patchTourPricing, tourId],
  );

  useEffect(() => {
    fetchTourPricing(tourId);
  }, [fetchTourPricing, tourId]);

  useEffect(() => {
    switch (patchRequestInstance.status) {
      case 'succeeded':
        enqueueSnackbar('Tour pricing updated successfully.', {
          variant: 'success',
        });
        break;
      case 'failed':
        enqueueSnackbar(`Submission failed! ${patchRequestInstance.error}`, {
          variant: 'error',
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequestInstance.status, enqueueSnackbar]);

  useEffect(() => {
    if (patchRequestInstance.status === 'succeeded') {
      setResult(patchRequestInstance.result);
    }
    if (fetchRequestInstance.status === 'succeeded' && patchRequestInstance.status === 'uninitiated') {
      setResult(fetchRequestInstance.result);
    }
  }, [fetchRequestInstance, patchRequestInstance]);

  if (fetchRequestInstance.status === 'pending' || fetchRequestInstance.status === 'uninitiated') {
    return <Spinner />;
  }

  if (fetchRequestInstance.status === 'failed') {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" variant="outlined" onClick={() => fetch(tourId)}>
            Retry
          </Button>
        }
      >
        <AlertTitle>Failed to fetch the form.</AlertTitle>
        {fetchRequestInstance.error}
      </Alert>
    );
  }

  return (
    <Box display="flex" gap={4} flexDirection="column" sx={{ width: 'fit-content' }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">Tour pricing summary</Typography>
        <Button variant="outlined" onClick={toggle}>
          {isViewMode ? 'Edit dynamic pricing' : 'View pricing'}
        </Button>
      </Stack>
      <TourPricingTableForm
        pricing={result.data}
        readonly={isViewMode}
        onUpdate={onSubmit}
        disableSave={patchRequestInstance.status === 'pending'}
      />
    </Box>
  );
}
