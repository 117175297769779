import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import SearchService, { UserRecentOfferPageView } from '~/services/SearchService';
import { formatDateWithClock } from '~/services/TimeService';

import { getHotelProductName } from '~/utils/hotelUtils';
import { reportError } from '~/utils/reportError';
import { titleCase } from '~/utils/stringUtils';

import GridPagination from '../Common/Elements/GridPagination';

interface Props {
  userId: string;
}

const columns: Array<GridColDef> = [
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    valueGetter: (value) => formatDateWithClock(value),
    flex: 1,
    display: 'flex',
  },
  {
    field: 'url',
    headerName: 'URL',
    renderCell: (params) => {
      const url = params.row.url;
      return (
        <a href={url} target="_blank" rel="noreferrer">
          URL
        </a>
      );
    },
  },
  {
    field: 'region',
    headerName: 'Region',
    renderCell: (params) => {
      const url = params.row.url;
      const region = url.match(/luxuryescapes\.com\/([^/]+)/);
      return <div>{region?.[1]?.toUpperCase() ?? '-'}</div>;
    },
  },
  {
    field: 'product',
    headerName: 'Product',
    flex: 1,
    display: 'flex',
    valueGetter: (value: UserRecentOfferPageView['product']) => {
      return getHotelProductName(value);
    },
  },
  {
    field: 'pathParams',
    headerName: 'Offer Title',
    flex: 1,
    display: 'flex',
    valueGetter: (value: UserRecentOfferPageView['pathParams']) => {
      if (!value) {
        return '-';
      }
      return titleCase(value.offerSlug.replace(/-/g, ' '));
    },
  },
  {
    field: 'destinationCity',
    headerName: 'Destination City',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'destinationRegion',
    headerName: 'Destination Region',
    flex: 1,
    display: 'flex',
  },
  {
    field: 'destinationCountry',
    headerName: 'Destination Country',
    flex: 1,
    display: 'flex',
  },
];

function UserRecentOfferPageViewsTable({ userId }: Props) {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const [result, setResult] = useState<UserRecentOfferPageView[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    try {
      SearchService.getUserRecentOfferPageViews(userId, brand)
        .then((response) => setResult(response.result))
        .finally(() => setLoading(false));
    } catch (error) {
      reportError(error);
      enqueueSnackbar('Error fetching recent offer page views', { variant: 'error' });
    }
  }, [userId, brand]);

  return (
    <Box>
      <DataGrid
        rows={result || []}
        columns={columns}
        getRowId={() => uuid()}
        autoHeight
        loading={loading}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5]}
        paginationModel={{ pageSize: 10, page }}
        onPaginationModelChange={({ page }) => setPage(page)}
        disableColumnMenu
        disableRowSelectionOnClick
        density="compact"
        slots={{ pagination: GridPagination }}
      />
    </Box>
  );
}

export default UserRecentOfferPageViewsTable;
