import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Autocomplete, Box, Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DesktopDateTimePicker } from '@mui/x-date-pickers-pro';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useQuery from '~/hooks/useQuery';

import { FailedEmailRecord, getFailedEmails, resendFailedEmail } from '~/services/NotificationProxyService';
import { Dayjs, toDayJs } from '~/services/TimeService';

import { availableEndpoints } from '../../consts/availableEndpoints';

import FailedEmailModel from './FailedEmailDialog';
import getColumns from './getColumns';

interface FormValues {
  lastOccurredAtStart: Dayjs | null;
  payloadKeywords: string;
  httpPath: string;
  isSuccessfulSent: boolean;
}
interface QueryParams {
  page_num: number;
  page_size: number;
  payload_keywords: string;
  http_path: string;
  last_occurred_at_start: string | null;
  is_successful_sent: boolean;
}

function FailedEmailContainer() {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState<FailedEmailRecord | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNum, setPageNum] = useState<number>(Number(query.get('page_num') ?? 0));
  const [pageSize, setPageSize] = useState<number>(Number(query.get('page_size') ?? 10));
  const [totalCount, setTotalCount] = useState(0);
  const tenant = useCurrentTenant();
  const history = useHistory();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      httpPath: query.get('http_path') || '',
      payloadKeywords: query.get('payload_keywords') || '',
      lastOccurredAtStart: query.get('last_occurred_at_start') ? toDayJs(query.get('last_occurred_at_start')) : null,
      isSuccessfulSent: query.get('is_successful_sent') === 'true',
    },
  });
  const setQueryParams = useCallback(() => {
    const lastOccurredAtStartIsoString = getValues('lastOccurredAtStart')
      ? getValues('lastOccurredAtStart').toISOString()
      : null;
    const searchParams = qs.stringify({
      page_num: pageNum,
      page_size: pageSize,
      payload_keywords: getValues('payloadKeywords'),
      http_path: getValues('httpPath'),
      last_occurred_at_start: lastOccurredAtStartIsoString,
      is_successful_sent: getValues('isSuccessfulSent'),
    } as QueryParams);
    history.push({
      search: `?${searchParams}`,
    });
  }, [getValues, history, pageNum, pageSize]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const {
        result: { data, total },
      } = await getFailedEmails({
        pageNum: pageNum,
        pageSize: pageSize,
        brand: tenant.tenant.brand,
        payloadQuery: getValues('payloadKeywords'),
        httpPath: getValues('httpPath'),
        lastOccurredAtStart: query.get('last_occurred_at_start') ? new Date(query.get('last_occurred_at_start')) : null,
        isSuccessfulSent: getValues('isSuccessfulSent'),
      });

      setData(data ?? []);
      setIsLoading(false);
      setTotalCount(Number(total));
      setQueryParams();
    } catch (error) {
      enqueueSnackbar('Fetch failed email sends failed', { variant: 'error' });
      return;
    }
  }, [pageNum, pageSize, tenant.tenant.brand, getValues, query, setQueryParams, enqueueSnackbar]);

  const dataRows = data.map((row, index) => ({
    id: index,
    ...row,
  }));

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      setPageNum(page);
      setPageSize(pageSize);
      query.set('page_num', page.toString());
      query.set('page_size', pageSize.toString());
      window.history.pushState({}, '', `${window.location.pathname}?${query.toString()}`);
      fetchData();
    },
    [fetchData, query],
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // START - New Markup Modal
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = useCallback(async () => {
    fetchData();
    handleClose();
    enqueueSnackbar('Search Succeed', { variant: 'success' });
  }, [enqueueSnackbar, fetchData]);

  const handleResendEmail = useCallback(
    async ({ id }: { id: string }) => {
      try {
        await resendFailedEmail({ id });
      } catch (error) {
        enqueueSnackbar('Resend Email Failed', { variant: 'error' });
        fetchData();
        return;
      }
      handleClose();
      enqueueSnackbar('Resend Email Succeed', { variant: 'success' });
    },
    [enqueueSnackbar, fetchData],
  );

  const handleViewDetails = useCallback(
    async ({ id }: { id: string }) => {
      setSelectedRecord(data.find((row) => row.id === id));
      setOpen(true);
    },
    [data],
  );

  return (
    <>
      <Helmet>
        <title>Channel Markup</title>
      </Helmet>

      <PageSubheader title={`Search Failed Email Sends [${data?.length} of ${totalCount}]`} />

      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        component="form"
        onSubmit={handleSubmit(handleSearch)}
      >
        <Grid item>
          <TextField
            variant="outlined"
            label="Search On Payload"
            {...register('payloadKeywords')}
            error={!!errors.payloadKeywords}
            helperText={errors.payloadKeywords?.message}
            sx={{ width: 300 }}
          />
        </Grid>
        <Grid item>
          <Controller
            control={control}
            name="lastOccurredAtStart"
            render={({ field }) => {
              return (
                <DesktopDateTimePicker
                  label="Last Occurred At (UTC Time)"
                  disableFuture
                  timezone="UTC"
                  sx={{ width: 300 }}
                  ampm={false}
                  onChange={field.onChange}
                  onAccept={field.onChange}
                  value={field.value}
                  inputRef={field.ref}
                />
              );
            }}
          />
        </Grid>
        <Grid item>
          <Autocomplete
            disablePortal
            options={availableEndpoints}
            sx={{ width: 300 }}
            freeSolo
            defaultValue={query.get('http_path') || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Http Path"
                {...register('httpPath')}
                error={!!errors.httpPath}
                helperText={errors.httpPath?.message}
              />
            )}
          />
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Controller
            name="isSuccessfulSent"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <FormControlLabel
                  control={<Switch checked={value} onChange={onChange} />}
                  label="Include Successful Sends"
                />
                {value && (
                  <Typography variant="caption" color="text.secondary" sx={{ ml: 0.5 }}>
                    Turn on this would impact the query speed
                  </Typography>
                )}
              </>
            )}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" color="primary" type="submit">
            Search
          </Button>
        </Grid>
      </Grid>
      <FailedEmailModel
        open={open}
        handleClose={handleClose}
        handleResendEmail={handleResendEmail}
        selectedRecord={selectedRecord}
      />

      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          loading={isLoading}
          rows={dataRows}
          columns={getColumns({ handleViewDetails, handleResendEmail })}
          autoHeight
          rowCount={totalCount}
          paginationMode="server"
          pageSizeOptions={[10, 20, 50]}
          paginationModel={{ page: pageNum, pageSize: pageSize }}
        />
      </Box>
    </>
  );
}

export default FailedEmailContainer;
