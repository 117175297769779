import { Request as APIRequest } from '@luxuryescapes/contract-svc-tour/types/requests';

import { EditPricingRooms, PricingRoom } from '~/components/Tours/TourDetails/Forms/TourPricingBulkUpdateForm';

function overridePercentage(roomPercentage: number | undefined, overridePercentage: number | undefined) {
  if (overridePercentage !== undefined && !(overridePercentage === 0 && roomPercentage === undefined)) {
    return overridePercentage;
  }
  return roomPercentage;
}
function overrideRoomPricing(room: APIRequest.PricingRoom, pricingRoom: PricingRoom) {
  return {
    ...room,
    discountPercentage: overridePercentage(room.discountPercentage, pricingRoom.discountPercentage),
    surchargePercentage: overridePercentage(room.surchargePercentage, pricingRoom.surchargePercentage),
    luxPlusDiscount: overridePercentage(room.luxPlusDiscount, pricingRoom.luxPlusDiscount),
  };
}
export function mergeRoomsPricing(
  rooms: APIRequest.TourOptionDeparturePackagePricingData['rooms'],
  overridePricingRooms: EditPricingRooms,
) {
  return {
    ...rooms,
    single: rooms.single ? overrideRoomPricing(rooms.single, overridePricingRooms.single) : undefined,
    twin: rooms.twin ? overrideRoomPricing(rooms.twin, overridePricingRooms.twin) : undefined,
  };
}
