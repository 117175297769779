import React from 'react';

import { useParams } from 'react-router-dom';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { CruiseInclusion } from '~/components/Cruises/pages/Inclusions/types';

import InclusionItemForm from './components/InclusionItemForm';

type Props = {
  inclusionType: CruiseInclusion['type'];
  onSave: () => void;
};

function InclusionsItemCreate({ inclusionType, onSave }: Props) {
  const { inclusionId } = useParams<{ inclusionId: string }>();

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>Create Inclusion Item</DialogTitle>
      <DialogContent>
        <InclusionItemForm inclusionId={inclusionId} inclusionType={inclusionType} onSave={onSave} />
      </DialogContent>
    </Dialog>
  );
}

export default InclusionsItemCreate;
