import React, { ChangeEventHandler, useCallback, useMemo, useState } from 'react';

import pluralize from 'pluralize';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Divider, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';

import {
  ExtendedMappedInternalRoom,
  InternalRoom,
  MappedInternalRoom,
  Room,
  RoomMappingPayload,
} from '~/services/AccommodationService';

import ControlRoomElement from './ControlRoomElement';

interface Props {
  propertyRooms: Array<Room>;
  selectedControlRoom: Room | null;
  selectedInternalRooms: Set<string>;
  appliedButNotSavedRooms: Array<InternalRoom>;
  handleCreateAndMapRoom: () => void;
  handleRoomSelection: (room: Room) => void;
  handleUpdateMapping: () => void;
  handleVerifyMappedRoom: (mappedRoom: MappedInternalRoom) => void;
  clearNewMappings: (room: Room) => void;
  handleRemove: (mappedRoom: ExtendedMappedInternalRoom) => void;
  updatePayload: RoomMappingPayload;
  removePayload: RoomMappingPayload;
}

export default function MappedRooms({
  propertyRooms,
  selectedControlRoom,
  selectedInternalRooms,
  appliedButNotSavedRooms,
  handleCreateAndMapRoom,
  handleRoomSelection,
  handleUpdateMapping,
  handleVerifyMappedRoom,
  clearNewMappings,
  handleRemove,
  updatePayload,
  removePayload,
}: Props) {
  const [filteringText, setFilteringText] = useState('');
  const resetFilteringText = useCallback(() => {
    setFilteringText('');
  }, []);
  const handleFilteringTextInput = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setFilteringText(event.target.value);
  }, []);

  const filteredPropertyRooms = useMemo(() => {
    return filteringText
      ? propertyRooms.filter((room) => {
          return room.name.toLowerCase().includes(filteringText.toLowerCase());
        })
      : propertyRooms;
  }, [propertyRooms, filteringText]);

  const selectedFromPropertyRooms = useMemo(() => {
    const totalCount = propertyRooms.length;

    const totalWithMappedRooms = propertyRooms.filter((room) => room.mappedRooms.length > 0).length;

    const totalWithoutMappedRooms = totalCount - totalWithMappedRooms;

    return selectedControlRoom
      ? `${selectedControlRoom.name} selected`
      : `${totalWithMappedRooms} ${pluralize(
          'rooms',
          totalWithMappedRooms,
        )} with mapping, ${totalWithoutMappedRooms} ${pluralize('rooms', totalWithoutMappedRooms)} without mapping`;
  }, [propertyRooms, selectedControlRoom]);

  return (
    <Stack direction="column" gap={2}>
      <Paper square sx={{ position: 'sticky', top: 0, zIndex: 1, pt: 1 }}>
        <Stack direction="column" gap={2}>
          <Typography variant="h6">Property Rooms: {selectedFromPropertyRooms}</Typography>
          <TextField
            label="Filter by room name, room ID, or mapped room ID:"
            value={filteringText}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={resetFilteringText} disabled={filteringText.length < 1} title="clear" edge="end">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onInput={handleFilteringTextInput}
          />
          <Button
            variant="contained"
            startIcon={<AccountTreeIcon />}
            title="Map selected internal rooms to the selected Room"
            onClick={handleUpdateMapping}
            disabled={Array.from(selectedInternalRooms.entries()).length < 1 || !selectedControlRoom}
          >
            Apply selected Mapping
          </Button>
          <Button
            variant="contained"
            title="Create new property room"
            onClick={handleCreateAndMapRoom}
            disabled={Array.from(selectedInternalRooms.entries()).length < 1 || !!selectedControlRoom}
          >
            Map to new Property Room
          </Button>
          <Divider />
        </Stack>
      </Paper>
      <Stack direction="column" gap={2}>
        {filteredPropertyRooms?.map((room) => (
          <ControlRoomElement
            key={room.id}
            room={room}
            isSelected={selectedControlRoom?.id === room?.id}
            onSelect={handleRoomSelection}
            onRemoveMappedRoom={handleRemove}
            onVerifyMappedRoom={handleVerifyMappedRoom}
            clearNewMappings={() => clearNewMappings(room)}
            newMappings={updatePayload.find((r) => r.roomId === room.id)}
            unsavedInternalRooms={appliedButNotSavedRooms}
            removePayload={removePayload}
          />
        ))}
        {filteringText && filteredPropertyRooms.length === 0 && <Typography>No rooms found based on filter</Typography>}
      </Stack>
    </Stack>
  );
}
