import React from 'react';

import { List, styled } from '@mui/material';

const InfoContainer = styled(List)({
  listStyle: 'outside',
  margin: 0,
  padding: '0 0 0 1em',
});

interface Props {
  info: string;
}

function RankingInfo({ info }: Props) {
  const lines = info.split('\n');
  if (lines.length === 1) {
    return <>{info}</>;
  }

  return (
    <InfoContainer>
      {lines.map((line, index) => (
        <li key={index}>{line}</li>
      ))}
    </InfoContainer>
  );
}

export default RankingInfo;
