import React, { ComponentProps, forwardRef, useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Stack } from '@mui/material';

import { AccommodationTabs } from '~/components/Accommodation/Pages/Home/AccommodationHomePage';
import AccommodationPropertiesResultsTable, {
  AccommodationPropertiesResultsTableRef,
} from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesResultsTable';
import AccommodationUnmappedSuppliersResultsTable from '~/components/Accommodation/Pages/Properties/components/AccommodationUnmappedSuppliersResultsTable';

import AccommodationPropertiesSearchForm, {
  AccommodationPropertiesSearchInput,
} from './components/AccommodationPropertiesSearchForm';

function getSearchInputFromSearchParams(searchParams: URLSearchParams): AccommodationPropertiesSearchInput {
  const input: AccommodationPropertiesSearchInput = {};
  const propertyName = searchParams.get('propertyName');
  if (propertyName) {
    input.propertyName = propertyName;
  }
  const id = searchParams.get('id');
  if (id) {
    input.id = id;
  }

  return input;
}

interface Props {
  selectedTab: AccommodationTabs;
  refreshProperties: () => void;
}

const AccommodationProperties = forwardRef<AccommodationPropertiesResultsTableRef, Props>(
  function AccommodationProperties({ selectedTab, refreshProperties }, ref) {
    const history = useHistory();
    const location = useLocation();

    const handleSearchFormSubmission = useCallback<
      ComponentProps<typeof AccommodationPropertiesSearchForm>['onSubmit']
    >(
      (formObject) => {
        const urlSearchParamsToPush = new URLSearchParams(formObject);
        history.push({
          search: urlSearchParamsToPush.toString(),
        });
      },
      [history],
    );

    const urlSearchParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search]);

    const { searchInput, pageNumber } = useMemo(() => {
      const pageFromSearchParams = urlSearchParams.get('page');
      return {
        searchInput: getSearchInputFromSearchParams(urlSearchParams),
        pageNumber: pageFromSearchParams ? Number(pageFromSearchParams) : 1,
      };
    }, [urlSearchParams]);

    const handlePageChange = useCallback(
      (pageIndex: number) => {
        const urlSearchParamsToPush = new URLSearchParams(urlSearchParams);
        if (pageIndex) {
          urlSearchParamsToPush.set('page', String(pageIndex + 1));
        } else {
          urlSearchParamsToPush.delete('page');
        }
        history.push({
          search: urlSearchParamsToPush.toString(),
        });
      },
      [urlSearchParams, history],
    );

    return (
      <Stack direction="column" spacing={2}>
        <AccommodationPropertiesSearchForm
          initialInput={searchInput}
          onSubmit={handleSearchFormSubmission}
          refreshProperties={refreshProperties}
          selectedTab={selectedTab}
        />
        {selectedTab === AccommodationTabs.PROPERTIES && (
          <AccommodationPropertiesResultsTable
            ref={ref}
            searchInput={searchInput}
            pageIndex={pageNumber - 1}
            onPageChange={handlePageChange}
          />
        )}
        {selectedTab === AccommodationTabs.UNMAPPED_SUPPLIERS && (
          <AccommodationUnmappedSuppliersResultsTable
            searchInput={searchInput}
            pageIndex={pageNumber - 1}
            onPageChange={handlePageChange}
          />
        )}
      </Stack>
    );
  },
);

export default AccommodationProperties;
