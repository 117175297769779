import { isAfter } from '~/services/TimeService';

export default function isOrderRefundable(order: App.Order, reservations?): boolean {
  // reservation is sometimes empty for bedbanks
  if (reservations && Object.keys(reservations).length > 0) {
    return isReservationRefundable(reservations);
  } else if (order) {
    return isOrderItemsRefundable(order);
  }
  return true;
}

function isReservationRefundable(reservations): boolean {
  const reservation = Object.values(reservations).find((value) => (value as any)?.cancellation_policies) as any;

  if (!reservation) {
    return true;
  }

  if (reservation.refundable !== undefined && reservation.connection) {
    return reservation.refundable;
  }

  if (reservation.cancellation_policies && reservation.cancellation_policies.length > 0) {
    const firstPolicy = reservation.cancellation_policies[0];
    if (firstPolicy.cancellation_policy === 'non-refundable') {
      return false;
    }
    return isAfter(firstPolicy.start, new Date());
  }

  return true;
}

function isOrderItemsRefundable(order: App.Order): boolean {
  if (order.items && order.items.length > 0 && order.items[0].cancellation_policies?.[0]?.start) {
    return isAfter(order.items[0].cancellation_policies?.[0].start);
  }

  if (order.bedbank_items && order.bedbank_items.length > 0) {
    return isBedBankRefundable(order.bedbank_items);
  }

  return true;
}

function isBedBankRefundable(bedbankItems) {
  // we can just access the first items cancellation policy
  // as all rooms in an order must be refunded together
  const firstRoom = bedbankItems[0]?.rooms[0];
  if (firstRoom?.cancellation_policies?.length > 0) {
    return isAfter(firstRoom.cancellation_policies[0].start);
  }
  return false;
}
