import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import fileDownload from 'react-file-download';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { Box, Button, Grid, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useCurrentTenant from '~/hooks/useCurrentTenant';
import useQuery from '~/hooks/useQuery';

import { GetContentfulFormsResponse, downloadContentfulFormCsv, getContentfulForms } from '~/services/PromoService';

import dateFormatter from '~/utils/dateFormatter';

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 1,
};

type FormValues = {
  slugKeywords: string;
};

const getColumns = ({
  enqueueSnackbar,
}: {
  enqueueSnackbar: (message: string, options: { variant: 'success' | 'error' }) => void;
}): Array<GridColDef> => [
  {
    field: 'slug',
    headerName: 'Slug',
    ...sharedOpts,
  },
  {
    field: 'count',
    headerName: 'Count',
    ...sharedOpts,
  },
  {
    field: 'first_updated_at',
    headerName: 'First Updated At',
    renderCell: (params) => dateFormatter(params.row.first_updated_at),
    ...sharedOpts,
  },
  {
    field: 'last_updated_at',
    headerName: 'Last Updated At',
    renderCell: (params) => dateFormatter(params.row.last_updated_at),
    ...sharedOpts,
  },
  {
    field: 'operation',
    headerName: 'Operation',
    renderCell: (params) => {
      const handleDownload = async () => {
        try {
          const csv = await downloadContentfulFormCsv(params.row.slug);
          const fileName = `contentful-form-${params.row.slug}.csv`;
          await fileDownload(csv, fileName);
          enqueueSnackbar('Download succeed', { variant: 'success' });
        } catch (error) {
          console.error('Download failed:', error);
          enqueueSnackbar('Download failed', { variant: 'error' });
        }
      };

      return (
        <Button variant="contained" color="secondary" size="small" onClick={handleDownload}>
          Download
        </Button>
      );
    },
    ...sharedOpts,
  },
];

type FormData = GetContentfulFormsResponse['result'];
function ContentfulFormsContainer() {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<FormData>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNum, setPageNum] = useState<number>(query.get('page_num') ? Number(query.get('page_num')) : 0);
  const [pageSize, setPageSize] = useState<number>(query.get('page_size') ? Number(query.get('page_size')) : 10);
  const [slugKeywords, setSlugKeywords] = useState<string>(query.get('slug_keywords') || '');
  const [totalCount, setTotalCount] = useState(0);
  const tenant = useCurrentTenant();
  const history = useHistory();

  const { register, handleSubmit, getValues } = useForm<FormValues>({
    defaultValues: {
      slugKeywords: query.get('slug_keywords') || '',
    },
  });

  const setQueryParams = useCallback(() => {
    const searchParams = qs.stringify({
      page_num: pageNum,
      page_size: pageSize,
      slug_keywords: slugKeywords,
    });
    history.push({
      search: `?${searchParams}`,
    });
  }, [history, pageNum, pageSize, slugKeywords]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await getContentfulForms({
        pageNum: pageNum,
        pageSize: pageSize,
        brand: tenant.tenant.brand,
        slugKeywords: getValues('slugKeywords'),
      });
      setData(result.result ?? []);
      setIsLoading(false);
      setTotalCount(Number(result.count));
      setQueryParams();
    } catch (error) {
      enqueueSnackbar('Channel Markup fetch failed', { variant: 'error' });
      return;
    }
  }, [pageNum, pageSize, tenant.tenant.brand, getValues, setQueryParams, enqueueSnackbar]);

  useEffect(() => {
    fetchData();
  }, [fetchData, pageSize, pageNum]);

  const onPageChange = useCallback(
    (pageNum: number, pageSize: number) => {
      setPageNum(pageNum);
      setPageSize(pageSize);
      query.set('page_num', pageNum.toString());
      query.set('page_size', pageSize.toString());
      const searchParams = qs.stringify({
        page_num: pageNum,
        page_size: pageSize,
        slug_keywords: slugKeywords,
      });
      // Update URL
      history.push({
        search: `?${searchParams}`,
      });
      fetchData();
    },
    [fetchData, history, query, slugKeywords],
  );

  const handleSearch = useCallback(
    (data: FormValues) => {
      setSlugKeywords(data.slugKeywords);
      enqueueSnackbar('Search Succeed', { variant: 'success' });
    },
    [enqueueSnackbar],
  );

  return (
    <>
      <Helmet>
        <title>Contentful Forms</title>
      </Helmet>

      <PageSubheader title={`Search Contentful Forms [${data?.length} of ${totalCount}]`} />

      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        alignItems="end"
        component="form"
        onSubmit={handleSubmit(handleSearch)}
      >
        <Grid item>
          <TextField label="Search by slug" {...register('slugKeywords')} />
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" color="primary">
            Search
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          loading={isLoading}
          rows={data}
          columns={getColumns({ enqueueSnackbar })}
          autoHeight
          rowCount={totalCount}
          paginationMode="server"
          pageSizeOptions={[10, 20, 50]}
          paginationModel={{ page: pageNum, pageSize: pageSize }}
          getRowId={(row) => row.slug}
        />
      </Box>
    </>
  );
}

export default ContentfulFormsContainer;
