import React from 'react';

import { Checkbox, FormControlLabel, Paper, Typography } from '@mui/material';

import { InternalRoom, InternalServices } from '~/services/AccommodationService';

import { titleCase } from '~/utils/stringUtils';

import SupplierRoomListElement from './SupplierRoomListElement';

interface Props {
  room: InternalRoom;
  isSelected: boolean;
  internalService: InternalServices;
  onSelect: (internalRoomId: string, selected: boolean) => void;
}

export default function InternalRoomElement({ room, isSelected, internalService, onSelect }: Props) {
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 2,
        backgroundColor: !isSelected ? 'grey.100' : undefined,
        borderColor: isSelected ? 'info.light' : undefined,
      }}
    >
      <FormControlLabel
        checked={isSelected}
        onChange={() => onSelect(room.id, !isSelected)}
        control={<Checkbox />}
        label={room.name}
        componentsProps={{ typography: { variant: 'h6' } }}
      />
      <Typography sx={{ m: '1rm' }}>
        <strong>Internal Service:</strong> {titleCase(internalService)}
      </Typography>
      {room.supplierRooms.map((supplierRoom) => (
        <SupplierRoomListElement key={supplierRoom.id} supplierRoom={supplierRoom} />
      ))}
    </Paper>
  );
}
