import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import LinkIcon from '@mui/icons-material/Link';
import { Alert, Card, CardContent, IconButton, Link, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import { AccommodationPropertiesSearchInput } from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesSearchForm';
import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import {
  PropertyResponse,
  SupplierProperty,
  listProperties,
  mapSupplierProperty,
} from '~/services/AccommodationService';

interface Props {
  supplierProperty: SupplierProperty;
  onClose: () => void;
  refetchUnmapped: () => void;
}

const PAGE_SIZE = 20;

export default function AccommodationMapPropertyFinder({ supplierProperty, onClose, refetchUnmapped }: Props) {
  const [propertyName, setPropertyName] = useState('');
  const [id, setId] = useState('');
  const [searchInput, setSearchInput] = useState<AccommodationPropertiesSearchInput>({ propertyName: '', id: '' });
  const [pageIndex, setPageIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const showConfirmDialog = useConfirmDialog();
  const clearSearches = useCallback(() => {
    setPropertyName('');
    setId('');
  }, []);

  const setSearch = useCallback((key: keyof AccommodationPropertiesSearchInput, value: string) => {
    if (key === 'propertyName') {
      setPropertyName(value);
    } else {
      setId(value);
    }
    if (key === 'propertyName' && (value.length === 0 || value.length >= 3)) {
      setSearchInput((prev) => ({ ...prev, [key]: value }));
    } else if (key === 'id' && value.length >= 36) {
      setSearchInput((prev) => ({ ...prev, [key]: value }));
    }
  }, []);

  const doMap = useCallback(
    async (property: PropertyResponse) => {
      const confirmed = await showConfirmDialog({
        title: 'Map Supplier Property?',
        description: 'This will map this supplier property to this property. Are you sure you want to proceed?',
      });

      if (!confirmed) {
        return;
      }
      try {
        await mapSupplierProperty(property.id, supplierProperty.id);
        onClose();
        enqueueSnackbar(`Successfully mapped "${supplierProperty.name}"`, { variant: 'success' });
        refetchUnmapped();
      } catch (error) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    },
    [enqueueSnackbar, onClose, refetchUnmapped, showConfirmDialog, supplierProperty.id, supplierProperty.name],
  );

  const getProperties = useCallback(
    () =>
      listProperties({
        name: searchInput.propertyName,
        id: searchInput.id,
        page: String((pageIndex ?? 0) + 1),
        limit: String(PAGE_SIZE),
      }),
    [pageIndex, searchInput],
  );

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: getProperties,
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onPageChange = useCallback(
    (page: number) => {
      setPageIndex(page);
      refetch();
    },
    [refetch],
  );

  const columns: Array<GridColDef<PropertyResponse>> = [
    {
      field: 'name',
      headerName: 'Property Name',
      display: 'flex',
      flex: 1,
      renderCell: (params) => (
        <Link
          href={`/accommodation/properties/${params.row.id}`}
          target="_blank"
          className="accommodation-properties-search-result-link"
        >
          {params.value}
        </Link>
      ),
      hideable: false,
    },
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      display: 'flex',
      hideable: true,
    },
  ];

  if (supplierProperty) {
    columns.push({
      field: ' ',
      headerName: 'Map',
      headerAlign: 'center',
      width: 50,
      sortable: false,
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        return (
          <IconButton onClick={() => doMap(params.row)} color="info">
            <LinkIcon fontSize="medium" />
          </IconButton>
        );
      },
    });

    return (
      <>
        <Card>
          <CardContent>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Property name"
                value={propertyName}
                type="text"
                onChange={(e) => setSearch('propertyName', e.target.value)}
                onFocus={clearSearches}
                fullWidth
              />
              <TextField
                label="Property ID"
                value={id}
                type="text"
                onChange={(e) => setSearch('id', e.target.value)}
                onFocus={clearSearches}
                fullWidth
              />
            </Stack>
          </CardContent>
        </Card>
        {fetchReqState.status === 'failed' && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {JSON.stringify(fetchReqState.error)}
          </Alert>
        )}
        <DataGrid
          className="accommodation-properties-search-results-table"
          columns={columns}
          rows={fetchReqState.status === 'succeeded' ? fetchReqState.result.properties : []}
          rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.total : undefined}
          getRowHeight={() => 'auto'}
          pageSizeOptions={[PAGE_SIZE]}
          paginationMode="server"
          paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          loading={fetchReqState.status === 'pending'}
          autoHeight
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </>
    );
  }
}
