import React, { useCallback, useEffect, useMemo, useState } from 'react';

import _, { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { InfoOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TablePagination,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material';

import { regions } from '@luxuryescapes/lib-regions/lib/regions';

import PlaceSearchForm from '~/components/Common/Forms/PlaceSearchForm';

import SearchService, {
  ANYWHERE_PLACE_ID,
  EvVariant,
  HotelSearchList,
  OrderOffer,
  ScoreSettings,
  TourSearchList,
  TypeaheadQueryParams,
  TypeaheadResult,
  TypeaheadType,
  UnifiedSearchList,
} from '~/services/SearchService';

import Spinner from '../../Common/Spinner';

import SearchRankingTable from './SearchRankingTable';

const TYPEAHEAD_TYPE: Record<TypeaheadType, boolean> = {
  airport: false,
  province_state: true,
  neighborhood: true,
  city: true,
  high_level_region: true,
  country: true,
  multi_city_vicinity: true,
  metro_station: false,
  continent: true,
  train_station: false,
  point_of_interest: true,
  colloquial_area: true,
  le_property: true,
  le_property_unique_stays: true,
  bedbank_property: true,
  bedbank_property_unique_stays: true,
  le_experience: false,
  channel_experience: false,
  car_hire_location: false,
};

const PAGE_LIMITS = [100, 500, 1000, 1500, 2000];

export type Vertical = 'hotel' | 'tour' | 'cruise' | 'experience' | 'all' | 'ultra lux';
const Verticals: Array<Vertical> = ['hotel', 'tour', 'cruise', 'experience', 'all', 'ultra lux'];

const VariantSupportedVerticals: Array<Vertical> = ['hotel', 'tour', 'cruise', 'experience'];

export interface AlgorithmOptions {
  [key: string]: string;
}

export const DEFAULT_ALGORITHM_OPTION = {
  [EvVariant.Current]: 'Current Live',
};

const segmentOptions: Array<App.Segment> = [
  {
    name: 'New South Wales',
    shortName: 'NSW',
    value: 'state:AU-NSW',
  },
  {
    name: 'Victoria',
    shortName: 'VIC',
    value: 'state:AU-VIC',
  },
  {
    name: 'Queensland',
    shortName: 'QLD',
    value: 'state:AU-QLD',
  },
  {
    name: 'South Australia',
    shortName: 'SA',
    value: 'state:AU-SA',
  },
  {
    name: 'Western Australia',
    shortName: 'WA',
    value: 'state:AU-WA',
  },
  {
    name: 'Tasmania',
    shortName: 'TAS',
    value: 'state:AU-TAS',
  },
  {
    name: 'Northern Territory',
    shortName: 'NT',
    value: 'state:AU-NT',
  },
  {
    name: 'ACT',
    shortName: 'ACT',
    value: 'state:AU-ACT',
  },
  {
    name: 'Family',
    shortName: 'Occupancy',
    value: 'occupancy:CHILDREN',
  },
  {
    name: 'No Kids',
    shortName: 'No Children',
    value: 'occupancy:NO-CHILDREN',
  },
];

const segmentOptionsMap = _.fromPairs(segmentOptions.map((option) => [option.value, option]));

const SortOptions: Record<string, Array<string>> = {
  tour: ['default', 'ev'],
  cruise: ['default', 'ev'],
  experience: ['default', 'ev'],
  all: ['default', 'ev', 'daily sales'],
};

const ListNames = {
  tour: 'vertical-tour',
  cruise: 'vertical-cruise',
  experience: 'vertical-experience',
  all: 'home',
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#dadde9',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const productTypeMapping = {
  rental: 'Rentals',
  tactical_ao_hotel: 'LPC',
  bedbank: 'LPP',
};

function SearchRankingPage() {
  const { enqueueSnackbar } = useSnackbar();

  const [listOffers, setListOffers] = useState<Array<OrderOffer>>([]);
  const [hotelOffers, setHotelOffers] = useState<HotelSearchList>([]);
  const [tourOffers, setTourOffers] = useState<TourSearchList>([]);
  const [cruiseOffers, setCruiseOffers] = useState<UnifiedSearchList>([]);
  const [experienceOffers, setExperienceOffers] = useState<UnifiedSearchList>([]);
  const [unifiedOffers, setUnifiedOffers] = useState<UnifiedSearchList>([]);
  const [ultraLuxOffers, setUltraLuxOffers] = useState<UnifiedSearchList>([]);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [region, setRegion] = useState('AU');
  const [vertical, setVertical] = useState<Vertical>('hotel');
  const [page, setPage] = useState(1);
  const [offersPerPage, setOffersPerPage] = useState(PAGE_LIMITS[0]);
  const [evVariant, setEvVariant] = useState<EvVariant>(EvVariant.Current);
  const [algorithmOptions, setAlgorithmOptions] = useState<AlgorithmOptions>(DEFAULT_ALGORITHM_OPTION);
  const [isPlaceSearchOpen, setIsPlaceSearchOpen] = useState<boolean>(false);
  const [placeValue, setPlaceValue] = useState('Anywhere');
  const [destinationId, setDestinationId] = useState<string | undefined>(ANYWHERE_PLACE_ID);
  const [placeSearchResults, setPlaceSearchResults] = useState<Array<TypeaheadResult>>([]);
  const [scoreSettings, setScoreSettings] = useState<ScoreSettings | undefined>(undefined);
  const [sorting, setSorting] = useState<string>('default');
  const [segments, setSegments] = useState<Array<App.Segment>>([]);
  const [segmentWeight, setSegmentWeight] = useState<string>('0.25');

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const onVerticalChange = useCallback(
    (event: SelectChangeEvent) => {
      const vertical = event.target.value as Vertical;
      setVertical(vertical);
      setSegments([]);
      setEvVariant(EvVariant.Current);
      if (!SortOptions[vertical]?.includes(sorting)) {
        setSorting('default');
      }
    },
    [sorting],
  );

  const onVariantChange = useCallback(
    (event: SelectChangeEvent) => {
      setEvVariant(event.target.value as EvVariant);
    },
    [setEvVariant],
  );

  const onRegionChange = useCallback(
    (event: SelectChangeEvent) => {
      setSegments([]);
      setRegion(event.target.value);
    },
    [setRegion],
  );

  const sortOptions = useMemo(() => SortOptions[vertical], [vertical]);

  const loadListOffers = useCallback(async () => {
    const listName = ListNames[vertical];
    if (listName && sorting == 'default') {
      const { result } = await SearchService.getListOrder(listName, brand, region);
      setListOffers(result.offers);
    } else {
      setListOffers([]);
    }
  }, [vertical, sorting, brand, region]);

  const loadVariants = useCallback(async () => {
    if (VariantSupportedVerticals.includes(vertical)) {
      const { result } = await SearchService.getOfferScoreSettings(vertical, region);
      setAlgorithmOptions(result?.variants?.variantInfos);
    } else {
      setAlgorithmOptions(DEFAULT_ALGORITHM_OPTION);
    }
  }, [vertical, region]);

  const loadOffers = useCallback(async () => {
    switch (vertical) {
      case 'hotel':
        {
          const segmentsWithWeight = segments.map((segment) => `${segment.value}:${segment.weight}`);
          const { result } = await SearchService.getHotelOffers({
            region,
            destinationId,
            segments: segmentsWithWeight,
            includeAllBedbankWithSales: true,
            evVariant: evVariant,
          });
          setHotelOffers(result);
        }
        break;
      case 'tour':
        {
          const { result } = await SearchService.getAllTourOffers(region, destinationId, sorting);
          setTourOffers(result);
        }
        break;
      case 'cruise':
        {
          const { result } = await SearchService.getAllCruiseOffers(region, destinationId, sorting, evVariant);
          setCruiseOffers(result);
        }
        break;
      case 'experience':
        {
          const { result } = await SearchService.getAllExperienceOffers(brand, region, destinationId, sorting);
          setExperienceOffers(result);
        }
        break;
      case 'all':
        {
          const { results } = await SearchService.getAllUnifiedOffers(region, destinationId, sorting);
          setUnifiedOffers(results);
        }
        break;
      case 'ultra lux':
        {
          const { results } = await SearchService.getAllUltraLuxOffers(region, destinationId);
          setUltraLuxOffers(results);
        }
        break;
    }
  }, [vertical, segments, region, destinationId, evVariant, sorting, brand]);

  useEffect(() => {
    setFetchingState('loading');
    Promise.all([loadOffers(), loadListOffers(), loadVariants()])
      .then(() => {
        setFetchingState('success');
      })
      .catch((error) => {
        setFetchingState('failed');
        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      });
  }, [enqueueSnackbar, loadListOffers, loadOffers, loadVariants]);

  useEffect(() => {
    if (vertical !== 'all' && vertical !== 'ultra lux') {
      SearchService.getOfferScoreSettings(vertical, region)
        .then((res) => {
          setScoreSettings(res.result);
        })
        .catch((error) => {
          enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
        });
    } else {
      // we cannot show score setting if all verticals are selected
      setScoreSettings(undefined);
    }
  }, [enqueueSnackbar, vertical, region]);

  const offers = {
    hotel: hotelOffers,
    tour: tourOffers,
    cruise: cruiseOffers,
    experience: experienceOffers,
    all: unifiedOffers,
    'ultra lux': ultraLuxOffers,
  }[vertical];

  const curPageOffers: any = useMemo(
    () => offers.slice((page - 1) * offersPerPage, page * offersPerPage),
    [offers, page, offersPerPage],
  );

  const debouncedPlaceSearch = debounce(async (value: string) => {
    const res = await SearchService.typeahead(value, {
      type: Object.keys(TYPEAHEAD_TYPE).filter((type) => TYPEAHEAD_TYPE[type]) as Array<keyof typeof TYPEAHEAD_TYPE>,
      brand: brand as TypeaheadQueryParams['brand'],
      region: 'AU',
      limit: 20,
    });
    setPlaceSearchResults(res.result.results);
  }, 500);

  const onPlaceSearchTyping = useCallback(
    (value: string) => {
      setPlaceValue(value);

      if (value == '') {
        setDestinationId(ANYWHERE_PLACE_ID);
      }

      if (value.length > 2) {
        debouncedPlaceSearch(value);
      }
    },
    [debouncedPlaceSearch],
  );

  const onPlaceSelect = (selection: TypeaheadResult) => {
    setDestinationId(selection.fk);
    setPlaceValue(selection.primary_text);
  };

  const hasSegments = vertical === 'hotel' && region === 'AU';
  const onSegmentsChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const value = event.target.value;
      if (value) {
        const segmentValues = typeof value === 'string' ? value.split(',') : value;
        const segments = segmentValues
          .map((value) => segmentOptionsMap[value])
          .map((segment) => ({ ...segment, weight: parseFloat(segmentWeight) }));
        setSegments(segments);
      } else {
        setSegments([]);
      }
    },
    [segmentWeight],
  );

  const showSegmentWeight = useMemo(() => {
    return hasSegments && segments.length > 0;
  }, [hasSegments, segments]);

  const onSegmentWeightChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newWeight = event.target.value;
      const newSegments = segments.map((segment) => ({ ...segment, weight: parseFloat(newWeight) }));
      setSegmentWeight(newWeight);
      setSegments(newSegments);
    },
    [segments],
  );

  // the unified index updates between 7 am and 10 am Sydney time
  const hours = new Date().getUTCHours();
  const isUpdatingWindow = useMemo(() => {
    return hours >= 20 && hours < 23;
  }, [hours]);

  const isCruiseSearch = useMemo(() => {
    return vertical === 'cruise' && sorting === 'default';
  }, [sorting, vertical]);

  const dayRangeInfo = useMemo(() => {
    if (scoreSettings) {
      let info = `${scoreSettings.daySpan} Default`;
      const extendedTimelineSettings = scoreSettings.extendedTimeline?.[evVariant];
      if (extendedTimelineSettings) {
        info += `, ${extendedTimelineSettings.days} ${extendedTimelineSettings.products
          .map((product) => productTypeMapping[product] || product)
          .join('/')}`;
      }
      return info;
    }
  }, [evVariant, scoreSettings]);

  return (
    <div>
      {isUpdatingWindow && (
        <Alert severity="warning">
          Please be aware that between 7:00 AEST and 10:00 AEST, the index will undergo updates, resulting in certain
          rankings not being promptly updated during this period.
        </Alert>
      )}
      {isCruiseSearch && (
        <Alert severity="info">
          The cruise search doesn't support tourV1 offers, so the results may differ from the offer list order.
        </Alert>
      )}
      <FormGroup>
        <Box display="flex" gap={8} justifyContent="space-between" alignItems="flex-end" marginBottom={2}>
          <Box display="flex" gap={4} alignItems="center">
            <FormControl sx={{ minWidth: 300 }}>
              <PlaceSearchForm
                setValue={onPlaceSearchTyping}
                value={placeValue}
                setOpen={setIsPlaceSearchOpen}
                open={isPlaceSearchOpen}
                onSelect={onPlaceSelect}
                results={placeSearchResults}
              />
            </FormControl>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="vertical-select-label">Product</InputLabel>
              <Select
                labelId="vertical-select-label"
                label="Vertical"
                value={vertical}
                onChange={onVerticalChange}
                sx={{ textTransform: 'capitalize' }}
              >
                {Verticals.map((vertical) => (
                  <MenuItem key={vertical} value={vertical} sx={{ textTransform: 'capitalize' }}>
                    {vertical}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="region-select-label">Region</InputLabel>
              <Select labelId="region-select-label" label="Region" value={region} onChange={onRegionChange}>
                {regions['luxuryescapes'].map((region) => (
                  <MenuItem key={region.code} value={region.code}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {hasSegments && (
              <>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="segment-select-label">Segments</InputLabel>
                  <Select
                    labelId="segment-select-label"
                    label="Segments"
                    value={segments[0]?.value ?? ''}
                    onChange={onSegmentsChange}
                    autoWidth
                  >
                    <MenuItem>None</MenuItem>
                    {segmentOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {showSegmentWeight && (
                  <FormControl sx={{ width: 80 }}>
                    <InputLabel id="weight-input-label">Weight</InputLabel>
                    <OutlinedInput
                      id="weight-input"
                      label="Weight"
                      type="number"
                      inputProps={{ max: 10, min: 0, step: 0.01 }}
                      value={segmentWeight}
                      onChange={onSegmentWeightChange}
                    />
                  </FormControl>
                )}
              </>
            )}
            {sortOptions && (
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="sort-select-label">Sort by</InputLabel>
                <Select
                  labelId="sort-select-label"
                  label="Sort by"
                  value={sorting}
                  onChange={(event) => setSorting(event.target.value)}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {sortOptions.map((sorting) => (
                    <MenuItem key={sorting} value={sorting} sx={{ textTransform: 'capitalize' }}>
                      {sorting}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {scoreSettings && (
              <HtmlTooltip
                title={
                  <>
                    {scoreSettings ? (
                      <>
                        <div>Day Range: {dayRangeInfo}</div>
                        <div>Min Views: {scoreSettings.minViews}</div>
                        <div>Regions: {scoreSettings.regions.join(',')}</div>
                        <Link href="https://aussiecommerce.atlassian.net/wiki/spaces/OSS/pages/2971435033/Documentation+-+Search+Ranking">
                          Confluence Page
                        </Link>
                        <div></div>
                      </>
                    ) : (
                      <div>Loading...</div>
                    )}
                  </>
                }
                open
                placement="right"
                arrow
              >
                <InfoOutlined sx={{ fontSize: 24 }} />
              </HtmlTooltip>
            )}
          </Box>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="sort-select-label">Variant</InputLabel>
            <Select labelId="variant-select-label" label="Variant" value={evVariant} onChange={onVariantChange}>
              {Object.keys(algorithmOptions).map((variant: string) => (
                <MenuItem key={variant} value={variant}>
                  {algorithmOptions[variant]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </FormGroup>
      {fetchingState == 'loading' && <Spinner />}
      {fetchingState == 'success' && offers.length === 0 && <div>No offers found</div>}
      {fetchingState == 'success' && offers.length > 0 && (
        <SearchRankingTable
          vertical={vertical}
          offers={curPageOffers}
          listOffers={listOffers}
          region={region}
          segments={segments}
          variant={evVariant}
        />
      )}
      {fetchingState == 'success' && (
        <TablePagination
          component="div"
          count={offers.length}
          page={page - 1}
          onPageChange={(_, page) => setPage(page + 1)}
          rowsPerPage={offersPerPage}
          onRowsPerPageChange={(event) => {
            setOffersPerPage(parseInt(event.target.value));
            setPage(1);
          }}
          rowsPerPageOptions={PAGE_LIMITS}
        />
      )}
    </div>
  );
}
export default SearchRankingPage;
