import React, { useMemo, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import OrderSearchForm, { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';
import { withTenant } from '~/components/hoc';

import useQuery from '~/hooks/useQuery';

import { addQuery, removeQuery } from '~/utils/url';

import CruisesPurchaseList from './CruisesPurchaseList';

const DEFAULT_SIZE_PER_PAGE = 10;
const SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE = 50;

type Props = {
  tenant: App.Tenant;
};

function CruisesPurchasePage({ tenant }: Props) {
  const query = useQuery();
  const customerId = query.get('customer_id');
  const currentPage = useMemo(() => parseInt(query.get('page')) || 1, [query]);

  const history = useHistory();
  const location = useLocation();

  const [filterField, setFilterField] = useState<string>(null);
  const [filterValue, setFilterValue] = useState<string>(null);
  const [additionalSearchParams, setAdditionalSearchParams] = useState<MultiFilterState>(null);

  const sizePerPage = useMemo(() => {
    return customerId ? SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE : DEFAULT_SIZE_PER_PAGE;
  }, [customerId]);

  const searchQuery = (field, value) => {
    setFilterField(field);
    setFilterValue(value);

    const newLocation = removeQuery(location, 'customer_id', 'page');
    history.push(newLocation);
  };

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  return (
    <>
      <OrderSearchForm
        e2eSuffix="-cruise"
        showDepartureByIdFilter
        searchQuery={searchQuery}
        bookingIdType="booking_numbers"
        setAdditionalParams={setAdditionalSearchParams}
        bookingNumberPlaceholder="Enter Cruises Booking Number"
      />

      <CruisesPurchaseList
        tenant={tenant}
        page={currentPage}
        additionalSearchParams={additionalSearchParams}
        filterField={filterField}
        filterValue={filterValue}
        customerId={customerId}
        currentPage={currentPage}
        sizePerPage={sizePerPage}
        onPageChange={onPageChange}
      />
    </>
  );
}

export default withTenant(CruisesPurchasePage);
