import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Alert } from '@mui/material';

import { ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';

import { ITEM_TYPE_TOUR } from '../../../consts/order';
import { getPurchases } from '../../../services/OrdersService';
import { addQuery, parseSearchString, removeQuery } from '../../../utils/url';
import OrderSearchForm, { MultiFilterState } from '../../Common/Forms/OrderSearchForm';
import Spinner from '../../Common/Spinner';
import { withTenant } from '../../hoc';

import ToursPage from './ToursPage';

const DEFAULT_SIZE_PER_PAGE = 10;
const SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE = 50;

function ToursPageContainer() {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const { user } = useCurrentUser();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [purchases, setPurchases] = useState(null);
  const [filterField, setFilterField] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [additionalSearchParams, setAdditionalSearchParams] = useState<MultiFilterState>(null);

  const [sizePerPage, setSizePerPage] = useState(DEFAULT_SIZE_PER_PAGE);

  const isReady = !isLoading && !error && purchases;

  const queries = () => {
    return parseSearchString(location.search);
  };

  const currentPage = parseInt(queries().page as string) || 1;
  const customerId = queries().customer_id as string;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setPurchases(null);
    setError(null);

    if (user?.roles.includes(ROLE_ICS_STORE_TEAM)) {
      // we don't want these users to be able to see all results so
      // if there are no filters selected, just return empty
      if (!filterField || !filterValue) {
        setPurchases({
          result: [],
          total: 0,
        });
        setIsLoading(false);
        return;
      }
    }

    try {
      const toursPurchases = await getPurchases({
        page: currentPage,
        per_page: sizePerPage,
        brand,
        customer_id: customerId,
        filterBy: filterField,
        filterValue,
        additionalFilter: additionalSearchParams,
        item_type: ITEM_TYPE_TOUR,
      });

      if (!toursPurchases) {
        enqueueSnackbar('Failed to load data', {
          variant: 'error',
        });
      }

      setPurchases(toursPurchases);
      setIsLoading(false);
    } catch (error) {
      setError(JSON.stringify(error));
      setIsLoading(false);
    }
  }, [
    user?.roles,
    filterField,
    filterValue,
    currentPage,
    sizePerPage,
    brand,
    customerId,
    additionalSearchParams,
    enqueueSnackbar,
  ]);

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [fetchData, user]);

  useEffect(() => {
    if (customerId) {
      setSizePerPage(SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE);
    } else {
      setSizePerPage(DEFAULT_SIZE_PER_PAGE);
    }
  }, [customerId]);

  const searchQuery = (field, value) => {
    setFilterField(field);
    setFilterValue(value);

    const newLocation = removeQuery(location, 'customer_id', 'page');

    history.push(newLocation);
  };

  return (
    <>
      <OrderSearchForm
        bookingNumberPlaceholder="Enter Tours Booking Number"
        searchQuery={searchQuery}
        setAdditionalParams={setAdditionalSearchParams}
        e2eSuffix="-tour"
        bookingIdType="booking_numbers"
      />
      <>
        {isLoading && <Spinner size={48} />}

        {error && <Alert severity="warning">Failed to load data: {error}</Alert>}

        {isReady && (
          <ToursPage
            orders={purchases?.result}
            total={purchases?.total}
            page={currentPage}
            sizePerPage={sizePerPage}
            onPageChange={onPageChange}
            rerender={fetchData}
          />
        )}
      </>
    </>
  );
}

export default withTenant(ToursPageContainer);
