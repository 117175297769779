import React from 'react';

import { useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';

import { TourDetailsTab } from './TourDetailsContext';

const KNOWN_TAB_KEYS_ROUTES: Record<string, '' | `/${string}`> = {
  root: '',
  'tour-options': '/tour-options',
  'tour-images': '/tour-images',
  'tour-pricing': '/tour-pricing',
  'tour-dynamic-pricing': '/tour-dynamic-pricing',
  'traveller-requirements': '/traveller-requirements',
  'fine-print': '/fine-print',
  'campaign-schedule': '/campaign-schedule',
  inventory: '/inventory',
  'add-ons-accommodation': '/add-ons/accommodation',
  'optional-extras': '/optional-extras',
  'user-reviews': '/user-reviews',
};

interface Props {
  tabs: TourDetailsTab[];
}

export default function TourDetailsTabs(props: Props) {
  const { tabs } = props;
  const { pathname } = useLocation();
  const { tour_id } = useParams<{ tour_id: string }>();

  if (!tabs.length) {
    return null;
  }

  return (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      aria-label="Vertical tabs example"
      sx={{ border: 0 }}
      value={pathname}
    >
      {tabs.map(
        (tab, index) =>
          KNOWN_TAB_KEYS_ROUTES[tab.type] !== undefined && (
            <Tab
              key={`${tab.type}-${index}`}
              label={tab.title}
              component={NavLink}
              value={`/tours/details/${tour_id}${KNOWN_TAB_KEYS_ROUTES[tab.type]}`}
              to={`/tours/details/${tour_id}${KNOWN_TAB_KEYS_ROUTES[tab.type]}`}
            />
          ),
      )}
    </Tabs>
  );
}
