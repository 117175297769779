import { FETCH_BEDBANK_RESERVATION } from '~/actions/bedbank';

const initialState: App.BedbankReservationState = {};

export default function (state = initialState, action): App.BedbankReservationState {
  switch (action.type) {
    case FETCH_BEDBANK_RESERVATION: {
      return {
        ...state,
        [action.reservation.id]: action.reservation,
      };
    }
    default:
      return state;
  }
}
