import React, { Fragment } from 'react';

import currencyFormatter from 'currency-formatter';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Alert, Box, Stack, TextField, Typography } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';

import {
  applyLeCredit,
  applyPromoCode,
  emptyCart,
  purchaseFailure,
  setPromoCodeError,
  updateCredit,
  updateOrderNotes,
  updateReservation,
} from '~/actions/cart';

import { BOOKING_INFORMATION } from '~/consts/notes';
import { ITEM_TYPE_GIFT_CARD, ITEM_TYPE_INSURANCE, ITEM_TYPE_OFFER, ITEM_TYPE_OFFLINE_FLIGHT } from '~/consts/order';
import { REASON_FOR_THE_WAIVED_SURCHARGE } from '~/consts/reservation';

import { addOrderNote, getOrder, getOrderCombinedItemIds } from '~/services/OrdersService';
import {
  createLeCreditTransaction,
  createPromoTransaction,
  createStripeTransaction,
  getCredits,
} from '~/services/PaymentsService';

import { isAdmin } from '~/utils/adminPermission';
import getStripeAmount from '~/utils/getStripeAmount';

import { InsuranceUpdateError } from '../../Users/Orders/UpdateInsurance/StepPayment';
import ErrorDisplay from '../ErrorDisplay';
import Spinner from '../Spinner';

import AttributionSource from './AttributionSource';
import CreditPayment from './CreditPayment';
import DefaultDataPlaceholder from './DefaultDataPlaceholder';
import FlightOrderSummaryList from './FlightOrderSummaryList';
import GiftCardOrderSummaryList from './GiftCardOrderSummaryList';
import LatitudePayment from './LatitudePayment';
import OrderAddonsSummary from './OrderAddonsSummary';
import OrderInsuranceSummary from './OrderInsuranceSummary';
import OrderSummaryList from './OrderSummaryList';
import PaymentBreakdown from './PaymentBreakdown';
import PromoPayment from './PromoPayment';
import StripePayment from './StripePayment';
import UseGiftCard from './UseGiftCard';

const summaryListComponents = {
  [ITEM_TYPE_OFFER]: OrderSummaryList,
  [ITEM_TYPE_GIFT_CARD]: GiftCardOrderSummaryList,
  [ITEM_TYPE_INSURANCE]: null,
  [ITEM_TYPE_OFFLINE_FLIGHT]: FlightOrderSummaryList,
};

// TODO: move this into API? or @luxuryescapes/lib-regions?
const MIN_LATITUDE_PAYMENT = 999;

function getRegionByCurrencyCode(currencyCode, brand) {
  const regions = libRegions.getRegions(brand);

  return regions.find((item) => {
    return item.currencyCode == currencyCode;
  });
}

type Props = RouteComponentProps & {
  tenant: App.Tenant;
  brand: App.Brands;
  cart: App.Cart;
  insurance: App.InsuranceState;
  heading: string;
  dataPlaceholder: React.ReactElement;
  handleOrder: (cart: App.Cart, offerSource, brand, insuranceState) => Promise<void>;
  onAfterPayment: (cart: App.Cart, orderId: string) => Promise<unknown>;
  doUpdateLeCredit: Utils.ActionDispatcher<typeof updateCredit>;
  doApplyLeCredit: Utils.ActionDispatcher<typeof applyLeCredit>;
  doApplyPromoCode: Utils.ActionDispatcher<typeof applyPromoCode>;
  doSetPromoCodeError: Utils.ActionDispatcher<typeof setPromoCodeError>;
  doEmptyCart: Utils.ActionDispatcher<typeof emptyCart>;
  doPurchaseFailure: Utils.ActionDispatcher<typeof purchaseFailure>;
  doUpdateOrderNotes: Utils.ActionDispatcher<typeof updateOrderNotes>;
  doUpdateReservation: Utils.ActionDispatcher<typeof updateReservation>;
};

type State = {
  transacting: boolean;
  loading: boolean;
  offerSource: string;
  waiveSurchargeNote: string;
  credit?: any;
  error?: Error & { errors?: Array<Error> };
};

// <Payment /> is responsible only for making payments. Callers must inject
// behaviors for creating or updating an order, and optionally before/after
// hooks for payment.
class Payment extends React.Component<Props, State> {
  isAdmin: boolean;

  constructor(props: Props, context) {
    super(props);

    this.isAdmin = isAdmin(context.user);
    this.state = {
      transacting: false,
      loading: true,
      offerSource: props.tenant.brand,
      waiveSurchargeNote: '',
      credit: null,
    };
  }

  componentDidMount() {
    if (!this.isDataPresent()) {
      this.setState({ loading: false });
      return;
    }

    this.refreshCredit();
  }

  isDataPresent() {
    const {
      cart: { insurance: insuranceQuoteResult, offers, items },
    } = this.props;

    return offers.length > 0 || items.length > 0 || insuranceQuoteResult.applied;
  }

  isStripeEnabled = () => {
    const {
      cart,
      tenant: { brand },
    } = this.props;
    const { currencyCode } = cart;

    const currentRegion = getRegionByCurrencyCode(currencyCode, brand);
    return currentRegion.paymentMethods.includes('stripe');
  };

  isLatitudeEnabled = () => {
    if (window.configs.LATITUDE_ENABLED !== 'on') {
      return false;
    }

    const {
      cart,
      tenant: { brand },
    } = this.props;
    const {
      currencyCode,
      bookDatesOnly,
      amounts: { grandTotal },
      orderType,
    } = cart;

    const currentRegion = getRegionByCurrencyCode(currencyCode, brand);
    return (
      !bookDatesOnly &&
      grandTotal >= MIN_LATITUDE_PAYMENT &&
      currentRegion.paymentMethods.includes('latitude') &&
      orderType !== ITEM_TYPE_OFFLINE_FLIGHT
    );
  };

  shouldShowGiftCards = () => {
    const {
      cart: { orderType, currencyCode },
      tenant: { brand },
    } = this.props;

    return (
      libRegions.getPaymentMethodsByCurrencyCode(currencyCode, brand).includes('giftcard') &&
      ![ITEM_TYPE_OFFLINE_FLIGHT].includes(orderType)
    );
  };

  refreshCredit = () => {
    const { cart, doUpdateLeCredit, tenant } = this.props;
    const { currencyCode, customer } = cart;

    return getCredits(customer.id_member, currencyCode, tenant.brand)
      .then((credit) => {
        doUpdateLeCredit(currencyCode, parseFloat(credit.metadata.available_credit).toFixed(2), null);

        this.setState({ credit, loading: false });
      })
      .catch((err) => {
        doUpdateLeCredit(currencyCode, 0, err);
      });
  };

  onPurchaseCompletion = (orderId) => {
    const {
      history,
      doEmptyCart,
      cart: { orderType },
    } = this.props;

    if (orderType == ITEM_TYPE_GIFT_CARD) {
      history.push(`/purchases/${orderId}/gift-card`);
    } else {
      history.push(`/purchases/${orderId}`);
    }
    doEmptyCart();
  };

  adminCanPayWithCredit = () => {
    return (
      window.configs.SALES_TEAM_LEADERS &&
      window.configs.SALES_TEAM_LEADERS.split(',').includes(this.context.user.email)
    );
  };

  canPayEntirelyWithLeCredit = () => {
    const {
      cart: { credit, amounts, orderType },
    } = this.props;

    if (orderType === ITEM_TYPE_OFFLINE_FLIGHT) {
      return false;
    }

    if (orderType === ITEM_TYPE_GIFT_CARD && !this.adminCanPayWithCredit()) {
      return false;
    }

    // If already paying partially with le credit, return false
    if (amounts.creditPaymentAmount > 0) {
      return false;
    }

    return credit.balance >= amounts.grandTotal;
  };

  canPayPartiallyWithLeCredit = () => {
    const {
      cart: { credit, amounts, orderType },
    } = this.props;

    if (orderType === ITEM_TYPE_OFFLINE_FLIGHT) {
      return false;
    }

    if (orderType === ITEM_TYPE_GIFT_CARD && !this.adminCanPayWithCredit()) {
      return false;
    }

    return credit.balance > 0 && credit.balance < amounts.grandTotal;
  };

  canUsePromo = () => {
    const {
      cart: { orderType, bookDatesOnly },
    } = this.props;

    return ![ITEM_TYPE_GIFT_CARD, ITEM_TYPE_OFFLINE_FLIGHT].includes(orderType) && !bookDatesOnly;
  };

  setOfferSource = (source) => {
    this.setState({ offerSource: source });
  };

  // paymentWillStart is invoked by payment options before the actual payment
  // begins.
  paymentWillStart = (done) => {
    if (this.props.cart.items.some((item) => item.reservation?.waivedSurchargeAmount)) {
      let reason = null;

      do {
        reason = prompt(REASON_FOR_THE_WAIVED_SURCHARGE);
      } while (reason === '');

      if (reason) {
        this.setState({ transacting: true, waiveSurchargeNote: reason }, done);
      }

      return;
    }

    this.setState({ transacting: true }, done);
  };

  createOrder = () => {
    const { cart, handleOrder, tenant, insurance } = this.props;

    return handleOrder(cart, this.state.offerSource, tenant.brand, insurance);
  };

  // handlePayment creates the order and transactions for each payment methods
  // in the current payment breakdown. Upon completion, it redirects to the
  // confirmation page
  handlePayment = async (paymentType, paymentInfo, orderId) => {
    const { cart, onAfterPayment, tenant } = this.props;
    const { customer, currencyCode, amounts, promo, transactionKey } = cart;

    let order;
    let combinedItemIds;

    try {
      if (!orderId) {
        order = await this.createOrder();
        orderId = order.id;
        combinedItemIds = order.combinedItemIds;
      }

      // HACK: Will be revorked and removed upon refactoring of order
      // workflow and hiding payment service api behind order service
      if (!order) {
        order = await getOrder(orderId);
        combinedItemIds = getOrderCombinedItemIds(order);
      }

      if (order.status === 'abandoned') {
        throw 'Order has "abandoned" status';
      }

      const paymentRequired = {
        fk_orders: orderId,
        transaction_key: transactionKey,
        brand: tenant.brand,
      };

      if (amounts.savedAmount > 0) {
        await createPromoTransaction({
          ...paymentRequired,
          promo_id: promo.id,
          fk_orders: orderId,
          amount: amounts.savedAmount,
          id_customer: customer.id_member,
          currency: currencyCode,
        });
      }

      let creditPaymentAmount = 0;
      if (paymentType === 'lecredit') {
        creditPaymentAmount = amounts.grandTotal;
      } else {
        creditPaymentAmount = amounts.creditPaymentAmount;
      }

      if (paymentType === 'stripe') {
        await createStripeTransaction({
          ...paymentRequired,
          ...paymentInfo,
          amount: getStripeAmount(amounts.grandTotal, currencyCode),
          item_ids: combinedItemIds,
        });
      }

      if (creditPaymentAmount > 0) {
        await createLeCreditTransaction({
          ...paymentRequired,
          amount: creditPaymentAmount,
          id_customer: customer.id_member,
          currency_code: currencyCode,
          comments: `For order ${orderId}`,
        });
      }

      if (onAfterPayment) {
        await onAfterPayment(cart, orderId);
      }

      if (this.state.waiveSurchargeNote) {
        await addOrderNote(orderId, {
          comment: 'Waived surcharge: ' + this.state.waiveSurchargeNote,
          comment_type: BOOKING_INFORMATION,
        });
      }

      this.setState({ transacting: false, waiveSurchargeNote: '' });
      this.onPurchaseCompletion(orderId);
    } catch (error) {
      console.warn('error payment', error);

      if (typeof error.errors == 'string') {
        error.errors = [new Error(error.errors)];
      }

      this.props.doPurchaseFailure();

      if (error instanceof InsuranceUpdateError) {
        // Block payment form in case of insurance update error
        this.setState({ error, transacting: true, waiveSurchargeNote: '' });
      } else {
        this.setState({ error, transacting: false, waiveSurchargeNote: '' });
      }
    }
  };

  getGroupedAddons = (addons) => {
    // We store each addon in cart as separate item.
    // In order to show actual quantity just group them by addonId

    const result = {};
    for (const addon of addons) {
      if (result[addon.addonId]) {
        result[addon.addonId].qty += 1;
      } else {
        result[addon.addonId] = {
          ...addon,
          qty: 1,
        };
      }
    }

    return Object.values(result);
  };

  render() {
    const {
      cart,
      brand,
      doApplyLeCredit,
      doApplyPromoCode,
      doSetPromoCodeError,
      dataPlaceholder,
      tenant,
      doUpdateReservation,
      heading,
    } = this.props;
    const { loading, error, transacting } = this.state;
    const {
      customer,
      currencyCode,
      credit,
      amounts,
      promo,
      bookDatesOnly,
      insurance: insuranceQuoteResult,
      orderType,
      offers,
      items,
      stateOfResidence,
    } = cart;
    const insurance = this.props.insurance;
    const SummaryList = summaryListComponents[cart.orderType];

    const dataPresent = this.isDataPresent();
    const showPayWithLeCreditButton = this.canPayEntirelyWithLeCredit();
    const showUseLeCreditCheckbox = this.canPayPartiallyWithLeCredit();
    const canUsePromo = this.canUsePromo();
    const showGiftCards = this.shouldShowGiftCards();
    const showStripe = this.isStripeEnabled();
    const showLatitude = this.isLatitudeEnabled();
    const isSurchargeWaived = offers.some((o) => o.items.some((item) => item.reservation?.waivedSurchargeAmount));

    if (loading) {
      return <Spinner />;
    }

    if (!dataPresent) {
      return dataPlaceholder || <DefaultDataPlaceholder />;
    }

    return (
      <div className="payment">
        {error && <ErrorDisplay message={error.message} />}
        {error && error.errors && error.errors.map((err, i) => <ErrorDisplay key={i} message={err.message} />)}

        {transacting && !error && <Alert severity="info">Transaction in progress</Alert>}

        <Stack direction="row" spacing={2} alignItems="start">
          <Box flexGrow={3}>
            <div className="order-summary">
              <h2>{heading}</h2>

              {offers.map((offer, i) => (
                <Fragment key={i}>
                  {offer && <AttributionSource setOfferSource={this.setOfferSource} source={this.state.offerSource} />}

                  {SummaryList && (
                    <SummaryList
                      stateOfResidence={stateOfResidence}
                      orderType={orderType}
                      offer={offer}
                      offerNumber={i + 1}
                      items={offer.items}
                      currencyCode={currencyCode}
                      bookDatesOnly={bookDatesOnly}
                      isAdmin={this.isAdmin}
                      doUpdateReservation={doUpdateReservation}
                    />
                  )}
                  {offer.addons && offer.addons.length > 0 && (
                    <OrderAddonsSummary addons={this.getGroupedAddons(offer.addons)} currencyCode={currencyCode} />
                  )}
                </Fragment>
              ))}

              {SummaryList && (
                <SummaryList
                  orderType={orderType}
                  offer={null}
                  items={items}
                  currencyCode={currencyCode}
                  bookDatesOnly={bookDatesOnly}
                  isAdmin={this.isAdmin}
                  doUpdateReservation={doUpdateReservation}
                />
              )}

              {insuranceQuoteResult.applied && (
                <OrderInsuranceSummary
                  productName={insuranceQuoteResult.productName}
                  total={insuranceQuoteResult.total}
                  destinationCountries={insurance.destinationCountries}
                  currencyCode={insurance.currencyCode}
                  travellers={insurance.travellers}
                  startDate={insurance.startDate}
                  endDate={insurance.endDate}
                />
              )}

              <PaymentBreakdown
                currencyCode={currencyCode}
                subTotal={amounts.subTotal}
                savedAmount={amounts.savedAmount}
                creditPaymentAmount={amounts.creditPaymentAmount}
                grandTotal={amounts.grandTotal}
                promoDiscountValue={promo.discount}
                promoType={promo.type}
              />

              {showUseLeCreditCheckbox && (
                <div>
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        const checked = e.target.checked;

                        let creditAmount = 0;
                        if (checked) {
                          creditAmount = credit.balance;
                        }
                        doApplyLeCredit(creditAmount);
                      }}
                    />
                    Use credit - balance
                    {currencyFormatter.format(credit.balance, {
                      code: currencyCode,
                    })}
                  </label>
                </div>
              )}

              <Stack mt={2} direction="column" spacing={2}>
                {canUsePromo && (
                  <PromoPayment
                    onPromoCodeApply={doApplyPromoCode}
                    onPromoCodeError={doSetPromoCodeError}
                    error={promo.error}
                    disabled={transacting}
                    customerId={customer.id_member}
                    currencyCode={currencyCode}
                    brand={brand}
                    cartValue={cart.amounts.value}
                  />
                )}

                {showGiftCards && (
                  <UseGiftCard onGiftCardRedeemed={this.refreshCredit} customerId={customer.id_member} />
                )}
              </Stack>
            </div>
          </Box>

          <Box flexGrow={1}>
            <Stack direction="column" spacing={2}>
              {showStripe ? (
                <StripePayment
                  paymentWillStart={this.paymentWillStart}
                  onPayment={this.handlePayment}
                  customerId={customer.id_member}
                  customerEmail={customer.email}
                  customerName={customer.fullName}
                  currencyCode={currencyCode}
                  packagePrice={cart.amounts.grandTotal}
                  tenant={tenant}
                  disabled={transacting}
                />
              ) : (
                <div>Stripe is not enabled for {currencyCode}.</div>
              )}
              {showLatitude && (
                <div>
                  <LatitudePayment
                    onPayment={this.handlePayment}
                    customerId={customer.id_member}
                    brand={tenant.brand}
                    cart={cart}
                    createOrder={this.createOrder}
                    shouldPromoptWaiveSurcharge={isSurchargeWaived}
                  />
                </div>
              )}
              {showPayWithLeCreditButton && (
                <div>
                  <CreditPayment
                    paymentWillStart={this.paymentWillStart}
                    onPayment={this.handlePayment}
                    disabled={transacting}
                  />
                </div>
              )}
            </Stack>

            <Box mt={4}>
              <Typography fontWeight={500}>Vendor Notes</Typography>
              <TextField
                className="order-notes-input"
                value={cart.notes}
                onChange={(e) => this.props.doUpdateOrderNotes(e.target.value)}
                minRows={2}
                multiline
                fullWidth
              />
            </Box>
          </Box>
        </Stack>
      </div>
    );
  }
}

const mapStateToProps = (state: App.State) => ({
  cart: state.cart,
  insurance: state.insurance,
  brand: state.tenant.brand,
  tenant: state.tenant,
});

const mapDispatchToProps = {
  doUpdateLeCredit: updateCredit,
  doApplyLeCredit: applyLeCredit,
  doApplyPromoCode: applyPromoCode,
  doSetPromoCodeError: setPromoCodeError,
  doEmptyCart: emptyCart,
  doPurchaseFailure: purchaseFailure,
  doUpdateOrderNotes: updateOrderNotes,
  doUpdateReservation: updateReservation,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Payment.contextTypes = {
  user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Payment));
