import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';

type Props = React.PropsWithChildren<{
  title: React.ReactChild;
  subtitle?: React.ReactChild;
  backButton?: string | boolean;
  color?: string;
}>;

export default function PageHeader({ title, children, subtitle, backButton, color }: Props) {
  const history = useHistory();

  const backButtonProps = () => {
    if (typeof backButton === 'string') {
      return { to: backButton, component: Link };
    }

    return { onClick: goBack };
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Box pt={1} pb={3}>
      <Stack direction="row" alignItems="center" spacing={2} mb={1}>
        {backButton && (
          <IconButton sx={{ border: 1 }} size="small" color="secondary" title="Back" {...backButtonProps()}>
            <ArrowBackIcon />
          </IconButton>
        )}

        {!subtitle && (
          <Typography flex={1} component="h1" fontSize="2.5rem" fontWeight={500} gutterBottom color={color}>
            {title}
          </Typography>
        )}

        {!!subtitle && (
          <Stack direction="column">
            <Typography flex={1} component="h1" fontSize="2.5rem" fontWeight={500}>
              {title}
            </Typography>
            <Typography component="h2" fontSize="1.5rem">
              {subtitle}
            </Typography>
          </Stack>
        )}

        {children && <Box>{children}</Box>}
      </Stack>

      <Divider />
    </Box>
  );
}
