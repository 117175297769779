import React from 'react';

import { Helmet } from 'react-helmet';

import { Box } from '@mui/material';

import useQuery from '~/hooks/useQuery';

import PageSubheader from '../../Common/Elements/PageSubheader';
import { withTenant } from '../../hoc';

import PromoTester from './PromoTester';

interface Props {
  tenant: App.Tenant;
}

function PromoTesterContainer({ tenant }: Props) {
  const query = useQuery();

  const orderId = query.get('orderId');
  const codeName = query.get('codeName');
  const applyWithOrignalUserAccount = query.get('applyWithOrignalUserAccount') === 'true';
  const ignoreLuxPlusPricing = !query.get('ignoreLuxPlusPricing') || query.get('ignoreLuxPlusPricing') === 'true';
  return (
    <div>
      <Helmet>
        <title>Promo Tester</title>
      </Helmet>
      <PageSubheader title="Test promos against existing orders" />
      <Box mt={2}>
        <PromoTester
          initOrderId={orderId}
          initCodeName={codeName}
          initApplyWithOrignalUserAccount={applyWithOrignalUserAccount}
          initIgnoreLuxPlusPricing={ignoreLuxPlusPricing ?? true}
          tenant={tenant}
        />
      </Box>
    </div>
  );
}

export default withTenant(PromoTesterContainer);
