import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import OrderSearchForm, { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';
import Spinner from '~/components/Common/Spinner';

import { ITEM_TYPE_FLIGHT } from '~/consts/order';
import { ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';
import useQuery from '~/hooks/useQuery';

import { flightPNRSearch, getPurchases } from '~/services/OrdersService';

import { addQuery, removeQuery } from '~/utils/url';

import FlightsPage from './FlightsPage';

const DEFAULT_SIZE_PER_PAGE = 10;
const SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE = 50;

function FlightsPageContainer() {
  const history = useHistory();
  const { user } = useCurrentUser();
  const location = useLocation();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [isLoading, setIsLoading] = useState(true);
  const [purchases, setPurchases] = useState(null);
  const [filterField, setFilterField] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [additionalSearchParams, setAdditionalSearchParams] = useState<MultiFilterState>(null);
  const [sizePerPage, setSizePerPage] = useState(DEFAULT_SIZE_PER_PAGE);

  const currentPage = parseInt(query.get('page')) || 1;
  const customerId = query.get('customer_id');

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setPurchases(null);

    if (user?.roles.includes(ROLE_ICS_STORE_TEAM)) {
      // we don't want these users to be able to see all results so
      // if there are no filters selected, just return empty
      if (!filterField || !filterValue) {
        setPurchases({
          result: [],
          total: 0,
        });
        setIsLoading(false);
        return;
      }
    }

    try {
      let fetchedPurchases;
      if (filterField === 'pnr_id') {
        fetchedPurchases = await flightPNRSearch(filterValue);
      } else {
        fetchedPurchases = await getPurchases({
          page: currentPage,
          per_page: sizePerPage,
          brand,
          customer_id: customerId,
          filterBy: filterField,
          filterValue,
          additionalFilter: additionalSearchParams,
          item_type: ITEM_TYPE_FLIGHT,
        });
      }

      if (!fetchedPurchases) {
        enqueueSnackbar('Failed to load data', {
          variant: 'error',
        });
      }

      setPurchases(fetchedPurchases);
    } catch (error) {
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    user?.roles,
    filterField,
    filterValue,
    currentPage,
    sizePerPage,
    brand,
    customerId,
    additionalSearchParams,
    enqueueSnackbar,
  ]);

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [fetchData, user]);

  useEffect(() => {
    if (customerId) {
      setSizePerPage(SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE);
    } else {
      setSizePerPage(DEFAULT_SIZE_PER_PAGE);
    }
  }, [customerId]);

  const searchQuery = (field, value) => {
    setFilterField(field);
    setFilterValue(value);

    const newLocation = removeQuery(location, 'customer_id', 'page');

    history.push(newLocation);
  };

  return (
    <>
      <OrderSearchForm
        bookingNumberPlaceholder="Enter Flights PNR ID"
        searchQuery={searchQuery}
        setAdditionalParams={setAdditionalSearchParams}
        bookingIdType="pnr_id"
        e2eSuffix="-flight"
      />
      <>
        {isLoading && <Spinner size={48} />}

        {!isLoading && (
          <FlightsPage
            orders={purchases?.result}
            total={purchases?.total}
            page={currentPage}
            sizePerPage={sizePerPage}
            onPageChange={onPageChange}
            rerender={fetchData}
          />
        )}
      </>
    </>
  );
}

export default FlightsPageContainer;
