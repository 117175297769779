import React, { useCallback, useEffect, useState } from 'react';

import fileDownload from 'react-file-download';
import { Link, useRouteMatch } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Tab, Tabs } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import Spinner from '~/components/Common/Spinner';

import { getRatePlansPDF } from '~/services/PDFService';
import ReservationService from '~/services/ReservationService';

import { reportError } from '~/utils/reportError';

import RatePlanList from '../../Common/RatePlanList';

import { CopyRatePlansModal } from './CopyRatePlansModal';

type Props = {
  vendorName?: string;
  offers: Array<any>;
  properties: Array<App.Property>;
};

export default function VendorRatePlansPageContainer({ offers, properties, vendorName }: Props) {
  const {
    params: { id_vendor: vendorId },
  } = useRouteMatch<{ id_vendor: string }>();

  const [ratePlans, setRatePlans] = useState([]);
  const [ratePlansActive, setRatePlansActive] = useState([]);
  const [ratePlansUnassigned, setRatePlansUnassigned] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDownloading, setDownloading] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState(false);
  const [isCloning, setCloning] = useState(false);
  const [isCopyModalVisible, setCopyModalVisible] = useState(false);
  const [key, setKey] = useState<TabKey>('all');

  const editBtnFormatter = (ratePlanId: string) => {
    return (
      <Button component={Link} to={`/vendors/${vendorId}/rate-plans/${ratePlanId}`} variant="text">
        Edit
      </Button>
    );
  };

  const cloneBtnFormatter = (ratePlanId: string) => {
    return (
      <Button variant="text" color="error" onClick={() => cloneRatePlan(ratePlanId)} disabled={isCloning}>
        Clone {isCloning && <Spinner size={15} inline />}
      </Button>
    );
  };

  const TabsMap = {
    all: {
      title: 'All Rate Plans',
      component: RatePlanList,
      props: {
        ratePlans: ratePlans,
        editBtnFormatter: editBtnFormatter,
        cloneBtnFormatter: cloneBtnFormatter,
      },
    },
    active: {
      title: 'Active Rate Plans',
      component: RatePlanList,
      props: {
        ratePlans: ratePlansActive,
        editBtnFormatter: editBtnFormatter,
        cloneBtnFormatter: cloneBtnFormatter,
      },
    },
    unassigned: {
      title: 'Unassigned Rate Plans',
      component: RatePlanList,
      props: {
        ratePlans: ratePlansUnassigned,
        editBtnFormatter: editBtnFormatter,
        cloneBtnFormatter: cloneBtnFormatter,
      },
    },
  } as const;
  type TabKey = keyof typeof TabsMap;
  const allTabs = Object.keys(TabsMap);
  const currentTab = TabsMap[key];

  const handleChange = useCallback((_: React.SyntheticEvent, newKey: TabKey) => {
    setKey(newKey);
  }, []);

  const fetchData = useCallback(() => {
    setLoading(true);
    setError(null);
    ReservationService.getRatePlans(vendorId)
      .then((data) => {
        const allRatePlans = data.result;
        setRatePlans(allRatePlans);

        const roomRates = new Map();
        const ratePlans = new Map();

        (properties || []).forEach((property) => {
          property.room_types.forEach((room_type) => {
            room_type.room_rates.forEach((room_rate) => {
              if (!ratePlans.get(room_rate.rate_plan.id)) {
                ratePlans.set(room_rate.rate_plan.id, {
                  id: room_rate.rate_plan.id,
                  propertyDisabled: property.deleted_at !== null,
                  hasOffer: false,
                  offerActive: false,
                  rates: [],
                });
              }
              const plan = ratePlans.get(room_rate.rate_plan.id);
              plan.rates.push(room_rate.id);
              if (property.deleted_at === null) {
                plan.propertyDisabled = false;
              }
              roomRates.set(room_rate.id, room_rate.rate_plan.id);
            });
          });
        });

        (offers || []).forEach((offer) => {
          offer?.packages?.forEach((pkg) => {
            pkg?.package_options?.forEach((option) => {
              const ratePlanId = roomRates.get(option.fk_room_rate_id);
              if (ratePlanId) {
                const ratePlan = ratePlans.get(ratePlanId);
                if (ratePlan) {
                  ratePlan.hasOffer = true;
                  ratePlan.offerActive = offer.status === 'content-approved';
                }
              }
            });
          });
        });

        const list = Array.from(ratePlans.values());
        const active = list.filter((record) => record.offerActive).map((record) => record.id);
        const unassigned = list.filter((record) => !record.hasOffer).map((record) => record.id);

        setRatePlansActive(allRatePlans.filter((ratePlan) => active.includes(ratePlan.id)));
        setRatePlansUnassigned(allRatePlans.filter((ratePlan) => unassigned.includes(ratePlan.id)));
      })
      .catch((err) => {
        console.warn(err);
        setError("Service error. Can't load vendor rate plans");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vendorId, offers, properties]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  const cloneRatePlan = async (id: string) => {
    setCloning(true);
    try {
      await ReservationService.cloneRatePlan(id);
      fetchData();
    } catch (error) {
      reportError(error);
    } finally {
      setCloning(false);
    }
  };

  const createRatePlansDetails = () => {
    return ratePlans.map((eachPlan) => {
      const comm_or_markup = eachPlan.rate_type === 'net' ? eachPlan.markup : eachPlan.commission;
      return {
        name: eachPlan.name,
        type: eachPlan.rate_type,
        comm_or_markup,
      };
    });
  };

  const downloadRatePlans = async () => {
    setDownloading(true);
    setDownloadFailed(false);

    try {
      const list = createRatePlansDetails();
      const pdf = await getRatePlansPDF({
        list,
        propertyName: vendorName,
      });
      const fileName = `LuxuryEscapes-Rate-Plans-${vendorId}.pdf`;

      await fileDownload(pdf, fileName);
    } catch (error) {
      console.warn(error);
      setDownloadFailed(true);
    } finally {
      setDownloading(false);
    }
  };

  const onCopyRatePlansModalClose = (isSaved = false) => {
    setCopyModalVisible(false);

    if (isSaved) {
      fetchData();
    }
  };

  return (
    <div>
      <Box textAlign="right" mb={2}>
        <Button onClick={() => setCopyModalVisible(true)} variant="text">
          <ContentCopyIcon />
          <span className="sr-only">Copy Offer</span>
        </Button>

        {ratePlans && (
          <Button type="button" variant="text" onClick={downloadRatePlans}>
            {isDownloading && <i className="fa fa-circle-o-notch fa-spin" style={{ marginRight: '5px' }} />}
            {!isDownloading && <DownloadIcon />}
            {isDownloading && <span>Downloading Rate Plans</span>}
            {!isDownloading && downloadFailed && <span>Download Failed! Try Again</span>}
          </Button>
        )}
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={key} onChange={handleChange} id="rate-plans">
          {allTabs.map((tabKey) => (
            <Tab key={tabKey} label={TabsMap[tabKey].title} value={tabKey} />
          ))}
        </Tabs>
      </Box>

      <Box pt={4}>
        <Box component={currentTab.component} {...currentTab.props} />
      </Box>

      {/*<RatePlanList ratePlans={ratePlans} editBtnFormatter={editBtnFormatter} cloneBtnFormatter={cloneBtnFormatter} />*/}

      <Button variant="contained" component={Link} to={`/vendors/${vendorId}/rate-plans`}>
        + New
      </Button>

      <CopyRatePlansModal isOpen={isCopyModalVisible} onClose={onCopyRatePlansModalClose} />
    </div>
  );
}
