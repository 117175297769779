import { HOTEL_OFFER_TYPES } from '~/consts/offerTypes';

import ReservationService from '~/services/ReservationService';

const FK_PROPERTY_ID = 'fk_property_id';

export const getHotelUniquePropertyIds = (offerDetails: App.AccommodationOffer): string[] => {
  const { type, packages } = offerDetails;
  const isHotelOffer = HOTEL_OFFER_TYPES.includes(type);
  return isHotelOffer
    ? packages
        .map((pkg) => pkg[FK_PROPERTY_ID])
        .filter((pkgId, index, ids) => pkgId && typeof pkgId === 'string' && ids.indexOf(pkgId) === index)
    : [];
};

export const getHotelUniquePropertyDetails = async (offerDetails): Promise<App.Property> => {
  const uniqueHotelIds = getHotelUniquePropertyIds(offerDetails);
  if (uniqueHotelIds.length === 1) {
    const property = await ReservationService.getProperty(uniqueHotelIds[0]);
    return property.result;
  }
  return null;
};

export const getHotelProductName = (rawProductId: string): string => {
  if (rawProductId === 'hotel') {
    return 'Flash';
  }

  if (rawProductId === 'tactical_ao_hotel') {
    return 'LPC';
  }

  if (rawProductId === 'bedbank') {
    return 'LPP (Bedbank)';
  }

  return rawProductId;
};
