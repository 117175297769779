import React from 'react';

import { Box, Link } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { formatDateSlashes, sortByDate } from '~/services/TimeService';

import userUtils from '~/utils/userUtils';

import { CompedPackage } from './CompedPackagesForm';

interface prizeContainerProps {
  prizeWinnersList: Array<CompedPackage>;
  sizePerPage: number;
  page: number;
  total: number;
  setPage: (page: number) => void;
  loading?: boolean;
}

const CompedPackagesPage = ({ prizeWinnersList, sizePerPage, page, total, setPage, loading }: prizeContainerProps) => {
  const columns: GridColDef[] = [
    {
      field: 'fk_offer_id',
      headerName: 'Offer ID',
      minWidth: 175,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'offerName',
      headerName: 'Offer Name',
      minWidth: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link href={`/offers/${params.row.fk_offer_id}`} target="_blank">
          {params.row.offerName}
        </Link>
      ),
      display: 'flex',
    },
    {
      field: 'customer',
      headerName: 'Traveller name',
      minWidth: 100,
      flex: 1,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <Link href={`/users/${params.row.fk_customer_id}`} target="_blank">
          {params.row.customer}
        </Link>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 200,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 75,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'addedBy',
      headerName: 'Added by',
      minWidth: 100,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'created_at',
      headerName: 'Created date',
      minWidth: 100,
      flex: 1,
      type: 'date',
      sortComparator: sortByDate,
      valueFormatter: (value) => formatDateSlashes(value),
      display: 'flex',
    },
    {
      field: 'updated_at',
      headerName: 'Last updated',
      minWidth: 100,
      flex: 1,
      type: 'date',
      sortComparator: sortByDate,
      valueFormatter: (value) => formatDateSlashes(value),
      display: 'flex',
    },
    {
      field: 'Link',
      headerName: 'Edit ',
      minWidth: 100,
      flex: 0,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Link href={`/comped/${params.row.id_comped_package}`} target="_blank">
          Open
        </Link>
      ),
      display: 'flex',
    },
    {
      field: 'promo_link',
      headerName: 'Promo Code',
      minWidth: 100,
      flex: 0,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.row.fk_promo_code_id ? (
          <Link href={`/promos/${params.row.fk_promo_code_id}`} target="_blank">
            Promo Code
          </Link>
        ) : (
          ''
        ),
      display: 'flex',
    },
    {
      field: 'is_employee',
      headerName: 'Is employee',
      minWidth: 100,
      flex: 1,
      type: 'boolean',
      valueGetter: (_value, row) => userUtils.isEmployeeEmail(row.customerEmail),
      display: 'flex',
    },
  ];

  return (
    <Box>
      <DataGrid
        autoHeight
        rows={prizeWinnersList}
        columns={columns}
        paginationModel={{ page, pageSize: sizePerPage }}
        pageSizeOptions={[sizePerPage]}
        pagination
        paginationMode="server"
        rowCount={total}
        onPaginationModelChange={({ page }) => setPage(page)}
        getRowId={(row: any) => row.id_comped_package}
        sx={{
          minHeight: 310,
        }}
        loading={loading}
      />
    </Box>
  );
};

export default CompedPackagesPage;
