import useCurrentUser from '~/hooks/useCurrentUser';

interface Props {
  // a user must be in a list of user IDs
  userIDs?: Array<string>;
  overridePermission?: boolean;
  children?: any;
}

export default function UserIDPermissionedComponent({ userIDs = [], overridePermission, children }: Props) {
  const { user } = useCurrentUser();

  if (overridePermission) {
    return children;
  }
  if (userIDs.includes(user.id_member)) {
    return children;
  }

  return null;
}
