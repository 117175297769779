import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button, ButtonGroup, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { Order as OrderContract } from '@luxuryescapes/contract-svc-order';

import ErrorDisplay from '~/components/Common/ErrorDisplay';

import { ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';
import useQuery from '~/hooks/useQuery';

import { getPurchases } from '~/services/OrdersService';
import { formatDateShort } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';
import isUUID from '~/utils/isUUID';

import { customerDataFormatter } from '../formatters';

import type { SearchFilters } from './SearchFilters';

type Props = {
  searchFilters: SearchFilters | null;
};

type CustomOfferItem = {
  id: string;
  total: string;
  fk_custom_offer_id: string;
  custom_offer: App.CustomOffer;
};

type Order = OrderContract.Order;

const columns: Array<GridColDef> = [
  {
    field: 'customer_email',
    headerName: 'Customer',
    flex: 1,
    renderCell: customerDataFormatter,
    display: 'flex',
  },
  {
    field: 'custom_offer_name',
    headerName: 'Offer Name',
    flex: 1,
    renderCell: (params: GridRenderCellParams<Order>) => (
      <Typography>{params.row.custom_offer_items[0].custom_offer.name}</Typography>
    ),
    display: 'flex',
  },
  {
    field: 'custom_offer_type',
    headerName: 'Offer Type',
    width: 130,
    renderCell: (params: GridRenderCellParams<Order>) => (
      <Typography textTransform="capitalize">{params.row.custom_offer_items[0].custom_offer.items[0].type}</Typography>
    ),
    display: 'flex',
  },
  {
    field: 'total',
    headerName: 'Order Total',
    width: 130,
    renderCell: (params: GridRenderCellParams<Order>) => (
      <Typography>
        {currencyFormatter(params.row.currency_code, params.row.custom_offer_items[0].custom_offer.price / 100)}
      </Typography>
    ),
    display: 'flex',
  },
  { field: 'status', headerName: 'Order Status', width: 130, display: 'flex' },
  {
    field: 'dates',
    headerName: 'Created at',
    width: 130,
    renderCell: (params: GridRenderCellParams<Order>) => formatDateShort(params.row.created_at),
    display: 'flex',
  },
  {
    field: 'due_date',
    headerName: 'Due date',
    width: 130,
    renderCell: (params: GridRenderCellParams<Order>) =>
      formatDateShort(params.row.custom_offer_items[0].custom_offer.due_date),
    display: 'flex',
  },
  {
    field: 'actions',
    headerName: '',
    width: 130,
    renderCell: (params: GridRenderCellParams<Order>) => (
      <ButtonGroup orientation="vertical" variant="text" size="small">
        <Button component={Link} to={`/purchases/${params.row.id_orders}`}>
          Order details
        </Button>
        <Button
          component={Link}
          to={`/users/${params.row.fk_customer_id}/custom-offers/${params.row.custom_offer_items[0].fk_custom_offer_id}`}
        >
          Offer details
        </Button>
      </ButtonGroup>
    ),
    display: 'flex',
  },
];

export default function PurchasesTab({ searchFilters }: Props) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [purchases, setPurchases] = useState<Array<Order>>([]);
  const [error, setError] = useState<string | null>(null);
  const query = useQuery();
  const customerId = query.get('customer_id');
  const { user } = useCurrentUser();

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const isLoading = loadingState === 'loading';

  useEffect(() => {
    setLoadingState('loading');

    const filters = {
      customer_id: customerId ? customerId : undefined,
      filterBy: null,
      filterValue: null,
    };

    if (searchFilters?.orderStatus && searchFilters.orderStatus !== 'all') {
      filters.filterBy = 'status';
      filters.filterValue = searchFilters.orderStatus;
    } else if (searchFilters?.offerId) {
      filters.filterBy = 'offer_id';
      filters.filterValue = searchFilters.offerId;
    } else if (searchFilters?.customer && isUUID(searchFilters.customer)) {
      filters.customer_id = searchFilters.customer;
    } else if (searchFilters?.customer) {
      filters.filterBy = 'customer_details';
      filters.filterValue = searchFilters.customer;
    } else if (searchFilters?.bookingNumbers) {
      filters.filterBy = 'order_number';
      filters.filterValue = searchFilters.bookingNumbers;
    } else if (searchFilters?.supplierBookingNumber) {
      filters.filterBy = 'vendor_reference_number';
      filters.filterValue = searchFilters.supplierBookingNumber;
    }

    if (user?.roles.includes(ROLE_ICS_STORE_TEAM)) {
      // we don't want these users to be able to see all results so
      // if there are no filters selected, just return empty
      if (!filters.filterBy || !filters.filterValue) {
        setPurchases([]);
        setTotal(0);
        setLoadingState('success');
        return;
      }
    }

    getPurchases({ page, per_page: perPage, brand, item_type: 'custom_offer', ...filters })
      .then((res) => {
        setTotal(res.total);
        setPurchases(res.result);
        setLoadingState('success');
      })
      .catch((err) => {
        setError(err.message);
        setLoadingState('failed');
      });
  }, [page, perPage, brand, searchFilters, customerId]);

  return (
    <div>
      {error && <ErrorDisplay message={error} />}

      <DataGrid
        columns={columns}
        rows={purchases}
        rowCount={total}
        loading={isLoading}
        pagination
        paginationMode="server"
        paginationModel={{ page: page - 1, pageSize: perPage }}
        pageSizeOptions={[perPage]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: perPage,
            },
          },
        }}
        onPaginationModelChange={({ page }) => setPage(page + 1)}
        getRowId={(row: Order) => row.id}
        getRowHeight={() => 'auto'}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
      />
    </div>
  );
}
