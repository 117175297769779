import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import PermissionedComponent from '~/components/Common/PermissionedComponent';
import RestrictedComponent from '~/components/Common/RestrictedComponent';

import { OPERATION_SET_INSURANCE_CLAIM_ADMIN } from '~/consts/order';
import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import { updateOrderItem } from '~/services/OrdersService';
import { addDays, dateNowUtc, diffDays } from '~/services/TimeService';

import { humanize } from '~/utils/humanize';

import InsuranceSummary from '../../Common/Booking/InsuranceSummary';

import Log from './OrderItem/Log';
import UpdateInsurance from './UpdateInsurance';

function getCoolingOff(createdDate) {
  const date = addDays(21, dateNowUtc(new Date(createdDate)));
  // +1 to include start date
  const daysLeft = diffDays(date) + 1;
  if (daysLeft <= 0) {
    return 'Ended';
  }
  return daysLeft + ' Days Left';
}

function getRefundPermissionGroups(createdDate) {
  const daysPast = diffDays(dateNowUtc(), createdDate, 'days');
  return daysPast > 14 ? [ROLE_ADMIN_USER] : [ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER];
}

export default function OrderInsuranceItemList({
  order,
  items,
  showRefundModal,
  hasAllowedRefund,
  purchaseDate,
  refreshData,
  refunds,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const onChangeOrderLockStatus = useCallback(
    async (itemId: string) => {
      try {
        setLoading(true);
        await updateOrderItem(order.id, itemId, {
          brand,
          op: OPERATION_SET_INSURANCE_CLAIM_ADMIN,
          insuranceDetails: {
            claimStatus: order.order_lock?.is_locked ? 'admin_reset' : 'admin_lock',
          },
        });
        setTimeout(() => window.location.reload(), 200);
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
        setLoading(false);
      }
    },
    [enqueueSnackbar, order, brand],
  );

  return (
    <Box mt={2}>
      <FoldableSection title="Travel insurance" initiallyExpanded>
        <Box className="T-Order-Insurance-Item-List">
          {items.map((item) => (
            <Grid container key={item.id_insurance_items} spacing={2}>
              <Grid xs={12} md={5}>
                <InsuranceSummary
                  name={item.product_name}
                  type={item.insurance_type}
                  policyId={item.contract_number}
                  policyStartDate={item.start_date}
                  policyEndDate={item.end_date}
                  pds={item._links && item._links.pds ? item._links.pds.href : ''}
                  coolingOffMessage={getCoolingOff(purchaseDate)}
                  provider={item.provider}
                  coverAmount={item.cover_amount}
                  currencyCode={order.currency_code}
                  claimStatus={item.claim_status}
                />
              </Grid>

              <Grid xs={3} md={2}>
                <Stack direction="row" justifyContent="start" gap={1}>
                  <Typography>Sell price:</Typography>
                  <Typography fontWeight="bold" variant="body1">
                    ${item.total}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="start" gap={1}>
                  <Typography>Public price:</Typography>
                  <Typography fontWeight="bold" variant="body1">
                    ${item.public_price}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="start" gap={1}>
                  <Typography>LuxPlus price:</Typography>
                  <Typography fontWeight="bold" variant="body1">
                    ${item.lux_plus_price}
                  </Typography>
                </Stack>
              </Grid>

              <Grid xs={3} md={2}>
                <Typography fontWeight="bold" variant="h6">
                  {humanize(item.status)}
                </Typography>
              </Grid>

              <PermissionedComponent requiredRoles={getRefundPermissionGroups(purchaseDate)}>
                <RestrictedComponent excludedRoles={[ROLE_EXPERIENCES_COORDINATOR]}>
                  {item.insurance_type === 'cfmr' && item.status !== 'cancelled' && (
                    <Grid xs={2} md={1}>
                      <Button variant="text" disabled={loading} onClick={() => onChangeOrderLockStatus(item.id)}>
                        {order.order_lock?.is_locked ? 'Unlock' : 'Lock'} order
                      </Button>
                    </Grid>
                  )}
                  {hasAllowedRefund && item.status !== 'cancelled' && (
                    <Grid xs={2} md={1}>
                      <Button
                        onClick={() => {
                          showRefundModal({ itemId: item.id_insurance_items });
                        }}
                        className="T-issue-refund"
                        variant="text"
                      >
                        Issue refund
                      </Button>
                    </Grid>
                  )}
                </RestrictedComponent>
              </PermissionedComponent>

              {item.provider === 'cover_genius' && item.status !== 'cancelled' && (
                <Grid xs={4} md={2}>
                  <UpdateInsurance order={order} currentInsurance={item} onSuccess={refreshData} refunds={refunds} />
                </Grid>
              )}

              <Grid xs={12}>
                <Log orderId={item.fk_order_id} itemId={item.id_insurance_items} />
              </Grid>
            </Grid>
          ))}
        </Box>
      </FoldableSection>
    </Box>
  );
}
