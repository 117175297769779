import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import {
  getDefaultRegionCode,
  getRegionCodesSortedByKeyRegions,
  getRegionNameByCode,
} from '@luxuryescapes/lib-regions';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import ErrorDisplay from '~/components/Common/ErrorDisplay';

import SearchService, { OrderOffer } from '~/services/SearchService';

import { LE_AGENT_HUB, WHITE_LABELS } from '../../../consts/brands';

import OfferBox from './OfferBox';
import OfferInput from './OfferInput';
import OfferListOrder, { OrderListItemWithSortId } from './SortableOfferListOrder';
import { doesListMatch } from './helpers';
import { AUSTRALIA_LIST, BASE_LIST, EXPERIENCE_AUSTRALIA_LOCATIONS, ListOption } from './orderListOptions';
import { VerificationResult, verifyOffer } from './verifyOffers';

export const HOME_PAGE_CAROUSEL_ITEM_COUNT = 20;
const PREVIEW_COUNT = 5;

const ANYWHERE_PLACEID = 'le_d41d8cd98f00b204e9800998ecf8427e';

const ALL_OFFER_TYPES = [
  'hotel',
  'last_minute_hotel',
  'tactical_ao_hotel',
  'bundle_and_save',
  'bedbank_hotel',
  'tour',
  'tour_v2',
  'cruise',
  'flash',
  'always_on',
  'channel_manager',
].join(',');

const EXCLUDED_REGIONS = window.configs.EXCLUDED_REGIONS;

function filterListOptions(list: Array<ListOption>, isWhiteLabel: boolean) {
  const filterFromWhiteLabels = [
    'vertical-ultralux',
    'vertical-luxplus',
    'subregion-nsw',
    'subregion-vic',
    'carousel-hotel',
  ];
  if (isWhiteLabel) {
    return list.filter((item) => !filterFromWhiteLabels.includes(item.value));
  }

  return list;
}

function ContentPageContainer() {
  const tenant = useSelector((state: App.State) => state.tenant);

  const isWhiteLabel = WHITE_LABELS.includes(tenant.brand);

  const isAgentHub = tenant.brand === LE_AGENT_HUB;

  const { enqueueSnackbar } = useSnackbar();
  const { allRegionCodes, lastKeyRegionIndex } = getRegionCodesSortedByKeyRegions(
    tenant.brand,
    EXCLUDED_REGIONS ? EXCLUDED_REGIONS.split(',') : undefined,
  );
  const defaultRegionCode = getDefaultRegionCode(tenant.brand);
  const [selectedRegion, setSelectedRegion] = useState<string>(defaultRegionCode);

  const availableListOptions = filterListOptions(selectedRegion === 'AU' ? AUSTRALIA_LIST : BASE_LIST, isWhiteLabel);
  const firstExperienceLocationIndex = availableListOptions.findIndex(
    (opt) => opt.value === EXPERIENCE_AUSTRALIA_LOCATIONS[0].value,
  );

  const [selectedList, setSelectedList] = useState<ListOption>(availableListOptions[0]);
  const [isError, setIsError] = useState<boolean>(false);

  const [regionsToReplicateTo, setRegionsToReplicateTo] = useState<Array<string>>([]);
  const [applyToNSW, setApplyToNSW] = useState<boolean>(false);
  const [applyToVIC, setApplyToVIC] = useState<boolean>(false);
  const [applyToTaiwan, setApplyToTaiwan] = useState<boolean>(true);

  const showApplyToTaiwanField = useMemo(
    () => selectedRegion === 'HK' && !isWhiteLabel,
    [selectedRegion, isWhiteLabel],
  );

  const allRegionsToReplicateTo = useMemo(
    () => allRegionCodes.filter((code) => code !== selectedRegion),
    [allRegionCodes, selectedRegion],
  );

  const [listOrderState, setListOrderState] = useState<Array<OrderListItemWithSortId>>([]);
  const [evList, setEVList] = useState<Array<OrderListItemWithSortId>>([]);

  const isAustraliaList = !BASE_LIST.find((list) => list.value === selectedList.value);

  useEffect(() => {
    async function verifyList() {
      const invalidOffers = new Map<string, VerificationResult>();

      if (!listOrderState) {
        return;
      }

      for (const offer of listOrderState) {
        const result = await verifyOffer(offer, selectedRegion, tenant.brand, offer.offerType);
        if (!result?.isValid) {
          invalidOffers.set(offer.bk, result);
        }
      }

      setListOrderState((prevOffers) => {
        return prevOffers?.filter((offer) => !invalidOffers.has(offer.bk));
      });
    }

    verifyList();
  }, [tenant.brand, selectedRegion, listOrderState?.length]);

  const onRegionChange = (event: SelectChangeEvent) => {
    setSelectedRegion(event.target.value);
    setEVList(undefined);
    setListOrderState(undefined);
    setApplyToNSW(false);
    setApplyToVIC(false);
  };

  const onRemove = (removedOffer: OrderListItemWithSortId) => {
    setListOrderState((prevOffers) => prevOffers?.filter((o) => o.id !== removedOffer.id));
  };

  const onListChange = useCallback(
    (event: SelectChangeEvent) => {
      const newList = availableListOptions.find((option) => option.value === event.target.value);
      if (newList) {
        setSelectedList(newList);
        setEVList(undefined);
        setListOrderState(undefined);
        setApplyToNSW(false);
        setApplyToVIC(false);
      }
    },
    [availableListOptions],
  );

  useEffect(() => {
    setSelectedRegion(defaultRegionCode);
    setListOrderState([]);
    setEVList([]);
    setSelectedList(availableListOptions[0]);
  }, [tenant.brand]);

  useEffect(() => {
    setIsError(false);

    // Only proceed if selectedList and selectedRegion are valid
    if (!selectedList || !selectedRegion) {
      return;
    }

    SearchService.getListOrder(selectedList.value, tenant.brand, selectedRegion)
      .then((res) => {
        setListOrderState(res.result.offers.filter((offer) => !!offer.bk).map((offer) => ({ ...offer, id: uuid() })));
        if (selectedRegion === 'AU' && selectedList.value === 'home') {
          return Promise.all([
            SearchService.getListOrder('subregion-vic', tenant.brand, selectedRegion).then((vicRes) => {
              setApplyToVIC(doesListMatch(vicRes.result.offers, res.result.offers));
            }),
            SearchService.getListOrder('subregion-nsw', tenant.brand, selectedRegion).then((nswRes) => {
              setApplyToNSW(doesListMatch(nswRes.result.offers, res.result.offers));
            }),
          ]);
        }
      })
      .catch(() => setIsError(true));
  }, [tenant.brand, selectedRegion, selectedList]);

  useEffect(() => {
    SearchService.getUnifiedList({
      brand: tenant.brand,
      region: selectedRegion,
      placeIds: ANYWHERE_PLACEID,
      offerTypes: selectedList.offerTypes || ALL_OFFER_TYPES,
      limit: 10 * PREVIEW_COUNT,
      holidayTypesScoped: selectedList.holidayTypesScoped,
      luxPlusOffersOnly: selectedList.value === 'vertical-luxplus',
    }).then((res) => setEVList(res.results.map((offer) => ({ ...offer, id: uuid() }))));
  }, [selectedList, selectedRegion, tenant.brand]);

  const concatListItems = useCallback(
    (newItems: Array<OrderOffer>) => {
      const withIds = newItems.map((item) => ({ ...item, id: uuid() }));
      setListOrderState(listOrderState.concat(withIds));
    },
    [listOrderState],
  );

  const remainingEvItems = useMemo(() => {
    if (!listOrderState || !evList) {
      return [];
    }

    return evList
      .filter((evItem) => !listOrderState.some((manualItem) => manualItem.bk === evItem.bk))
      .slice(0, PREVIEW_COUNT);
  }, [evList, listOrderState]);

  const saveListOrder = useCallback(async () => {
    const offers: Array<OrderOffer> = listOrderState.map(
      ({ id: _, ...listOrderItemWithoutId }) => listOrderItemWithoutId,
    );
    try {
      const regionPromises = [];

      regionPromises.push({
        region: selectedRegion,
        promise: SearchService.putListOrder(selectedList.value, offers, tenant.brand, selectedRegion),
      });

      if (applyToNSW) {
        regionPromises.push({
          region: 'subregion-nsw',
          promise: SearchService.putListOrder('subregion-nsw', offers, tenant.brand, selectedRegion),
        });
      }

      if (applyToVIC) {
        regionPromises.push({
          region: 'subregion-vic',
          promise: SearchService.putListOrder('subregion-vic', offers, tenant.brand, selectedRegion),
        });
      }

      if (showApplyToTaiwanField && applyToTaiwan) {
        regionPromises.push({
          region: 'TW',
          promise: SearchService.putListOrder(selectedList.value, offers, tenant.brand, 'TW'),
        });
      }

      if (regionsToReplicateTo.length > 0 && !isAustraliaList) {
        for (const regionCode of regionsToReplicateTo) {
          regionPromises.push({
            region: regionCode,
            promise: SearchService.putListOrder(selectedList.value, offers, tenant.brand, regionCode),
          });
        }
      }

      const responses = await Promise.allSettled(regionPromises.map(({ promise }) => promise));

      const errors = responses
        .map((response, index) => ({ region: regionPromises[index].region, response }))
        .filter((response) => response.response.status === 'rejected');

      if (errors.length > 0) {
        enqueueSnackbar(`failed to save on regions: ${errors.map((error) => error.region).join(', ')}`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('List Order successfully saved', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar('List Order failed to save', {
        variant: 'error',
      });
    }
  }, [
    listOrderState,
    selectedRegion,
    selectedList.value,
    tenant.brand,
    applyToNSW,
    applyToVIC,
    showApplyToTaiwanField,
    applyToTaiwan,
    regionsToReplicateTo,
    isAustraliaList,
    enqueueSnackbar,
  ]);

  if (!listOrderState) {
    return null;
  }

  return (
    <Box className="new-offer-list-order-page T-NEW-Offer-List-Order">
      <Grid item xs>
        <PageSubheader title="Offer list order" />
      </Grid>
      <Grid container item alignItems="center" justifyContent="space-between" flexDirection="row-reverse">
        <Grid item>
          <Button variant="contained" onClick={saveListOrder} disabled={false}>
            Save
          </Button>
        </Grid>
        <Grid>
          <Grid container gap={2}>
            <Grid item>
              <Grid container gap={1} alignItems="center">
                <Typography variant="h5">Region: </Typography>
                <Select id="region" value={selectedRegion} onChange={onRegionChange}>
                  {allRegionCodes.map((regionCode, index) => (
                    <MenuItem key={regionCode} value={regionCode} divider={index === lastKeyRegionIndex}>
                      {getRegionNameByCode(regionCode)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container gap={1} alignItems="center">
                <Typography variant="h5">List: </Typography>
                <Select value={selectedList.value} onChange={onListChange}>
                  {availableListOptions.map((listOption, index) => (
                    <MenuItem
                      key={listOption.value}
                      value={listOption.value}
                      divider={index + 1 === firstExperienceLocationIndex}
                    >
                      <Typography>{listOption.label}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          {((selectedRegion === 'AU' && selectedList.value === 'home' && !isWhiteLabel) ||
            applyToNSW ||
            applyToVIC) && (
            <Grid container item xs gap={2} alignItems="center">
              <Grid item>
                <Typography>Apply also to: </Typography>
              </Grid>
              <Grid item>
                <Grid container gap={1} alignItems="center">
                  <InputLabel htmlFor="checkbox-nsw">NSW</InputLabel>
                  <Checkbox id="checkbox-nsw" checked={applyToNSW} onChange={() => setApplyToNSW(!applyToNSW)} />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container gap={1} alignItems="center">
                  <InputLabel htmlFor="checkbox-vic">VIC</InputLabel>
                  <Checkbox id="checkbox-vic" checked={applyToVIC} onChange={() => setApplyToVIC(!applyToVIC)} />
                </Grid>
              </Grid>
            </Grid>
          )}

          {showApplyToTaiwanField && (
            <Grid container mt={1} alignItems="center">
              <Checkbox id="checkbox-nsw" checked={applyToTaiwan} onChange={() => setApplyToTaiwan(!applyToTaiwan)} />
              <InputLabel htmlFor="checkbox-nsw">Apply to Taiwan</InputLabel>
            </Grid>
          )}

          {isAgentHub && !isAustraliaList && (
            <Grid container gap={1} mt={1} alignItems="center">
              <InputLabel htmlFor="checkbox-replicate-regions">Replicate to Regions:</InputLabel>
              <Select
                id="checkbox-replicate-regions"
                value={regionsToReplicateTo}
                multiple
                onChange={(e) => setRegionsToReplicateTo(e.target.value as Array<string>)}
              >
                {allRegionsToReplicateTo.map((regionCode, index) => (
                  <MenuItem key={regionCode} value={regionCode} divider={index === lastKeyRegionIndex}>
                    {getRegionNameByCode(regionCode)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid>
        <Grid item sm={12}>
          <Typography>Click and drag to re-order, the click Save to confirm the changes.</Typography>
          <Typography>Please allow up to 20 minutes for your changes to appear on the homepage.</Typography>
        </Grid>
      </Grid>
      {isError && <ErrorDisplay message="Sorry, something went wrong. Please refresh to try again." />}
      {!isError && (
        <>
          {listOrderState.length === 0 && (
            <Alert severity="error">
              <Typography>There are no offers manually added to this list</Typography>
            </Alert>
          )}
          <OfferListOrder
            region={selectedRegion}
            offers={listOrderState}
            onChange={setListOrderState}
            softLimit={selectedList.softLimit}
            onRemove={onRemove}
          />
          <Divider variant="middle" sx={{ margin: '24px' }} />
          <Grid container gap={1}>
            <Grid item xs={12}>
              <Typography variant="h5">Insert offers by entering their business keys</Typography>
              <Typography variant="subtitle1">Separate them with a single comma to add multiple at once.</Typography>
            </Grid>
            <Grid item xs={12}>
              <OfferInput onSubmit={concatListItems} region={selectedRegion} brand={tenant.brand} />
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ margin: '24px' }} />
          <Typography variant="h5">Remainder of the list will be filled algorithmically</Typography>
          <Typography variant="subtitle1">
            They will frequently change, but here's the next 5 which are live now.
          </Typography>
          {remainingEvItems.length > 0 && (
            <Grid container gap={2} margin-top={1}>
              {remainingEvItems.map((item, i) => (
                <Grid
                  key={item.bk}
                  item
                  xs={12}
                  alignItems="center"
                  borderColor="grey.500"
                  sx={{
                    borderRadius: '4px',
                    borderStyle: 'solid',
                    borderWidth: '2px',
                  }}
                >
                  <OfferBox offer={item} region={selectedRegion} index={listOrderState.length + i} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
}

export default ContentPageContainer;
