import React, { useCallback, useState } from 'react';

import LinkIcon from '@mui/icons-material/Link';
import { Alert, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import AccommodationMapPropertyFinder from '~/components/Accommodation/Pages/Properties/components/AccommodationMapPropertyFinder';
import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import useToggleState from '~/hooks/useToggleState';

import { SupplierProperty, getUnmappedSuppliers } from '~/services/AccommodationService';

import { AccommodationPropertiesSearchInput } from './AccommodationPropertiesSearchForm';

interface Props {
  searchInput: AccommodationPropertiesSearchInput;
  pageIndex?: number;
  onPageChange: (pageIndex: number) => void;
}

const PAGE_SIZE = 20;

export default function AccommodationUnmappedSuppliersResultsTable({ searchInput, pageIndex, onPageChange }: Props) {
  const [supplierProperty, setSupplierProperty] = useState<SupplierProperty>(null);

  const {
    isToggled: isMapPropertyModalOpen,
    toggleOn: setOpenMapPropertyModal,
    toggleOff: setCloseMapPropertyModal,
  } = useToggleState(false);

  const getUnmappedSupplierFetch = useCallback(
    () =>
      getUnmappedSuppliers({
        name: searchInput.propertyName,
        id: searchInput.id,
        page: String((pageIndex ?? 0) + 1),
        limit: String(PAGE_SIZE),
      }),
    [pageIndex, searchInput],
  );

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: getUnmappedSupplierFetch,
  });

  const onMap = useCallback(
    async (supplierProperty: SupplierProperty) => {
      setSupplierProperty(supplierProperty);
      setOpenMapPropertyModal();
    },
    [setOpenMapPropertyModal],
  );

  const columns: Array<GridColDef<SupplierProperty>> = [
    {
      field: 'name',
      headerName: 'Property Name',
      display: 'flex',
      minWidth: 350,
      flex: 1,
      hideable: false,
    },
    {
      field: 'supplier',
      headerName: 'Supplier',
      display: 'flex',
      minWidth: 200,
      hideable: true,
    },
    {
      field: 'supplierId',
      headerName: 'Supplier ID',
      display: 'flex',
      minWidth: 350,
      hideable: true,
    },
    {
      field: 'source',
      headerName: 'Source',
      display: 'flex',
      minWidth: 100,
      hideable: true,
    },
    {
      field: 'sourceId',
      headerName: 'Source ID',
      display: 'flex',
      minWidth: 350,
      hideable: true,
    },
    {
      field: ' ',
      headerName: 'Map',
      headerAlign: 'center',
      width: 50,
      sortable: false,
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        return (
          <IconButton onClick={() => onMap(params.row)} color="info">
            <LinkIcon fontSize="medium" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      <DataGrid
        className="accommodation-unmapped-suppliers-search-results-table"
        columns={columns}
        rows={fetchReqState.status === 'succeeded' ? fetchReqState.result.supplierProperties : []}
        rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.total : undefined}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[PAGE_SIZE]}
        paginationMode="server"
        paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
        onPaginationModelChange={({ page }) => onPageChange(page)}
        loading={fetchReqState.status === 'pending'}
        autoHeight
        disableColumnFilter
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
        }}
      />
      <Dialog open={isMapPropertyModalOpen} onClose={() => setCloseMapPropertyModal()} fullWidth maxWidth="md">
        {isMapPropertyModalOpen && (
          <>
            <DialogTitle>Select a property to map to "{supplierProperty.name}"</DialogTitle>
            <DialogContent>
              <AccommodationMapPropertyFinder
                supplierProperty={supplierProperty}
                onClose={setCloseMapPropertyModal}
                refetchUnmapped={refetch}
              />
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
}
