import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import { COMMISSION_RULE_CONDITION_TYPES, CommissionsConditionTypes, RuleCondition } from '~/consts/agentHub';

import AgentHubCommissionsAffiliationInput from './AgentHubCommissionsAffiliationInput';
import AgentHubCommissionsAgencyInput from './AgentHubCommissionsAgencyInput';
import AgentHubCommissionsAgentInput from './AgentHubCommissionsAgentInput';
import AgentHubCommissionsLocationInput from './AgentHubCommissionsLocationInput';
import AgentHubCommissionsProductTypeInput from './AgentHubCommissionsProductTypeInput';
import AgentHubCommissionsTextInput from './AgentHubCommissionsTextInput';
import AgentHubCommissionsTierInput from './AgentHubCommissionsTierInput';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  addCondition: (condition: RuleCondition) => void;
}

function AddNewConditionForm({ isOpen, onClose, addCondition }: Props) {
  const [selectedConditionType, setSelectedConditionType] = useState<CommissionsConditionTypes>('productType');

  const handleSelect = (event: SelectChangeEvent<CommissionsConditionTypes>) => {
    setSelectedConditionType(event.target.value as CommissionsConditionTypes);
  };

  const handleCreateCondition = (condition: RuleCondition) => {
    addCondition(condition);
    onClose();
  };

  return (
    <Dialog open={isOpen} fullWidth onClose={onClose}>
      <DialogTitle>New Condition</DialogTitle>
      <Stack direction="row" alignItems="center" padding={2}>
        <Stack spacing={2} flex={1}>
          <FormControl>
            <InputLabel>Condition type</InputLabel>
            <Select value={selectedConditionType} label="Condition type" required fullWidth onChange={handleSelect}>
              {COMMISSION_RULE_CONDITION_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedConditionType === 'agency' && (
            <AgentHubCommissionsAgencyInput setCreatedConditions={handleCreateCondition} />
          )}

          {selectedConditionType === 'agent' && (
            <AgentHubCommissionsAgentInput setCreatedConditions={handleCreateCondition} />
          )}

          {selectedConditionType === 'location' && (
            <AgentHubCommissionsLocationInput setCreatedConditions={handleCreateCondition} />
          )}

          {selectedConditionType === 'productType' && (
            <AgentHubCommissionsProductTypeInput setCreatedConditions={handleCreateCondition} />
          )}

          {selectedConditionType === 'vendor' && (
            <AgentHubCommissionsTextInput conditionType="vendor" setCreatedConditions={handleCreateCondition} />
          )}

          {selectedConditionType === 'offer' && (
            <AgentHubCommissionsTextInput conditionType="offer" setCreatedConditions={handleCreateCondition} />
          )}

          {selectedConditionType === 'affiliation' && (
            <AgentHubCommissionsAffiliationInput setCreatedConditions={handleCreateCondition} />
          )}

          {selectedConditionType === 'tier' && (
            <AgentHubCommissionsTierInput setCreatedConditions={handleCreateCondition} />
          )}
        </Stack>
      </Stack>
      <Divider />
    </Dialog>
  );
}

export default AddNewConditionForm;
