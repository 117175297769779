import { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { operations } from '@luxuryescapes/contract-svc-promo';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import { debugPromoByOrderID } from '~/services/PromoService';

type PromoError = {
  code: string;
  message: string;
};

type UseOrderDiscountsProps = {
  initOrderId?: string;
  initCodeName?: string;
  initApplyWithOrignalUserAccount: boolean;
  initIgnoreLuxPlusPricing: boolean;
};

type OrderDiscountFilter = {
  codeName: string;
  orderId: string;
  brand: string;
  applyWithOrignalUserAccount: boolean;
  ignoreLuxPlusPricing: boolean;
};

export default function usePromoSimulator({
  initOrderId,
  initCodeName,
  initApplyWithOrignalUserAccount,
  initIgnoreLuxPlusPricing = true,
}: UseOrderDiscountsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [orderDiscount, setOrderDiscount] =
    useState<operations['calculateDiscountByOrderId']['responses']['200']['content']['application/json']['result']>(
      null,
    );
  const [internals, setInternals] = useState<any>(null);
  const [promoCodeErrors, setPromoCodeErrors] = useState<Array<PromoError>>([]);
  const { tenant } = useCurrentTenant();

  const [isLoading, setIsLoading] = useState(false);

  const setInternalsFromErrorBody = (errorBody: any) => {
    if ('result' in errorBody && 'internals' in errorBody.result) {
      setInternals(errorBody.result.internals);
    }
  };

  const reSimulateDiscountEvent = useCallback(
    async ({ codeName, orderId, brand, applyWithOrignalUserAccount, ignoreLuxPlusPricing }: OrderDiscountFilter) => {
      setIsLoading(true);
      try {
        const response = await debugPromoByOrderID(
          codeName,
          orderId,
          brand,
          applyWithOrignalUserAccount,
          ignoreLuxPlusPricing,
          setInternalsFromErrorBody,
        );
        if (response.status === 200) {
          setOrderDiscount(response.result);
          setPromoCodeErrors([]);
          setInternals(null);
        } else {
          setOrderDiscount(null);
          setPromoCodeErrors(response);
        }
      } catch (err) {
        if ('errors' in err && err.errors?.length > 0) {
          setOrderDiscount(null);
          setPromoCodeErrors(err.errors);
        } else {
          setPromoCodeErrors([err]);
          enqueueSnackbar(`Error: ${JSON.stringify(err)}`, { variant: 'error', autoHideDuration: 5000 });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (initOrderId && initCodeName) {
      reSimulateDiscountEvent({
        codeName: initCodeName,
        orderId: initOrderId,
        brand: tenant.brand,
        applyWithOrignalUserAccount: initApplyWithOrignalUserAccount,
        ignoreLuxPlusPricing: initIgnoreLuxPlusPricing,
      });
    }
  }, [reSimulateDiscountEvent, initOrderId, initCodeName, enqueueSnackbar]);

  return {
    orderDiscount,
    isLoading,
    promoCodeErrors,
    reSimulateDiscountEvent,
    internals,
  };
}
