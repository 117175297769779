import React from 'react';

import PropTypes from 'prop-types';
import dayjs from '~/timeInit';

import { Alert, Box, Button, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material';

import * as libCountries from '@luxuryescapes/lib-countries';

import { TRAVELLER_TITLE_OPTIONS, TRAVELLER_WEIGHT_OPTIONS } from '~/consts/traveller-details';

import { getDetails, updateDetails } from '~/services/TravellerService';

import Spinner from '../../Spinner';

const countries = libCountries.getCountries();

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';

const travellerSchema = {
  title: {
    required: true,
    label: 'Title',
  },
  first_name: {
    required: true,
    label: 'First Name',
  },
  middle_name: {
    required: false,
    label: 'Middle Name',
  },
  last_name: {
    required: true,
    label: 'Last Name',
  },
  id_first_name: {
    required: true,
    label: 'First Name as per passport or id',
    placeholder: 'First Name',
  },
  id_middle_name: {
    required: false,
    label: 'Middle Name as per passport or id',
    placeholder: 'Middle Name',
  },
  id_last_name: {
    required: true,
    label: 'Last Name as per passport or id',
    placeholder: 'Last Name',
  },
  gender: {
    required: true,
    label: 'Gender',
  },
  weight: {
    required: false,
    label: 'Weight',
  },
  dob: {
    required: true,
    label: 'Date of Birth',
  },
  passport_expiry: {
    required: true,
    label: 'Passport Expiry',
  },
  passport_number: {
    required: true,
    label: 'Passport Number',
  },
  nationality: {
    required: true,
    label: 'Nationality',
  },
  bedding_configuration: {
    required: true,
    label: 'Bedding Configuration',
  },
  contact_number: {
    required: true,
    label: 'Contact Number',
  },
  email: {
    required: true,
    label: 'Email Address',
  },
  emergency_contact_email: {
    required: true,
    label: 'Email',
  },
  emergency_contact_name: {
    required: true,
    label: 'Full Name',
  },
  emergency_contact_phone: {
    required: true,
    label: 'Contact Number',
  },
  emergency_contact_relationship: {
    required: true,
    label: 'Relationship',
  },
  medical_conditions: {
    required: false,
    label: 'Medical Conditions',
  },
  mobility_requirements: {
    required: false,
    label: 'Mobility Requirements',
  },
  dietary_requirements: {
    required: false,
    label: 'Dietary Requirements',
  },
  conditions: {
    required: false,
    label: 'Other Requirements (deprecated)',
  },
  requests: {
    required: false,
    label: 'Additional Requests',
  },

  pre_tour_accommodation: {
    required: false,
    label: 'Pre-Tour Accommodation',
  },
  post_tour_accommodation: {
    required: false,
    label: 'Post-Tour Accommodation',
  },
  insurance_provider: {
    required: true,
    label: 'Insurance Provider',
  },
  insurance_policy_number: {
    required: true,
    label: 'Insurance Policy Number',
  },
};

const travellerFlightSchema = {
  arrival_date: {
    required: true,
    label: 'Arrival Date',
  },
  arrival_time: {
    required: true,
    label: 'Arrival Time (24 hour time)',
  },
  arrival_airport: {
    required: true,
    label: 'Airport (3 Letter Code)',
    pattern: '^[a-zA-Z]{3}$', // 3 letter
  },
  arrival_flight_number: {
    required: true,
    label: 'Flight Number',
    pattern: '^[a-zA-Z0-9]{5,6}$', // 5 to 6 characters mix of digit/letter
  },
  departure_date: {
    required: true,
    label: 'Departure Date',
  },
  departure_time: {
    required: true,
    label: 'Departure Time (24 hour time)',
  },
  departure_airport: {
    required: true,
    label: 'Airport (3 Letter Code)',
    pattern: '^[a-zA-Z]{3}$', // 3 letter
  },
  departure_flight_number: {
    required: true,
    label: 'Flight Number',
    pattern: '^[a-zA-Z0-9]{5,6}$', // 5 to 6 characters mix of digit/letter
  },
};

const travellerFormData = () => {
  let data = {};
  Object.keys(Object.assign({}, travellerSchema, travellerFlightSchema)).forEach((name) => {
    data[name] = '';
  });
  return data;
};

function populateFormData(traveller, formData) {
  const formDataCopy = { ...formData };
  Object.keys(formDataCopy).forEach((name) => {
    formDataCopy[name] = traveller[name] || '';
  });

  formDataCopy['booking_number'] = traveller.booking_number;
  formDataCopy['traveller_id'] = traveller.traveller_id;
  formDataCopy['order_id'] = traveller.order_id;
  formDataCopy['customer_id'] = traveller.customer_id;

  return formDataCopy;
}

export default class TravellerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: props.traveller ? populateFormData(props.traveller, travellerFormData()) : travellerFormData(),
      errors: [],
      errorFields: {},
      success: false,
      loading: !props.traveller,
      sending: false,
      traveller: props.traveller ?? {},
      totalTravellers: props.traveller ? 1 : 0,
      tourFlightDetailsRequired: props.tourFlightDetailsRequired,
      tourWeightRequired: props.tourWeightRequired,
      passportRequired: props.traveller?.passportRequired ?? true,
      displayFlightsForm: props.traveller?.flights_submitted ?? false,
    };
  }

  fetchTravellerData = async () => {
    const { bookingNumber, orderId } = this.props.bookingData;
    const { tourFlightDetailsRequired } = this.props;

    try {
      let formData = Object.assign({}, this.state.formData);

      const travellers = await getDetails(orderId, false, false);
      const traveller = travellers.find((data) => data.booking_number === bookingNumber);
      if (traveller) {
        formData = populateFormData(traveller, formData);
      }

      this.setState({
        loading: false,
        traveller: traveller,
        totalTravellers: travellers.length,
        formData: formData,
        displayFlightsForm: traveller.flights_submitted || false,
        passportRequired: traveller?.passportRequired ?? true,
        tourFlightDetailsRequired: traveller?.purchased_item?.tour_flight_details_required ?? tourFlightDetailsRequired,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  };

  handleFieldChange = (e) => {
    if (e.target.validity) {
      this.setState({
        errorFields: {
          ...this.state.errorFields,
          [e.target.name]: !e.target.validity.valid,
        },
      });
    }

    this.setState({ success: false });
    let data = Object.assign({}, this.state.formData);
    data[e.target.name] = e.target.value;
    this.setState({ formData: data });
  };

  handleDisplayFlightsForm = (display) => {
    this.setState({ success: false });
    this.setState({
      displayFlightsForm: display,
    });
  };

  handleImageChange = (image, error) => {
    this.setState({ success: false });

    if (error) {
      this.setState({
        errors: [error],
      });
    }
    this.setState({
      formData: Object.assign({}, this.state.formData, {
        passport_image: image,
      }),
    });
  };

  validateForm = (submit) => {
    let errors = [];
    const data = this.state.formData;
    const { passportRequired, tourWeightRequired } = this.state;

    let schema = Object.assign({}, travellerSchema);
    schema.weight.required = tourWeightRequired;

    if (this.state.tourFlightDetailsRequired && this.state.displayFlightsForm) {
      schema = Object.assign({}, schema, travellerFlightSchema);
    }

    if (submit) {
      for (let item in data) {
        if (!passportRequired && ['passport_number', 'passport_expiry', 'nationality'].includes(item)) {
          continue;
        }

        if (!data[item] && schema[item] && schema[item].required === true) {
          errors.push(`Field ${item} is required`);
        }
      }
    }

    if (data.dob && !dayjs(data.dob, DATE_FORMAT, true).isValid()) {
      errors.push('Please enter date of birth in the correct format');
    }

    if (data.passport_expiry && !dayjs(data.passport_expiry, DATE_FORMAT, true).isValid()) {
      errors.push('Please enter the passport expiry date in the correct format');
    }

    if (this.state.tourFlightDetailsRequired && this.state.displayFlightsForm) {
      if (data.arrival_date && !dayjs(data.arrival_date, DATE_FORMAT, true).isValid()) {
        errors.push('Please enter the arrival date in the correct format');
      }

      if (data.departure_date && !dayjs(data.departure_date, DATE_FORMAT, true).isValid()) {
        errors.push('Please enter the departure date in the correct format');
      }

      if (data.arrival_time && !dayjs(data.arrival_time, TIME_FORMAT, true).isValid()) {
        errors.push('Please enter the arrival time in the correct format');
      }

      if (data.departure_time && !dayjs(data.departure_time, TIME_FORMAT, true).isValid()) {
        errors.push('Please enter the departure time in the correct format');
      }

      if (this.state.errorFields.departure_airport) {
        errors.push('Please enter a valid departure airport code');
      }

      if (this.state.errorFields.departure_flight_number) {
        errors.push('Please enter a valid departure flight number');
      }

      if (this.state.errorFields.arrival_airport) {
        errors.push('Please enter a valid arrival airport code');
      }

      if (this.state.errorFields.arrival_flight_number) {
        errors.push('Please enter a valid arrival flight number');
      }
    }

    return errors;
  };

  submitTraveller = (event) => {
    event.preventDefault();
    this.sendForm(true);
  };

  saveTraveller = (event) => {
    event.preventDefault();
    this.sendForm();
  };

  sendForm = async (submit = false) => {
    this.setState({
      errors: [],
      sending: true,
    });

    try {
      let errors = this.validateForm(submit);
      const traveller_submitted = submit ? { traveller_submitted: submit } : {};
      const includeFlights = this.state.displayFlightsForm;
      const flights_submitted = includeFlights && submit ? { flights_submitted: submit } : {};
      let formData = Object.assign({}, this.state.formData, {
        ...traveller_submitted,
        ...flights_submitted,
      });
      if (!errors.length) {
        await updateDetails({
          ...formData,
          vendor_id: this.context.user.id_member || 'admin',
          with_flights: includeFlights,
        });

        if (this.props.getTravellers) {
          await this.props.getTravellers(formData.order_id);
        }

        this.setState({
          success: true,
          sending: false,
        });

        if (this.props.setTravellers) {
          await this.props.setTravellers(formData.order_id);
        }

        if (this.props.setTravellerSubmitted) {
          this.props.setTravellerSubmitted(traveller_submitted);
        }

        if (this.props.setFlightsSubmitted) {
          this.props.setFlightsSubmitted(flights_submitted.flights_submitted ?? false);
        }
      }

      this.setState({
        errors,
        sending: false,
      });
    } catch (err) {
      this.setState({
        errors: err.errors || err,
        sending: false,
      });
    }
  };

  componentDidMount = async () => {
    if (this.state.loading === true && this.props.bookingData && !this.props.traveller) {
      await this.fetchTravellerData();
    }
  };

  render() {
    const schema = Object.assign({}, travellerSchema, travellerFlightSchema);
    let errors = this.state.errors;

    let { loading } = this.state;

    const { passportRequired, tourWeightRequired } = this.state;

    if (loading) {
      return <Spinner />;
    }

    return (
      <form onSubmit={this.submitTraveller}>
        <Stack mt={1} direction="column" spacing={2}>
          <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr">
            <Box>
              <TextField
                label={schema['title'].label}
                name="title"
                value={this.state.formData.title}
                onChange={this.handleFieldChange}
                InputProps={{ displayEmpty: true }}
                fullWidth
              >
                <MenuItem key={`title`} value="">
                  Select title...
                </MenuItem>
                {TRAVELLER_TITLE_OPTIONS.map((option) => (
                  <MenuItem key={`title_${option}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box>
              <TextField
                label={schema['first_name'].label}
                required
                type="text"
                name="first_name"
                placeholder={schema['first_name'].label}
                value={this.state.formData.first_name}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                label={schema['middle_name'].label}
                type="text"
                name="middle_name"
                placeholder={schema['middle_name'].label}
                value={this.state.formData.middle_name}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                label={schema['last_name'].label}
                required
                type="text"
                name="last_name"
                placeholder={schema['last_name'].label}
                value={this.state.formData.last_name}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                label={schema['gender'].label}
                name="gender"
                placeholder=""
                value={this.state.formData.gender}
                onChange={this.handleFieldChange}
                InputProps={{ displayEmpty: true }}
                select
                fullWidth
              >
                <MenuItem key={`gender`} value="">
                  Select gender...
                </MenuItem>
                {['Male', 'Female', 'Other'].map((option) => (
                  <MenuItem key={`gender_${option}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            {schema.weight !== undefined && tourWeightRequired && (
              <Box>
                <TextField
                  label={schema['weight'].label}
                  name="weight"
                  placeholder=""
                  value={this.state.formData.weight}
                  onChange={this.handleFieldChange}
                  InputProps={{ displayEmpty: true }}
                  select
                  fullWidth
                >
                  <MenuItem key={`weight`} value="">
                    Select weight...
                  </MenuItem>
                  {TRAVELLER_WEIGHT_OPTIONS.map((option) => (
                    <MenuItem key={`weight_${option}`} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}

            <Box>
              <TextField
                label={schema['dob'].label}
                type="date"
                name="dob"
                value={this.state.formData.dob}
                onChange={this.handleFieldChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box>
              <TextField
                required
                label={schema['id_first_name'].label}
                type="text"
                name="id_first_name"
                placeholder={schema['id_first_name'].placeholder}
                value={this.state.formData.id_first_name}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                label={schema['id_middle_name'].label}
                type="text"
                name="id_middle_name"
                placeholder={schema['id_middle_name'].placeholder}
                value={this.state.formData.id_middle_name}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                required
                label={schema['id_last_name'].label}
                type="text"
                name="id_last_name"
                placeholder={schema['id_last_name'].placeholder}
                value={this.state.formData.id_last_name}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            {passportRequired && (
              <>
                <Box>
                  <TextField
                    fullWidth
                    label={schema['passport_number'].label}
                    type="text"
                    name="passport_number"
                    value={this.state.formData.passport_number}
                    onChange={this.handleFieldChange}
                  />
                </Box>

                <Box>
                  <TextField
                    fullWidth
                    label={schema['passport_expiry'].label}
                    type="date"
                    name="passport_expiry"
                    value={this.state.formData.passport_expiry}
                    onChange={this.handleFieldChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </>
            )}
            {passportRequired && (
              <>
                <Box>
                  <TextField
                    label={schema['nationality'].label}
                    name="nationality"
                    placeholder=""
                    value={this.state.formData.nationality}
                    onChange={this.handleFieldChange}
                    InputProps={{ displayEmpty: true }}
                    fullWidth
                    select
                  >
                    <MenuItem key={`nationality`} value="">
                      Select nationality...
                    </MenuItem>
                    {countries.map((country) => (
                      <MenuItem key={`nationality_${country}`} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </>
            )}
          </Box>

          <Divider />

          <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr">
            <Box>
              <TextField
                label={schema['insurance_provider'].label}
                type="text"
                name="insurance_provider"
                placeholder=""
                value={this.state.formData.insurance_provider}
                onChange={this.handleFieldChange}
                fullWidth
                required
              />
            </Box>
            <Box>
              <TextField
                label={schema['insurance_policy_number'].label}
                type="text"
                name="insurance_policy_number"
                placeholder=""
                value={this.state.formData.insurance_policy_number}
                onChange={this.handleFieldChange}
                required
                fullWidth
              />
            </Box>
          </Box>

          <Divider />

          <Box>
            <TextField
              label={schema['bedding_configuration'].label}
              name="bedding_configuration"
              placeholder=""
              value={this.state.formData.bedding_configuration}
              onChange={this.handleFieldChange}
              select
              fullWidth
            >
              <MenuItem key={`bedding_configuration`} value="">
                Select value...
              </MenuItem>
              {['Double', 'Twin', 'Single'].map((bedding) => (
                <MenuItem key={`bedding_configuration_${bedding}`} value={bedding}>
                  {bedding}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Divider />

          <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr">
            <Box>
              <TextField
                label={schema['contact_number'].label}
                type="tel"
                name="contact_number"
                placeholder="+61..."
                value={this.state.formData.contact_number}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                label={schema['email'].label}
                type="email"
                name="email"
                title="For example, myname@example.com"
                placeholder="myname@example.com"
                value={this.state.formData.email}
                onChange={this.handleFieldChange}
                fullWidth
                required
              />
            </Box>
          </Box>

          <Divider />

          <Box>
            <Typography fontWeight="bold">Emergency Contact</Typography>
            <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr">
              <Box>
                <TextField
                  label={schema['emergency_contact_name'].label}
                  type="text"
                  name="emergency_contact_name"
                  value={this.state.formData.emergency_contact_name}
                  onChange={this.handleFieldChange}
                  fullWidth
                />
              </Box>

              <Box>
                <TextField
                  label={schema['emergency_contact_relationship'].label}
                  type="text"
                  name="emergency_contact_relationship"
                  value={this.state.formData.emergency_contact_relationship}
                  onChange={this.handleFieldChange}
                  fullWidth
                />
              </Box>

              <Box>
                <TextField
                  label={schema['emergency_contact_phone'].label}
                  type="tel"
                  name="emergency_contact_phone"
                  placeholder="+61..."
                  value={this.state.formData.emergency_contact_phone}
                  onChange={this.handleFieldChange}
                  fullWidth
                />
              </Box>

              <Box>
                <TextField
                  label={schema['emergency_contact_email'].label}
                  type="email"
                  name="emergency_contact_email"
                  title="For example, myname@example.com"
                  placeholder="myname@example.com"
                  value={this.state.formData.emergency_contact_email}
                  onChange={this.handleFieldChange}
                  fullWidth
                  required
                />
              </Box>
            </Box>
          </Box>

          <Divider />

          <Stack direction="column" spacing={2}>
            <Typography fontWeight="bold">Medical Details</Typography>
            <Box>
              <TextField
                label={schema['medical_conditions'].label}
                multiline
                minRows={2}
                name="medical_conditions"
                value={this.state.formData.medical_conditions}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                label={schema['mobility_requirements'].label}
                multiline
                minRows={2}
                name="mobility_requirements"
                value={this.state.formData.mobility_requirements}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                label={schema['dietary_requirements'].label}
                multiline
                minRows={2}
                name="dietary_requirements"
                value={this.state.formData.dietary_requirements}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>

            <Box>
              <TextField
                label={schema['conditions'].label}
                multiline
                minRows={2}
                name="conditions"
                value={this.state.formData.conditions}
                onChange={this.handleFieldChange}
                fullWidth
              />
            </Box>
          </Stack>

          <Divider />

          <Box>
            <TextField
              label="Additional Requests"
              multiline
              minRows={2}
              name="requests"
              value={this.state.formData.requests}
              onChange={this.handleFieldChange}
              fullWidth
            />
          </Box>

          <Divider />

          <Stack direction="column" spacing={2}>
            <Typography fontWeight="bold">Accommodation Details</Typography>

            <TextField
              label={schema['pre_tour_accommodation'].label}
              multiline
              minRows={2}
              name="pre_tour_accommodation"
              value={this.state.formData.pre_tour_accommodation}
              onChange={this.handleFieldChange}
              fullWidth
            />

            <TextField
              multiline
              minRows={2}
              name="post_tour_accommodation"
              value={this.state.formData.post_tour_accommodation}
              onChange={this.handleFieldChange}
              label={schema['post_tour_accommodation'].label}
              fullWidth
            />
          </Stack>

          {this.state.tourFlightDetailsRequired && (
            <>
              <Divider />
              <div>
                <Typography>Flights</Typography>
                <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr">
                  <Box gridColumn="span 2">
                    {!this.state.traveller.flights_submitted && (
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant={this.state.displayFlightsForm === true ? 'contained' : 'text'}
                          onClick={() => this.handleDisplayFlightsForm(true)}
                        >
                          Yes
                        </Button>
                        <Button
                          variant={this.state.displayFlightsForm === false ? 'contained' : 'text'}
                          onClick={() => this.handleDisplayFlightsForm(false)}
                        >
                          No
                        </Button>
                      </Stack>
                    )}
                  </Box>
                  {this.state.displayFlightsForm && (
                    <>
                      <Box>
                        <TextField
                          label={schema['arrival_date'].label}
                          fullWidth
                          type="date"
                          name="arrival_date"
                          value={this.state.formData.arrival_date}
                          onChange={this.handleFieldChange}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>

                      <Box>
                        <TextField
                          label={schema['arrival_time'].label}
                          fullWidth
                          type="text"
                          name="arrival_time"
                          placeholder="e.g 14:00"
                          value={this.state.formData.arrival_time}
                          onChange={this.handleFieldChange}
                        />
                      </Box>

                      <Box>
                        <TextField
                          label={schema['arrival_airport'].label}
                          fullWidth
                          type="text"
                          name="arrival_airport"
                          placeholder="e.g LAX"
                          value={this.state.formData.arrival_airport}
                          onChange={this.handleFieldChange}
                          inputProps={{
                            pattern: schema['arrival_airport'].pattern,
                          }}
                          error={this.state.errorFields.arrival_airport}
                          helperText={this.state.errorFields.arrival_airport ? 'Please enter 3 letters' : ''}
                        />
                      </Box>

                      <Box>
                        <TextField
                          label={schema['arrival_flight_number'].label}
                          fullWidth
                          type="text"
                          name="arrival_flight_number"
                          placeholder="e.g QF 1234"
                          value={this.state.formData.arrival_flight_number}
                          onChange={this.handleFieldChange}
                          inputProps={{
                            pattern: schema['arrival_flight_number'].pattern,
                          }}
                          error={this.state.errorFields.arrival_flight_number}
                          helperText={this.state.errorFields.arrival_flight_number ? 'Please enter 5/6 characters' : ''}
                        />
                      </Box>

                      <Box>
                        <TextField
                          label={schema['departure_date'].label}
                          fullWidth
                          type="date"
                          name="departure_date"
                          value={this.state.formData.departure_date}
                          onChange={this.handleFieldChange}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>

                      <Box>
                        <TextField
                          label={schema['departure_time'].label}
                          fullWidth
                          type="text"
                          name="departure_time"
                          placeholder="e.g 14:00"
                          value={this.state.formData.departure_time}
                          onChange={this.handleFieldChange}
                        />
                      </Box>

                      <Box>
                        <TextField
                          label={schema['departure_airport'].label}
                          fullWidth
                          type="text"
                          name="departure_airport"
                          placeholder="e.g LAX"
                          value={this.state.formData.departure_airport}
                          onChange={this.handleFieldChange}
                          inputProps={{
                            pattern: schema['departure_airport'].pattern,
                          }}
                          error={this.state.errorFields.departure_airport}
                          helperText={this.state.errorFields.departure_airport ? 'Please enter 3 letters' : ''}
                        />
                      </Box>

                      <Box>
                        <TextField
                          label={schema['departure_flight_number'].label}
                          fullWidth
                          type="text"
                          name="departure_flight_number"
                          placeholder="e.g QF 1234"
                          error={this.state.errorFields.departure_flight_number}
                          helperText={
                            this.state.errorFields.departure_flight_number ? 'Please enter 5/6 characters' : ''
                          }
                          value={this.state.formData.departure_flight_number}
                          onChange={this.handleFieldChange}
                          inputProps={{
                            pattern: schema['departure_flight_number'].pattern,
                          }}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </div>
            </>
          )}

          {this.state.sending && <Spinner />}

          {errors.length > 0 && (
            <Alert severity="error">
              {errors.map((e, id) => (
                <p key={id}>{e}</p>
              ))}
            </Alert>
          )}

          {this.state.success > 0 && <Alert severity="success">Success</Alert>}

          <Stack direction="row-reverse" spacing={1}>
            <Button variant="contained" type="button" onClick={this.saveTraveller}>
              Save
            </Button>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </Stack>
      </form>
    );
  }
}

TravellerForm.contextTypes = {
  user: PropTypes.object,
};
