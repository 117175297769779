import { RefundMetadata } from '../types';

export default function getPricePerNightMap(
  refundMetadata: RefundMetadata,
  dateRange: Array<Date>,
): { [key: string]: number } {
  const priceDict = {};

  //  NOTE: we don't want to patch the ability to refund more nights than exist in the order
  const cashAmount = refundMetadata.item_metadata.cash_amount ?? 0;
  const totalNights = refundMetadata.total_nights ?? 1;
  const averagePrice = Number(cashAmount / totalNights);

  dateRange.forEach((date) => {
    priceDict[date.toISOString()] = averagePrice;
  });
  return priceDict;
}
