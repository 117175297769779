export const getUpdatedPriceDiff = (
  isLPMember: boolean,
  platform: string,
  pricesDiff: { totalPriceDiff: number; luxPlusPriceDiff: number; mobilePriceDiff: number },
) => {
  if (isLPMember) {
    return pricesDiff.luxPlusPriceDiff;
  }

  if (platform === 'mobile') {
    return pricesDiff.mobilePriceDiff;
  }

  return pricesDiff.totalPriceDiff;
};
