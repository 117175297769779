import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Alert } from '@mui/material';

import OrderSearchForm, { MultiFilterState } from '~/components/Common/Forms/OrderSearchForm';
import Spinner from '~/components/Common/Spinner';
import { withTenant } from '~/components/hoc';

import { ITEM_TYPE_INSURANCE } from '~/consts/order';
import { ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';
import useQuery from '~/hooks/useQuery';

import { getPurchases } from '~/services/OrdersService';

import { addQuery, removeQuery } from '~/utils/url';

import InsurancesPage from './InsurancesPage';

const DEFAULT_SIZE_PER_PAGE = 10;
const SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE = 50;

interface Props {
  tenant: App.Tenant;
}

function InsurancesPageContainer({ tenant }: Props) {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const { user } = useCurrentUser();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [purchases, setPurchases] = useState(null);
  const [filterField, setFilterField] = useState(null);
  const [filterValue, setFilterValue] = useState(null);
  const [additionalSearchParams, setAdditionalSearchParams] = useState<MultiFilterState>(null);
  const [sizePerPage, setSizePerPage] = useState(DEFAULT_SIZE_PER_PAGE);

  const currentPage = parseInt(query.get('page')) || 1;
  const customerId = query.get('customer_id');

  const searchQuery = (field, value) => {
    setFilterField(field);
    setFilterValue(value);

    const newLocation = removeQuery(location, 'customer_id', 'page');

    history.push(newLocation);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setPurchases(null);

    if (user?.roles.includes(ROLE_ICS_STORE_TEAM)) {
      // we don't want these users to be able to see all results so
      // if there are no filters selected, just return empty
      if (!filterField || !filterValue) {
        setPurchases({
          result: [],
          total: 0,
        });
        setIsLoading(false);
        return;
      }
    }

    try {
      const fetchedPurchases = await getPurchases({
        page: currentPage,
        per_page: sizePerPage,
        brand: tenant.brand,
        customer_id: customerId,
        filterBy: filterField,
        filterValue,
        additionalFilter: additionalSearchParams,
        item_type: ITEM_TYPE_INSURANCE,
      });

      setPurchases(fetchedPurchases);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }, [
    user?.roles,
    filterField,
    filterValue,
    currentPage,
    sizePerPage,
    tenant.brand,
    customerId,
    additionalSearchParams,
  ]);

  const onPageChange = (page) => {
    const newLocation = addQuery(location, { page });
    history.push(newLocation);
  };

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [fetchData, user]);

  useEffect(() => {
    if (customerId) {
      setSizePerPage(SEARCH_BY_CUSTOMER_ID_SIZE_PER_PAGE);
    } else {
      setSizePerPage(DEFAULT_SIZE_PER_PAGE);
    }
  }, [customerId]);

  let content;

  if (isLoading) {
    content = <Spinner />;
  } else if (error) {
    content = <Alert severity="warning">Failed to load data</Alert>;
  } else {
    content = (
      <InsurancesPage
        customerEmail={query.get('customer_email')}
        onPageChange={onPageChange}
        page={currentPage}
        purchases={purchases?.result}
        rerender={fetchData}
        sizePerPage={sizePerPage}
        total={purchases?.total}
      />
    );
  }

  return (
    <>
      <OrderSearchForm
        bookingIdType={null}
        hideReferenceNumberFilter
        setAdditionalParams={setAdditionalSearchParams}
        searchQuery={searchQuery}
        showInsuranceIdFilter
      />
      {content}
    </>
  );
}

export default withTenant(InsurancesPageContainer);
