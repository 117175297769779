import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import { GoogleAdsContainer } from '~/components/GoogleAds/GoogleAdsContainer';

import { ROLE_PRIZE_ADMIN_USER } from '~/consts/roles';
import tenants from '~/consts/tenant';

import ChannelMarkupContainer from '../ChannelMarkup/ChannelMarkup';
import PageHeader from '../Common/Elements/PageHeader';
import ContentfulFormsContainer from '../ContentfulForms/ContentfulFormsContainer';
import BlackhawkGiftCardLogsContainer from '../GiftCards/GiftCardLogs/BlackhawkGiftCardLogsContainer';
import GiftCardLogsContainer from '../GiftCards/GiftCardLogs/GiftCardLogsContainer';
import GiftCardsContainer from '../GiftCards/GiftCards/GiftCardsContainer';
import AgentCommissions from '../LeAgentHub/components/Commission/AgentCommissions';
import AgentQuotes from '../LeAgentHub/components/Quotes/AgentQuotes';
import BundledContainer from '../Promo/BundledContainer';
import InAppMessageContainer from '../Promo/InAppMessageContainer';
import PromoRequestContainer from '../Promo/PromoRequests/PromoRequestContainer';
import PromoSearchContainer from '../Promo/PromoSearchContainer';
import PromoTesterContainer from '../Promo/PromoTester/PromoTesterContainer';
import ReferralLogs from '../Promo/ReferralLogs';
import PromoContainerV3 from '../PromoV3/PromoContainer';

import BadgesContainer from './Badges/BadgesContainer';
import CompedPackagesContainer from './CompedPackages/CompedPackagesContainer';
import SecureUrlContainer from './SecureUrl/SecureUrlContainer';
import Tags from './Tags';
import TemplatesContainer from './Templates/TemplatesContainer';

const CREATE_PROMO_TAB = 'create-promo';
const COMPETITION_TAB = 'competitions';
const COMPED_TAB = 'comped';

const tabsMap = {
  'agent-commissions': {
    label: 'Agent Commissions',
    component: AgentCommissions,
    tenants: new Set([tenants.leagenthub.value]),
  },
  'agent-quotes': {
    label: 'Agent Quotes',
    component: AgentQuotes,
    tenants: new Set([tenants.leagenthub.value]),
  },
  [CREATE_PROMO_TAB]: {
    label: 'Create Promo Codes',
    component: PromoContainerV3,
  },
  'search-promo': {
    label: 'Search Promo Codes',
    component: PromoSearchContainer,
  },
  'promo-requests': {
    label: 'Promo Requests',
    component: PromoRequestContainer,
  },
  'in-app-messaging': {
    label: 'In App Promo Messaging',
    component: InAppMessageContainer,
  },
  bundles: {
    label: 'Bundles',
    component: BundledContainer,
  },
  'campaign-tags': {
    label: 'Campaign Tags',
    component: Tags,
  },
  badges: {
    label: 'Badges',
    component: BadgesContainer,
  },
  templates: {
    label: 'Templates',
    component: TemplatesContainer,
  },
  hash: {
    label: 'Secure Url for Headers',
    component: SecureUrlContainer,
  },
  [COMPED_TAB]: {
    label: 'Famils and competition winners',
    component: CompedPackagesContainer,
  },
  referralLogs: {
    label: 'Referral Logs',
    component: ReferralLogs,
  },
  'gift-cards': {
    label: 'Gift Cards',
    component: GiftCardsContainer,
  },
  'gift-card-logs': {
    label: 'Gift Card Logs',
    component: GiftCardLogsContainer,
  },
  'blackhawk-gift-card-logs': {
    label: 'Blackhawk Logs',
    component: BlackhawkGiftCardLogsContainer,
  },
  'promo-tester': {
    label: 'Promo Tester',
    component: PromoTesterContainer,
  },
  'google-ads': {
    label: 'Google Ads',
    component: GoogleAdsContainer,
  },
  'channel-markup': {
    label: 'Channel Markup',
    component: ChannelMarkupContainer,
  },
  'contentful-forms': {
    label: 'Forms',
    component: ContentfulFormsContainer,
  },
} as const;

type Tab = keyof Partial<typeof tabsMap> | undefined;

export default function MarketingPage(props: RouteComponentProps<{ tab: Tab }>, context: { user: App.User }) {
  const key = props.match.params.tab || CREATE_PROMO_TAB;

  const currentTab = tabsMap[key];

  const brand = useSelector((state: App.State) => state.tenant);

  const tabsList = useMemo(() => {
    return Object.keys(tabsMap).filter((key) => !tabsMap[key].tenants || tabsMap[key].tenants.has(brand.value));
  }, [brand]);

  const { user } = context;

  const title = `Marketing / ${currentTab.label}`;

  const isPrizeAdmin = useMemo(() => {
    return user && user.roles.includes(ROLE_PRIZE_ADMIN_USER);
  }, [user]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader title={title} />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={key}>
            {tabsList.map((tab) => (
              <Tab key={tab} value={tab} label={tabsMap[tab].label} to={`/marketing/${tab}`} component={Link} />
            ))}
          </Tabs>
        </Box>
      </Container>

      <Box mt={4} sx={{ maxWidth: '2xl', padding: '0 3rem', margin: '1rem' }}>
        <Box component={currentTab.component} />
      </Box>
    </>
  );
}

MarketingPage.contextTypes = {
  user: PropTypes.object,
};
