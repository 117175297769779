import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';
import { CruiseInclusion } from '~/components/Cruises/pages/Inclusions/types';

import inclusionsService from '~/services/cruises/InclusionsService';

import InclusionForm from '../components/InclusionForm';

import InclusionsFilterList from './InclusionsFilter/InclusionsFilterList';
import InclusionsItemList from './InclusionsItem/InclusionsItemList';

function InclusionsCreate() {
  const [inclusion, setInclusion] = useState<CruiseInclusion | null>(null);
  const [selectedCruiseLineId, setSelectedCruiseLineId] = useState<string | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  const { inclusionId, tab } = useParams<{ inclusionId: string; tab?: string }>();

  useEffect(() => {
    const fetchInclusion = async () => {
      setFetching(true);

      const inclusion = await inclusionsService.getById(inclusionId);
      setInclusion(inclusion);
      setSelectedCruiseLineId(inclusion.fkVendorId);

      setFetching(false);
    };

    fetchInclusion();
  }, [inclusionId, setInclusion, setFetching]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Edit Inclusion</title>
      </Helmet>

      <PageHeader title="Edit Inclusion" backButton="/cruises/inclusions" />

      {fetching && <Spinner size={30} />}

      {inclusion && !fetching && (
        <Box mt={1}>
          <Box>
            <InclusionForm inclusion={inclusion} setSelectedCruiseLineId={setSelectedCruiseLineId} />
          </Box>
          <Box mt={3} border={1} borderColor="divider" borderRadius={1}>
            <Tabs value={tab} variant="scrollable">
              <Tab
                value="items"
                label="Inclusion Items"
                to={`/cruises/inclusions/edit/${inclusionId}/items`}
                component={Link}
              />
              <Tab
                value="filters"
                label="Inclusion Filters"
                to={`/cruises/inclusions/edit/${inclusionId}/filters`}
                component={Link}
              />
            </Tabs>

            <Box borderTop={1} borderColor="divider">
              {tab === 'items' && <InclusionsItemList inclusionType={inclusion.type} />}
              {tab === 'filters' && <InclusionsFilterList vendorId={selectedCruiseLineId} />}
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default InclusionsCreate;
