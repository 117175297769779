import React, { useMemo, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { getDetailedReason } from '~/consts/refund';

import { CollectionReason } from '../../types';

interface Props {
  reason: CollectionReason;
  defaultComment?: string;
  defaultCaseId?: string;
  commentPlaceholder?: string;
  commentLabel?: string;
}

export default function CaseIDAndReasons({
  reason,
  defaultComment = '',
  defaultCaseId = '',
  commentPlaceholder = 'Brief explanation of why the customer is requesting a refund',
  commentLabel = "Customer's Reason",
}: Props) {
  const [refundSubtype, setRefundSubtype] = useState('');
  const detailedReasonTypes = useMemo(() => {
    return getDetailedReason(reason);
  }, [reason]);

  return (
    <>
      <Stack spacing={2}>
        <Box>
          <Typography fontWeight="bold">{reason?.label ?? reason.key}</Typography>
          <Typography>{reason.description}</Typography>
        </Box>
        <TextField
          data-cy="CustomerReason"
          defaultValue={defaultComment}
          fullWidth
          label={commentLabel}
          minRows={3}
          multiline
          name="comment"
          placeholder={commentPlaceholder}
          required
        />
      </Stack>

      <Stack gap={1} mt={1}>
        <Box>
          <TextField data-cy="CaseID" defaultValue={defaultCaseId} name="caseId" placeholder="Case ID" required />
        </Box>

        {detailedReasonTypes && (
          <Grid container>
            <Grid xs={8}>
              <FormControl fullWidth>
                <InputLabel id="refund-subtype-label">Refund Subtype</InputLabel>
                <Select
                  labelId="refund-subtype-label"
                  name="refundSubtype"
                  id="refund_subtype"
                  fullWidth
                  label="Refund Subtype"
                  defaultValue={null}
                  value={refundSubtype}
                  onChange={(e) => {
                    setRefundSubtype(e.target.value);
                  }}
                >
                  {Object.keys(detailedReasonTypes).map((reasonType, index) => (
                    <MenuItem key={index} value={reasonType}>
                      <Grid direction="row" justifyContent="space-between" container width="100%">
                        <Grid xs={11} alignContent="center">
                          <Typography>{reasonType}</Typography>
                        </Grid>

                        <Grid xs={1}>
                          <Tooltip title={detailedReasonTypes[reasonType]}>
                            <IconButton size="small">
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
                  <MenuItem key="no-selection" value={null}>
                    None
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Stack>
    </>
  );
}
