import React, { useEffect, useState } from 'react';

import dayjs from '~/timeInit';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import ReservationService from '../../../services/ReservationService';
import Spinner from '../../Common/Spinner';

interface IOrderItem {
  reservation?: {
    id: string;
    has_vcc?: boolean;
  };
}

interface IReservations {
  [key: string]: {
    vcc_reservation: IVCCReservation;
  };
}

interface IVCCReservation {
  card_id: string;
  currency: string;
  deposit_amount: number;
  deposit_percent: number;
  deposit_state: string;
  total: number;
  type_of_pay: string;
}

interface OrderDetailProps {
  items: IOrderItem[];
  reservationsFetched: boolean;
  reservations: IReservations;
}

const dateFormatter = (cell) => {
  const date = new Date(cell);
  return dayjs(date).format('Do MMM YYYY HH:mm:ss');
};

const debugFormatter = (cell) => {
  return JSON.stringify(cell);
};

function Row(props: { card }) {
  const { card } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{card.pan}</TableCell>
        <TableCell align="right">{card.expiry}</TableCell>
        <TableCell align="right">{card.validityFrom}</TableCell>
        <TableCell align="right">{card.validityTo}</TableCell>
        <TableCell size="small" align="right">
          {card.limit}
        </TableCell>
        <TableCell size="small" align="right">
          {card.balance}
        </TableCell>
        <TableCell size="small" align="right">
          {card.state}
        </TableCell>
        <TableCell size="small">{debugFormatter(card.customFields)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="purchases">
                  <TableHead
                    sx={{
                      backgroundColor: 'grey.200',
                    }}
                  >
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                          Created At
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                          Old state
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                          New state
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                          Attributes
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {card.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {dateFormatter(historyRow.createdAt)}
                        </TableCell>
                        <TableCell>{historyRow.oldState}</TableCell>
                        <TableCell align="left">{historyRow.newState}</TableCell>
                        <TableCell align="left">{debugFormatter(historyRow.customFields)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function isFulfilled<T>(val: PromiseSettledResult<T>): val is PromiseFulfilledResult<T> {
  return val.status === 'fulfilled';
}

function fetchCardsHistory<T>(reservationId: string) {
  return ReservationService.getVirtualCreditCardsHistory(reservationId) as Promise<T>;
}

const columns: GridColDef[] = [
  {
    field: 'card_id',
    headerName: 'Card ID',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'total',
    headerName: 'Total',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'currency',
    headerName: 'Currency',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'type_of_pay',
    headerName: 'Type of pay',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'deposit_amount',
    headerName: 'Deposit Amount',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'deposit_percent',
    headerName: 'Deposit Percent',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'deposit_state',
    headerName: 'Deposit State',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
];

interface ICardResponse {
  status: number;
  message: string;
  result: ICard;
}

interface ICard {
  id: string;
  pan: string;
  expiry: string;
  limit: number;
  validityFrom: string;
  validityTo: string;
  state: string;
  customFields: CustomFields;
}

interface CustomFields {
  bookingNumber: string;
  checkInDate: string;
  reference: number;
  guestName: string;
  supplierEmail: string;
  orderCurrency?: string;
  paymentMethod?: string;
}

const OrderDetailPage: React.FC<OrderDetailProps> = ({ items, reservationsFetched, reservations }) => {
  const [cards, setCards] = useState<ICard[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const getVCCPromises = items
      .filter((item) => item.reservation && item.reservation?.has_vcc)
      .map((item) => fetchCardsHistory<ICardResponse>(item.reservation.id).then((response) => response.result));
    Promise.allSettled(getVCCPromises)
      .then((result) => result.filter(isFulfilled).map((i) => i.value))
      .then((data) => setCards(data))
      .catch(() => setError('Could not load VCC history'));
  }, [items]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  if (!cards || !reservationsFetched) {
    return <Spinner />;
  }
  if (cards.length === 0) {
    return null;
  }

  const vccReservations = [];
  Object.values(reservations)
    .filter((reservation) => reservation && Object.keys(reservation).length > 0)
    .map((reservation) => {
      if (reservation?.vcc_reservation && Object.keys(reservation.vcc_reservation).length > 0) {
        vccReservations.push(reservation.vcc_reservation);
      }
    });

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        sx={{
          backgroundColor: 'grey.200',
          height: '60px',
        }}
      >
        <Typography>Virtual Credit Cards</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {vccReservations.length > 0 && (
          <DataGrid
            columns={columns}
            rows={vccReservations}
            getRowId={(row) => row.card_id}
            autoHeight
            pageSizeOptions={[]}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={() => 'auto'}
          />
        )}
        <TableContainer component={Paper}>
          <Table
            sx={{
              width: '100%',
            }}
            aria-label="collapsible table"
          >
            <TableHead
              sx={{
                backgroundColor: 'grey.200',
              }}
            >
              <TableRow>
                <TableCell size="small" />
                <TableCell size="small">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Pan
                  </Typography>
                </TableCell>
                <TableCell align="left" size="small">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Expiry
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                    Validity From
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                    Validity To
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Limit
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Balance
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold">
                    State
                  </Typography>
                </TableCell>
                <TableCell align="left" size="small">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Attributes
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cards.map((card) => (
                <Row key={card.id} card={card} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrderDetailPage;
