import React, { createRef, useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Box, Button, Container, Stack, Tab, Tabs } from '@mui/material';

import AccommodationAddPropertyProgressModal from '~/components/Accommodation/Pages/Properties/components/AccommodationAddPropertyProgressModal';
import { AccommodationPropertiesResultsTableRef } from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesResultsTable';
import PageHeader from '~/components/Common/Elements/PageHeader';

import useToggleState from '~/hooks/useToggleState';

import { InternalService, ValidationErrorCodes, importProperty } from '~/services/AccommodationService';

import AccommodationProperties from '../Properties/AccommodationProperties';

export enum AccommodationTabs {
  PROPERTIES = 'properties',
  UNMAPPED_SUPPLIERS = 'unmapped_suppliers',
}

export default function AccommodationHomePage() {
  const [selectedTab, setSelectedTab] = useState(
    (window.location.hash?.replace('#', '') as AccommodationTabs) ?? AccommodationTabs.PROPERTIES,
  );

  useEffect(() => {
    if (window.location.hash?.replace('#', '') === '') {
      window.location.hash = AccommodationTabs.PROPERTIES;
      setSelectedTab(AccommodationTabs.PROPERTIES);
    }
  }, []);

  const {
    isToggled: isAddPropertyModalOpen,
    toggleOn: setOpenAddPropertyModal,
    toggleOff: setCloseAddPropertyModal,
  } = useToggleState(false);
  const propertiesTableRef = createRef<AccommodationPropertiesResultsTableRef>();

  const refreshProperties = useCallback(() => {
    propertiesTableRef.current?.refresh();
  }, [propertiesTableRef]);

  const closeAddPropertyModal = useCallback(() => {
    setCloseAddPropertyModal();
    refreshProperties();
  }, [refreshProperties, setCloseAddPropertyModal]);

  const startImportProperty = (
    propertyId: string,
    internalService: InternalService,
    validationOverrides?: Array<ValidationErrorCodes>,
  ) => {
    return importProperty({ propertyId, internalService, validationOverrides });
  };

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: AccommodationTabs) => {
    setSelectedTab(newValue);
    window.location.hash = newValue;
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <Helmet>
          <title>Accommodation - Properties</title>
        </Helmet>
        <PageHeader title="Accommodation - Properties" />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Tabs sx={{ mb: 2 }} value={selectedTab} onChange={handleTabChange}>
            <Tab key={AccommodationTabs.PROPERTIES} value={AccommodationTabs.PROPERTIES} label="Properties" />
            <Tab
              key={AccommodationTabs.UNMAPPED_SUPPLIERS}
              value={AccommodationTabs.UNMAPPED_SUPPLIERS}
              label="Unmapped Suppliers"
            />
          </Tabs>
          <Box display="flex" alignItems="right">
            <Button variant="text" size="small" onClick={setOpenAddPropertyModal}>
              Import Property
            </Button>
          </Box>
        </Stack>
        <AccommodationProperties
          selectedTab={selectedTab}
          ref={propertiesTableRef}
          refreshProperties={refreshProperties}
        />
      </Container>
      <AccommodationAddPropertyProgressModal
        open={isAddPropertyModalOpen}
        onClose={closeAddPropertyModal}
        apiCall={startImportProperty}
      />
    </>
  );
}
