import React, { FormEventHandler, useState } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';

import { VARIANT_OF_REFUND_SURCHARGE } from '~/consts/refund';

import { CollectionReason } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';
import MarkCancelled from '../Common/MarkCancelled';

import SelectRefundVariantWithSurcharge from './SelectRefundVariantWithSurcharge';

interface Props {
  currencyCode?: string;
  decrementStep: () => void;
  defaultCaseId: string;
  defaultComment: string;
  defaultVccChecked: boolean;
  hasSurcharge?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
  reason: CollectionReason;
  surchargeAmount?: number;
}

export default function PackageAmendment({
  currencyCode,
  decrementStep,
  defaultCaseId,
  defaultComment,
  defaultVccChecked,
  hasSurcharge = false,
  onSubmit,
  reason,
  surchargeAmount,
}: Props) {
  const [variantOfRefund, setVariantOfRefund] = useState<string>();

  return (
    <>
      <DialogContent dividers>
        <form onSubmit={onSubmit} id="package-amendment-form">
          <CaseIDAndReasons reason={reason} defaultComment={defaultComment} defaultCaseId={defaultCaseId} />
          <SelectRefundVariantWithSurcharge
            defaultVccChecked={defaultVccChecked}
            hasSurcharge={hasSurcharge}
            currencyCode={currencyCode}
            surchargeAmount={surchargeAmount}
            variantOfRefund={variantOfRefund}
            setVariantOfRefund={setVariantOfRefund}
          />
          {variantOfRefund !== VARIANT_OF_REFUND_SURCHARGE && <MarkCancelled />}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained" form="package-amendment-form">
          Continue
        </Button>
      </DialogActions>
    </>
  );
}
