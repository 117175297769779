import React, { useCallback, useState } from 'react';

import sum from 'lodash/sum';

import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';

import NumberFormatCustom from '~/components/Common/ReactNumberFormat/NumberFormatCustom';
import { RefundMetadata } from '~/components/Refund/types';

import currencyFormatter from '~/utils/currencyFormatter';

import { getCurrencySymbol } from '../../Utils/RefundUtils';

interface Props {
  refundMetadata: RefundMetadata;
  numberOfNights: number;
  currencyCode: string;
  formRef: any;
  handleLateChangeOfMindFieldChange: any;
}

function generateNightLabels(numberOfNights: number): Array<string> {
  const nightLabels = [];
  for (let i = 0; i < numberOfNights; i++) {
    nightLabels.push(`Night ${i + 1}`);
  }
  return nightLabels;
}

function getPricePerNightMap(refundMetadata: RefundMetadata, nightsRange: Array<string>): { [key: string]: number } {
  const pricePerNightObj = {};

  //  NOTE: we don't want to patch the ability to refund more nights than exist in the order
  const cashAmount = refundMetadata.item_metadata.cash_amount ?? 0;
  const merchantFee = refundMetadata.merchant_fee_amount ?? 0;
  const totalNights = refundMetadata.total_nights ?? 1;
  const averagePrice = Number((cashAmount + merchantFee) / totalNights);

  nightsRange.forEach((date) => {
    pricePerNightObj[date] = averagePrice;
  });
  return pricePerNightObj;
}

export default function BNBLSelectNights({
  refundMetadata,
  numberOfNights,
  currencyCode,
  formRef,
  handleLateChangeOfMindFieldChange,
}: Props) {
  const nightsRange = generateNightLabels(numberOfNights);
  const pricesPerNight = getPricePerNightMap(refundMetadata, nightsRange);
  const [checkedDates, setCheckedDates] = useState<Set<string>>(new Set());
  const [refundToCustomer, setRefundToCustomer] = useState(0);

  const handleCheckboxChange = (night, event) => {
    const formData = new FormData(formRef.current);
    const arrCopy = new Set(checkedDates);

    if (event.target.checked) {
      // Add night to checkedDates if checkbox is checked
      arrCopy.add(night);
    } else {
      // Remove night from checkedDates if checkbox is unchecked
      arrCopy.delete(night);
    }

    const totalPricesPerNight = Array.from(arrCopy).map((night) => {
      return Number(pricesPerNight[night]);
    });
    setCheckedDates(arrCopy);
    setRefundToCustomer(sum(totalPricesPerNight));

    formData.set('totalRefundedToCustomer', sum(totalPricesPerNight).toString());
    formData.set('numberOfNights', arrCopy.size.toString());
    handleLateChangeOfMindFieldChange(formData);
  };

  const handleTotalRefundedToCustomerChange = useCallback(
    (e) => {
      const formData = new FormData(formRef.current);
      setRefundToCustomer(parseFloat(e.target.value));
      formData.set('totalRefundedToCustomer', e.target.value);
      handleLateChangeOfMindFieldChange(formData);
    },
    [formRef, handleLateChangeOfMindFieldChange],
  );

  const handleSelectAllNights = () => {
    const formData = new FormData(formRef.current);
    const arrCopy = new Set(nightsRange);
    const totalPricesPerNight = Array.from(arrCopy).map((date) => {
      return Number(pricesPerNight[date]);
    });
    setCheckedDates(arrCopy);
    const totalPricesSum = sum(totalPricesPerNight);
    setRefundToCustomer(totalPricesSum);

    formData.set('totalRefundedToCustomer', totalPricesSum.toString());
    formData.set('numberOfNights', arrCopy.size.toString());
    handleLateChangeOfMindFieldChange(formData);
  };

  const handleSelectNoNights = () => {
    const formData = new FormData(formRef.current);
    const arrCopy = new Set<string>();
    const totalPricesPerNight = [];
    const totalPricesSum = sum(totalPricesPerNight);
    setCheckedDates(arrCopy);
    setRefundToCustomer(totalPricesSum);

    formData.set('totalRefundedToCustomer', totalPricesSum.toString());
    formData.set('numberOfNights', arrCopy.size.toString());
    handleLateChangeOfMindFieldChange(formData);
  };

  const handleAllNightsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      handleSelectAllNights();
    } else {
      handleSelectNoNights();
    }
  };

  return (
    <Box mt={3}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedDates.size === nightsRange.length}
              indeterminate={checkedDates.size !== 0 && checkedDates.size !== nightsRange.length}
              size="small"
              onChange={handleAllNightsChange}
            />
          }
          label="All Nights"
        />
      </FormGroup>

      <Stack spacing={2}>
        <FormGroup>
          {nightsRange.map((night, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    data-cy={`date-range-${index}`}
                    checked={checkedDates.has(night)}
                    onChange={(e) => handleCheckboxChange(night, e)}
                  />
                }
                key={night}
                label={
                  <Grid container width="320px" justifyContent="space-between">
                    <Grid>
                      <Typography>{night}</Typography>
                    </Grid>
                    <Grid>
                      <Typography>{currencyFormatter(currencyCode, pricesPerNight[night], 2)}</Typography>
                    </Grid>
                  </Grid>
                }
                sx={{
                  // this overrides the tall mui checkbox
                  height: '27px',
                }}
              />
            );
          })}
        </FormGroup>
        <Grid container pl={2}>
          <Grid sm={5} alignContent="center">
            <Typography fontWeight="bold">
              {checkedDates.size} / {nightsRange.length} {nightsRange.length === 1 ? 'night' : 'nights'} selected
            </Typography>
            {/* Hidden input to hold the numberOfNights tab value */}
            <input type="hidden" name="numberOfNights" value={checkedDates.size} />
          </Grid>
          <Grid sm={5}>
            <Stack>
              <Typography fontWeight="bold">Total Refunded To Customer</Typography>
              <NumberFormatCustom
                value={refundToCustomer}
                onChange={handleTotalRefundedToCustomerChange}
                size="small"
                data-cy="RefundToCustomerAmount"
                prefix={getCurrencySymbol(currencyCode)}
              />
              {/* Hidden input to hold the totalRefundedToCustomer tab value */}
              <input type="hidden" name="totalRefundedToCustomer" value={refundToCustomer} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
