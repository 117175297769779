import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';

import UpdateInsuranceModal from './UpdateInsuranceModal';

type Props = {
  order: App.Order;
  currentInsurance: App.InsuranceItem;
  onSuccess: () => void;
  refunds: {
    status: number;
    result: Array<{ accounting_amount: string }>;
  };
};

export default function UpdateInsurance({ currentInsurance, order, refunds, onSuccess }: Props) {
  const [showModal, setShowModal] = useState(false);
  const [totalTicketPrice, setTotalTicketPrice] = useState(0);
  const [currentOrderAmount, setCurrentOrderAmount] = useState(0);
  const refundsResult = refunds?.result;

  useEffect(() => {
    const insurancesTotal = order.insurance_items
      .filter((i) => i.status_reason !== 'insurance_upgraded')
      .reduce((acc, curr) => acc + curr.total, 0);
    const orderTotal = Math.round((order.total - insurancesTotal) * 100) / 100;

    const orderAmount = parseFloat(
      (orderTotal - refundsResult?.reduce((acc, refund) => acc + Number(refund.accounting_amount), 0)).toFixed(2),
    );

    setCurrentOrderAmount(orderAmount);
    setTotalTicketPrice(currentInsurance.cover_amount);

    if (!currentInsurance.cover_amount) {
      setTotalTicketPrice(orderAmount);
    }
  }, []);

  const toggleModal = () => setShowModal(!showModal);

  const showUpdateButton =
    currentInsurance && currentInsurance.status !== 'cancelled' && currentInsurance.insurance_type !== 'cfmr';

  return (
    <>
      {showUpdateButton && (
        <>
          <Button onClick={toggleModal} variant="text">
            Update insurance
          </Button>

          <UpdateInsuranceModal
            totalTicketPrice={totalTicketPrice}
            currentOrderAmount={currentOrderAmount}
            currentInsurance={currentInsurance}
            order={order}
            show={showModal}
            onSuccess={onSuccess}
            onClose={toggleModal}
          />
        </>
      )}
    </>
  );
}
