import React from 'react';

import { CustomOfferFormValues } from '@/typings/formValues';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

import { Box, Checkbox, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import DateWidget from '../../Elements/DateWidget';
import { LE_ENTITIES } from '../constants';

interface Props {
  control: Control<any>;
  isSubmitting: boolean;
  setValue: UseFormSetValue<CustomOfferFormValues>;
}

export default function AccountsPayable({ control, isSubmitting, setValue }: Props) {
  return (
    <>
      <Box mt={2}>
        <Typography variant="h6">Accounts Payable</Typography>
      </Box>

      <Grid mt={2} container columns={6} spacing={2}>
        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.le_entity"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="LE Entity"
                helperText={error?.message}
                error={!!error}
                disabled={isSubmitting}
                select
                fullWidth
              >
                {LE_ENTITIES.map((entity) => (
                  <MenuItem key={entity} value={entity}>
                    {entity}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.refund_received"
            control={control}
            render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
              <DateWidget
                {...field}
                slotProps={{
                  textField: { helperText: error?.message, error: !!error },
                  field: {
                    clearable: true,
                  },
                }}
                disabled={isSubmitting}
                label="Refund Recieved"
              />
            )}
          />
        </Grid>

        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.commission_amount"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                helperText={error?.message}
                error={!!error}
                disabled={isSubmitting}
                label="Commission Amount - AUD"
                type="number"
                inputProps={{ step: 0.01 }}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.commission_received"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} color="primary" />}
                label="Commission Received"
              />
            )}
          />
        </Grid>

        <Grid xs={3} md={1}>
          <Controller
            name="payment_metadata.additional_vendor_payment"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                helperText={error?.message}
                error={!!error}
                disabled={isSubmitting}
                label="Additional Vendor Payment (LE Error)"
                type="number"
                inputProps={{ step: 0.01 }}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}
