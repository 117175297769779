import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import { Request as APIRequest } from '@luxuryescapes/contract-svc-tour/types/requests';

import { formatDateCalendar } from '~/services/TimeService';

interface FilterProps {
  tourOptions: Array<string>;
  departures: Array<string>;
  departuresMonth: Array<string>;
  showDiscountApplied: boolean;
}

interface Props {
  pricing: Array<APIRequest.TourOptionDeparturePackagePricingData>;
  filterPricing: FilterProps;
  onChange: (filteredPricing: FilterProps) => void;
}

export default function TourPricingFilters({ pricing, filterPricing, onChange }: Props) {
  const [filteredPricing, setFilteredPricing] = useState(filterPricing);

  const tourOptions = useMemo<Array<string>>(() => {
    return [...new Set(pricing.map((pricing) => pricing.tourOption))];
  }, [pricing]);

  const departures = useMemo<Array<string>>(() => {
    const departures = pricing
      .map((pricing) => pricing.departureDate)
      .filter((departure) => {
        if (filteredPricing.departuresMonth.length > 0) {
          const yearMonth = formatDateCalendar(new Date(departure));
          return filteredPricing.departuresMonth.includes(yearMonth);
        }
        return true;
      });
    return [...new Set(departures)];
  }, [pricing, filteredPricing]);

  const departuresMonth = useMemo<Array<string>>(() => {
    const departuresMonth = pricing.reduce((acc, price) => {
      const yearMonth = formatDateCalendar(new Date(price.departureDate));
      const existingMonthYear = acc.get(yearMonth) || [];
      acc.set(yearMonth, [...existingMonthYear, price.departureDate]);
      return acc;
    }, new Map());
    return Array.from(departuresMonth.keys());
  }, [pricing]);

  const onChangeMultipleSelect = useCallback(
    (event) => {
      const key = event.target.name;
      const updatedfilteredPricing = {
        ...filteredPricing,
      };
      if (event.target.value.includes('all')) {
        updatedfilteredPricing[key] = [];
      } else {
        updatedfilteredPricing[key] = event.target.value;
      }
      setFilteredPricing(updatedfilteredPricing);
      onChange(updatedfilteredPricing);
    },
    [onChange, filteredPricing, setFilteredPricing],
  );

  const onChangeShowDiscountedApplied = useCallback(() => {
    const updatedfilteredPricing = {
      ...filteredPricing,
      showDiscountApplied: !filteredPricing.showDiscountApplied,
    };
    setFilteredPricing(updatedfilteredPricing);
    onChange(updatedfilteredPricing);
  }, [onChange, filteredPricing, setFilteredPricing]);

  return (
    <Box>
      <Stack direction="row" gap={4}>
        <Typography variant="h5">Filters</Typography>
        <FormControl sx={{ width: 200 }}>
          <InputLabel>Tour option</InputLabel>
          <Select
            name="tourOptions"
            multiple
            value={filteredPricing.tourOptions}
            label="Tour option"
            onChange={onChangeMultipleSelect}
          >
            {filteredPricing.tourOptions.length > 0 && (
              <MenuItem key="all" value="all">
                Select all
              </MenuItem>
            )}
            {tourOptions.map((tourOption) => (
              <MenuItem key={tourOption} value={tourOption}>
                {tourOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 200 }}>
          <InputLabel>Departure month</InputLabel>
          <Select
            name="departuresMonth"
            multiple
            value={filteredPricing.departuresMonth}
            label="Departure month"
            onChange={onChangeMultipleSelect}
          >
            {filteredPricing.departuresMonth.length > 0 && (
              <MenuItem key="all" value="all">
                Select all
              </MenuItem>
            )}
            {departuresMonth.map((departureMonth) => (
              <MenuItem key={departureMonth} value={departureMonth}>
                {departureMonth}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 200 }}>
          <InputLabel>Departure date</InputLabel>
          <Select
            name="departures"
            multiple
            value={filteredPricing.departures}
            label="Departure date"
            onChange={onChangeMultipleSelect}
          >
            {filteredPricing.departures.length > 0 && (
              <MenuItem key="all" value="all">
                Select all
              </MenuItem>
            )}
            {departures.map((departure) => (
              <MenuItem key={departure} value={departure}>
                {departure}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 300 }}>
          <FormControlLabel
            control={<Checkbox />}
            onChange={onChangeShowDiscountedApplied}
            checked={filteredPricing.showDiscountApplied}
            value={filteredPricing.showDiscountApplied}
            label="Show only discount/markup prices"
          />
        </FormControl>
      </Stack>
    </Box>
  );
}
