import qs from 'qs';

import { paths } from '@luxuryescapes/contract-svc-lux-loyalty';

import { arrayToObject } from '~/utils/arrayUtils';
import { isLuxLoyaltyEnabled } from '~/utils/luxLoyalty';

import { request } from './common';

type GetLuxLoyaltyAccountResponse =
  paths['/api/lux-loyalty/account/me']['get']['responses']['200']['content']['application/json']['result']['accountSummary'];

type GetLuxLoyaltyProgramConfigResponse =
  paths['/api/lux-loyalty/program-configuration']['get']['responses']['200']['content']['application/json']['result'];

const BASE_PATH = `${window.configs.API_HOST}/api/lux-loyalty`;

async function getLuxLoyaltyAccount(customerId: string): Promise<GetLuxLoyaltyAccountResponse | null> {
  if (!customerId || !isLuxLoyaltyEnabled()) {
    return null;
  }

  const queryParams = qs.stringify({ customerId });
  const uri = `${BASE_PATH}/account?${queryParams}`;

  const { result } = await request(uri, { method: 'GET' });
  const accountSummary = result.accountSummary;

  return accountSummary;
}

function mapLuxLoyaltyProgramConfig(response: GetLuxLoyaltyProgramConfigResponse): App.LuxLoyaltyProgramConfig {
  return {
    programName: response.programName,
    currencyName: response.currencyName,
    statusCreditsName: response.statusCreditsName,
    tierLabel: response.tierLabel,
    tiers: arrayToObject(response.tiers, (tier) => tier.id),
  };
}

async function getLuxLoyaltyProgramConfig(): Promise<GetLuxLoyaltyProgramConfigResponse | null> {
  if (!isLuxLoyaltyEnabled()) {
    return null;
  }

  const uri = `${BASE_PATH}/program-configuration`;

  const { result } = await request(uri, { method: 'GET' });
  return result;
}

export { BASE_PATH, getLuxLoyaltyAccount, getLuxLoyaltyProgramConfig, mapLuxLoyaltyProgramConfig };
