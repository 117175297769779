// PAYLOAD_DATE_FORMAT is the format of the date for creating items
export const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD';

export const ITEM_TYPE_OFFER = 'offer';
export const ITEM_TYPE_GIFT_CARD = 'gift_card';
export const ITEM_TYPE_INSURANCE = 'insurance';
export const ITEM_TYPE_ADDON = 'addon';
export const ITEM_TYPE_EXPERIENCE = 'experience';
export const ITEM_TYPE_FLIGHT = 'flight';
export const ITEM_TYPE_OFFLINE_FLIGHT = 'offline_flight';
export const ITEM_TYPE_TOUR = 'tour';
export const ITEM_TYPE_CRUISE = 'cruise';
export const ITEM_TYPE_CAR_HIRE = 'car_hire';
export const ITEM_TYPE_SERVICE_FEE = 'service_fee';
export const ITEM_TYPE_SUBSCRIPTION = 'subscription';
export const ITEM_TYPE_BEDBANK = 'bedbank';

export const EDIT_ORDER_TITLE = 'Edit Order';
export const PATCH_REPLACE_ORDER = 'replace';
export const ADD_NOTE = 'Add new note';

export const UPDATE_ORDER_GIFT = 'Update Gift';

export const ITEM_STATUS_CANCELLED = 'cancelled';
export const ITEM_STATUS_COMPLETED = 'completed';
export const ITEM_STATUS_FAILED = 'failed';

export const ORDER_STATUS_COMPLETED = 'completed';

export const OPERATION_UPDATE_ORDER_CUSTOM_ITEM = 'update_order_custom_item';
export const OPERATION_REBOOK_AS_CUSTOM_OFFER = 'rebook_as_custom_offer';
export const OPERATION_CANCEL_CUSTOM_ORDER_ADMIN = 'cancel_custom_order_admin';
export const OPERATION_SET_INSURANCE_CLAIM_ADMIN = 'set_insurance_claim_admin';
