import React from 'react';

import { Controller } from 'react-hook-form';

import { TableCell, TableRow } from '@mui/material';

import TourPercentagePriceField from '~/components/Tours/TourDetails/Forms/TourPercentagePriceField';

import currencyFormatter from '~/utils/currencyFormatter';

const CURRENCY_AUD = 'AUD';

interface Props {
  index: number;
  control: any;
  departurePricing: any;
  readonly: boolean;
}

function getColorBasePrice(originalPrice: number, price: number): string {
  if (price > originalPrice) {
    return '#43a047'; // green
  } else if (price < originalPrice) {
    return '#e53935'; // red
  }
  return '';
}

export default function TourPricingTableRow({ departurePricing, index, control, readonly }: Props) {
  return (
    <TableRow>
      <TableCell sx={{ borderLeft: 1, borderColor: 'grey.300' }}>{departurePricing.departureDate}</TableCell>
      <TableCell>{departurePricing.tourOption}</TableCell>
      {!departurePricing.rooms.twin && (
        <TableCell sx={{ borderLeft: 1, borderColor: 'grey.300' }} colSpan={6} align="center">
          No twin room pricing available
        </TableCell>
      )}
      {!!departurePricing.rooms.twin && (
        <>
          <TableCell sx={{ borderLeft: 1, borderColor: 'grey.300' }} align="right">
            {currencyFormatter(CURRENCY_AUD, departurePricing.rooms.twin.fullPrice)}
          </TableCell>
          <TableCell
            sx={{
              color: getColorBasePrice(
                departurePricing.rooms.twin.fullPrice,
                departurePricing.rooms.twin.discountPrice,
              ),
            }}
            align="right"
          >
            {currencyFormatter(CURRENCY_AUD, departurePricing.rooms.twin.discountPrice)}
          </TableCell>
          <TableCell sx={{ color: '#01579b' }} align="right">
            {currencyFormatter(CURRENCY_AUD, departurePricing.rooms.twin.memberPrice)}
          </TableCell>
          <TableCell align="right">
            {readonly && departurePricing.rooms.twin.discountPercentage}
            {!readonly && (
              <Controller
                name={`pricing.${index}.rooms.twin.discountPercentage`}
                control={control}
                render={({ field, fieldState }) => (
                  <TourPercentagePriceField {...field} onChange={field.onChange} fieldState={fieldState} />
                )}
              />
            )}
          </TableCell>
          <TableCell align="right">
            {readonly && departurePricing.rooms.twin.surchargePercentage}
            {!readonly && (
              <Controller
                name={`pricing.${index}.rooms.twin.surchargePercentage`}
                control={control}
                render={({ field, fieldState }) => (
                  <TourPercentagePriceField {...field} onChange={field.onChange} fieldState={fieldState} />
                )}
              />
            )}
          </TableCell>
          <TableCell align="right">
            {readonly && departurePricing.rooms.twin.luxPlusDiscount}
            {!readonly && (
              <Controller
                name={`pricing.${index}.rooms.twin.luxPlusDiscount`}
                control={control}
                render={({ field, fieldState }) => (
                  <TourPercentagePriceField {...field} onChange={field.onChange} fieldState={fieldState} />
                )}
              />
            )}
          </TableCell>
        </>
      )}
      {!departurePricing.rooms.single && (
        <TableCell sx={{ borderLeft: 1, borderRight: 1, borderColor: 'grey.300' }} colSpan={6} align="center">
          No single room pricing available
        </TableCell>
      )}
      {!!departurePricing.rooms.single && (
        <>
          <TableCell sx={{ borderLeft: 1, borderColor: 'grey.300' }} align="right">
            {currencyFormatter(CURRENCY_AUD, departurePricing.rooms.single.fullPrice)}
          </TableCell>
          <TableCell
            sx={{
              color: getColorBasePrice(
                departurePricing.rooms.single.fullPrice,
                departurePricing.rooms.single.discountPrice,
              ),
            }}
            align="right"
          >
            {currencyFormatter(CURRENCY_AUD, departurePricing.rooms.single.discountPrice)}
          </TableCell>
          <TableCell sx={{ color: '#01579b' }} align="right">
            {currencyFormatter(CURRENCY_AUD, departurePricing.rooms.single.memberPrice)}
          </TableCell>
          <TableCell align="right">
            {readonly && departurePricing.rooms.single.discountPercentage}
            {!readonly && (
              <Controller
                name={`pricing.${index}.rooms.single.discountPercentage`}
                control={control}
                render={({ field, fieldState }) => (
                  <TourPercentagePriceField {...field} onChange={field.onChange} fieldState={fieldState} />
                )}
              />
            )}
          </TableCell>
          <TableCell align="right">
            {readonly && departurePricing.rooms.single.surchargePercentage}
            {!readonly && (
              <Controller
                name={`pricing.${index}.rooms.single.surchargePercentage`}
                control={control}
                render={({ field, fieldState }) => (
                  <TourPercentagePriceField {...field} onChange={field.onChange} fieldState={fieldState} />
                )}
              />
            )}
          </TableCell>
          <TableCell sx={{ borderRight: 1, borderColor: 'grey.300' }} align="right">
            {readonly && departurePricing.rooms.single.luxPlusDiscount}
            {!readonly && (
              <Controller
                name={`pricing.${index}.rooms.single.luxPlusDiscount`}
                control={control}
                render={({ field, fieldState }) => (
                  <TourPercentagePriceField {...field} onChange={field.onChange} fieldState={fieldState} />
                )}
              />
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}
