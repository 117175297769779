import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import PageHeader from '../Common/Elements/PageHeader';
import FailedEmailContainer from '../FailedEmail/FailedEmail';

const tabsMap = {
  'failed-email': {
    label: 'Failed Email',
    component: FailedEmailContainer,
  },
} as const;

type TabType = keyof Partial<typeof tabsMap> | undefined;

export default function EmailPage({
  match: {
    params: { tab },
  },
}: RouteComponentProps<{ tab: TabType }>) {
  const key = tab || 'failed-email';
  const currentTab = tabsMap[key];

  const brand = useSelector((state: App.State) => state.tenant);

  const tabsList = useMemo(() => {
    return Object.keys(tabsMap).filter((key) => !tabsMap[key].tenants || tabsMap[key].tenants.has(brand.value));
  }, [brand]);

  const title = `Email / ${currentTab.label}`;
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Container maxWidth="xl">
        <PageHeader title={title} />

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={key}>
            {tabsList.map((tab) => (
              <Tab key={tab} value={tab} label={tabsMap[tab].label} to={`/email/${tab}`} component={Link} />
            ))}
          </Tabs>
        </Box>
      </Container>

      <Box mt={4} sx={{ maxWidth: '2xl', padding: '0 3rem', margin: '1rem' }}>
        <Box component={currentTab.component} />
      </Box>
    </>
  );
}

EmailPage.contextTypes = {
  user: PropTypes.object,
};
