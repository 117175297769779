import React, { memo } from 'react';

import Spinner from '~/components/Common/Spinner';

import { customerPortalHost } from '~/services/common';

import TourOrderItem from './TourOrderItem';

function TourOrderList({ item, order, showRefundModal, tourInfo, tenant }) {
  const offerUrl = customerPortalHost(tenant) + `/tour/${item.tour_id}`;

  return (
    <div className="T-Order-Item-List">
      {tourInfo ? (
        <TourOrderItem
          count={1}
          order={order}
          item={item}
          showRefundModal={showRefundModal}
          tourInfo={tourInfo}
          offerUrl={offerUrl}
        />
      ) : (
        <Spinner size={36} />
      )}
    </div>
  );
}

export default memo(TourOrderList);
