import React, { useCallback, useEffect, useState } from 'react';

import sum from 'lodash/sum';

import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';

import NumberFormatCustom from '~/components/Common/ReactNumberFormat/NumberFormatCustom';
import { RefundMetadata } from '~/components/Refund/types';
import getPricePerNightMap from '~/components/Refund/utils/getPricePerNightMap';

import ReservationService from '~/services/ReservationService';
import { formatDateWeekDayShortMonth, generateDateRange } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';

import { getCurrencySymbol } from '../../Utils/RefundUtils';

interface Props {
  refundMetadata: RefundMetadata;
  checkInDate: string;
  checkOutDate: string;
  currencyCode: string;
  formRef: any;
  handleLateChangeOfMindFieldChange: any;
  reservationId?: string;
  maxRefundAmount: number;
}

export default function SelectNights({
  refundMetadata,
  checkInDate,
  checkOutDate,
  currencyCode,
  formRef,
  handleLateChangeOfMindFieldChange,
  reservationId,
  maxRefundAmount,
}: Props) {
  const checkIn = new Date(checkInDate);
  const checkOut = new Date(checkOutDate);
  const dateRange = generateDateRange(checkIn, checkOut);
  const [pricesPerNight, setPricesPerNight] = useState<{ [key: string]: number }>({});
  const [checkedDates, setCheckedDates] = useState<Set<string>>(new Set());
  const [refundToCustomer, setRefundToCustomer] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if (reservationId) {
        const pricesPerNight = await ReservationService.getReservationRates(reservationId);
        if (pricesPerNight.result) {
          const priceMap = {};
          pricesPerNight.result.map((rate) => {
            priceMap[new Date(rate.date).toISOString()] = rate.sell_amount;
          });

          setPricesPerNight(priceMap);
        }
      } else {
        const pricesPerNight = getPricePerNightMap(refundMetadata, dateRange);
        setPricesPerNight(pricesPerNight);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (date) => (event) => {
    const formData = new FormData(formRef.current);
    const arrCopy = new Set(checkedDates);
    const dateString = date.toISOString();

    if (event.target.checked) {
      // Add date to checkedDates if checkbox is checked
      arrCopy.add(dateString);
    } else {
      // Remove date from checkedDates if checkbox is unchecked
      arrCopy.delete(dateString);
    }

    const totalPricesPerNight = Array.from(arrCopy).map((date) => {
      return Number(pricesPerNight[date]);
    });

    const totalPricesSum = sum(totalPricesPerNight);
    setCheckedDates(arrCopy);
    setRefundToCustomer(totalPricesSum);

    formData.set('totalRefundedToCustomer', totalPricesSum.toString());
    formData.set('numberOfNights', arrCopy.size.toString());
    handleLateChangeOfMindFieldChange(formData);
  };

  const handleTotalRefundedToCustomerChange = useCallback(
    (e) => {
      const formData = new FormData(formRef.current);
      const limitedRefundAmount = Math.min(e.target.value, maxRefundAmount);
      setRefundToCustomer(limitedRefundAmount);
      formData.set('totalRefundedToCustomer', limitedRefundAmount.toFixed(2));
      handleLateChangeOfMindFieldChange(formData);
    },
    [formRef, handleLateChangeOfMindFieldChange, maxRefundAmount],
  );

  const handleSelectAllNights = () => {
    const formData = new FormData(formRef.current);
    const arrCopy = new Set(dateRange.map((date) => date.toISOString()));
    const totalPricesPerNight = Array.from(arrCopy).map((date) => {
      return Number(pricesPerNight[date]);
    });
    setCheckedDates(arrCopy);
    const totalPricesSum = sum(totalPricesPerNight);
    setRefundToCustomer(totalPricesSum);

    formData.set('totalRefundedToCustomer', totalPricesSum.toString());
    formData.set('numberOfNights', arrCopy.size.toString());
    handleLateChangeOfMindFieldChange(formData);
  };

  const handleSelectNoNights = () => {
    const formData = new FormData(formRef.current);
    const arrCopy = new Set<string>();
    const totalPricesPerNight = [];
    const totalPricesSum = sum(totalPricesPerNight);
    setCheckedDates(arrCopy);
    setRefundToCustomer(totalPricesSum);

    formData.set('totalRefundedToCustomer', totalPricesSum.toString());
    formData.set('numberOfNights', arrCopy.size.toString());
    handleLateChangeOfMindFieldChange(formData);
  };

  const handleAllNightsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      handleSelectAllNights();
    } else {
      handleSelectNoNights();
    }
  };

  return (
    <Box mt={3}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedDates.size === dateRange.length}
              indeterminate={checkedDates.size !== 0 && checkedDates.size !== dateRange.length}
              size="small"
              onChange={handleAllNightsChange}
            />
          }
          label="All Nights"
        />
      </FormGroup>
      <Stack spacing={2}>
        <FormGroup>
          {dateRange.map((date, index) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    data-cy={`date-range-${index}`}
                    checked={checkedDates.has(date.toISOString())}
                    onChange={handleCheckboxChange(date)}
                  />
                }
                key={date.toISOString()}
                label={
                  <Grid container width="320px" justifyContent="space-between">
                    <Grid>
                      <Typography>{formatDateWeekDayShortMonth(date)}</Typography>
                    </Grid>
                    <Grid>
                      <Typography>{currencyFormatter(currencyCode, pricesPerNight[date.toISOString()], 2)}</Typography>
                    </Grid>
                  </Grid>
                }
                sx={{
                  // this overrides the tall mui checkbox
                  height: '27px',
                }}
              />
            );
          })}
        </FormGroup>
        <Grid container pl={2}>
          <Grid sm={5} alignContent="center">
            <Typography fontWeight="bold">
              {checkedDates.size} / {dateRange.length} {dateRange.length === 1 ? 'night' : 'nights'} selected
            </Typography>
            {/* Hidden input to hold the numberOfNights tab value */}
            <input type="hidden" name="numberOfNights" value={checkedDates.size} />
          </Grid>
          <Grid sm={5}>
            <Stack>
              <Typography fontWeight="bold">Total Refunded To Customer</Typography>
              <NumberFormatCustom
                value={refundToCustomer}
                onChange={handleTotalRefundedToCustomerChange}
                size="small"
                data-cy="RefundToCustomerAmount"
                prefix={getCurrencySymbol(currencyCode)}
              />
              {/* Hidden input to hold the totalRefundedToCustomer tab value */}
              <input type="hidden" name="totalRefundedToCustomer" value={refundToCustomer} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
