import React from 'react';

import { useSnackbar } from 'notistack';

import { Stack } from '@mui/material';

import { customerPortalHost } from '~/services/common';

import OrderItem from './Item';

export default function List({
  order,
  item,
  bedbankRoomsInfo,
  disableInteraction,
  showRefundModal,
  tenant,
  hasAllowedRefund,
  shouldShowCancellationWarning,
  refundPolicies,
}) {
  const { offer } = item;
  const { enqueueSnackbar } = useSnackbar();

  const offerUrl = customerPortalHost(tenant) + `/partner/${offer.slug}/${offer.id}`;

  return (
    <Stack className="T-Order-Item-List" direction="column" spacing={1}>
      {item.rooms.map((room, index) => {
        const count = index + 1;
        const bedbankRoomInfo = bedbankRoomsInfo[room.id_reservation_room] || null;
        const refundPolicy = refundPolicies?.[room.id_reservation_room];

        return (
          <OrderItem
            order={order}
            key={room.id}
            item={item}
            bedbankRoomInfo={bedbankRoomInfo}
            room={room}
            regionCode={order.region_code}
            currencyCode={order.currency_code}
            propertyTimezone={offer.timezone}
            count={count}
            offerUrl={offerUrl}
            hasAllowedRefund={hasAllowedRefund}
            showRefundModal={showRefundModal}
            disableInteraction={disableInteraction}
            promotions={offer.promotions}
            enqueueSnackbar={enqueueSnackbar}
            shouldShowCancellationWarning={shouldShowCancellationWarning}
            refundPolicy={refundPolicy}
          />
        );
      })}
    </Stack>
  );
}
