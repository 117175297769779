import React from 'react';

import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

type RawLogs = {
  [key: string]: unknown;
};

function DownloadRawRates({ rawlogs }: { rawlogs: RawLogs }) {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const currDate = new Date().toLocaleDateString();
    const fileName = `raw_rates-${currDate}.txt`;
    fileDownload(JSON.stringify(rawlogs), fileName);
  };

  if (!rawlogs) {
    return null;
  }

  return (
    <div className="button-container">
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Download Rate JSON
      </Button>
    </div>
  );
}

export default DownloadRawRates;
