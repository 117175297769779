import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

import { Alert, Button } from '@mui/material';

import { formatDateISO, startOfMonth } from '~/services/TimeService';
import VendorsService from '~/services/VendorsService';

import ErrorDisplay from '../ErrorDisplay';

import AutocompleteWidget from './widgets/AutocompleteWidget';

function validateForm(formData, errors) {
  if (typeof formData.dateOfPayment === 'undefined') {
    errors.dateOfPayment.addError('Please enter date of payment');
  }

  if (typeof formData.fromCurrency === 'undefined') {
    errors.fromCurrency.addError('Please select a currency');
  }

  if (typeof formData.toCurrency === 'undefined') {
    errors.toCurrency.addError('Please select a currency');
  }

  return errors;
}

const CURRENCIES = [
  'AUD',
  'EUR',
  'GBP',
  'HKD',
  'IDR',
  'INR',
  'JPY',
  'KRW',
  'MAD',
  'MYR',
  'NZD',
  'QAR',
  'SGD',
  'THB',
  'USD',
  'VND',
];

const formSchema: RJSFSchema = {
  description: 'Please enter a date of payment and select a currency to generate an IMT file',
  type: 'object',
  properties: {
    dateOfPayment: {
      title: 'Date of payment',
      type: 'string',
      format: 'date',
      default: formatDateISO(startOfMonth()),
    },
    fromCurrency: {
      title: 'From currency',
      type: 'string',
      enum: CURRENCIES,
    },
    toCurrency: {
      title: 'To currency',
      type: 'string',
      enum: CURRENCIES,
    },
  },
};

const uiSchema = {
  dateOfPayment: {
    'ui:widget': 'date',
  },
  fromCurrency: {
    'ui:widget': 'AutocompleteWidget',
  },
  toCurrency: {
    'ui:widget': 'AutocompleteWidget',
  },
};

const widgets = {
  AutocompleteWidget,
};

export default function IMTFileForm() {
  const [error, setError] = useState(null);
  const [comment, setComment] = useState(null);
  const [formData, setFormData] = useState({});

  const handleSubmit = async (event: IChangeEvent) => {
    try {
      setComment('Downloading IMT File...');

      await VendorsService.downloadIMTFile(
        event.formData.dateOfPayment,
        event.formData.fromCurrency,
        event.formData.toCurrency,
      );

      setComment('IMT File downloaded successfully.');
      setError(null);
    } catch (err) {
      setComment(null);
      setError(err.message);
    }
  };

  const handleChange = (event: IChangeEvent) => {
    setFormData(event.formData);
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      onChange={handleChange}
      onSubmit={handleSubmit}
      customValidate={validateForm}
      validator={validator}
      widgets={widgets}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Download IMT File
      </Button>

      {error && <ErrorDisplay message={error} />}

      {comment && (
        <Alert severity="info">
          <p>{comment}</p>
        </Alert>
      )}
    </Form>
  );
}
