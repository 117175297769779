import React, { useCallback, useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';

import { getReasonsByItemType } from '../Utils/RefundUtils';

interface Props {
  itemType: string;
  isRefundable: boolean;
  order: Order.Order;
  handleReasonSubmission: (key: string) => void;
}

export default function RefundReasons({ itemType, order, isRefundable, handleReasonSubmission }: Props) {
  const { refundTypes, reasons } = getReasonsByItemType(itemType, order, isRefundable);
  const [selectedReason, setSelectedReason] = useState<string>(undefined);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReason((event.target as HTMLInputElement).value);
  };

  const handleSubmit = useCallback(() => {
    handleReasonSubmission(selectedReason);
  }, [handleReasonSubmission, selectedReason]);

  return (
    <>
      <DialogContent dividers>
        <form id="refund-reason-form" onSubmit={handleSubmit}>
          <FormControl required>
            <RadioGroup value={selectedReason} onChange={handleChange}>
              {refundTypes
                .filter((key) => !reasons[key]?.hidden)
                .map((key) => {
                  return (
                    <FormControlLabel
                      key={key}
                      value={key}
                      control={
                        <Radio
                          inputProps={{
                            required: true,
                          }}
                        />
                      }
                      label={
                        <Box mb={1}>
                          <Typography fontWeight="bold" component="span" display="block">
                            {reasons[key]?.label ?? reasons[key].key}
                          </Typography>
                          <Typography component="span" display="block">
                            {reasons[key].description}
                          </Typography>
                        </Box>
                      }
                      disableTypography
                    />
                  );
                })}
            </RadioGroup>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" type="submit" form="refund-reason-form">
          Continue
        </Button>
      </DialogActions>
    </>
  );
}
