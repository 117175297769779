import React, { forwardRef, useCallback, useImperativeHandle } from 'react';

import { Alert, Link } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import { PropertyResponse, listProperties } from '~/services/AccommodationService';

import { AccommodationPropertiesSearchInput } from './AccommodationPropertiesSearchForm';

const columns: Array<GridColDef<PropertyResponse>> = [
  {
    field: 'name',
    headerName: 'Property Name',
    display: 'flex',
    minWidth: 260,
    flex: 1,
    renderCell: (params) => (
      <Link
        href={`/accommodation/properties/${params.row.id}`}
        target="_blank"
        className="accommodation-properties-search-result-link"
      >
        {params.value}
      </Link>
    ),
    hideable: false,
  },
  {
    field: 'id',
    headerName: 'ID',
    maxWidth: 240,
    minWidth: 200,
    display: 'flex',
    hideable: true,
  },
];

interface Props {
  searchInput: AccommodationPropertiesSearchInput;
  pageIndex?: number;
  onPageChange: (pageIndex: number) => void;
}

const PAGE_SIZE = 20;

export interface AccommodationPropertiesResultsTableRef {
  refresh: () => void;
}

const AccommodationPropertiesResultsTable = forwardRef<AccommodationPropertiesResultsTableRef, Props>(
  function AccommodationPropertiesResultsTable({ searchInput, pageIndex, onPageChange }, ref) {
    const getProperties = useCallback(
      () =>
        listProperties({
          name: searchInput.propertyName,
          id: searchInput.id,
          page: String((pageIndex ?? 0) + 1),
          limit: String(PAGE_SIZE),
        }),
      [pageIndex, searchInput],
    );

    const { fetchReqState, refetch } = useAccommodationServiceFetch({
      fetchFn: getProperties,
    });

    useImperativeHandle(ref, () => ({
      refresh: refetch,
    }));

    return (
      <>
        {fetchReqState.status === 'failed' && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {JSON.stringify(fetchReqState.error)}
          </Alert>
        )}
        <DataGrid
          className="accommodation-properties-search-results-table"
          columns={columns}
          rows={fetchReqState.status === 'succeeded' ? fetchReqState.result.properties : []}
          rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.total : undefined}
          getRowHeight={() => 'auto'}
          pageSizeOptions={[PAGE_SIZE]}
          paginationMode="server"
          paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          loading={fetchReqState.status === 'pending'}
          autoHeight
          disableColumnFilter
          disableDensitySelector
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </>
    );
  },
);

export default AccommodationPropertiesResultsTable;
