import React from 'react';

import { ControllerFieldState } from 'react-hook-form/dist/types/controller';

import { TextField } from '@mui/material';

interface Props {
  fieldState: ControllerFieldState;
  onChange: (...event: any[]) => void;
}

export default function TourPercentagePriceField({ fieldState, onChange, ...props }: Props) {
  return (
    <TextField
      {...props}
      type="number"
      error={fieldState.invalid}
      onChange={(event) => {
        const value = event.target.value != '' ? Number(event.target.value) : undefined;
        onChange(value);
      }}
      sx={{ width: 80 }}
      inputProps={{ step: 0.01, min: 0, max: 1 }}
    />
  );
}
