import React, { FormEventHandler, useCallback, useEffect, useState } from 'react';

import { Button, Card, CardActions, CardContent, Grid, TextField } from '@mui/material';

import { AccommodationTabs } from '~/components/Accommodation/Pages/Home/AccommodationHomePage';

export interface AccommodationPropertiesSearchInput {
  propertyName?: string;
  id?: string;
}

const DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT: AccommodationPropertiesSearchInput = {
  propertyName: '',
  id: '',
};

interface Props {
  initialInput?: AccommodationPropertiesSearchInput;
  onSubmit: (formObject: Record<string, string>) => void;
  refreshProperties: () => void;
  selectedTab: AccommodationTabs;
}

export default function AccommodationPropertiesSearchForm({ initialInput, onSubmit, selectedTab }: Props) {
  const [propertyName, setPropertyName] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyName);
  const [id, setId] = useState<string>(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.id);

  const clearSearches = useCallback(() => {
    setPropertyName('');
    setId('');
  }, []);

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();

      const formObject: Record<string, string> = {};

      if (propertyName) {
        formObject.propertyName = propertyName;
      }
      if (id) {
        formObject.id = id;
      }
      onSubmit(formObject);
    },
    [onSubmit, propertyName, id],
  );

  const handleReset = useCallback<FormEventHandler<HTMLFormElement>>((e) => {
    e.preventDefault();

    setPropertyName(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyName);
    setId(DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.id);
  }, []);

  useEffect(() => {
    setPropertyName(initialInput?.propertyName ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.propertyName);
    setId(initialInput?.id ?? DEFAULT_ACCOMMODATION_PROPERTIES_SEARCH_INPUT.id);
  }, [initialInput]);

  const getIdLabel = useCallback(() => {
    if (selectedTab === AccommodationTabs.UNMAPPED_SUPPLIERS) {
      return 'Supplier ID / Internal Service ID';
    }
    return 'Property ID / Supplier ID / Internal Service ID';
  }, [selectedTab]);

  return (
    <form onSubmit={handleSubmission} onReset={handleReset}>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Property name"
                value={propertyName}
                type="text"
                onChange={(e) => setPropertyName(e.target.value)}
                onFocus={clearSearches}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={getIdLabel()}
                value={id}
                type="text"
                onChange={(e) => setId(e.target.value)}
                onFocus={clearSearches}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'end' }}>
          <Button variant="text" type="reset">
            Reset
          </Button>
          <Button variant="contained" type="submit">
            Search
          </Button>
        </CardActions>
      </Card>
    </form>
  );
}
