import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { currencyCodes } from '~/globals';

import { Box, MenuItem, TextField } from '@mui/material';

import { getCarts } from '~/services/CartService';

import Spinner from '../Common/Spinner';

import CartPane from './CartPane';

const CURRENCIES = ['Any', ...currencyCodes] as const;

export default function CartPaneContainer() {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [carts, setCarts] = useState(null);
  const [currency, setCurrency] = useState<string>('Any');
  const [page, setPage] = useState(0);

  const onCurrencyChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(event.target.value);
  }, []);

  const onPageChange = useCallback((page: number) => setPage(page), []);

  useEffect(() => {
    setIsLoading(true);

    getCarts(currency, page, 10, brand)
      .then((carts) => setCarts(carts))
      .catch((error) => {
        enqueueSnackbar('Error loading carts: ' + error.message, { variant: 'error' });
      })
      .finally(() => setIsLoading(false));
  }, [currency, page, brand, enqueueSnackbar]);

  return (
    <>
      <Box width={250}>
        <TextField label="Currency" value={currency} onChange={onCurrencyChange} fullWidth select>
          {CURRENCIES.map((currency) => (
            <MenuItem key={currency} value={currency}>
              {currency}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {isLoading && !carts && <Spinner />}

      {carts && (
        <Box mt={2}>
          <CartPane
            isLoading={isLoading}
            carts={carts.result}
            total={carts.total}
            page={page}
            onPageChange={onPageChange}
          />
        </Box>
      )}
    </>
  );
}
