import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { getDayJs } from '~/services/TimeService';
import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerScheduleGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/schedules/{scheduleId}']['get'];

function mapHeroPlannerSchedule(
  external: HeroPlannerScheduleGetContract['responses']['200']['content']['application/json']['result'],
): CustomerCommunication.HeroPlannerSchedule {
  return {
    id: external.scheduleId,
    sendDate: getDayJs(external.identifiers.sendDate).tz(window.configs.DEFAULT_TIMEZONE),
    brandId: external.identifiers.brand,
    countryGroupId: external.identifiers.countryGroup,
    countryId: external.identifiers.country,
    stateId: external.identifiers.state,
    cityId: external.identifiers.city,
    segmentId: external.identifiers.segment,
    cadenceId: external.identifiers.cadence,
    membershipId: external.identifiers.loyalty,

    emailSubjectLine: external.data.emailSubjectLine,
    emailPreHeader: external.data.emailPreheader,
    emailStatus: external.data.emailStatus,
    emailBannerId: external.data.emailBannerId,
    emailBannerPosition2Id: external.data.emailBannerPosition2Id,
    emailHeroOfferIds: external.data.emailHeroDeals,
    isApprovedForTpfm: external.data.isApprovedForTpfm,
    pushTitle: external.data.pushTitle,
    pushSubtitle: external.data.pushSubtitle,
    pushMessage: external.data.pushMessage,
    pushLink: external.data.pushLink,
    pushStatus: external.data.pushStatus,
    pushSvcImageId: external.data.pushImageId,
  };
}

async function requestGetHeroPlannerSchedule(scheduleId: string) {
  if (!scheduleId) throw 'missing scheduleId';

  const url = makeCustomerCommunicationV1URL(`/todays-escapes-schedule/hero-planner/schedules/${scheduleId}`);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerScheduleGetContract['responses']['200']['content']['application/json']) => {
      return mapHeroPlannerSchedule(response.result);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerSchedule;
