import React, { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import HelpIcon from '@mui/icons-material/Help';
import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import { Checkbox, Input } from '~/components/Common/Forms/fields';
import DebugModal from '~/components/DebugModal/DebugModal';

import { addQuery } from '~/utils/url';

import Spinner from '../../Common/Spinner';
import usePromoSimulator from '../hooks/usePromoSimulator';

import Internals from './Internals';

interface Props {
  initCodeName?: string;
  initOrderId?: string;
  initApplyWithOrignalUserAccount?: boolean;
  initIgnoreLuxPlusPricing?: boolean;
  tenant: App.Tenant;
}

function PromoTester({
  initCodeName,
  initOrderId,
  initApplyWithOrignalUserAccount,
  initIgnoreLuxPlusPricing,
  tenant,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { orderDiscount, promoCodeErrors, isLoading, reSimulateDiscountEvent, internals } = usePromoSimulator({
    initOrderId,
    initCodeName,
    initApplyWithOrignalUserAccount,
    initIgnoreLuxPlusPricing,
  });

  const { control, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      codeName: initCodeName || '',
      orderId: initOrderId || '',
      brand: tenant.brand,
      applyWithOrignalUserAccount: initApplyWithOrignalUserAccount,
      ignoreLuxPlusPricing: initIgnoreLuxPlusPricing,
    },
  });

  useEffect(() => {
    setValue('codeName', initCodeName || '');
    setValue('orderId', initOrderId || '');
  }, [initCodeName, initOrderId, setValue]);

  const setCode = (code) => {
    setValue('codeName', code);
    const values = getValues();
    reSimulateDiscountEvent({
      codeName: code,
      ...values,
    });
  };

  const setOrder = (order) => {
    setValue('orderId', order);
    const values = getValues();
    reSimulateDiscountEvent({
      orderId: order,
      ...values,
    });
  };

  const onSubmit = async (formData) => {
    if (formData.orderId === '' || formData.codeName === '') {
      enqueueSnackbar('Please enter both Order ID and Code Name', { variant: 'error' });
      return;
    }
    try {
      const newLocation = addQuery(location, formData);
      history.push(newLocation);
      reSimulateDiscountEvent({
        codeName: formData.codeName,
        orderId: formData.orderId,
        brand: tenant.brand,
        applyWithOrignalUserAccount: formData.applyWithOrignalUserAccount,
        ignoreLuxPlusPricing: formData.ignoreLuxPlusPricing,
      });
    } catch (err) {
      enqueueSnackbar(err.message || 'Error! Unable to update filters.', { variant: 'error' });
    }
  };

  return (
    <>
      <Box mb={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body1" fontSize={14}>
          This form allows for testing promo code against orders to allow for a shorter feedback loop when testing promo
          logic. It works best when using against orders that don't have any existing promo codes applied.
          <br />
          Note: This is testing the promo log behavior and not the promo code request behavior (which depends on the
          information be passed to /api/promo/discount when the promo is being retrieved - see{' '}
          <a href="/marketing/promo-requests">promoRequests</a> for more info here)
        </Typography>
        <Stack spacing={3}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Input fullWidth control={control} muiLabel="Code Name" name="codeName" />
            <Button onClick={() => setCode('e2etestall')}>Set e2etestall Promo</Button>
            <Button onClick={() => setCode('e2etest')}>Set e2etest Promo</Button>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Input fullWidth control={control} muiLabel="Order ID" name="orderId" />
            <Button onClick={() => setOrder('test')}>Set test order</Button>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
            <Stack direction="row" spacing={2}>
              <Checkbox control={control} label="Apply With Original Account" name="applyWithOrignalUserAccount" />
              <Tooltip title="Check this box to run the calculation with the original user's account (by default the calculation is run with the current user account)">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
              <Checkbox control={control} label="Ignore LuxPlus Pricing" name="ignoreLuxPlusPricing" />
              <Tooltip title="By Default, LuxPlus Pricing is ignored when logging the promo (i.e. when we normally run this calculation logic). This is to reduce the rate of hard promo failures due to missing/inaccurate original package price differences when running the calculation on the client-side and re-running the order-based calculation. This flag bypasses this default and includes LuxPlus Pricing when running these (promo log) calculations.">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Button variant="contained" type="submit">
              Simulate for {tenant.title}
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box>
        {isLoading && <Spinner />}
        {orderDiscount && (
          <Stack direction="row" spacing={3} alignItems="center">
            <Internals internals={orderDiscount.internals} />

            <DebugModal
              title={`${'discount_total' in orderDiscount.promo ? orderDiscount.promo.discount_total : ''} ${
                orderDiscount.promo.currency
              } - Promo Code ${orderDiscount.promo.code_name} `}
              type="generic"
              data={orderDiscount.promo}
            />
            <DebugModal title="All details" type="generic" data={orderDiscount} />
          </Stack>
        )}
        {internals && <Internals internals={internals} />}
        {promoCodeErrors?.length > 0 &&
          promoCodeErrors.map((pc, i) => <ErrorDisplay key={i} severity="warning" message={JSON.stringify(pc)} />)}
      </Box>
    </>
  );
}

export default PromoTester;
