import React, { FormEventHandler } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import currencyFormatter from '~/utils/currencyFormatter';

import { RefundPayload } from '../../types';
import SelectRefundMethod from '../Common/SelectRefundMethod';
import { RefundMethods, convertStrOrBoolToBool } from '../Utils/RefundUtils';

interface Props {
  customerName: string;
  currencyCode: string;
  refundPayload: RefundPayload;
  vendorContribution: number;
  LEContribution: number;
  onSubmit: FormEventHandler<HTMLFormElement>;
  decrementStep: () => void;
  refundMethods: Array<RefundMethods>;
  defaultCustomerRefundNotification?: boolean;
  LEProfit: number;
}

export default function RefundConfirmation({
  customerName,
  currencyCode,
  refundPayload,
  vendorContribution,
  LEContribution,
  onSubmit,
  decrementStep,
  refundMethods,
  defaultCustomerRefundNotification,
  LEProfit,
}: Props) {
  return (
    <>
      <DialogContent dividers>
        <form onSubmit={onSubmit} id="refund-confirmation-form">
          <Stack spacing={2}>
            <Typography fontWeight="bold">Refund Summary</Typography>
            {/* Refund Summary */}
            <Grid container spacing={2}>
              <Grid xs={5}>Help Desk Ticket ID</Grid>
              <Grid xs={7}>{refundPayload.ticket_id}</Grid>
              <Grid xs={5}>Customer Name</Grid>
              <Grid xs={7}>{customerName}</Grid>
              <Grid xs={5}>Refund Category</Grid>
              <Grid xs={7}>{refundPayload.reason}</Grid>
              <Grid xs={5}>Customer's Reason</Grid>
              <Grid xs={7}>{refundPayload.comment}</Grid>
              <Grid xs={5}>Source of Fund</Grid>
              <Grid xs={7}>{refundPayload.accounting_metadata[0].source}</Grid>
              <Grid xs={5}>Customer Fee</Grid>
              <Grid xs={7}>{currencyFormatter(currencyCode, refundPayload.accounting_metadata[0].refund_fee, 2)}</Grid>
              <Grid xs={5}>Package Cancelled</Grid>
              <Grid xs={7}>
                {convertStrOrBoolToBool(refundPayload.mark_cancelled) ? (
                  <Typography color="red">This package will be cancelled.</Typography>
                ) : (
                  <Typography>This package will not be cancelled.</Typography>
                )}
              </Grid>
              {/* Vendor and LE Contribution Comparison */}
              <Grid xs={5}>Vendor Contribution</Grid>
              <Grid xs={7}>{currencyFormatter(currencyCode, vendorContribution, 2)}</Grid>
              {LEProfit > 0 && <Grid xs={5}>LE Profit</Grid>}
              {LEProfit > 0 && (
                <Grid xs={7} color="green">
                  {currencyFormatter(currencyCode, LEProfit, 2)}
                </Grid>
              )}
              <Grid xs={5}>LE Contribution</Grid>
              <Grid xs={7}>{currencyFormatter(currencyCode, LEContribution, 2)}</Grid>
              <Grid xs={12}>
                <Divider />
              </Grid>
              <Grid xs={5}>
                <Typography color="red" fontWeight="bold">
                  Total Refund To Customer
                </Typography>
              </Grid>
              <Grid xs={7}>
                <Typography color="red" fontWeight="bold">
                  {currencyFormatter(currencyCode, refundPayload.amount ?? 0, 2)}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <SelectRefundMethod refundMethods={refundMethods} />
            <FormControl>
              <FormLabel
                id="send-customer-refund-notification"
                required
                sx={{
                  fontWeight: 'bold',
                }}
              >
                Send customer refund notification?
              </FormLabel>
              <RadioGroup
                name="customerRefundNotification"
                row
                defaultValue={defaultCustomerRefundNotification ?? false}
              >
                <FormControlLabel
                  data-cy="send-customer-refund-notification-yes"
                  key="send-customer-refund-notification-yes"
                  value
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  key="send-customer-refund-notification-no"
                  value={false}
                  control={<Radio />}
                  data-cy="send-customer-refund-notification-no"
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button variant="contained" type="submit" form="refund-confirmation-form">
          Submit
        </Button>
      </DialogActions>
    </>
  );
}
