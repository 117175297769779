import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';

import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import Flag from '~/components/Common/Flag';

import { AVAILABLE_REGIONS } from '~/consts/agentHub';

import useQuery from '~/hooks/useQuery';

import { listEOI } from '~/services/AgentHub/AgentService';

import { EoiRegionCode, ListEoiParams } from '~/types/services/agentHub';

import EoiStatusChip from '../EoiStatusChip';

import AgencySubmissionDetail from './AgencySubmissionDetails/AgencySubmissionDetailModal';

const DEFAULT_SIZE_PER_PAGE = 10;

export default function AgencySubmissions() {
  const params = useParams<{ id?: string }>();
  const queryFilters = useQuery();

  const { push: setQueryString } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);
  const [details, setDetails] = useState({ id: params.id ?? null, opened: !!params.id });

  const filter = useMemo(() => {
    return {
      page: parseInt(queryFilters.get('page') ?? '0'),
      status: queryFilters.get('status') ?? 'all',
      regionCode: queryFilters.get('region') ?? 'all',
      search: queryFilters.get('search') ?? null,
    };
  }, [queryFilters]);

  const onPageChange = useCallback(
    async (page: number): Promise<void> => {
      queryFilters.set('page', page.toString());
      setQueryString({
        search: queryFilters.toString(),
      });
    },
    [queryFilters, setQueryString],
  );

  const onStatusChange = useCallback(
    (status: string) => {
      if (!status || status === 'all') {
        queryFilters.delete('status');
      } else {
        queryFilters.set('status', status);
      }
      setQueryString({
        search: queryFilters.toString(),
      });
    },
    [queryFilters, setQueryString],
  );

  const onSearchChange = useCallback(
    (search: string) => {
      if (search === '') {
        queryFilters.delete('search');
      } else {
        queryFilters.set('search', search);
      }
      setQueryString({
        search: queryFilters.toString(),
      });
    },
    [queryFilters, setQueryString],
  );

  const onRegionChange = useCallback(
    (region: string) => {
      if (!region || region === 'all') {
        queryFilters.delete('region');
      } else {
        queryFilters.set('region', region);
      }
      setQueryString({
        search: queryFilters.toString(),
      });
    },
    [queryFilters, setQueryString],
  );

  const onSearchChangeDebounced = debounce(onSearchChange, 500);

  useEffect(() => {
    if (params.id) {
      setDetails({ id: params.id, opened: true });
    }
  }, [params]);

  const fetchSubmissionsList = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const { rows, total } = await listEOI({
        page: filter.page.toString(),
        pageSize: DEFAULT_SIZE_PER_PAGE.toString(),
        ...(filter.status !== 'all' ? { status: filter.status as ListEoiParams['status'] } : {}),
        ...(filter.search ? { search: filter.search } : {}),
        ...(filter.regionCode !== 'all' ? { regionCode: filter.regionCode as EoiRegionCode } : {}),
      });

      setTotal(total);
      setResult(rows);
    } catch (e) {
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [filter, setTotal, setResult, enqueueSnackbar]);

  const closeDetailsModal = useCallback(() => {
    setDetails({ id: null, opened: false });
    setQueryString('/users-list/agency-submission', { replace: true });
    fetchSubmissionsList();
  }, [setQueryString, fetchSubmissionsList]);

  const openDetailsModal = useCallback(
    (id: string) => {
      setQueryString(`agency-submission/${id}`, { replace: true });
      setDetails({ id: id, opened: true });
    },
    [setDetails, setQueryString],
  );

  useEffect(() => {
    fetchSubmissionsList();
  }, [fetchSubmissionsList]);

  const columns: Array<GridColDef> = useMemo(() => {
    return [
      { field: 'agencyName', headerName: 'Agency Name', flex: 1, sortable: false, display: 'flex' },
      { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
      {
        field: 'status',
        headerName: 'Status',
        align: 'center',
        flex: 1,
        sortable: true,
        display: 'flex',
        renderCell: (params) => <EoiStatusChip status={params.row.status} />,
      },
      { field: 'submittedAt', headerName: 'Submitted At', flex: 1, sortable: true, display: 'flex' },
      { field: 'updatedAt', headerName: 'Updated At', flex: 1, sortable: true, display: 'flex' },
      {
        field: 'user',
        headerName: 'Country',
        flex: 1,
        display: 'flex',
        align: 'center',
        renderCell: (params) => {
          return params.row.regionCode && <Flag region={params.row.regionCode} style={{ marginRight: 10 }} />;
        },
      },
      {
        field: 'action',
        headerName: '',
        renderCell: (params) => {
          return (
            <Button variant="text" onClick={() => openDetailsModal(params.row.id)}>
              Show
            </Button>
          );
        },
        display: 'flex',
      },
    ];
  }, [openDetailsModal]);

  return (
    <>
      <Grid columns={12} display="flex" justifyContent="end">
        <TextField
          data-testid="search"
          type="text"
          name="search"
          label="Search"
          placeholder="Search"
          value={filter.search}
          onChange={(e) => onSearchChangeDebounced(e.target.value)}
          fullWidth
        />
        <TextField
          sx={{ marginLeft: 1, minWidth: 130 }}
          select
          title="Status"
          label="Status"
          value={filter.status}
          placeholder="Placeholder"
          onChange={(e) => onStatusChange(e.target.value)}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="pending">Pending</MenuItem>
          <MenuItem value="approved">Approved</MenuItem>
          <MenuItem value="onboarded">Onboarded</MenuItem>
          <MenuItem value="rejected">Rejected</MenuItem>
        </TextField>
        <TextField
          sx={{ marginLeft: 1, minWidth: 130 }}
          select
          title="Country"
          label="Country"
          value={filter.regionCode}
          placeholder="Placeholder"
          onChange={(e) => onRegionChange(e.target.value)}
        >
          <MenuItem value="all">All</MenuItem>
          {AVAILABLE_REGIONS.map((region) => (
            <MenuItem key={region.value} value={region.value}>
              {region.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid mt={1}>
        <DataGrid
          rows={result}
          rowCount={total}
          columns={columns}
          loading={loading}
          pagination
          paginationMode="server"
          paginationModel={{ page: filter.page, pageSize: DEFAULT_SIZE_PER_PAGE }}
          pageSizeOptions={[DEFAULT_SIZE_PER_PAGE]}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          slots={{ pagination: GridPagination }}
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          autoHeight
        />
        <AgencySubmissionDetail onClose={closeDetailsModal} id={details.id} opened={details.opened} />
      </Grid>
    </>
  );
}
