import React, { useCallback } from 'react';

import { Cancel, CheckCircle } from '@mui/icons-material';
import { Alert, Box, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import {
  MappedInternalRoom,
  MappingModel,
  VerificationLog,
  getVerificationLogs,
} from '~/services/AccommodationService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';

const columns: Array<GridColDef<VerificationLog>> = [
  {
    field: 'isVerified',
    headerName: 'Verified?',
    minWidth: 100,
    display: 'flex',
    hideable: true,
    renderCell: (params) => (params.value ? <CheckCircle color="success" /> : <Cancel color="error" />),
  },
  {
    field: 'verifiedBy',
    headerName: 'Verified By',
    minWidth: 350,
    display: 'flex',
    hideable: true,
  },
  {
    field: 'createdAt',
    headerName: 'Date',
    valueFormatter: (value) => formatDateOrdinalWithClock(new Date(value as string)),
    minWidth: 200,
    display: 'flex',
    hideable: true,
  },
  {
    field: 'verifiedComments',
    headerName: 'Comments',
    minWidth: 300,
    display: 'flex',
    hideable: true,
  },
];

interface Props {
  mappedRoom: MappedInternalRoom;
  onClose: () => void;
}

export default function VerificationLogTable({ mappedRoom, onClose }: Props) {
  const fetchVerificationLogs = useCallback(
    () => getVerificationLogs('mapped_rooms' as MappingModel, mappedRoom.mappedRoomId),
    [mappedRoom.mappedRoomId],
  );

  const { fetchReqState } = useAccommodationServiceFetch({
    fetchFn: fetchVerificationLogs,
  });

  if (fetchReqState.status === 'pending' || fetchReqState.status === 'uninitiated') {
    return (
      <Box p={2} display="flex" justifyContent="center">
        <CircularProgress variant="indeterminate" />
      </Box>
    );
  }

  if (fetchReqState.status === 'failed') {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {JSON.stringify(fetchReqState.error)}
      </Alert>
    );
  }

  return (
    <DataGrid
      className="verification-logs-table"
      columns={columns}
      rows={fetchReqState.status === 'succeeded' ? fetchReqState.result : []}
      getRowHeight={() => 'auto'}
      autoHeight
      disableRowSelectionOnClick
      hideFooterPagination
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      slots={{
        toolbar: GridToolbar,
      }}
    />
  );
}
