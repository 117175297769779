import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useFieldArray, useForm } from 'react-hook-form';

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { Request as APIRequest } from '@luxuryescapes/contract-svc-tour/types/requests';

import TourPricingBulkUpdateForm, {
  EditPricingRooms,
} from '~/components/Tours/TourDetails/Forms/TourPricingBulkUpdateForm';
import TourPricingFilters from '~/components/Tours/TourDetails/Forms/TourPricingFilters';
import TourPricingTableRow from '~/components/Tours/TourDetails/Forms/TourPricingTableRow';
import { mergeRoomsPricing } from '~/components/Tours/utils/pricing';

import { formatDateCalendar } from '~/services/TimeService';

interface TourPricingForm {
  pricing: Array<APIRequest.TourOptionDeparturePackagePricingData>;
}

interface Props {
  pricing: Array<APIRequest.TourOptionDeparturePackagePricingData>;
  readonly: boolean;
  onUpdate: (data: Array<APIRequest.TourOptionDeparturePackagePricingData>) => void;
  disableSave: boolean;
}

export default function TourPricingTableForm({ pricing, readonly, onUpdate, disableSave }: Props) {
  const [filterPricing, setFilterPricing] = useState({
    tourOptions: [],
    departures: [],
    departuresMonth: [],
    showDiscountApplied: false,
  });

  const filteredPricing = useMemo<Array<APIRequest.TourOptionDeparturePackagePricingData>>(() => {
    let pricingToFilter = pricing;
    if (filterPricing.tourOptions.length > 0) {
      pricingToFilter = pricingToFilter.filter((pricing) => filterPricing.tourOptions.includes(pricing.tourOption));
    }
    if (filterPricing.departures.length > 0) {
      pricingToFilter = pricingToFilter.filter((pricing) => filterPricing.departures.includes(pricing.departureDate));
    }
    if (filterPricing.departuresMonth.length > 0) {
      pricingToFilter = pricingToFilter.filter((pricing) => {
        const yearMonth = formatDateCalendar(new Date(pricing.departureDate));
        return filterPricing.departuresMonth.includes(yearMonth);
      });
    }
    if (filterPricing.showDiscountApplied) {
      pricingToFilter = pricingToFilter.filter((pricing) => {
        return (
          !!pricing.rooms.single?.discountPercentage ||
          !!pricing.rooms.single?.surchargePercentage ||
          !!pricing.rooms.twin?.discountPercentage ||
          !!pricing.rooms.twin?.surchargePercentage
        );
      });
    }
    return pricingToFilter;
  }, [pricing, filterPricing]);

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useForm<TourPricingForm>({
    defaultValues: {
      pricing: filteredPricing,
    },
  });
  const { fields: pricingFields, replace, update } = useFieldArray({ control, name: 'pricing' });

  useEffect(() => {
    // update form values when pricing data from filters
    reset({ pricing: filteredPricing });
    replace(filteredPricing);
  }, [reset, replace, filteredPricing]);

  const onValid = useCallback(
    (data): void => {
      // only update data that has been changed
      const pricingData = data.pricing.filter((price, index) => !!dirtyFields.pricing?.[index]);
      onUpdate(pricingData);
    },
    [onUpdate, dirtyFields],
  );

  const bulKUpdatePricingRooms = useCallback(
    (pricingRooms: EditPricingRooms) => {
      pricingFields.forEach((field, index) => {
        update(index, {
          ...field,
          rooms: {
            ...mergeRoomsPricing(field.rooms, pricingRooms),
          },
        });
      });
    },
    [pricingFields, update],
  );

  return (
    <>
      <TourPricingFilters pricing={pricing} filterPricing={filterPricing} onChange={setFilterPricing} />
      <form onSubmit={handleSubmit(onValid)}>
        <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
          <Table size="small">
            <TableHead>
              {!readonly && <TourPricingBulkUpdateForm onApply={bulKUpdatePricingRooms} />}
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell sx={{ border: 1, borderRight: 0, borderColor: 'grey.300' }} align="center" colSpan={6}>
                  Twin
                </TableCell>
                <TableCell sx={{ border: 1, borderColor: 'grey.300' }} align="center" colSpan={6}>
                  Single
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderLeft: 1, borderColor: 'grey.300' }} rowSpan={2}>
                  Departure date
                </TableCell>
                <TableCell rowSpan={2}>Tour option</TableCell>
                <TableCell sx={{ borderLeft: 1, borderColor: 'grey.300' }}>Standard price</TableCell>
                <TableCell>Display price</TableCell>
                <TableCell>Member price</TableCell>
                <TableCell>Discount %</TableCell>
                <TableCell>Markup %</TableCell>
                <TableCell>LuxPlus %</TableCell>
                <TableCell sx={{ borderLeft: 1, borderColor: 'grey.300' }}>Standard price</TableCell>
                <TableCell>Display price</TableCell>
                <TableCell>Member price</TableCell>
                <TableCell>Discount %</TableCell>
                <TableCell>Markup %</TableCell>
                <TableCell sx={{ borderRight: 1, borderColor: 'grey.300' }}>LuxPlus %</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricing.length === 0 && (
                <TableRow>
                  <TableCell sx={{ border: 1, borderTop: 0, borderColor: 'grey.300' }} colSpan={14} align="center">
                    No pricing available
                  </TableCell>
                </TableRow>
              )}
              {pricingFields?.map((departurePricing, index) => (
                <TourPricingTableRow
                  key={departurePricing.id}
                  index={index}
                  control={control}
                  departurePricing={departurePricing}
                  readonly={readonly}
                />
              ))}
              {!readonly && pricingFields?.length > 0 && (
                <TableRow>
                  <TableCell sx={{ border: 0, paddingTop: 4 }} colSpan={14} align="right">
                    <Button type="submit" variant="contained" color="primary" disabled={disableSave}>
                      Update pricing
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </form>
    </>
  );
}
