import React, { useState } from 'react';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Typography } from '@mui/material';

import TravellerModalForm from '~/components/Common/Forms/TravellerForm/TravellerModalForm';
import { LE_TOUR_TYPES } from '~/components/Tours/constants';

const TourTraveller = ({ traveller, flightsRequired, weightRequired, setTravellers, productType }) => {
  const [isTravellerModalVisible, toggleIsTravellerModalVisible] = useState(false);
  const [travellerSubmitted, setTravellerSubmitted] = useState(traveller.traveller_submitted);
  const [flightsSubmitted, setFlightsSubmitted] = useState(traveller.flights_submitted);

  const toggleTravellerModal = () => {
    toggleIsTravellerModalVisible((prevState) => !prevState);
  };

  return (
    <div>
      <Typography>
        <strong>First Name:</strong> {traveller.first_name}
      </Typography>
      <Typography>
        <strong>Last Name:</strong> {traveller.last_name}
      </Typography>
      <Typography>
        <strong>Room Type:</strong> {traveller.room_type ?? ''}
      </Typography>
      <Typography>
        <strong>Room Number:</strong> {traveller.room_number ?? ''}
      </Typography>
      {traveller.customer_origin_airport && (
        <Typography>
          <strong>Departure airport:</strong> {traveller.customer_origin_airport ?? ''}
        </Typography>
      )}

      {LE_TOUR_TYPES.includes(productType) && (
        <div>
          <div className="section">
            <Button onClick={toggleTravellerModal} variant="outlined" size="small">
              Travellers details
            </Button>
          </div>

          <TravellerModalForm
            traveller={traveller}
            isTravellerModalVisible={isTravellerModalVisible}
            hideTravellerModal={toggleTravellerModal}
            tourFlightDetailsRequired={flightsRequired}
            tourWeightRequired={weightRequired}
            setTravellerSubmitted={setTravellerSubmitted}
            setFlightsSubmitted={setFlightsSubmitted}
            setTravellers={setTravellers}
          />
          {travellerSubmitted ? (
            <div>
              <TaskAltIcon color="success" />
              &nbsp;Traveller details complete
            </div>
          ) : (
            <div>
              <WarningAmberIcon color="warning" />
              &nbsp;Traveller details not complete
            </div>
          )}
          {flightsRequired && flightsSubmitted && (
            <div>
              <TaskAltIcon color="success" />
              &nbsp;Flight details complete
            </div>
          )}
          {flightsRequired && !flightsSubmitted && (
            <div>
              <WarningAmberIcon color="warning" />
              &nbsp;Flight details not complete
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TourTraveller;
