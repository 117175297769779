import React, { FormEventHandler } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';

import { CollectionReason } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';

interface Props {
  reason: CollectionReason;
  onSubmit: FormEventHandler<HTMLFormElement>;
  decrementStep: () => void;
  defaultComment: string;
  defaultCaseId: string;
}

export default function RefundTestPurchase({ reason, onSubmit, decrementStep, defaultComment, defaultCaseId }: Props) {
  return (
    <>
      <DialogContent dividers>
        <form onSubmit={onSubmit} id="refund-test-purchase-form">
          <CaseIDAndReasons
            reason={reason}
            defaultComment={defaultComment}
            defaultCaseId={defaultCaseId ?? 'N/A'}
            commentPlaceholder=""
            commentLabel="Name of agent processing refund"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained" form="refund-test-purchase-form">
          Continue
        </Button>
      </DialogActions>
    </>
  );
}
