import React from 'react';

import { Autocomplete, TextField } from '@mui/material';

export default function AutocompleteWidget(props) {
  const { value, onChange, options } = props;

  const handleChange = (event, newValue) => {
    onChange(newValue || '');
  };

  return (
    <Autocomplete
      value={value || null}
      onChange={handleChange}
      options={options.enumOptions.map((option) => option.value)}
      isOptionEqualToValue={(option, value) => option === value || value === ''}
      renderInput={(params) => <TextField {...params} label={props.label} />}
    />
  );
}
