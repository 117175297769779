import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { LinearProgress, Link, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import { userLinkFormatter } from '~/components/Purchases/Home/formatters';

import { getAirportTransfers, getOrdersByIds } from '~/services/OrdersService';
import { formatDateSlashesWithClock } from '~/services/TimeService';

interface AirportTransferRow {
  booking_number: string;
  customer_name: string;
  fk_customer_id: string;
  fk_order_id: string;
  transfer_direction: string;
  is_standalone_experience: boolean;
  has_completed_transfer_form: boolean;
  created_at: string;
  updated_at: string;
  check_in_date: string;
}

interface AirportTransferData {
  airportTransfers: Array<AirportTransferRow>;
  total: number;
}

function getTransferType(order?: Order.Order) {
  if (!order) {
    return 'N/A';
  }

  // TODO: update conditional for complimentary experiences when unified
  const isComplimentary = false;
  const isUpsellExperience = order.accommodation_items.length > 0 || order.bedbank_items.length > 0;

  if (isComplimentary) {
    return 'Complimentary';
  } else if (isUpsellExperience) {
    return 'Upsell';
  } else {
    return 'Standalone';
  }
}

function getFormCompletionStatus(hasCompletedForm: boolean, transferStatus: string): { text: string; color: string } {
  if (transferStatus !== 'completed') {
    return { text: 'Cancelled', color: 'black' };
  } else if (hasCompletedForm) {
    return { text: 'Completed', color: 'green' };
  } else {
    return { text: 'Incomplete', color: 'red' };
  }
}

export default function AirportTransfers() {
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [airportTransferData, setAirportTransferData] = useState<AirportTransferData>({
    airportTransfers: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const fetchAirportTransfers = useCallback(
    async (currentPage, perPage) => {
      setLoading(true);
      try {
        const response = await getAirportTransfers(currentPage + 1, perPage);
        const airportTransfers = response.result;

        const orderIds = [...new Set<string>(airportTransfers.map((airportTransfer) => airportTransfer.fk_order_id))];

        // Fetch orders data
        const orders: Array<Order.Order> = await getOrdersByIds(orderIds, brand);

        // Attach relevant order data to airport transfers
        const updatedAirportTransfers = airportTransfers.map((airportTransfer) => {
          const matchedOrder = orders.find((order) => order.id === airportTransfer.fk_order_id);

          // Customers can have " " as full name for some reason
          const customerNameTrimmed = matchedOrder?.customer_full_name?.trim() || '';
          const hasCompletedTransferForm = airportTransfer.deal_options.flight_number && airportTransfer.ticket.time;

          const customer_name = customerNameTrimmed || matchedOrder?.customer_email || 'N/A';
          const fk_customer_id = matchedOrder?.fk_customer_id;
          const form_completion_status = getFormCompletionStatus(hasCompletedTransferForm, airportTransfer.status);
          const transfer_direction = airportTransfer.deal_options.type;
          const check_in_date = airportTransfer.ticket.date;
          const transfer_type = getTransferType(matchedOrder);

          return {
            ...airportTransfer,
            form_completion_status,
            customer_name,
            fk_customer_id,
            transfer_direction,
            check_in_date,
            transfer_type,
          };
        });

        setAirportTransferData({
          airportTransfers: updatedAirportTransfers,
          total: response.total,
        });
      } catch (error) {
        enqueueSnackbar('Failed to fetch airport transfers.', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [brand, enqueueSnackbar],
  );

  useEffect(() => {
    fetchAirportTransfers(paginationModel.page, paginationModel.pageSize);
  }, [fetchAirportTransfers, paginationModel.page, paginationModel.pageSize]);

  const ordersPageLink = useCallback((orderId: string) => {
    return `/purchases/${orderId}`;
  }, []);

  const columns: Array<GridColDef> = useMemo(
    () => [
      {
        field: 'customer_name',
        headerName: 'Customer',
        width: 200,
        renderCell: userLinkFormatter,
      },
      {
        field: 'fk_order_id',
        headerName: 'Order ID',
        width: 100,
        renderCell: (params) => {
          const orderId = params.value;
          return (
            <Link href={ordersPageLink(orderId)} target="_blank" rel="noopener" title={params.value} noWrap>
              {params.value}
            </Link>
          );
        },
      },
      {
        field: 'transfer_direction',
        headerName: 'Transfer Direction',
        width: 200,
      },
      {
        field: 'transfer_type',
        headerName: 'Type',
        width: 200,
      },
      {
        field: 'form_completion_status',
        headerName: 'Form Completion',
        width: 150,
        renderCell: (params) => {
          const { text, color } = params.value;
          return <Typography color={color}>{text}</Typography>;
        },
      },
      {
        field: 'created_at',
        headerName: 'Order Date',
        width: 150,
        valueFormatter: (value) => {
          return formatDateSlashesWithClock(value);
        },
      },
      {
        field: 'updated_at',
        headerName: 'Updated At',
        width: 150,
        valueFormatter: (value) => {
          return formatDateSlashesWithClock(value);
        },
      },
      {
        field: 'check_in_date',
        headerName: 'Check In',
        width: 150,
        valueFormatter: (value) => {
          return formatDateSlashesWithClock(value);
        },
      },
    ],
    [ordersPageLink],
  );

  return (
    <Paper>
      <PageSubheader title="Airport transfers" />
      {loading && <LinearProgress />}
      <DataGrid
        rows={airportTransferData.airportTransfers}
        columns={columns}
        rowCount={airportTransferData.total}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        getRowId={(row) => row.booking_number}
        getRowHeight={() => 'auto'}
        autoHeight
        disableRowSelectionOnClick={loading}
      />
    </Paper>
  );
}
