import React, { ChangeEventHandler, useCallback, useMemo, useState } from 'react';

import pluralize from 'pluralize';

import ClearIcon from '@mui/icons-material/Clear';
import { Divider, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';

import { InternalRoom } from '~/services/AccommodationService';

import InternalRoomElement from './InternalRoomElement';

interface Props {
  selectedInternalRooms: Set<string>;
  internalRooms: Array<InternalRoom>;
  alreadyMappedRooms: Record<string, boolean>;
  appliedButNotSavedRooms: Array<InternalRoom>;
  handleInternalRoomSelection: (internalRoomId: string, selected: boolean) => void;
}

export default function UnmappedRooms({
  selectedInternalRooms,
  internalRooms,
  alreadyMappedRooms,
  appliedButNotSavedRooms,
  handleInternalRoomSelection,
}: Props) {
  const [internalFilteringText, setInternalFilteringText] = useState('');
  const resetInternalFilteringText = useCallback(() => {
    setInternalFilteringText('');
  }, []);
  const handleInternalFilteringTextInput = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setInternalFilteringText(event.target.value);
  }, []);

  const filteredInternalRooms = useMemo(() => {
    const internalFilteredRooms = internalFilteringText
      ? internalRooms.filter((room) => {
          return (
            room.name.toLowerCase().includes(internalFilteringText.toLowerCase()) ||
            room.internalId.toLowerCase().includes(internalFilteringText.toLowerCase()) ||
            room.internalService.toLowerCase().includes(internalFilteringText.toLowerCase())
          );
        })
      : internalRooms;

    // Rooms that have been applied but not saved should not be shown in the list
    return internalFilteredRooms.filter(
      (internalRoom) => !appliedButNotSavedRooms.some((r) => r.id === internalRoom.id),
    );
  }, [appliedButNotSavedRooms, internalFilteringText, internalRooms]);

  const selectedFromInternalRooms = useMemo(() => {
    const selectedCount = Array.from(selectedInternalRooms.entries()).length;

    const unmappedInternalRooms = internalRooms.filter((room) => !alreadyMappedRooms[room.id]);

    const totalCount = unmappedInternalRooms.length;

    return selectedCount > 0
      ? `${selectedCount} selected of ${totalCount} unmapped ${pluralize('rooms', totalCount)}`
      : `${totalCount} unmapped ${pluralize('rooms', totalCount)}`;
  }, [alreadyMappedRooms, selectedInternalRooms, internalRooms]);

  return (
    <Stack direction="column" gap={2} position="sticky" top={0} overflow="auto" height="calc(100dvh - 56px)">
      <Paper square sx={{ position: 'sticky', top: 0, zIndex: 1, pt: 1 }}>
        <Stack direction="column" gap={2}>
          <Typography variant="h6">Internal Rooms: {selectedFromInternalRooms}</Typography>
          <Stack direction="column" gap={1}>
            <TextField
              fullWidth
              label="Filter by room name:"
              value={internalFilteringText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={resetInternalFilteringText}
                      disabled={internalFilteringText.length < 1}
                      title="clear"
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onInput={handleInternalFilteringTextInput}
            />
          </Stack>
        </Stack>
        <Divider />
      </Paper>
      <Stack direction="column" gap={2}>
        {filteredInternalRooms?.map(
          (room, ind) =>
            !alreadyMappedRooms[room.internalId] && (
              <InternalRoomElement
                key={`${room.internalId}_${ind}`}
                room={room}
                onSelect={handleInternalRoomSelection}
                isSelected={selectedInternalRooms.has(room.id)}
                internalService={room.internalService}
              />
            ),
        )}
        {internalFilteringText && filteredInternalRooms.length === 0 && (
          <Typography>No rooms found based on filter</Typography>
        )}
      </Stack>
    </Stack>
  );
}
