import React, { useEffect, useState } from 'react';

import ReactJson from '@microlink/react-json-view';
import { useSnackbar } from 'notistack';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';
import Spinner from '~/components/Common/Spinner';

import { BRAINTREE_PAYMENT_TYPE, PROMO_PAYMENT_TYPE } from '~/consts/payment';

import { getPromoById } from '~/services/PromoService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';

interface Props {
  payments: Array<any>;
}

const initiallyExpanded = true;

function OrderDetailPayments({ payments }: Props) {
  const [isExpanded, setExpanded] = useState(initiallyExpanded);
  const [loading, setLoading] = useState(false);
  const [promoCodes, setPromoCodes] = useState<Array<any>>([]);
  const [openDebug, setOpenDebug] = useState(false);
  const [debugJson, setDebugJson] = useState<{ [key: string]: unknown }>({});
  const [showMore, setShowMore] = useState(!initiallyExpanded);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadPromoCodes = async () => {
      try {
        setLoading(true);
        const promoPromises = payments
          .filter((payment) => payment.type === PROMO_PAYMENT_TYPE)
          .map((payment) =>
            getPromoById({
              idPromo: payment.response_json.id_promo,
              brand: payment.brand,
            }),
          );

        const promoCodes = await Promise.all(promoPromises);
        setPromoCodes(promoCodes.map((response) => response.result));
        setLoading(false);
      } catch (err) {
        // just ignore errors for now
      }
    };
    loadPromoCodes();
  }, [payments]);

  const toggleMore = () => {
    setShowMore((prevState) => !prevState);
  };

  const toggleExpanded = (isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const additionalInfo = (row: any) => {
    if (row.stripe_account_id) {
      return `Stripe Account Id: ${row.stripe_account_id}`;
    }

    if (row.type === BRAINTREE_PAYMENT_TYPE) {
      if (row.response_json && row.response_json.transaction && row.response_json.transaction.paypal) {
        return `PayPal Capture Id: ${row.response_json.transaction.paypal.captureId}`;
      }
    }

    if (row.type === PROMO_PAYMENT_TYPE) {
      const promoCode = promoCodes.find((promoCode) => promoCode.id_promo_code === row.response_json.id_promo);
      if (promoCode) {
        return (
          <Link href={`/promos/${promoCode.id_promo_code}`} target="_blank">
            {promoCode.code_name}
          </Link>
        );
      }
    }

    return null;
  };
  const columns: Array<GridColDef> = [
    {
      field: 'id_payment',
      headerName: 'Payment ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'psp_name',
      headerName: 'PSP Name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1.1,
      display: 'flex',
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1.2,
      display: 'flex',
    },
    {
      field: 'intent',
      headerName: 'Intent',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      display: 'flex',
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      sortable: false,
      disableColumnMenu: true,
      flex: 1.5,
      valueFormatter: (value) => formatDateOrdinalWithClock(value),
      display: 'flex',
    },
    {
      field: 'amount',
      headerName: 'Value',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      valueGetter: (_value, row) => currencyFormatter(row.currency, row.amount, 2),
      display: 'flex',
    },
    {
      field: 'currency',
      headerName: 'Currency',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      display: 'flex',
    },
    {
      field: 'failure_reason',
      headerName: 'Failure Reason',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
    },
    ...(showMore
      ? [
          {
            field: 'ext_transaction_id',
            headerName: 'External Transaction Id',
            sortable: false,
            disableColumnMenu: true,
            flex: 3,
            display: 'flex',
            renderCell: (params) => {
              return (
                <Typography
                  overflow="scroll"
                  onClick={() => {
                    navigator.clipboard.writeText(params.value);
                    enqueueSnackbar('External Transaction Id copied to clipboard', { variant: 'success' });
                  }}
                >
                  {params.value}
                </Typography>
              );
            },
          } as GridColDef,
          {
            field: 'response_json',
            headerName: 'Additional Info',
            sortable: false,
            disableColumnMenu: true,
            flex: 2.5,
            renderCell: (params) => additionalInfo(params.row),
            display: 'flex',
          } as GridColDef,
          {
            field: 'button',
            headerName: 'Debug',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            display: 'flex',
            renderCell: (params) => {
              return (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setOpenDebug(true);
                    setDebugJson(params.row.response_json);
                  }}
                >
                  Details
                </Button>
              );
            },
          } as GridColDef,
        ]
      : []),
  ];

  return (
    <>
      <FoldableSection
        title="Transactions history"
        actions={
          isExpanded ? (
            <Button variant="text" size="small" onClick={toggleMore}>
              More details
            </Button>
          ) : undefined
        }
        onChange={toggleExpanded}
        initiallyExpanded={initiallyExpanded}
      >
        {loading && <Spinner size={36} />}
        {!loading && (
          <DataGrid
            columns={columns}
            rows={payments}
            getRowId={(row) => row.id_payment}
            autoHeight
            pageSizeOptions={[]}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={() => 'auto'}
          />
        )}
      </FoldableSection>

      <Dialog open={openDebug} onClose={() => setOpenDebug(false)} maxWidth="md">
        <DialogTitle>Transaction internal details</DialogTitle>

        <DialogContent>
          <ReactJson
            src={debugJson}
            style={{ overflow: 'scroll' }}
            enableClipboard={false} //disabled as not working by default
          />
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={() => setOpenDebug(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default OrderDetailPayments;
