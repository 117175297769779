import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';

import * as libRegions from '@luxuryescapes/lib-regions';

import RankingInfo from '~/components/Common/RankingInfo';
import { FlexTableCell } from '~/components/Content/SearchRanking/FlexTableCell';
import { Vertical } from '~/components/Content/SearchRanking/SearchRankingPage';
import { Text } from '~/components/Experiences/components';

import SearchService, { EvVariant } from '~/services/SearchService';

interface Props {
  offerId: string;
  vertical: Vertical;
}

const ScoreTable = styled(Table)(() => ({
  '& tr': { height: '100%' },
  '& td': { height: '0' },
  '@supports (-moz-appearance: none)': {
    '& td': { height: '100%' },
  },
}));

const ScoreRegionLine = styled(Box)({
  display: 'flex',
  margin: '8px',
});

const RegionSelector = styled(Select)({
  display: 'inline-block',
  width: '250px',
});

const regionCodes = libRegions.getRegions().map((r) => r.code);

const emptyScore: Partial<App.OfferScore> = {
  expectedValue: 0,
  expectedValueScore: 0,
  expectedValueWeight: 1,
  overrideScore: 10,
  overrideWeight: 0,
  overrideExpireDate: '',
  tierScore: 0,
  tierWeight: 0,
  calculatedScore: 0,
};

export default function SearchOrdering({ offerId, vertical }: Props) {
  const [error, setError] = React.useState(null);
  const [score, setScore] = React.useState<Partial<App.OfferScore>>(emptyScore);
  const [region, setRegion] = React.useState(regionCodes[0]);
  const [allVariants, setAllVariants] = useState<Array<EvVariant>>([EvVariant.Current]);
  const [variant, setVariant] = React.useState<EvVariant>(EvVariant.Current);
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    async function fetchScores() {
      if (!offerId) {
        return;
      }

      try {
        const response = await SearchService.getOfferScoreForVariant({
          vertical: 'hotel',
          offerId,
          region,
          variant,
        });
        setScore(response.result.score);
      } catch (error) {
        setError(error);
      }
    }

    fetchScores();
  }, [offerId, variant, region]);

  useEffect(() => {
    const loadVariants = async () => {
      const { result } = await SearchService.getOfferScoreSettings('hotel', 'AU');
      setAllVariants(result?.variants?.variants);
    };

    loadVariants();
  }, []);

  const reload = () => {
    location.reload();
  };

  useEffect(() => {
    const expectedValueWeight = score.tierWeight
      ? score.expectedValueWeight * (1 - score.tierWeight)
      : score.expectedValueWeight;

    setRows([
      {
        attribute: 'Expected Value',
        value: score.expectedValue?.toFixed(3),
        valueInfo: !!score.expectedValue && score.expectedValueInfo,
        score: score.adjustedExpectedValue?.toFixed(3),
        scoreInfo: !!score.adjustedExpectedValue && score.adjustedExpectedValueInfo,
        weight: `${expectedValueWeight * 100}%`,
      },
      {
        attribute: 'Manual Override',
        value: 'N/A',
        score: score.overrideScore,
        weight: `${score.overrideWeight * 100}%`,
      },
    ]);
  }, [region, score]);

  return (
    <Box>
      <h2 className="page-header">
        Search Ordering
        <Link
          to={{
            pathname: `/edit-offers/${vertical}/${offerId}/search-orderings`,
          }}
        >
          <EditIcon className="small" />
          <span className="sr-only">Edit</span>
        </Link>
      </h2>
      {error && (
        <Alert
          severity="error"
          action={
            <Button color="inherit" variant="outlined" onClick={reload}>
              Retry
            </Button>
          }
        >
          {error.message}
        </Alert>
      )}
      {!error && (
        <>
          <ScoreRegionLine>
            <Text size="18px" lineHeight="21px" align="left">
              {'Calculated search score: ' + score.calculatedScore.toFixed(3)}
            </Text>
            <Box display="flex" gap={2}>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Select an Variant</InputLabel>
                <Select
                  required
                  value={variant}
                  label="Select an Variant"
                  onChange={(event: SelectChangeEvent) => setVariant(event.target.value as EvVariant)}
                  renderValue={(selected) => selected}
                >
                  {allVariants.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Radio checked={variant === option} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <RegionSelector
                labelId="offer-search-ordering-region-selector-label"
                id="offer-search-ordering-region-selector"
                defaultValue={region === undefined ? regionCodes[0] : regionCodes.find((code) => code === region)}
                value={region}
                onChange={(event: SelectChangeEvent) => setRegion(event.target.value)}
              >
                {libRegions.getRegions().map((r) => {
                  return <MenuItem key={r.code} value={r.code}>{`Region(${r.code})`}</MenuItem>;
                })}
              </RegionSelector>
            </Box>
          </ScoreRegionLine>
          <ScoreTable className="offer-search-ordering">
            <TableHead>
              <TableRow>
                <TableCell> Attribute </TableCell>
                <TableCell align="right">Raw Value</TableCell>
                <TableCell align="right">Adjusted Value</TableCell>
                <TableCell align="right">Weighting</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.attribute} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.attribute}
                  </TableCell>
                  <FlexTableCell align="right">
                    {row.value}
                    {row.valueInfo && (
                      <Tooltip title={<RankingInfo info={row.valueInfo} />} placement="top" arrow>
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </FlexTableCell>
                  <FlexTableCell align="right">
                    {row.score}
                    {row.scoreInfo && (
                      <Tooltip title={<RankingInfo info={row.scoreInfo} />} placement="top" arrow>
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </FlexTableCell>
                  <TableCell align="right">{row.weight}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </ScoreTable>
        </>
      )}
    </Box>
  );
}
